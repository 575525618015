import React, {useState} from "react";
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {connect, useSelector} from "react-redux";
import {OpenModal} from "../../../redux/Common/action";
import {OPEN_EXTEND_SUBSCRIPTIONS_MODAL} from "../../../redux/ModalReducer/actionTypes";
import {RootState} from "../../../redux/store";
import TextField from "../../InputComponents/TextField/RenderTextField";
import moment from "moment";

const ExtendSubscriptionModal = ({OpenModal}: any) => {
    const modal:any = useSelector((store: RootState) => store?.modal?.extendSubscriptionModal) || {};
    const [isLoading, setLoading] = useState(false);
    const [expiry_date, setExpiryDate] = useState();
    const handleClose = () => {
        OpenModal(OPEN_EXTEND_SUBSCRIPTIONS_MODAL, {isOpen: false});
    };

    const handleExpiryDateChange = async (e) => {
        const value = e.target.value;
        setExpiryDate(value);
    };

    const handleSubmit = async () => {
        const moment = (await import("moment")).default;
        setLoading(true);
        modal.callBack(true, expiry_date ? moment(expiry_date).format("YYYY-MM-DD") : "");
    };

    return <Dialog maxWidth={modal?.data?.modalSize || "sm"}
                   fullWidth={modal?.data?.fullWidth || true}
                   onClose={handleClose}
                   open={modal?.isOpen || false}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
                   disableBackdropClick={modal?.data?.disableBackdropClick || false}>
        <DialogTitle id="alert-dialog-title">{modal?.title}</DialogTitle>
        <DialogContent className="mb-5">
            <TextField id="expiry_date"
                       type="date"
                       value={expiry_date}
                       defaultValue={moment(modal?.data?.expiry_date).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD")}
                       onChange={handleExpiryDateChange}
                       label="Expiry date"/>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                {modal?.cancelTitle || "Cancel"}
            </Button>
            <Button onClick={handleSubmit} className="ml-3" color="primary" variant="contained"
                    disabled={isLoading || !expiry_date}>
                {isLoading ?
                    <CircularProgress size="1.3rem"
                                      className="circular-progress-primary ml-0"/> : modal?.submitTitle || "Ok"}
            </Button>
        </DialogActions>
    </Dialog>;
};

export default connect(null, {OpenModal})(React.memo(ExtendSubscriptionModal));