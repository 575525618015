import React from 'react';
import style from './Styles.module.scss';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputLabel } from '@material-ui/core';
import DateUtils from '@date-io/moment';

const RenderDateAndTimePicker = ({
    id,
    name,
    label,
    showInputLabel,
    value,
    format,
    defaultValue,
    removeMarginAndPadding,
    disabled,
    containerClassName,
    inputClassName,
    onChange,
    helperText,
    error,
    disablePast,
    disableFuture,
    minDate
}: any) => {
    return (
        <div className={style.inputContainer}>
            {showInputLabel ? (
                <InputLabel shrink htmlFor={id} className={style.inputLabels}>
                    {label}
                </InputLabel>
            ) : null}
            <MuiPickersUtilsProvider utils={DateUtils}>
                <DateTimePicker
                    autoOk
                    ampm
                    id={id}
                    name={name}
                    disabled={disabled}
                    label={!showInputLabel ? label : ''}
                    fullWidth
                    value={value}
                    format={format}
                    disablePast={disablePast}
                    disableFuture={disableFuture}
                    className={containerClassName}
                    helperText={error ? helperText : ''}
                    error={error}
                    inputVariant="outlined"
                    minDate={minDate}
                    inputProps={{
                        className: `${inputClassName}
                            ${
                                removeMarginAndPadding
                                    ? style.normalMarginAndPadding
                                    : style.datePicker
                            }`
                    }}
                    defaultValue={defaultValue}
                    onChange={(d) => onChange(d)}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};
export default RenderDateAndTimePicker;
