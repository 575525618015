import React, {useEffect, useState} from "react";
import style from "./Styles.module.scss";
import {InputLabel, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";

interface DataArray {
    title: string,
    value?: string | number,
    id?: string | number
}

interface IRenderCreatableAutocompleteWithoutFocusProps {
    id?: string,
    label: string | JSX.Element,
    value?: any,
    variant?: any,
    className?: string,
    filterSelectedOptions?: boolean,
    disableCloseOnSelect?: boolean,
    options: DataArray[],
    inputPlaceholder?: string,
    inputAutoComplete?: string,
    error?: boolean,
    disableClearable?: boolean,
    openOnFocus?: boolean,
    hideLabel?: boolean,
    helperText?: string,
    paperClass?: string,
    onChange?: (e: object, value: any) => void
    onKeyDown?: (e: object) => void
    handleInputChange?: (e: object) => void,
    onInput?: (e: object) => void,
    onBlur?: (e: object) => void
}

const RenderCreatableAutocompleteWithoutFocus = ({
                                                     id, options, filterSelectedOptions = true, label, value, onBlur, disableClearable = false,
                                                     disableCloseOnSelect = true, className, variant, onChange, inputPlaceholder,
                                                     error, helperText, paperClass, hideLabel, onKeyDown, inputAutoComplete, openOnFocus, handleInputChange
                                                 }: IRenderCreatableAutocompleteWithoutFocusProps) => {
    const [filtered, setFiltered] = useState([]);
    const [inputValue, setInputValue] = React.useState("");
    const [inputOpen, setOpen] = React.useState(false);
    const [hasInputFocus, setFocus] = React.useState(openOnFocus);

    useEffect(() => {
        setFiltered(options);
        setOpen(true);
    }, [options]);

    const handleInputFocus = (flag) => {
        setFocus(flag);
    };
    const handleInputOpen = () => {
        if (inputValue.length > 0) {
            setOpen(true);
        }
    };

    const onInputChange = (event) => {
        const newInputValue = event?.target?.value;
        setInputValue(newInputValue);
        if (newInputValue.length > 0) {
            setFiltered([]);
            handleInputChange(newInputValue.toLowerCase());
        } else {
            setOpen(false);
        }
    };


    return <div className={style.inputContainer}>
        {!hideLabel ? <InputLabel htmlFor={id} className={style.inputLabels}>
            {label}
        </InputLabel> : null}

        <Autocomplete id={id}
                      freeSolo
                      selectOnFocus
                      handleHomeEndKeys
                      disableClearable={disableClearable}
                      value={value}
                      classes={{paper: paperClass}}
                      onOpen={handleInputOpen}
                      onFocus={() => handleInputFocus(true)}
                      open={inputValue && inputOpen && hasInputFocus}
                      onInput={onInputChange}
                      className={className ? className : style.inputFields}
                      options={filtered && filtered.length ? filtered : []}
                      disableCloseOnSelect={disableCloseOnSelect}
                      filterSelectedOptions={filterSelectedOptions}
                      onChange={(e, data) => {
                          setOpen(false);
                          onChange(e, data);
                      }}
                      onBlur={(e) => {
                          handleInputFocus(false);
                          onBlur(e);
                      }}
            /*filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== "") {
                    filtered.push({
                        value: params.inputValue,
                        title: params.inputValue
                    });
                }

                return filtered;
            }}*/
                      getOptionLabel={(option) => {
                          // e.g value selected with enter, right from the input
                          if (typeof option === "string") {
                              return option;
                          }
                          if (option?.title) {
                              return option?.title;
                          }
                          return option?.title;
                      }}
                      renderInput={(params) => <TextField {...params}
                                                          error={error}
                                                          helperText={helperText}
                                                          onKeyDown={onKeyDown}
                                                          autoComplete={inputAutoComplete}
                                                          variant={variant ? variant : "outlined"}
                                                          placeholder={inputPlaceholder ? inputPlaceholder : "Search"}/>}/>
    </div>;
};
export default RenderCreatableAutocompleteWithoutFocus;