import { Tab, Tabs, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { FirmsTab, ROWS_PER_PAGE, Routes } from '../../constants';
import {
    CommonDispatcher,
    GetFirmsClassify,
    GetInvestmentsTypeMaster
} from '../../redux/Common/action';
import { GetFirmsList } from '../../redux/Firms/actions';
import { RootState } from '../../redux/store';
import { getQuery } from '../../utils/common';
import { getSelectedTab } from '../Common/commonUtils';
import ActiveComponent from './Active';
import AllFirmsComponent from './AllFirms';
import ImportFirmsModal from './ImportFirmsModal';
import InActiveComponent from './InActive';
import ShowUserModal from './ShowUserModal';
import { FIRMS_ALL } from '../../redux/Firms/actionTypes';

const Firms = ({
    GetFirmsList,
    GetInvestmentsTypeMaster,
    GetFirmsClassify,
    CommonDispatcher
}) => {
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isTabLoading, setIsTabLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const firms_counts = useSelector(
        (store: RootState) => store?.firms.firms_counts
    );

    const query = getQuery(history);
    const type = query.get('type') || '';
    const keyword = query.get('keyword');
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const filterParam = searchParams.get('filter');
    let decodedFilters;
    if (filterParam) {
        decodedFilters = JSON.parse(decodeURIComponent(filterParam));
    }

    const showUserModal = useSelector(
        (store: RootState) => store?.modal?.showUserModal
    );
    const importFirmModal = useSelector(
        (store: RootState) => store?.modal?.importFirmModal
    );

    const handleTabLoading = (data) => {
        setIsTabLoading(data);
    };
    useEffect(() => {
        if (keyword || type || type === '' || decodedFilters) {
            setIsFilterApplied(true);
            getData({
                keyword,
                type,
                tab: getSelectedTab(history?.location?.pathname)
            });
        }
    }, [keyword, type, filterParam]);

    useEffect(() => {
        [FirmsTab.TYPE_ACTIVE, FirmsTab.TYPE_IN_ACTIVE, FIRMS_ALL].forEach(
            (tab) => {
                getData({ page: 1, tab }, true, false);
            }
        );
        GetInvestmentsTypeMaster();
        GetFirmsClassify();
    }, []);

    const getAllTabsData = (data) => {
        if (!data?.tab) {
            getData({ data }, false);
        }
        [FirmsTab.TYPE_ACTIVE, FirmsTab.TYPE_IN_ACTIVE].forEach((tab) => {
            getData({ page: 1, tab }, false);
        });
    };
    const getSelectedTabData = (data) => {
        getData({
            data,
            keyword,
            tab: getSelectedTab(history?.location?.pathname)
        });
    };

    const getData = (data, isLoading = true, setReduxListing = true) => {
        data.size = ROWS_PER_PAGE;
        if (filterParam) {
            data.filter = decodedFilters;
        }
        setIsLoading(isLoading);
        GetFirmsList(token, data, setReduxListing).then(() => {
            setIsLoading(false);
            setIsTabLoading(false);
            setTab();
        });
    };

    const setTab = () => {
        switch (history.location?.pathname) {
            case Routes.firms.active:
                setValue(1);
                break;
            case Routes.firms.inactive:
                setValue(2);
                break;
            default:
            case Routes.firms.firms:
                setValue(0);
                break;
        }
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        let pathname;
        switch (newValue) {
            case 1:
                pathname = Routes.firms.active;
                break;
            case 2:
                pathname = Routes.firms.inactive;
                break;
            case 0:
            default:
                pathname = Routes.firms.firms;
                break;
        }
        setIsTabLoading(true);
        history.push(pathname);
    };

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <>
            {showUserModal?.isOpen ? <ShowUserModal title="Firms" /> : null}
            {importFirmModal?.isOpen ? (
                <ImportFirmsModal title="Show to user Modal" />
            ) : null}
            <Tabs
                value={value}
                onChange={(e, v) => handleChange(v)}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                classes={{
                    flexContainer: !isSmallScreen
                        ? 'justify-content-center'
                        : ''
                }}
            >
                <Tab
                    label={`All Firms (${firms_counts?.allFirmCount})`}
                    className="text-inherit"
                    disabled={isTabLoading}
                    disableRipple={true}
                    disableFocusRipple={true}
                />

                <Tab
                    label={`Active (${firms_counts?.activeCount})`}
                    className="text-inherit"
                    disabled={isTabLoading}
                    disableRipple={true}
                    disableFocusRipple={true}
                />

                <Tab
                    label={`InActive (${firms_counts?.inActiveCount})`}
                    className="text-inherit"
                    disabled={isTabLoading}
                    disableRipple={true}
                    disableFocusRipple={true}
                />
            </Tabs>
            <>
                <Route
                    path={Routes.firms.firms}
                    exact
                    render={() => (
                        <AllFirmsComponent
                            sectionBaseUrl={Routes.firms.firms}
                            loading={isLoading}
                            isTabLoading={isTabLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            getSelectedTabData={getSelectedTabData}
                            handleTabLoading={handleTabLoading}
                        />
                    )}
                />
                <Route
                    path={Routes.firms.active}
                    exact
                    render={() => (
                        <ActiveComponent
                            sectionBaseUrl={Routes.firms.active}
                            loading={isLoading}
                            isTabLoading={isTabLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            getSelectedTabData={getSelectedTabData}
                            handleTabLoading={handleTabLoading}
                        />
                    )}
                />
                <Route
                    path={Routes.firms.inactive}
                    exact
                    render={() => (
                        <InActiveComponent
                            sectionBaseUrl={Routes.firms.inactive}
                            loading={isLoading}
                            isTabLoading={isTabLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                            getSelectedTabData={getSelectedTabData}
                            handleTabLoading={handleTabLoading}
                        />
                    )}
                />
            </>
        </>
    );
};
export default connect(null, {
    GetFirmsList,
    GetInvestmentsTypeMaster,
    GetFirmsClassify,
    CommonDispatcher
})(Firms);
