import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    Avatar,
    Checkbox,
    Chip,
    CircularProgress,
    FormControlLabel,
    List,
    ListItem,
    Switch,
    Table
} from '@material-ui/core';
import { useCookies } from 'react-cookie';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { connect } from 'react-redux';
import TablePagination from '@material-ui/core/TablePagination';
import {
    GetAccessUsersList,
    GetMatchingUsersCount
} from '../../../../redux/Deals/actions';
import style from './Style.module.scss';

const MatchingUsers = ({
    checked,
    setChecked,
    selected,
    setSelected,
    isFilterLoading,
    equity_id,
    filter,
    usersList,
    matchingUsersList = [],
    GetAccessUsersList,
    GetMatchingUsersCount
}) => {
    const [keyword, setKeyword] = React.useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);

    const [matchingUsers, setMatchingUsers] = useState([]);
    const [toggleCheckUncheckAll, setToggleCheckUncheckAll] = useState(true);
    const delayedQuery = useCallback(
        _.debounce((k) => handleChange(k), 500),
        []
    );
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [page, setPage] = useState(usersList?.page || 1);
    const rowsPerPage = 30;

    useEffect(() => {
        setIsUsersLoading(isFilterLoading);
    }, [isFilterLoading]);

    useEffect(() => {
        setMatchingUsers(matchingUsersList);
    }, [matchingUsersList]);

    useEffect(() => {
        setIsUsersLoading(true);
        getAccessList();
    }, []);

    const getAccessList = () => {
        GetAccessUsersList(token, { equity_id }).then((r) => {
            const accessList = r?.response?.deal_accesses;
            if (accessList && accessList.length) {
                const matchedUsers = accessList.map((d) => d?.user_id);
                setChecked(matchedUsers);
                setToggleCheckUncheckAll(true);
            } else {
                setChecked([]);
                setToggleCheckUncheckAll(false);
            }
            setIsUsersLoading(false);
            setIsSearchLoading(false);
        });
    };

    const doSearch = (e: any) => {
        const value = e?.target?.value;
        setKeyword(value);
        delayedQuery(value);
    };

    const handleChange = (keyword = '') => {
        setIsSearchLoading(true);
        GetMatchingUsersCount(token, { keyword, filter }).then((r) => {
            getAccessList();
            setMatchingUsers(r?.response?.users);
            console.log(r?.response);
        });
    };

    const handleToggle = (value) => () => {
        const currentIndex = selected.indexOf(value);
        const newChecked = [...selected];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelected(newChecked);
    };

    const handleToggleCheckUncheckAll = (e, toggle) => {
        setToggleCheckUncheckAll(toggle);
        setSelected(toggle ? usersList?.users.map((d) => d?.user_id) : []);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        getData({ page: newPage + 1, filter });
    };

    const getData = (data) => {
        setIsUsersLoading(true);
        GetMatchingUsersCount(token, data).then((countResp) => {
            // setMatchingUsers(countResp?.response);
            setIsUsersLoading(false);
        });
    };

    return (
        <List>
            <ListItem role={undefined} dense>
                <div className="d-flex align-items-center w-100">
                    <input
                        type="text"
                        autoComplete="false"
                        onChange={doSearch}
                        value={keyword}
                        placeholder={'Search your contact'}
                        tabIndex={-1}
                        className={style.searchBar}
                    />
                    {isSearchLoading ? (
                        <span className="ml-3">
                            <CircularProgress
                                size="1.3rem"
                                className="circular-progress-primary"
                            />
                        </span>
                    ) : null}
                </div>
            </ListItem>
            {isUsersLoading ? (
                <div className="loader" />
            ) : matchingUsers && matchingUsers.length ? (
                <>
                    <ListItem role={undefined} dense className="my-3">
                        <FormControlLabel
                            control={
                                <Switch
                                    defaultChecked={toggleCheckUncheckAll}
                                    value={toggleCheckUncheckAll}
                                    color="primary"
                                />
                            }
                            label={
                                toggleCheckUncheckAll
                                    ? 'Uncheck All'
                                    : 'Check All'
                            }
                            onChange={handleToggleCheckUncheckAll}
                        />
                    </ListItem>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                {matchingUsers.map((d) => {
                                    const userExists =
                                        checked.indexOf(d?.user_id) !== -1;
                                    const selectedUserExists =
                                        selected.indexOf(d?.user_id) !== -1;
                                    return (
                                        <TableRow
                                            key={d?.user_id}
                                            className="cursor-pointer"
                                            onClick={handleToggle(d?.user_id)}
                                        >
                                            <TableCell className="border-0 py-2 pr-0">
                                                <Checkbox
                                                    edge="start"
                                                    checked={
                                                        userExists ||
                                                        selectedUserExists
                                                    }
                                                    tabIndex={-1}
                                                    onChange={handleToggle(
                                                        d?.user_id
                                                    )}
                                                    color="primary"
                                                    disableRipple
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            d?.user_id + ''
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell className="border-0 py-2 pr-0">
                                                <Avatar
                                                    alt={d?.full_name}
                                                    src={d?.profile_picture}
                                                    className={style.avatar}
                                                />
                                            </TableCell>
                                            <TableCell className="border-0 py-2 d-flex gap-1">
                                                <div className="d-flex flex-direction-column">
                                                    <span className="font-weight-bold">
                                                        {d?.full_name}
                                                    </span>
                                                    <span>{d?.email}</span>
                                                </div>
                                                <Chip
                                                    size="small"
                                                    className={
                                                        userExists
                                                            ? 'visible'
                                                            : 'invisible'
                                                    }
                                                    label={'Shared'}
                                                    color={'primary'}
                                                />
                                                <Chip
                                                    size="small"
                                                    className={
                                                        selectedUserExists &&
                                                        !userExists
                                                            ? 'visible'
                                                            : 'invisible'
                                                    }
                                                    label={'Selected'}
                                                    color={'secondary'}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        hidden={matchingUsers && !matchingUsers.length}
                        rowsPerPageOptions={[]}
                        component="div"
                        count={matchingUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                    />
                </>
            ) : (
                <ListItem role={undefined} dense className="my-0 fs-14 my-5">
                    No users found.
                </ListItem>
            )}
        </List>
    );
};
export default connect(null, { GetAccessUsersList, GetMatchingUsersCount })(
    MatchingUsers
);
