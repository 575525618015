import * as React from "react";
import style from "./RenderSelectField.module.scss";
import {createStyles, FormControl, Input, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";

interface DataArray {
    title: string,
    value?: string | number,
    id?: string | number,
    disabled?: boolean,
    selected?: boolean
}

interface IRenderSelectFieldProps {
    id?: string,
    inputId?: string,
    Icon?: any,
    label?: string | JSX.Element,
    value?: any,
    className?: string,
    defaultValue?: any,
    variant?: any,
    dropdownListHeight?: number,
    dataArray: DataArray[],
    autoComplete?: string,
    onFocus?: (e: object) => void
    onChange?: (e: object) => void
    onBlur?: (e: object) => void
    error?: boolean
    disabled?: boolean
    helperText?: string
    hideLabel?: boolean
    removeLabel?: boolean
    formControlClassName?: string
    inputContainerClassName?: string
}

const RenderSelectField = ({
                               helperText, error, id, inputId, dropdownListHeight, defaultValue, dataArray, label, value,
                               className, variant, onChange, onFocus, onBlur, formControlClassName, inputContainerClassName,
                               hideLabel, disabled, removeLabel
                           }: IRenderSelectFieldProps) => {
    const useStyles = makeStyles(() =>
        createStyles({
            menuPaper: {
                maxHeight: dropdownListHeight ? dropdownListHeight : "unset"
            }
        })
    );

    const classes = useStyles();
    return <div className={`${style.inputContainer} ${inputContainerClassName}`}>
        {!removeLabel ?
            <InputLabel shrink htmlFor={id} className={`${style.inputLabels} ${hideLabel ? "invisible" : "visible"}`}>
                {label}
            </InputLabel> : null}
        <FormControl disabled={disabled}
                     className={`${style.inputFields} ${formControlClassName} ${error ? style.error : ""}`}>
            <Select id={id}
                    value={value || ""}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    defaultValue={defaultValue}
                    variant={variant || "outlined"}
                    className={`${className} ${style.dropdown}`}
                    input={<Input id={inputId}/>}
                    MenuProps={{classes: {paper: classes.menuPaper}}}
                    SelectDisplayProps={{id}}>
                {dataArray && dataArray.length ? dataArray.map((d) => <MenuItem key={d.value || d.id}
                                                                                value={d.value || d.id}
                                                                                className={style.menuItem}
                                                                                selected={d.selected}
                                                                                disabled={d.disabled}>
                        {d.title}
                    </MenuItem>
                ) : null}
            </Select>
        </FormControl>
        {error ? <InputLabel error={true} className="mx-0">{helperText}</InputLabel> : null}
    </div>;
};
export default RenderSelectField;