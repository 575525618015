import axios from 'axios';
import { API_PATH } from '../../constants';
import {
    CREATE_METADATA,
    DELETE_METADATA,
    FETCH_METADATA_LIST,
    FETCH_SINGLE_METADATA,
    UPDATE_METADATA
} from './actionTypes';

export const createMetadata = (token, metadata) => async (dispatch) => {
    try {
        const response = await axios.post(
            `${API_PATH}/admin/metadata`,
            metadata,
            {
                headers: { Authorization: token }
            }
        );
        dispatch({
            type: CREATE_METADATA,
            payload: response.data?.data
        });
        return response;
    } catch (error) {
        console.error('Error creating metadata:', error);
        return { type: 'failed', response: error?.response };
    }
};
export const updateMetadata = (token, metadata) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${API_PATH}/admin/metadata`,
            metadata,
            {
                headers: { Authorization: token }
            }
        );
        dispatch({
            type: UPDATE_METADATA,
            payload: metadata
        });
        return { type: 'success', response };
    } catch (error) {
        console.error('Error creating metadata:', error);
        return { type: 'failed', response: error?.response };
    }
};
export const GetEcoSystemType = (token) => async (dispatch) => {
    try {
        // const response = await axios.get(`${API_PATH}/admin/ecosystem-types`, {
        //     headers: { Authorization: token }
        // });
        const response = await axios.get(`${API_PATH}/admin/ecosystem-types`, {
            headers: { Authorization: token }
        });
        // dispatch({
        //     type: UPDATE_METADATA,
        //     payload: metadata
        // });
        return response;
    } catch (error) {
        console.error('Error creating metadata:', error);
        return { type: 'failed', response: error?.response };
    }
};
export const UpdateEcoSystemType = (token, metadata) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${API_PATH}/admin/ecosystem-types`,
            metadata,
            {
                headers: { Authorization: token }
            }
        );
        return response;
    } catch (error) {
        console.error('Error creating metadata:', error);
        return { type: 'failed', response: error?.response };
    }
};
export const DeleteMetaData = (token, data) => async (dispatch) => {
    try {
        const config = {
            method: 'delete',
            data,
            url: `${API_PATH}/admin/metadata`,
            headers: { Authorization: token }
        };
        const response = (await axios(config as any))?.data?.data;
        dispatch({
            type: DELETE_METADATA,
            payload: data
        });

        return { type: 'success', response };
    } catch (error) {
        console.error('Error creating metadata:', error);
        return { type: 'failed', response: error?.response };
    }
};

export const fetchMetadataList =
    (token, data = {}) =>
        async (dispatch) => {
            try {
                const config = {
                    method: 'post',
                    url: `${API_PATH}/admin/metadata/list`,
                    headers: {
                        Authorization: token
                    },
                    data
                };
                const payload = (await axios(config as any))?.data?.data;
                dispatch({
                    type: FETCH_METADATA_LIST,
                    payload: payload
                });
            } catch (error) {
                console.error('Error fetching metadata list:', error);
                return { type: 'failed', response: error?.response };
            }
        };

export const fetchSingleMetadata = (token, id) => async (dispatch) => {
    try {
        const response = await axios.get(`${API_PATH}/admin/metadata/${id}`, {
            headers: { Authorization: token }
        });
        dispatch({
            type: FETCH_SINGLE_METADATA,
            payload: response.data
        });
    } catch (error) {
        console.error('Error fetching single metadata:', error);
        return { type: 'failed', response: error?.response };
    }
};

export const UploadMetaData = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/upload-picture`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            console.log(error);
            return { type: 'failed', response: error?.response };
        }
    };
};
