import React, { useState } from 'react';
import {
    Button,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { TOGGLE_SUBMIT_ALERT_MODAL } from '../../../../redux/ModalReducer/actionTypes';
import { OpenModal } from '../../../../redux/Common/action';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';

interface IAlertModalProps {
    OpenModal: (type: string, payload: object) => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        dialogContainer: { maxWidth: '600px' }
    })
);

const SubmitAlertModal = ({ OpenModal }: IAlertModalProps) => {
    const classes = useStyles();
    const alertModal: any =
        useSelector((store: RootState) => store?.modal?.submitAlertModal) || {};
    const [isLoading, setLoading] = useState(false);
    const [messageCharLimit] = React.useState(
        alertModal?.messageCharLimit || 200
    );
    const [messageCharCount, setMessageCharCount] = React.useState(0);
    const [note, setNote] = React.useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClose = () => {
        OpenModal(TOGGLE_SUBMIT_ALERT_MODAL, { isOpen: false });
    };

    const handleYes = () => {
        setLoading(true);
        alertModal.callBack(true, note);
    };

    const limit = messageCharLimit - messageCharCount;

    const handleChange = (e) => {
        let value = e?.target?.value;
        value = value.substr(0, messageCharLimit);
        setMessageCharCount(value.length);
        setNote(value);
        if (value && value.length === messageCharLimit) {
            return;
        }
    };

    return (
        <Dialog
            maxWidth={'sm'}
            fullScreen={fullScreen}
            fullWidth={true}
            onClose={handleClose}
            open={alertModal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={
                alertModal?.data?.disableBackdropClick || false
            }
            classes={{ paperScrollPaper: classes.dialogContainer }}
        >
            <DialogTitle id="alert-dialog-title">
                {alertModal?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {alertModal?.description}
                    {alertModal?.subDescription ? (
                        <div className="my-4">{alertModal?.subDescription}</div>
                    ) : null}

                    <p className="my-0 float-right colorSecondary">
                        {limit} characters left
                    </p>
                    <RenderTextField
                        id="title"
                        rows={5}
                        placeholder="Add Note"
                        max={messageCharLimit}
                        value={note}
                        multiline={true}
                        onChange={handleChange}
                        onKeyPress={handleChange}
                        autoFocus={true}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button onClick={handleClose} color="primary" autoFocus>
                    {alertModal?.cancelTitle || 'Cancel'}
                </Button>
                <Button
                    onClick={handleYes}
                    className="ml-3"
                    color={alertModal?.isDelete ? 'secondary' : 'primary'}
                    variant="contained"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress-primary ml-0"
                        />
                    ) : (
                        alertModal?.submitTitle || 'Ok'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, { OpenModal })(SubmitAlertModal);
