import {
    Button,
    CircularProgress,
    FormControlLabel,
    FormHelperText,
    Grid
} from '@material-ui/core';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import useStateRef from 'react-usestateref';
import {
    ChangeAdminPassword,
    ChangeSingleUserPassword,
    CommonDispatcher
} from '../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../redux/Common/actionTypes';
import { CHANGE_PASSWORD_MODAL } from '../../redux/ModalReducer/actionTypes';
import RenderCheckboxComponent from '../InputComponents/CheckboxComponent';
import RenderTextField from '../InputComponents/TextField/RenderTextField';
import { validate, validateWithMasterPassword } from './validate';
import { Routes } from '../../constants';
import moment from 'moment';

const ChangePassword = ({
    ChangeSingleUserPassword,
    CommonDispatcher,
    ChangeAdminPassword,
    user = null,
    isAdmin = false
}) => {
    const [cookies, setCookie, removeCookie] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setError] = useStateRef({}) as [any, any, any];

    const { control, getValues } = useForm({
        mode: 'onChange',
        shouldUnregister: false
    });

    const onSubmit = (e = null) => {
        e && e.preventDefault();
        let data: any = getValues();

        const err = isAdmin ? validateWithMasterPassword(data) : validate(data);
        setError(err);

        let payload = isAdmin
            ? {
                  new_password: data?.newPassword,
                  old_password: data?.currentPassword,
                  master_password: data?.master_password,
                  sing_out_from_devices: data?.sing_out_from_devices
              }
            : {
                  user_id: user?.user_id,
                  new_password: data?.newPassword,
                  admin_password: data?.currentPassword
              };
        const API = isAdmin ? ChangeAdminPassword : ChangeSingleUserPassword;

        if (Object.keys(err).length) {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Please fill the mandatory fields.',
                error: true,
                type: 'error',
                variant: 'filled'
            });
        } else {
            setIsLoading(true);
            API(token, payload).then((res) => {
                if (res?.type === 'success') {
                    setIsLoading(false);
                    CommonDispatcher(CHANGE_PASSWORD_MODAL, { isOpen: false });
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            res.response.message ||
                            'Password updated successfully.'
                    });
                    if (isAdmin) {
                        const oneYearFromNow = new Date();
                        oneYearFromNow.setFullYear(
                            oneYearFromNow.getFullYear() + 1
                        );
                        setCookie('pgAdminToken', '', {
                            path: Routes.home,
                            expires: oneYearFromNow
                        });
                        removeCookie('pgAdminToken', { path: Routes.home });
                    }
                } else if (
                    res?.type === 'failed' &&
                    res?.response?.data?.error
                ) {
                    setIsLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            res?.response?.data?.error ||
                            'Failed to update the Password.',
                        hideAlert: true,
                        error: true
                    });
                } else {
                    setIsLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Failed to update the Password.',
                        hideAlert: true,
                        error: true
                    });
                }
            });
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className="d-flex align-items-center w-100 fs-16">
                        You can change the user password if the user has
                        forgotton the password for their account.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="currentPassword"
                        render={(field) => (
                            <RenderTextField
                                value={field?.value}
                                autoComplete="off"
                                label={
                                    isAdmin
                                        ? 'Current password *'
                                        : 'Admin Password *'
                                }
                                inputContainerClassName={'w-100'}
                                onChange={(e: any) =>
                                    field?.onChange(e?.target?.value)
                                }
                            />
                        )}
                        control={control}
                        defaultValue={null}
                    />
                    <FormHelperText
                        error={true}
                        className={
                            errors?.currentPassword ? 'visible' : 'invisible'
                        }
                    >
                        {isAdmin
                            ? 'Current password is mandatory.'
                            : 'Admin password is mandatory.'}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="newPassword"
                        render={(field) => (
                            <RenderTextField
                                value={field?.value}
                                autoComplete="off"
                                label="New password *"
                                inputContainerClassName={'w-100'}
                                onChange={(e: any) =>
                                    field?.onChange(e?.target?.value)
                                }
                            />
                        )}
                        control={control}
                        defaultValue={null}
                    />
                    <FormHelperText
                        error={true}
                        className={
                            errors?.newPassword ? 'visible' : 'invisible'
                        }
                    >
                        New password is mandatory.
                    </FormHelperText>
                </Grid>
                {isAdmin ? (
                    <Grid item xs={12}>
                        <Controller
                            name="master_password"
                            render={(field) => (
                                <RenderTextField
                                    value={field?.value}
                                    autoComplete="off"
                                    label="Master Password *"
                                    inputContainerClassName={'w-100'}
                                    onChange={(e: any) =>
                                        field?.onChange(e?.target?.value)
                                    }
                                />
                            )}
                            control={control}
                            defaultValue={null}
                        />
                        <FormHelperText
                            error={true}
                            className={
                                errors?.master_password
                                    ? 'visible'
                                    : 'invisible'
                            }
                        >
                            Master password is mandatory.
                        </FormHelperText>
                    </Grid>
                ) : null}
                {/* <Grid item xs={12} className="py-0">
                    <Controller
                        control={control}
                        name="sendCredentials"
                        render={(field) => (
                            <FormControlLabel
                                label={
                                    <div className="fs-14">
                                        Send credentials via email.
                                    </div>
                                }
                                labelPlacement="end"
                                control={
                                    <>
                                        <RenderCheckboxComponent
                                            checked={field?.value}
                                            onChange={(e: any) =>
                                                field?.onChange(
                                                    e?.target?.checked
                                                )
                                            }
                                            color="secondary"
                                        />
                                    </>
                                }
                            />
                        )}
                        defaultValue={false}
                    />
                </Grid>
                <Grid item xs={12} className="py-0">
                    <Controller
                        control={control}
                        name="forceToChangePassword"
                        render={(field) => (
                            <FormControlLabel
                                label={
                                    <div className="fs-14">
                                        Force user to change password on first
                                        log in
                                    </div>
                                }
                                labelPlacement="end"
                                control={
                                    <>
                                        <RenderCheckboxComponent
                                            checked={field?.value}
                                            onChange={(e: any) =>
                                                field?.onChange(
                                                    e?.target?.checked
                                                )
                                            }
                                            color="secondary"
                                        />
                                    </>
                                }
                            />
                        )}
                        defaultValue={false}
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <div className="d-flex flex-direction-column row-gap-10">
                        <div>{`Name : ${user?.full_name}`}</div>
                        <div>{`Email : ${user?.email}`}</div>
                        <div>
                            {`Last Update : ${moment(user?.updatedAt).format(
                                'DD MMM, YYYY hh:mm A'
                            )}`}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} className="py-0">
                    <Controller
                        control={control}
                        name="sing_out_from_devices"
                        render={(field) => (
                            <FormControlLabel
                                label={
                                    <div className="fs-14">
                                        Sign out from all devices (Recommended)
                                    </div>
                                }
                                labelPlacement="end"
                                control={
                                    <>
                                        <RenderCheckboxComponent
                                            checked={field?.value}
                                            onChange={(e: any) =>
                                                field?.onChange(
                                                    e?.target?.checked
                                                )
                                            }
                                            color="secondary"
                                        />
                                    </>
                                }
                            />
                        )}
                        defaultValue={false}
                    />
                </Grid>
                <Grid item xs={12} className="py-0 mt-5">
                    <Button
                        color="primary"
                        variant="contained"
                        className={`text-inherit`}
                        disabled={isLoading}
                        onClick={onSubmit}
                    >
                        Change
                        {isLoading ? (
                            <CircularProgress
                                size="1.3rem"
                                className="circular-progress"
                            />
                        ) : null}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
export default connect(null, {
    ChangeSingleUserPassword,
    CommonDispatcher,
    ChangeAdminPassword
})(ChangePassword);
