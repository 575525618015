import {
    Button,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { Add, Delete } from '@material-ui/icons';
import { UpdatePost } from '../Common';
import { RootState } from '../../../../redux/store';
import { useCookies } from 'react-cookie';
import { UpdateDeal } from '../../../../redux/Deals/actions';
import {
    SET_STARTUP_EQUITY_DEAL_DATA,
    UPDATE_WIZARD_FORM_DATA
} from '../../../../redux/Deals/actionTypes';
import { isValidURL } from '../../../../utils/common';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import RenderCreatableAutocomplete from '../../../InputComponents/CreatableAutocomplete';
import {
    CommonDispatcher,
    UploadImages
} from '../../../../redux/Common/action';

const linksTypeList = [
    { value: 'Investment Intro', title: 'Investment Intro' },
    { value: 'Investment Deck', title: 'Investment Deck' },
    { value: 'Financial Model', title: 'Financial Model' },
    { value: 'Data Room', title: 'Data Room' },
    { value: 'Business Projections', title: 'Business Projections' },
    { value: 'Competitor Analysis', title: 'Competitor Analysis' }
];
const StepThreeComponent = ({
    equity_id: post_id,
    CommonDispatcher,
    UpdateDeal,
    UploadImages
}: any) => {
    const state = useSelector((store: RootState) => store);
    const activeStep = state?.deals?.wizard?.currentStep || 0;
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isLoading, setLoading] = useState(false);
    const steps = state?.deals?.wizard?.formSteps || [];
    const deal_data = state?.deals?.deal_data || {};

    const {
        control,
        reset,
        errors,
        getValues,
        register,
        formState: { isValid }
    } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'documents'
    });

    useEffect(() => {
        let obj: any = { ...deal_data };
        if (post_id) {
            if (obj?.documents && !obj?.documents.length) {
                obj.documents = [
                    {
                        name: '',
                        doc_url: ''
                    }
                ];
            } else if (!obj?.documents) {
                obj.documents = [
                    {
                        name: '',
                        doc_url: ''
                    }
                ];
            }
        } else {
            obj.documents = [
                {
                    name: '',
                    doc_url: ''
                }
            ];
        }
        reset(obj);
    }, [deal_data]);

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {
                currentStep: activeStep - 1
            });
        }
        let data: any = getFormValues(getValues());
        if (data?.documents && data?.documents.length) {
            data.documents = data?.documents.filter(
                (d) => d?.name && d?.doc_url
            );
        }
        CommonDispatcher(SET_STARTUP_EQUITY_DEAL_DATA, data);
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());

        if (data?.documents && data?.documents?.length) {
            data.documents = data?.documents
                .map((d) => {
                    d.name =
                        typeof d?.name === 'string' ? d?.name : d?.name?.title;
                    return d;
                })
                .filter((d) => d?.name && d?.doc_url);
        } else data.documents = [];

        CommonDispatcher(SET_STARTUP_EQUITY_DEAL_DATA, data);
        if (data?.documents) {
            if (post_id) {
                data.equity_id = post_id;
            } else {
                data.equity_id = deal_data?.equity_id;
            }
            if (data?.equity_id) {
                setLoading(true);
                UpdatePost(
                    UpdateDeal,
                    CommonDispatcher,
                    data,
                    UploadImages,
                    setLoading,
                    next,
                    token
                );
            }
        } else {
            next();
        }
    };

    const getFormValues = (obj) => {
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            if (key === 'documents') {
                if (value && Array.isArray(value) && value?.length) {
                    data[key] = value
                        .map((d) => {
                            d.name =
                                typeof d?.name === 'string'
                                    ? d?.name
                                    : d?.name?.title;
                            return d;
                        })
                        .filter((d) => d?.name && d?.doc_url);
                } else data[key] = [];
            } else {
                data[key] = value;
            }
        });
        return data;
    };

    const validateLink = (index) => {
        const link = getValues(`documents[${index}].doc_url`);
        const documentsError =
            errors?.documents &&
            errors?.documents?.length &&
            errors?.documents[index]?.doc_url;
        return (
            <FormHelperText
                error={documentsError}
                className={documentsError ? 'mb-1' : ''}
            >
                {link && !isValidURL(link) && 'Invalid doc link.'}
            </FormHelperText>
        );
    };

    return (
        <Container maxWidth="sm">
            <Grid container spacing={3} className="mb-5">
                <Grid item xs={12} md={10} sm={10} className="m-auto pb-5">
                    <div className="">
                        <form>
                            <div data-aos="fade-left" data-aos-duration="200">
                                <Grid container spacing={3} className="mb-3">
                                    <Grid item xs={12} className="py-1">
                                        <Typography
                                            variant="body2"
                                            component="p"
                                            className="colorPrimary font-weight-bold fs-16"
                                        >
                                            Documents :
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="my-1">
                                    <Grid item xs={12} className="py-1">
                                        {fields.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className="w-100 d-flex flex-direction-xs-column align-items-flex-start"
                                                >
                                                    <div className="mt-2 mr-3 w-100">
                                                        <Controller
                                                            name={`documents[${index}].name`}
                                                            render={({
                                                                onChange,
                                                                value
                                                            }) => {
                                                                return (
                                                                    <div className="w-100">
                                                                        <RenderCreatableAutocomplete
                                                                            id={`documents[${index}].name`}
                                                                            value={
                                                                                value ||
                                                                                []
                                                                            }
                                                                            label={`Attachment Info ${
                                                                                index +
                                                                                1
                                                                            }`}
                                                                            inputAutoComplete="off"
                                                                            options={
                                                                                linksTypeList
                                                                            }
                                                                            onBlur={(
                                                                                e: any
                                                                            ) => {
                                                                                const value =
                                                                                    e
                                                                                        ?.target
                                                                                        ?.value;
                                                                                onChange(
                                                                                    {
                                                                                        title: value,
                                                                                        value: value
                                                                                    }
                                                                                );
                                                                            }}
                                                                            onChange={(
                                                                                e,
                                                                                data
                                                                            ) => {
                                                                                onChange(
                                                                                    data
                                                                                );
                                                                            }}
                                                                            inputPlaceholder="Search or Add new Attachment Info"
                                                                        />
                                                                    </div>
                                                                );
                                                            }}
                                                            control={control}
                                                            defaultValue={
                                                                item.name ||
                                                                null
                                                            }
                                                            rules={{
                                                                required: false
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mt-2 mr-3 w-100">
                                                        <Controller
                                                            name={`documents[${index}].doc_url`}
                                                            as={
                                                                <RenderTextField
                                                                    id={`documents[${index}].doc_url`}
                                                                    inputContainerClassName={
                                                                        'w-100'
                                                                    }
                                                                    label={`Link to attachment ${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                />
                                                            }
                                                            control={control}
                                                            inputRef={register}
                                                            defaultValue={
                                                                item.doc_url ||
                                                                null
                                                            }
                                                            rules={{
                                                                validate: (
                                                                    link
                                                                ) => {
                                                                    return isValidURL(
                                                                        link
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        {validateLink(index)}
                                                    </div>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() =>
                                                            remove(index)
                                                        }
                                                        className="d-flex align-self-flex-xs-end mt-5 mt-xs-0"
                                                    >
                                                        <Delete />
                                                    </IconButton>
                                                </div>
                                            );
                                        })}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3} className="mb-3">
                                    <Grid item xs={12} className="mt-3">
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            type="button"
                                            disabled={fields?.length >= 10}
                                            className="float-right"
                                            onClick={() => {
                                                append({
                                                    name: '',
                                                    doc_url: ''
                                                });
                                            }}
                                        >
                                            <Add className="mr-2" />
                                            Add a new link
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="mt-5 d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <Button
                                        color="primary"
                                        variant="text"
                                        className={`fs-16 text-inherit`}
                                        onClick={() => handleBack()}
                                    >
                                        Back
                                    </Button>
                                </div>
                                <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={onSave}
                                        disabled={isLoading}
                                        className={`fs-16 text-inherit btn-submit-style`}
                                    >
                                        Next{' '}
                                        {isLoading ? (
                                            <CircularProgress
                                                size="1.3rem"
                                                className="circular-progress"
                                            />
                                        ) : null}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    CommonDispatcher,
    UploadImages,
    UpdateDeal
})(StepThreeComponent);
