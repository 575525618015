import { connect, useSelector } from 'react-redux';
import {
    CreateInterest,
    DeleteInterest,
    GetCapTableData,
    GetSingleDeal,
    SaveAndNotify,
    UpdateDeal,
    UpdateInterest
} from '../../../redux/Deals/actions';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import RenderMaskedTextField from '../../InputComponents/MaskedTextField';
import {
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import style from './Styles.module.scss';

import { Add, Refresh } from '@material-ui/icons';
import RenderDatePicker from '../../InputComponents/DatePickerComponent/RenderDatePicker';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import { addCommas, removeNonNumeric } from '../../../utils/common';
import moment from 'moment';
import HistoryModal from './HistoryModal';
import { RootState } from '../../../redux/store';
import {
    OPEN_ALERT_MODAL,
    TOGGLE_CAP_TABLE_HISTORY_MODAL,
    TOGGLE_DISCLOSE_PRE_MONEY_MODAL,
    TOGGLE_SUBMIT_ALERT_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import { CommonDispatcher } from '../../../redux/Common/action';
import DisclosePremoneyModal from './DisclosePremoneyModal';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import SubmitAlertModal from './SubmitAlertModal';
import CommonDealDetailComponent from '../../Common/CommonDealDetailComponent';
import { Routes } from '../../../constants';
import { useHistory } from 'react-router-dom';

const CapTable = ({
    match,
    GetSingleDeal,
    GetCapTableData,
    DeleteInterest,
    CreateInterest,
    SaveAndNotify,
    UpdateDeal,
    UpdateInterest,
    CommonDispatcher
}) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const {
        params: { equity_id }
    } = match;
    const [deal, setDeal]: any = useState({});
    const [tableHeader, setTableHeader]: any = useState([]);
    const [showAddNewItemForm, setShowAddNewItemForm] = useState(false);
    const [isDeleteInterestLoading, setIsDeleteInterestLoading] =
        useState(false);
    const [closingDate, setClosingDate] = useState(null);
    const [pre_money, setPremoney] = useState(0.0);
    const [commitment, setCommitment] = useState(0.0);
    const [isPremoneyLoading, setIsPremoneyLoading] = useState(false);
    const [isCommitmentLoading, setIsCommitmentLoading] = useState(false);
    const [isPlannedClosingLoading, setIsPlannedClosingLoading] =
        useState(false);

    const store = useSelector((store: RootState) => store);
    const capTableHistoryModal: any = store?.modal?.capTableHistoryModal || {};
    const submitAlertModal: any = store?.modal?.submitAlertModal || {};
    const disclosePremoneyModal: any =
        store?.modal?.disclosePremoneyModal || {};
    const [isSaveLoading, setIsSaveLoading] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isNotifyLoading, setIsNotifyLoading] = useState({});
    const [capTableInterests, setCapTableInterests] = useState([]);
    const { control, watch, trigger, reset } = useForm({ mode: 'onChange' });
    const updatedValues = watch()?.investmentTable || [];
    const history = useHistory();

    const {
        fields: tableFields,
        append: tableAppend,
        remove: tableRemove
    } = useFieldArray({
        control,
        name: 'investmentTable'
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        setIsLoading(true);
        GetSingleDeal(token, { equity_id }).then((r) => {
            const deal = r?.response?.deal;
            setDeal(deal);
            const tableHeader = [
                {
                    title: 'Date',
                    minWidth: '110'
                },
                {
                    title: 'Name',
                    minWidth: '110'
                },
                {
                    title: 'Company',
                    minWidth: '110'
                },
                {
                    title: `Min Investment in ${deal?.currency?.label}`,
                    minWidth: '110'
                },
                {
                    title: '% stake',
                    minWidth: '80'
                },
                {
                    title: `Max Investment in ${deal?.currency?.label}`,
                    minWidth: '110'
                },
                {
                    title: '% stake',
                    minWidth: '80'
                },
                {
                    title: 'History',
                    minWidth: '150'
                },
                {
                    title: `Final Accepted Amount in ${deal?.currency?.label}`,
                    minWidth: '110'
                },
                {
                    title: 'Ownership % stake',
                    minWidth: '80'
                },
                {
                    title: 'Action',
                    minWidth: '150'
                }
            ];
            setTableHeader(tableHeader);
            if (deal?.pre_money) {
                setPremoney(deal?.pre_money);
            }
            if (deal?.soft_circled) {
                setCommitment(deal?.soft_circled);
            }
            setClosingDate(deal?.expiry_date);
            GetCapTableData(token, equity_id).then((c) => {
                const interests = c?.response?.interests;
                setIsLoading(false);
                trigger();
                reset({ investmentTable: interests });
                setCapTableInterests(interests);
            });
        });
    };

    useEffect(() => {
        if (capTableInterests && capTableInterests.length) {
            trigger();
            reset({ investmentTable: capTableInterests });
        }
    }, [capTableInterests]);

    const handleAddMore = () => {
        setShowAddNewItemForm(true);
        tableAppend({ isNew: true });
    };

    const handleClosingDateChange = (d) => {
        setClosingDate(d);
    };

    const handlePremonyChange = (e) => {
        setPremoney(e);
    };

    const handleCommitmentsChange = (e) => {
        setCommitment(e);
    };

    let minInvestmentTotal = 0,
        maxInvestmentTotal = 0,
        finalAcceptedAmountTotal = 0,
        ownershipStakeTotal = 0,
        minInvestmentPercentTotal = 0,
        maxInvestmentPercentTotal = 0;

    updatedValues.forEach((d) => {
        const min = d?.min_investment
            ? d?.min_investment.toString().replace(/,/g, '')
            : null;
        const max = d?.max_investment
            ? d?.max_investment.toString().replace(/,/g, '')
            : null;
        const final = d?.accepted_investment ? d?.accepted_investment : null;
        const ownership = d?.accepted_investment_percent
            ? d?.accepted_investment_percent.toString().replace(/,/g, '')
            : null;
        minInvestmentTotal += parseFloat(min) || 0;
        maxInvestmentTotal += parseFloat(max) || 0;
        finalAcceptedAmountTotal += parseFloat(final) || 0;
        ownershipStakeTotal += parseFloat(ownership) || 0;
    });

    let minTargetRaisePercent = (
            (minInvestmentTotal / parseFloat(deal?.target_raise)) *
            100
        ).toFixed(1),
        maxTargetRaisePercent = (
            (maxInvestmentTotal / parseFloat(deal?.target_raise)) *
            100
        ).toFixed(1),
        finalAcceptedAmountPercent = (
            (finalAcceptedAmountTotal / parseFloat(deal?.target_raise)) *
            100
        ).toFixed(1);

    const handleDeleteInterest = (index, isNew, interest_id) => {
        if (isNew) {
            tableRemove(index);
        } else {
            CommonDispatcher(OPEN_ALERT_MODAL, {
                isOpen: true,
                title: 'Delete!',
                description: `Are you sure you want to delete this interest? This action cannot be undone.`,
                data: { disableBackdropClick: true },
                submitTitle: 'Delete',
                cancelTitle: 'No',
                callBack: async () => {
                    setIsDeleteInterestLoading(true);
                    DeleteInterest(token, interest_id)
                        .then((r) => {
                            if (r?.type === 'success') {
                                tableRemove(index);
                                setIsDeleteInterestLoading(false);
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: 'Interest deleted successfully.',
                                    hideAlert: true
                                });
                            } else {
                                setIsDeleteInterestLoading(false);
                                CommonDispatcher(SHOW_SNACKBAR, {
                                    isShowing: true,
                                    message: 'Failed to delete interest.',
                                    hideAlert: true,
                                    error: true
                                });
                            }
                        })
                        .catch((e) => {
                            setIsDeleteInterestLoading(false);
                            CommonDispatcher(OPEN_ALERT_MODAL, {
                                is_open: false
                            });
                        })
                        .finally((e) => {
                            setIsDeleteInterestLoading(false);
                            CommonDispatcher(OPEN_ALERT_MODAL, {
                                is_open: false
                            });
                        });
                }
            });
        }
    };

    const handleOpenHistoryModal = (interest_id) => {
        const history =
            capTableInterests && capTableInterests.length
                ? capTableInterests.find((d) => d?.interest_id === interest_id)
                : [];
        CommonDispatcher(TOGGLE_CAP_TABLE_HISTORY_MODAL, {
            title: `History`,
            isOpen: true,
            config: { disableBackdropClick: true },
            data: { history, currency: deal?.currency?.symbol }
        });
    };

    const handleDisclosePremoneyModal = () => {
        CommonDispatcher(TOGGLE_DISCLOSE_PRE_MONEY_MODAL, {
            title: `Disclose premoney with`,
            isOpen: true,
            config: { disableBackdropClick: true },
            data: { equity_id },
            callBack: () => {
                CommonDispatcher(TOGGLE_DISCLOSE_PRE_MONEY_MODAL, {
                    isOpen: false
                });
            }
        });
    };

    const handleUpdatePlannedClosingDate = () => {
        setIsPlannedClosingLoading(true);
        UpdateDeal(token, {
            equity_id,
            expiry_date: moment(closingDate).format('YYYY-MM-DD')
        }).then((r) => {
            if (r?.type === 'success') {
                setIsPlannedClosingLoading(false);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Planned Closing Date updated successfully.',
                    hideAlert: true
                });
            } else {
                setIsPlannedClosingLoading(false);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Failed to update planned closing date.',
                    hideAlert: true,
                    error: true
                });
            }
        });
    };

    const handleUpdatePremoney = () => {
        setIsPremoneyLoading(true);
        UpdateDeal(token, { equity_id, pre_money: pre_money || null }).then(
            (r) => {
                if (r?.type === 'success') {
                    setIsPremoneyLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Premoney updated successfully.',
                        hideAlert: true
                    });
                } else {
                    setIsPremoneyLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Failed to update premoney.',
                        hideAlert: true,
                        error: true
                    });
                }
            }
        );
    };

    const handleUpdateCommitment = () => {
        setIsCommitmentLoading(true);
        UpdateDeal(token, { equity_id, soft_circled: commitment || null }).then(
            (r) => {
                if (r?.type === 'success') {
                    setIsCommitmentLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Commitment updated successfully.',
                        hideAlert: true
                    });
                } else {
                    setIsCommitmentLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Failed to update Commitment.',
                        hideAlert: true,
                        error: true
                    });
                }
            }
        );
    };

    const handleSaveAndNotify = (index, obj, data) => {
        CommonDispatcher(TOGGLE_SUBMIT_ALERT_MODAL, {
            isOpen: true,
            title: 'Save',
            description: `Are you sure you want to save this interest?`,
            data: { disableBackdropClick: true },
            submitTitle: 'Submit Interest',
            cancelTitle: 'Cancel',
            callBack: async (updatedNote) => {
                setIsNotifyLoading({ [index]: true });

                const d = { ...data[index] };
                d.equity_id = equity_id;
                d.note = updatedNote || null;
                d.accepted_investment = d?.accepted_investment
                    ? d?.accepted_investment.replace(/,/g, '')
                    : null;
                d.accepted_investment_percent =
                    d?.accepted_investment_percent || null;
                d.interest_id = obj?.interest_id;
                SaveAndNotify(token, d).then((r) => {
                    if (r?.type === 'success') {
                        GetCapTableData(token, equity_id).then((resp) => {
                            CommonDispatcher(TOGGLE_SUBMIT_ALERT_MODAL, {
                                is_open: false
                            });
                            setCapTableInterests(resp?.response?.interests);
                            obj.isNew = false;
                            trigger();
                            reset({
                                investmentTable: resp?.response?.interests
                            });
                            setIsSaveLoading({ [index]: false });
                            setIsNotifyLoading({ [index]: false });
                            CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                message:
                                    'Interest saved and notified successfully.',
                                hideAlert: true
                            });
                        });
                    } else {
                        CommonDispatcher(TOGGLE_SUBMIT_ALERT_MODAL, {
                            is_open: false
                        });
                        setIsSaveLoading({ [index]: false });
                        setIsNotifyLoading({ [index]: false });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Failed to update interest and notify.',
                            hideAlert: true,
                            error: true
                        });
                    }
                });
            }
        });
    };

    const handleSave = (index, obj, data) => {
        CommonDispatcher(TOGGLE_SUBMIT_ALERT_MODAL, {
            isOpen: true,
            title: obj?.isNew ? 'Save' : 'Update',
            description: `Are you sure you want to ${
                obj?.isNew ? 'save' : 'update'
            } this interest?`,
            data: { disableBackdropClick: true },
            submitTitle: obj?.isNew ? 'Submit Interest' : 'Update Interest',
            cancelTitle: 'Cancel',
            callBack: async (flag, updatedNote) => {
                setIsSaveLoading({ [index]: true });
                saveInterest(index, obj, data, CreateInterest, updatedNote);
            }
        });
    };

    const saveInterest = (index, obj, data, func, note = '') => {
        const d = { ...data[index] };
        d.equity_id = equity_id;
        d.note = note || null;
        if (obj?.isNew) {
            d.min_investment = d?.min_investment
                ? d?.min_investment.replace(/,/g, '')
                : null;
            d.max_investment = d?.max_investment
                ? d?.max_investment.replace(/,/g, '')
                : null;
            d.accepted_investment = d?.accepted_investment
                ? d?.accepted_investment.replace(/,/g, '')
                : null;
            d.accepted_investment_percent =
                d?.accepted_investment_percent || null;
            func(token, d).then((r) => {
                if (r?.type === 'success') {
                    GetCapTableData(token, equity_id).then((resp) => {
                        CommonDispatcher(TOGGLE_SUBMIT_ALERT_MODAL, {
                            is_open: false
                        });
                        setCapTableInterests(resp?.response?.interests);
                        obj.isNew = false;
                        trigger();
                        reset({ investmentTable: resp?.response?.interests });
                        setIsSaveLoading({ [index]: false });
                        setIsNotifyLoading({ [index]: false });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Interest created successfully.',
                            hideAlert: true
                        });
                    });
                } else {
                    CommonDispatcher(TOGGLE_SUBMIT_ALERT_MODAL, {
                        is_open: false
                    });
                    setIsSaveLoading({ [index]: false });
                    setIsNotifyLoading({ [index]: false });
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Failed to create interest.',
                        hideAlert: true,
                        error: true
                    });
                }
            });
        } else {
            d.accepted_investment = d?.accepted_investment
                ? d?.accepted_investment.replace(/,/g, '')
                : null;
            d.accepted_investment_percent =
                d?.accepted_investment_percent || null;
            d.interest_id = obj?.interest_id;
            UpdateInterest(token, d).then((r) => {
                if (r?.type === 'success') {
                    GetCapTableData(token, equity_id).then((resp) => {
                        CommonDispatcher(TOGGLE_SUBMIT_ALERT_MODAL, {
                            is_open: false
                        });
                        setCapTableInterests(resp?.response?.interests);
                        obj.isNew = false;
                        trigger();
                        reset({ investmentTable: resp?.response?.interests });
                        setIsSaveLoading({ [index]: false });
                        setIsNotifyLoading({ [index]: false });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Interest updated successfully.',
                            hideAlert: true
                        });
                    });
                } else {
                    CommonDispatcher(TOGGLE_SUBMIT_ALERT_MODAL, {
                        is_open: false
                    });
                    setIsSaveLoading({ [index]: false });
                    setIsNotifyLoading({ [index]: false });
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Failed to update interest.',
                        hideAlert: true,
                        error: true
                    });
                }
            });
        }
    };

    const handleCapTableRefresh = () => {
        getData();
    };

    let raised: number;
    raised = deal?.soft_circled
        ? (deal?.soft_circled / deal?.target_raise) * 100
        : 0;

    const renderStatus = () => {
        let label;
        let color = deal?.status;
        switch (deal?.status) {
            case 'COMPLETE':
                label = 'ADDED';
                color = 'default';
                break;
            case 'DRAFT':
                label = 'DRAFT';
                color = 'primary';
                break;
            case 'INVITED':
                label = 'DRAFT';
                color = 'default';
                break;
            default:
                label = 'VIEWED';
                break;
        }
        return (
            <Chip
                label={label}
                color={color}
                className={`mt-2 ${
                    deal?.status === 'COMPLETE'
                        ? 'border-0 bgColorGreen colorWhite'
                        : 'bgColorLightGray colorPrimary'
                }`}
            />
        );
    };

    const handleRedirect = (pathname) => {
        history.push(`${pathname}/${equity_id}`);
    };

    return (
        <div>
            {submitAlertModal?.isOpen ? <SubmitAlertModal /> : null}
            {disclosePremoneyModal?.isOpen ? <DisclosePremoneyModal /> : null}
            {capTableHistoryModal?.isOpen ? <HistoryModal /> : null}
            {isLoading ? (
                <div className="mainLoader">
                    <div className="loader" />
                </div>
            ) : (
                <>
                    <div className={`mt-5 m-auto ${style.container}`}>
                        <Card className="py-5">
                            <CardContent className="p-0">
                                <CommonDealDetailComponent
                                    data={deal}
                                    raised={raised}
                                    renderStatus={renderStatus}
                                />
                            </CardContent>
                        </Card>

                        <Card className="mt-5">
                            <CardContent>
                                <div className="d-flex justify-content-between flex-direction-sm-column">
                                    <div
                                        className="d-flex align-items-center justify-content-end
                            justify-content-xs-center w-100"
                                    >
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            size="small"
                                            className="text-inherit mr-3"
                                            onClick={() =>
                                                handleRedirect(
                                                    `${Routes.deals.deals}/edit-deal`
                                                )
                                            }
                                        >
                                            Edit Deal
                                        </Button>
                                        <Button
                                            color="primary"
                                            className={`text-inherit`}
                                            size="small"
                                            onClick={handleCapTableRefresh}
                                        >
                                            <IconButton className="p-0 mr-2 colorBlue">
                                                <Refresh />
                                            </IconButton>
                                            Refresh
                                        </Button>
                                    </div>
                                </div>

                                <Grid container className="mt-2 mt-xs-5">
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        className="colorGray d-flex align-items-center"
                                    >
                                        <span className="mr-3 fs-16 font-weight-bold">
                                            Commitments till-date
                                        </span>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={8}
                                        className="d-flex flex-direction-sm-column"
                                    >
                                        <Grid container>
                                            <Grid item xs={12} sm={5} lg={4}>
                                                <RenderMaskedTextField
                                                    id="commitment"
                                                    value={commitment}
                                                    startAdornment={
                                                        deal?.currency?.symbol
                                                    }
                                                    onChange={
                                                        handleCommitmentsChange
                                                    }
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={7}
                                                lg={8}
                                                className="d-flex flex-direction-xs-column"
                                            >
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    className="text-inherit mx-2 fs-16"
                                                    onClick={
                                                        handleUpdateCommitment
                                                    }
                                                >
                                                    Update
                                                    {isCommitmentLoading ? (
                                                        <CircularProgress
                                                            size="1.3rem"
                                                            className="circular-progress-primary"
                                                        />
                                                    ) : null}
                                                </Button>

                                                <span className="colorDanger d-flex align-items-center fs-12">
                                                    (This value will be viewed
                                                    by others as a progress)
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className="mt-2 mt-xs-5">
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        className="colorGray d-flex align-items-center"
                                    >
                                        <span className="mr-3 fs-16 font-weight-bold">
                                            Indicative Premoney
                                        </span>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={8}
                                        className="d-flex flex-direction-sm-column"
                                    >
                                        <Grid container>
                                            <Grid item xs={12} sm={5} lg={4}>
                                                <RenderMaskedTextField
                                                    id="pre_money"
                                                    value={pre_money}
                                                    startAdornment={
                                                        deal?.currency?.symbol
                                                    }
                                                    onChange={
                                                        handlePremonyChange
                                                    }
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={7}
                                                lg={8}
                                                className="d-flex flex-direction-xs-column"
                                            >
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    className="text-inherit mx-2 fs-16"
                                                    onClick={
                                                        handleUpdatePremoney
                                                    }
                                                >
                                                    Update
                                                    {isPremoneyLoading ? (
                                                        <CircularProgress
                                                            size="1.3rem"
                                                            className="circular-progress-primary"
                                                        />
                                                    ) : null}
                                                </Button>
                                                <span className="colorDanger d-flex align-items-center fs-12">
                                                    (This value will be viewed
                                                    by others)
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className="mt-2 mt-xs-5">
                                    <Grid
                                        item
                                        xs={12}
                                        sm={4}
                                        className="colorGray d-flex align-items-center"
                                    >
                                        <span className="mr-3 fs-16 font-weight-bold">
                                            Planned closing Date
                                        </span>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={8}
                                        className="d-flex flex-direction-sm-column"
                                    >
                                        <Grid container>
                                            <Grid item xs={12} sm={5} lg={4}>
                                                <RenderDatePicker
                                                    id="closingDate"
                                                    value={closingDate || null}
                                                    shrink={false}
                                                    removeMarginAndPadding={
                                                        true
                                                    }
                                                    showInputLabel={false}
                                                    format="DD-MM-YYYY"
                                                    onChange={
                                                        handleClosingDateChange
                                                    }
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={7}
                                                lg={8}
                                                className="d-flex flex-direction-xs-column"
                                            >
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    className="text-inherit mx-2 fs-16"
                                                    onClick={
                                                        handleUpdatePlannedClosingDate
                                                    }
                                                >
                                                    Update
                                                    {isPlannedClosingLoading ? (
                                                        <CircularProgress
                                                            size="1.3rem"
                                                            className="circular-progress-primary"
                                                        />
                                                    ) : null}
                                                </Button>

                                                {deal?.is_expired ? (
                                                    <span className="colorDanger d-flex align-items-center">
                                                        (Deal Closed on{' '}
                                                        {moment(
                                                            closingDate
                                                        ).format('Do MMM YYYY')}
                                                        )
                                                    </span>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className="py-5 my-xs-5">
                                    <Grid item xs={12} sm={4}></Grid>
                                    <Grid item xs={12} sm={8}>
                                        <span
                                            className="text-inherit cursor-pointer colorBlue fs-16"
                                            onClick={
                                                handleDisclosePremoneyModal
                                            }
                                        >
                                            Disclose valuation to those
                                            expressed interests
                                        </span>
                                    </Grid>
                                </Grid>

                                {deal?.display_target_raise ? (
                                    <Grid container className="mt-xs-5">
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            className="colorGray d-flex align-items-center"
                                        >
                                            <span className="my-0 fs-16 font-weight-bold">
                                                Target Raise
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={8}
                                            className="colorGray"
                                        >
                                            <span className="my-0 fs-15">
                                                <span className="d-xs-none">
                                                    :
                                                </span>{' '}
                                                {addCommas(
                                                    deal?.display_target_raise
                                                )}{' '}
                                                ({minTargetRaisePercent}% -{' '}
                                                {maxTargetRaisePercent}%
                                                commitment received)
                                            </span>
                                        </Grid>
                                    </Grid>
                                ) : null}

                                {deal?.display_target_raise ? (
                                    <Grid container className="mt-2 mt-xs-5">
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            className="colorGray d-flex align-items-center"
                                        >
                                            <span className="mr-0 fs-16 font-weight-bold">
                                                Total Received Commitment
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={8}
                                            className="colorGray"
                                        >
                                            {minInvestmentTotal &&
                                            maxInvestmentTotal ? (
                                                <span className="mr-0 fs-15">
                                                    <span className="d-xs-none">
                                                        :
                                                    </span>{' '}
                                                    {deal?.currency?.symbol}
                                                    {addCommas(
                                                        removeNonNumeric(
                                                            minInvestmentTotal
                                                                .toString()
                                                                .slice(0, 18)
                                                        )
                                                    )}
                                                    &nbsp;-&nbsp;
                                                    {deal?.currency?.symbol}
                                                    {addCommas(
                                                        maxInvestmentTotal
                                                    )}
                                                </span>
                                            ) : (
                                                <span className="mr-0 fs-15">
                                                    <span className="d-xs-none">
                                                        :
                                                    </span>{' '}
                                                    No commitments received
                                                </span>
                                            )}
                                        </Grid>
                                    </Grid>
                                ) : null}

                                {deal?.display_target_raise ? (
                                    <Grid container className="mt-2 mt-xs-5">
                                        <Grid
                                            item
                                            xs={12}
                                            sm={4}
                                            className="colorGray d-flex align-items-center"
                                        >
                                            <span className="mr-0 fs-16 font-weight-bold">
                                                Total confirmed amount by you
                                            </span>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={8}
                                            className="colorGray"
                                        >
                                            <span className="mr-0 fs-15">
                                                <span className="d-xs-none">
                                                    :
                                                </span>{' '}
                                                {deal?.currency?.symbol}
                                                {addCommas(
                                                    finalAcceptedAmountTotal
                                                )}
                                                {parseFloat(
                                                    finalAcceptedAmountPercent
                                                ) > 0
                                                    ? ` (${finalAcceptedAmountPercent} % of target confirmed)`
                                                    : '0'}
                                            </span>
                                        </Grid>
                                    </Grid>
                                ) : null}
                            </CardContent>
                        </Card>

                        <div className="pt-5">
                            <h4>
                                <span className="colorBlue fs-20">
                                    Cap Table -{' '}
                                </span>
                                Expressed investors can see only their own
                                proposals. Only the deal owner sees everything.
                            </h4>
                        </div>
                    </div>

                    <Container maxWidth="xl">
                        <Grid
                            container
                            spacing={3}
                            className={`${style.listings} ${style.formTable} mt-5 mx-0`}
                        >
                            <TableContainer
                                component={Paper}
                                className="box-shadow-none"
                            >
                                <Table className={style.table}>
                                    <TableHead>
                                        <TableRow className={style.tableRow}>
                                            {tableHeader.map((d, index) => {
                                                return (
                                                    <TableCell
                                                        key={index}
                                                        className="border-0 p-1 font-weight-bold line-clamp-unset"
                                                    >
                                                        {d?.title}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableFields.map((d, index) => {
                                            const preMoney = pre_money
                                                ? parseFloat(
                                                      pre_money
                                                          .toString()
                                                          .replace(/,/g, '')
                                                  )
                                                : 0;
                                            const createdAt = watch(
                                                `investmentTable[${index}].createdAt`
                                            );
                                            const minInvestment = parseFloat(
                                                watch(
                                                    `investmentTable[${index}].min_investment`
                                                )
                                            );
                                            const maxInvestment = parseFloat(
                                                watch(
                                                    `investmentTable[${index}].max_investment`
                                                )
                                            );
                                            const minStakeInvestment =
                                                parseFloat(
                                                    (
                                                        (minInvestment /
                                                            (minInvestmentTotal +
                                                                preMoney)) *
                                                        100
                                                    ).toFixed(4)
                                                );
                                            const maxStakeInvestment =
                                                parseFloat(
                                                    (
                                                        (maxInvestment /
                                                            (maxInvestmentTotal +
                                                                preMoney)) *
                                                        100
                                                    ).toFixed(4)
                                                );

                                            let minStake = !isNaN(
                                                Number(minStakeInvestment)
                                            )
                                                ? minStakeInvestment
                                                : 0;
                                            let maxStake = !isNaN(
                                                Number(maxStakeInvestment)
                                            )
                                                ? maxStakeInvestment
                                                : 0;

                                            minInvestmentPercentTotal +=
                                                minStake;
                                            maxInvestmentPercentTotal +=
                                                maxStake;

                                            return (
                                                <TableRow
                                                    hover
                                                    key={d?.interest_id}
                                                    className={`cursor-pointer ${style.tableRow}`}
                                                >
                                                    <TableCell className="border-0 p-1 line-clamp-unset">
                                                        {createdAt
                                                            ? moment(
                                                                  createdAt
                                                              ).format(
                                                                  'DD MMMM, YYYY'
                                                              )
                                                            : moment().format(
                                                                  'DD MMMM, YYYY'
                                                              )}
                                                    </TableCell>
                                                    <TableCell className="border-0 p-1 line-clamp-unset">
                                                        {d?.isNew ? (
                                                            <div className="w-100">
                                                                <Controller
                                                                    name={`investmentTable[${index}].name`}
                                                                    as={
                                                                        <RenderTextField
                                                                            id={`investmentTable[${index}].name`}
                                                                            inputContainerClassName={
                                                                                'w-100'
                                                                            }
                                                                            label={``}
                                                                        />
                                                                    }
                                                                    control={
                                                                        control
                                                                    }
                                                                    defaultValue={
                                                                        d.name ||
                                                                        null
                                                                    }
                                                                    rules={{
                                                                        required:
                                                                            false
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            d.name
                                                        )}
                                                    </TableCell>
                                                    <TableCell className="border-0 p-1 line-clamp-unset">
                                                        {d?.isNew ? (
                                                            <div className="w-100">
                                                                <Controller
                                                                    name={`investmentTable[${index}].company`}
                                                                    as={
                                                                        <RenderTextField
                                                                            id={`investmentTable[${index}].company`}
                                                                            inputContainerClassName={
                                                                                'w-100'
                                                                            }
                                                                            label={``}
                                                                        />
                                                                    }
                                                                    control={
                                                                        control
                                                                    }
                                                                    defaultValue={
                                                                        d.company ||
                                                                        null
                                                                    }
                                                                    rules={{
                                                                        required:
                                                                            false
                                                                    }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            d.company
                                                        )}
                                                    </TableCell>
                                                    <TableCell className="border-0 p-1 line-clamp-unset">
                                                        <div className="w-100">
                                                            <Controller
                                                                name={`investmentTable[${index}].min_investment`}
                                                                as={
                                                                    <RenderMaskedTextField
                                                                        id={`investmentTable[${index}].min_investment`}
                                                                        hideInput={
                                                                            !d?.isNew
                                                                        }
                                                                        label={
                                                                            d.min_investment
                                                                                ? addCommas(
                                                                                      d.min_investment
                                                                                  )
                                                                                : d.min_investment
                                                                        }
                                                                    />
                                                                }
                                                                control={
                                                                    control
                                                                }
                                                                defaultValue={
                                                                    d.min_investment ||
                                                                    null
                                                                }
                                                                rules={{
                                                                    required:
                                                                        false
                                                                }}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="border-0 p-1 line-clamp-unset">
                                                        {minStake}%
                                                    </TableCell>
                                                    <TableCell className="border-0 p-1 line-clamp-unset">
                                                        <div className="w-100">
                                                            <Controller
                                                                name={`investmentTable[${index}].max_investment`}
                                                                as={
                                                                    <RenderMaskedTextField
                                                                        id={`investmentTable[${index}].max_investment`}
                                                                        hideInput={
                                                                            !d?.isNew
                                                                        }
                                                                        label={
                                                                            d.max_investment
                                                                                ? addCommas(
                                                                                      d.max_investment
                                                                                  )
                                                                                : d.max_investment
                                                                        }
                                                                    />
                                                                }
                                                                control={
                                                                    control
                                                                }
                                                                defaultValue={
                                                                    d.max_investment ||
                                                                    null
                                                                }
                                                                rules={{
                                                                    required:
                                                                        false
                                                                }}
                                                            />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell className="border-0 p-1 line-clamp-unset">
                                                        {maxStake}%
                                                    </TableCell>
                                                    <TableCell
                                                        className="border-0 p-1 line-clamp-unset"
                                                        classes={{ body: '' }}
                                                    >
                                                        {/*<Controller name={`investmentTable[${index}].note`}
                                                    as={<RenderTextField
                                                        id={`investmentTable[${index}].note`}
                                                        inputContainerClassName={"w-100"}
                                                        label={``}/>}
                                                    control={control}
                                                    defaultValue={d.note || null}
                                                    rules={{required: false}}
                                        />*/}
                                                        <div>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className="text-inherit"
                                                                size="small"
                                                                disabled={
                                                                    d?.isActive ||
                                                                    d?.isNew
                                                                }
                                                                onClick={() =>
                                                                    handleOpenHistoryModal(
                                                                        d?.interest_id
                                                                    )
                                                                }
                                                            >
                                                                View History
                                                            </Button>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className="border-0 p-1 line-clamp-unset"
                                                        classes={{ body: '' }}
                                                    >
                                                        <Controller
                                                            name={`investmentTable[${index}].accepted_investment`}
                                                            as={
                                                                <RenderMaskedTextField
                                                                    id={`investmentTable[${index}].accepted_investment`}
                                                                    name={`investmentTable[${index}].accepted_investment`}
                                                                />
                                                            }
                                                            control={control}
                                                            defaultValue={
                                                                d.accepted_investment ||
                                                                null
                                                            }
                                                            rules={{
                                                                required: false
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        className="border-0 p-1 line-clamp-unset"
                                                        classes={{ body: '' }}
                                                    >
                                                        <Controller
                                                            name={`investmentTable[${index}].accepted_investment_percent`}
                                                            as={
                                                                <RenderTextField
                                                                    id={`investmentTable[${index}].accepted_investment_percent`}
                                                                    name={`investmentTable[${index}].accepted_investment_percent`}
                                                                    type="number"
                                                                />
                                                            }
                                                            control={control}
                                                            defaultValue={
                                                                d.accepted_investment_percent ||
                                                                null
                                                            }
                                                            rules={{
                                                                required: false
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        className="border-0 p-1 line-clamp-unset"
                                                        classes={{ body: '' }}
                                                    >
                                                        <div className="w-100 d-flex align-items-center flex-direction-column">
                                                            <Button
                                                                color="secondary"
                                                                className="text-inherit"
                                                                size="small"
                                                                onClick={() =>
                                                                    handleSave(
                                                                        index,
                                                                        d,
                                                                        watch()
                                                                            ?.investmentTable
                                                                    )
                                                                }
                                                            >
                                                                {d?.isNew
                                                                    ? 'Save'
                                                                    : 'Update'}
                                                                {Object.keys(
                                                                    isSaveLoading
                                                                ).length &&
                                                                isSaveLoading[
                                                                    index
                                                                ] ? (
                                                                    <CircularProgress
                                                                        size="1.3rem"
                                                                        className="circular-progress-primary"
                                                                    />
                                                                ) : null}
                                                            </Button>
                                                            {!d?.isNew ? (
                                                                <Button
                                                                    color="primary"
                                                                    className="text-inherit mx-2"
                                                                    size="small"
                                                                    onClick={() =>
                                                                        handleSaveAndNotify(
                                                                            index,
                                                                            d,
                                                                            watch()
                                                                                ?.investmentTable
                                                                        )
                                                                    }
                                                                >
                                                                    Send
                                                                    Reminder
                                                                    {Object.keys(
                                                                        isNotifyLoading
                                                                    ).length &&
                                                                    isNotifyLoading[
                                                                        index
                                                                    ] ? (
                                                                        <CircularProgress
                                                                            size="1.3rem"
                                                                            className="circular-progress-primary"
                                                                        />
                                                                    ) : null}
                                                                </Button>
                                                            ) : null}
                                                            <Button
                                                                color="secondary"
                                                                className="text-inherit"
                                                                size="small"
                                                                onClick={() =>
                                                                    handleDeleteInterest(
                                                                        index,
                                                                        d?.isNew,
                                                                        d?.interest_id
                                                                    )
                                                                }
                                                                disabled={
                                                                    isDeleteInterestLoading
                                                                }
                                                            >
                                                                Remove
                                                                {isDeleteInterestLoading ? (
                                                                    <CircularProgress
                                                                        size="1.3rem"
                                                                        className="circular-progress"
                                                                    />
                                                                ) : null}
                                                            </Button>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="mt-5 w-100">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="text-inherit"
                                    size="small"
                                    onClick={handleAddMore}
                                >
                                    <Add className="submitIcon" /> Add More
                                </Button>
                            </div>
                        </Grid>
                        <Grid
                            container
                            spacing={3}
                            className={`${style.listings} ${style.tableFooter} mx-0 position-fixed`}
                        >
                            <TableContainer
                                component={Paper}
                                className="box-shadow-none border-radius-unset"
                            >
                                <Table className={style.table}>
                                    <TableHead>
                                        <TableRow className={style.tableRow}>
                                            {tableHeader.map((d, index) => {
                                                return (
                                                    <TableCell
                                                        key={index}
                                                        style={{
                                                            // width: d?.minWidth ? `${d?.minWidth}px` : "",
                                                            height: '0px'
                                                        }}
                                                        className="border-0 p-0 m-0 font-weight-bold"
                                                    />
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset"
                                                classes={{ body: '' }}
                                            />
                                            <TableCell
                                                colSpan={2}
                                                className="border-0 p-1 line-clamp-unset font-weight-bold fs-16"
                                                classes={{ body: '' }}
                                            >
                                                Total
                                            </TableCell>
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset font-weight-bold fs-16"
                                                classes={{ body: '' }}
                                            >
                                                {addCommas(minInvestmentTotal)}
                                            </TableCell>
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset font-weight-bold fs-16"
                                                classes={{ body: '' }}
                                            >
                                                {parseFloat(
                                                    minInvestmentPercentTotal.toFixed(
                                                        4
                                                    )
                                                )}
                                                %
                                            </TableCell>
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset font-weight-bold fs-16"
                                                classes={{ body: '' }}
                                            >
                                                {addCommas(maxInvestmentTotal)}
                                            </TableCell>
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset font-weight-bold fs-16"
                                                classes={{ body: '' }}
                                            >
                                                {parseFloat(
                                                    maxInvestmentPercentTotal.toFixed(
                                                        4
                                                    )
                                                )}
                                                %
                                            </TableCell>
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset font-weight-bold fs-16"
                                                classes={{ body: '' }}
                                            />
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset font-weight-bold fs-16"
                                                classes={{ body: '' }}
                                            >
                                                {addCommas(
                                                    removeNonNumeric(
                                                        finalAcceptedAmountTotal
                                                            .toString()
                                                            .slice(0, 18)
                                                    )
                                                )}
                                            </TableCell>
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset font-weight-bold fs-16"
                                                classes={{ body: '' }}
                                            >
                                                {parseFloat(
                                                    ownershipStakeTotal.toFixed(
                                                        4
                                                    )
                                                )}
                                                %
                                            </TableCell>
                                            <TableCell
                                                className="border-0 p-1 line-clamp-unset fs-16 colorDanger"
                                                classes={{ body: '' }}
                                            >
                                                {parseFloat(
                                                    ownershipStakeTotal.toFixed(
                                                        4
                                                    )
                                                ) > 100
                                                    ? 'Total allocation exceeds 100%'
                                                    : ''}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Container>
                </>
            )}
        </div>
    );
};

export default connect(null, {
    GetSingleDeal,
    GetCapTableData,
    DeleteInterest,
    SaveAndNotify,
    UpdateDeal,
    UpdateInterest,
    CreateInterest,
    CommonDispatcher
})(CapTable);
