import { IconButton } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const InfoIconComponent = () => {
    return (
        <a
            href="https://sdgs.un.org/goals"
            target="_blank"
            title="https://sdgs.un.org/goals"
        >
            <IconButton
                aria-label="info"
                color="inherit"
                className="colorPrimary p-0 text-hover-colorPurple"
            >
                <Info />
            </IconButton>
        </a>
    );
};

export default InfoIconComponent;
