import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 425,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  typography: {
    fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif"
  },
  palette: {
    primary: {
      main: "#1976d2"
    },
    secondary: {
      main: "rgb(220, 0, 78)" //  #f50057
    },
    luxExpats: {
      white: "#FFFFFF"
    }
  }
});

export { theme };