export const AUTH = {
    logged_in: false,
    profile: {
        privileges: null,
        full_name: '',
        email: ''
    }
};

export const DASHBOARD_DATA = {
    selected_index: 0,
    tab_wise_data: {
        0: {
            headers: [
                {id: 'actions', label: 'Actions'},
                {id: 'createdAt', label: 'Join Date'},
                {id: 'type', label: 'Account Type'},
                {id: 'full_name', label: 'Name'},
                {id: 'email', label: 'Email'},
                {id: 'status', label: 'Email Status'},
            ],
            current_page: 0,
            total_pages: 0,
            rows: [],
            page_size: 0,
            count: 0
        },
        1: {
            headers: [
                {id: 'actions', label: 'Actions'},
                {id: 'createdAt', label: 'Listing Date'},
                {id: 'status', label: 'Post Status'},
                {id: 'expiry_date', label: 'Expiry Date'},
                {id: 'type', label: 'Listing Type'},
                {id: 'name', label: 'Post Title'},
                {id: 'views', label: 'Number of Views'},
                {id: 'owner_name', label: 'User Name'},
                {id: 'owner_email', label: 'User Email'},
            ],
            current_page: 0,
            total_pages: 0,
            rows: [],
            count: 0
        },
        2: {
            headers: [
                {id: 'actions', label: 'Actions'},
                {id: 'createdAt', label: 'Listing Date'},
                {id: 'status', label: 'Post Status'},
                {id: 'expiry_date', label: 'Expiry Date'},
                {id: 'type', label: 'Listing Type'},
                {id: 'title', label: 'Post Title'},
                {id: 'views', label: 'Number of Views'},
                {id: 'owner_name', label: 'User Name'},
                {id: 'owner_email', label: 'User Email'},
            ],
            current_page: 0,
            total_pages: 0,
            rows: [],
            count: 0
        },
        3: {
            headers: [
                {id: 'actions', label: 'Actions'},
                {id: 'createdAt', label: 'Listing Date'},
                {id: 'status', label: 'Post Status'},
                {id: 'expiry_date', label: 'Expiry Date'},
                {id: 'title', label: 'Post Title'},
                {id: 'views', label: 'Number of Views'},
                {id: 'owner_name', label: 'User Name'},
                {id: 'owner_email', label: 'User Email'},
            ],
            current_page: 0,
            total_pages: 0,
            rows: [],
            count: 0
        },
        4: {
            headers: [
                {id: 'actions', label: 'Actions'},
                {id: 'createdAt', label: 'Listing Date'},
                {id: 'status', label: 'Post Status'},
                {id: 'expiry_date', label: 'Expiry Date'},
                {id: 'title', label: 'Post Title'},
                {id: 'views', label: 'Number of Views'},
                {id: 'owner_name', label: 'User Name'},
                {id: 'owner_email', label: 'User Email'},
            ],
            current_page: 0,
            total_pages: 0,
            rows: [],
            count: 0
        },
        6: {
            headers: [
                {id: 'actions', label: 'Actions'},
                {id: 'createdAt', label: 'Listing Date'},
                {id: 'status', label: 'Post Status'},
                {id: 'title', label: 'Post Title'},
                {id: 'views', label: 'Number of Views'},
                {id: 'owner_name', label: 'User Name'},
                {id: 'owner_email', label: 'User Email'},
            ],
            current_page: 0,
            total_pages: 0,
            rows: [],
            count: 0
        }
    }
};