import {
    Button,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    Typography
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Add, Delete } from '@material-ui/icons';
import useStateRef from 'react-usestateref';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
    CommonDispatcher,
    UploadImages
} from '../../../../redux/Common/action';
import {
    SET_FUND_EQUITY_DEAL_DATA,
    SET_FUND_EQUITY_DEAL_FORM_ERROR,
    UPDATE_FUNDS_WIZARD_FORM_DATA
} from '../../../../redux/FundDeals/actionTypes';
import { validateThirdStep } from '../Common/validate';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import RenderCreatableAutocomplete from '../../../InputComponents/CreatableAutocomplete';
import RenderCheckboxComponent from '../../../InputComponents/CheckboxComponent';
import { UpdatePost } from '../Common';
import { UpdateDeal } from '../../../../redux/FundDeals/actions';
import { getQuery } from '../../../../utils/common';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const linksTypeList = [
    { title: 'Managing Partner', value: 'one_pager' },
    { title: 'General Partner', value: 'investment_deck' },
    { title: 'General Counsel', value: 'PPM Model' },
    { title: 'Partner', value: 'Data Room' },
    { title: 'Venture Partner', value: 'advisory_agreement' },
    { title: 'Advisor', value: 'track_record' },
    { title: 'COO', value: 'competition_slide' },
    { title: 'Chief of Staff', value: 'competition_slide' },
    { title: 'VP/Principal', value: 'competition_slide' },
    { title: 'Senior Associate', value: 'competition_slide' },
    { title: 'Associate', value: 'competition_slide' },
    { title: 'Analyst', value: 'competition_slide' },
    { title: 'Investment team', value: 'competition_slide' },
    { title: 'Operations team', value: 'competition_slide' },
    { title: 'Legal team', value: 'competition_slide' },
    { title: 'Impact team', value: 'competition_slide' },
    { title: 'Fundraising team', value: 'competition_slide' },
    { title: 'Investor Relations', value: 'competition_slide' },
    { title: 'Marketing team', value: 'competition_slide' }
];

const StepFourComponent = ({
    CommonDispatcher,
    UpdateDeal,
    UploadImages
}: any) => {
    const history = useHistory();
    const query = getQuery(history);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const post_id = query.get('post_id');
    const state = useSelector((store: RootState) => store);
    const [isLoading, setLoading] = useState(false);

    const activeStep = state?.FundDeals?.wizard?.currentStep || 0;
    const steps = state?.FundDeals?.wizard?.formSteps || [];
    const errorStep = state?.FundDeals?.wizard?.errorStep || [];
    const deal_data = state?.FundDeals?.deal_data || {};
    const deal_data_error = state?.FundDeals?.deal_data_error || {};
    const [errors, setError, errorRef] = useStateRef({
        ...deal_data_error[activeStep]
    }) as [any, any, any];

    const { control, reset, setValue, getValues, register } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    const {
        fields: team_members,
        append: documentAppend,
        remove: removeDocumentFields
    } = useFieldArray({
        control,
        name: 'team_members'
    });
    const fieldsToValidate = [
        'num_of_fulltime_members',
        'num_of_partime_members',
        'team_members'
    ];

    useEffect(() => {
        let obj: any = {};
        Object.entries(deal_data).forEach(([k]) => {
            if (fieldsToValidate.includes(k)) {
                obj[k] = deal_data[k];
            }
        });
        if (deal_data?.team_members && deal_data?.team_members?.length) {
            obj.team_members = deal_data?.team_members.filter(
                (d) =>
                    Boolean(d?.title) &&
                    Boolean(d?.name) &&
                    Boolean(d?.profile_link)
            );
        }
        if (
            !obj?.team_members ||
            (obj?.team_members && !obj?.team_members.length)
        ) {
            obj.team_members = [
                {
                    title: '',
                    name: '',
                    profile_link: ''
                }
            ];
        }

        reset(obj);
    }, [deal_data]);

    const setWizardError = () => {
        CommonDispatcher(SET_FUND_EQUITY_DEAL_FORM_ERROR, {
            [activeStep]: errorRef?.current
        });
        const errItemsCount = Object.keys(errorRef?.current).filter(
            (d) => errorRef?.current[d]
        )?.length;
        let errors = { ...errorStep, [activeStep]: errItemsCount };
        CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
            errorStep: errors
        });
    };

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };
    const handleBack = (flag = false) => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep - 1
            });
        }
        let data: any = getFormValues(getValues());
        const err = validateThirdStep(data);
        setError(err);
        if (data?.team_members && data?.team_members.length) {
            data.team_members = data?.team_members.filter(
                (d) =>
                    Boolean(d?.title) &&
                    Boolean(d?.name) &&
                    Boolean(d?.profile_link)
            );
        } else data.team_members = [];
        CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, data);
        flag && setWizardError();
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());
        data.equity_id = String(post_id || deal_data?.equity_id);

        if (data?.equity_id) {
            setLoading(true);
            UpdatePost(
                (data) => UpdateDeal(token, data),
                CommonDispatcher,
                data,
                UploadImages,
                setLoading,
                next,
                setWizardError
            );
        }
    };

    const getFormValues = (obj) => {
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            if (key === 'team_members') {
                if (value && Array.isArray(value) && value?.length) {
                    data[key] = value
                        .map((d) => {
                            d.title =
                                typeof d?.title === 'string'
                                    ? d?.title
                                    : d?.title?.title;
                            return d;
                        })
                        .filter(
                            (d) =>
                                Boolean(d?.title) ||
                                Boolean(d?.name) ||
                                Boolean(d?.profile_link)
                        );
                } else data[key] = [];
            } else {
                data[key] = value;
            }
        });
        return data;
    };

    return (
        <Container maxWidth="md">
            <Grid item xs={12} className="m-auto pb-5">
                <div className="">
                    <form>
                        <div data-aos="fade-left" data-aos-duration="200">
                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="num_of_fulltime_members"
                                        as={
                                            <RenderTextField
                                                id="num_of_fulltime_members"
                                                type="number"
                                                inputMode="numeric"
                                                autoComplete="off"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Total number of Full-Time members *"
                                            />
                                        }
                                        control={control}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="num_of_partime_members"
                                        as={
                                            <RenderTextField
                                                id="num_of_partime_members"
                                                type="number"
                                                inputMode="numeric"
                                                autoComplete="off"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Total number of part-time members"
                                            />
                                        }
                                        control={control}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="mb-3">
                                <Grid
                                    item
                                    xs={12}
                                    className="py-1 d-flex align-items-center"
                                >
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        className="colorPrimary font-weight-bold fs-16 mr-3 mt-5"
                                    >
                                        Team Members * :
                                    </Typography>
                                    <FormHelperText error={true}>
                                        {errors?.members &&
                                            !errors?.members.length &&
                                            'At least 1 team member is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                                className="my-1 bgColorLightGray"
                            >
                                <Grid item xs={12} className="py-1">
                                    {team_members.map((item, index) => {
                                        const nameError =
                                            errors?.inValidInvestors?.[index]
                                                ?.title;
                                        return (
                                            <div
                                                key={item?.id}
                                                className="appCard p-3 mb-4"
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                        lg={4}
                                                    >
                                                        <Controller
                                                            name={`team_members[${index}].title`}
                                                            render={({
                                                                onChange,
                                                                value
                                                            }) => {
                                                                return (
                                                                    <div className="w-100">
                                                                        <RenderCreatableAutocomplete
                                                                            value={
                                                                                value ||
                                                                                []
                                                                            }
                                                                            label={`Title ${
                                                                                index +
                                                                                1
                                                                            }`}
                                                                            inputAutoComplete="off"
                                                                            options={
                                                                                linksTypeList
                                                                            }
                                                                            onBlur={(
                                                                                e: any
                                                                            ) => {
                                                                                const value =
                                                                                    e
                                                                                        ?.target
                                                                                        ?.value;
                                                                                onChange(
                                                                                    value
                                                                                );
                                                                            }}
                                                                            onChange={(
                                                                                e,
                                                                                data
                                                                            ) => {
                                                                                onChange(
                                                                                    data.title
                                                                                );
                                                                            }}
                                                                            inputPlaceholder="Search title"
                                                                        />
                                                                    </div>
                                                                );
                                                            }}
                                                            control={control}
                                                            defaultValue={
                                                                item?.title ||
                                                                null
                                                            }
                                                        />
                                                        <FormHelperText
                                                            error={true}
                                                            className={
                                                                nameError
                                                                    ? 'mb-1'
                                                                    : ''
                                                            }
                                                        >
                                                            {nameError &&
                                                                'Attachment name is mandatory'}
                                                        </FormHelperText>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                        lg={4}
                                                    >
                                                        <Controller
                                                            name={`team_members[${index}].name`}
                                                            as={
                                                                <RenderTextField
                                                                    inputContainerClassName={
                                                                        'w-100'
                                                                    }
                                                                    autoComplete="off"
                                                                    label={`Full Name`}
                                                                />
                                                            }
                                                            control={control}
                                                            inputRef={register}
                                                            defaultValue={
                                                                item.name ||
                                                                null
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                        lg={4}
                                                    >
                                                        <Controller
                                                            name={`team_members[${index}].profile_link`}
                                                            as={
                                                                <RenderTextField
                                                                    inputContainerClassName={
                                                                        'w-100'
                                                                    }
                                                                    autoComplete="off"
                                                                    placeholder={`e.g. https://www.yourwebsitename.com`}
                                                                    label={`Profile link ${
                                                                        index +
                                                                        1
                                                                    } (eg. LinkedIn/Website)`}
                                                                />
                                                            }
                                                            control={control}
                                                            inputRef={register}
                                                            defaultValue={
                                                                item.profile_link ||
                                                                null
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    container
                                                    spacing={2}
                                                    className="my-1"
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="py-1"
                                                    >
                                                        <div className="mt-2 w-100 d-flex flex-direction-column">
                                                            <b className="fs-16 pt-5 colorGray w-100">
                                                                Experienced in
                                                            </b>
                                                            <div className="mt-2 w-100 d-flex align-items-center align-items-xs-unset flex-direction-xs-column">
                                                                <div className="d-flex align-items-center">
                                                                    <Controller
                                                                        name={`team_members[${index}].experienced_in_direct_investments`}
                                                                        render={({
                                                                            onChange,
                                                                            value
                                                                        }) => {
                                                                            return (
                                                                                <RenderCheckboxComponent
                                                                                    checked={
                                                                                        value
                                                                                    }
                                                                                    onChange={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        onChange(
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        );
                                                                                    }}
                                                                                    color="primary"
                                                                                />
                                                                            );
                                                                        }}
                                                                        control={
                                                                            control
                                                                        }
                                                                        defaultValue={
                                                                            item?.experienced_in_direct_investments ||
                                                                            null
                                                                        }
                                                                    />
                                                                    Direct
                                                                    investments
                                                                </div>

                                                                <div className="d-flex align-items-center">
                                                                    <Controller
                                                                        name={`team_members[${index}].experienced_in_co_investments`}
                                                                        render={({
                                                                            onChange,
                                                                            value
                                                                        }) => {
                                                                            return (
                                                                                <RenderCheckboxComponent
                                                                                    checked={
                                                                                        value
                                                                                    }
                                                                                    onChange={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        onChange(
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        );
                                                                                    }}
                                                                                    color="primary"
                                                                                />
                                                                            );
                                                                        }}
                                                                        control={
                                                                            control
                                                                        }
                                                                        defaultValue={
                                                                            item?.experienced_in_co_investments ||
                                                                            null
                                                                        }
                                                                    />
                                                                    Co-investments
                                                                </div>

                                                                <div className="d-flex align-items-center">
                                                                    <Controller
                                                                        name={`team_members[${index}].experienced_in_fund_investments`}
                                                                        render={({
                                                                            onChange,
                                                                            value
                                                                        }) => {
                                                                            return (
                                                                                <RenderCheckboxComponent
                                                                                    checked={
                                                                                        value
                                                                                    }
                                                                                    onChange={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        onChange(
                                                                                            e
                                                                                                ?.target
                                                                                                ?.checked
                                                                                        );
                                                                                    }}
                                                                                    color="primary"
                                                                                />
                                                                            );
                                                                        }}
                                                                        control={
                                                                            control
                                                                        }
                                                                        defaultValue={
                                                                            item?.experienced_in_fund_investments
                                                                        }
                                                                    />
                                                                    Fund
                                                                    investments
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <FormHelperText
                                                            error={true}
                                                            className={
                                                                errors?.technology &&
                                                                errors?.impact
                                                                    ? 'mb-1'
                                                                    : ''
                                                            }
                                                        >
                                                            {errors?.technology &&
                                                                errors?.impact &&
                                                                'Startup Type is mandatory'}
                                                        </FormHelperText>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            color="secondary"
                                                            variant="text"
                                                            type="button"
                                                            className="float-right"
                                                            size="small"
                                                            onClick={() => {
                                                                removeDocumentFields(
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            <Delete className="mr-2" />{' '}
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="mb-3">
                                <Grid item xs={12} className="mt-3">
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        type="button"
                                        size="small"
                                        disabled={team_members?.length >= 10}
                                        className="float-right"
                                        onClick={() => {
                                            documentAppend({
                                                title: '',
                                                name: '',
                                                profile_link: ''
                                            });
                                        }}
                                    >
                                        <Add className="mr-2" />
                                        Add Team member
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="mt-5 py-4 border-top mb-sm-12 d-flex align-items-center justify-content-center btn-submit-post w-100">
                            <Button
                                color="secondary"
                                variant="text"
                                type="button"
                                className={`fs-16 text-inherit`}
                                onClick={() => handleBack(true)}
                            >
                                Back
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onSave}
                                disabled={isLoading}
                                className="fs-16 text-inherit btn-submit-style ml-5"
                            >
                                Next{' '}
                                {isLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : null}
                            </Button>
                        </div>
                    </form>
                </div>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    CommonDispatcher,
    UpdateDeal,
    UploadImages
})(StepFourComponent);
