import * as ACTIONS from './actionTypes';

const initialValues = {
    deal_data: {},
    tableHeader: [
        { id: 'actions1', label: '' },
        { id: 'createdAt', label: 'Created Date' },
        { id: 'logo', label: 'Logo' },
        { id: 'name', label: 'Name' },
        { id: 'email', label: 'Email' },
        { id: 'dbname', label: 'Db Name' },
        { id: 'status', label: 'Status' },
        { id: 'actions2', label: 'Actions' }
    ],
    allClientConfigList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    }
};

const ClientConfigReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.SET_ALL_CLIENT_CONFIG_LIST:
            return {
                ...state,
                allClientConfigList: {
                    posts: action?.payload?.clients,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.RESET_CLIENT_CONFIG_DATA:
            return {
                ...state,
                allClientConfigList: action?.payload
            };
        default:
            return state;
    }
};

export default ClientConfigReducer;
