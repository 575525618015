import React from "react";
import style from "./Styles.module.scss";
import {InputLabel, TextField} from "@material-ui/core";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";

interface DataArray {
    title: string,
    value?: string | number,
    id?: string | number
}

interface IRenderCreatableAutocompleteProps {
    id?: string,
    label: string | JSX.Element,
    value?: any,
    variant?: any,
    className?: string,
    filterSelectedOptions?: boolean,
    disableCloseOnSelect?: boolean,
    options: DataArray[],
    inputPlaceholder?: string,
    inputAutoComplete?: string,
    error?: boolean,
    hideLabel?: boolean,
    helperText?: string,
    paperClass?: string,
    onChange?: (e: object, value: any) => void
    onKeyDown?: (e: object) => void
    onBlur?: (e: object) => void
}

const filter = createFilterOptions();
const RenderCreatableAutocomplete = ({
                                         id, options, filterSelectedOptions = true, label, value, onBlur,
                                         disableCloseOnSelect = true, className, variant, onChange, inputPlaceholder,
                                         error, helperText, paperClass, hideLabel, onKeyDown, inputAutoComplete
                                     }: IRenderCreatableAutocompleteProps) => {

    return <div className={style.inputContainer}>
        {!hideLabel ? <InputLabel htmlFor={id} className={style.inputLabels}>
            {label}
        </InputLabel> : null}

        <Autocomplete id={id}
                      freeSolo
                      selectOnFocus
                      handleHomeEndKeys
                      value={value}
                      classes={{paper: paperClass}}
                      className={className ? className : style.inputFields}
                      options={options && options.length ? options : []}
                      disableCloseOnSelect={disableCloseOnSelect}
                      filterSelectedOptions={filterSelectedOptions}
                      onChange={onChange}
                      onBlur={onBlur}
                      filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          if (params.inputValue !== "") {
                              filtered.push({
                                  value: params.inputValue,
                                  title: params.inputValue
                              });
                          }

                          return filtered;
                      }}
                      getOptionLabel={(option) => {
                          // e.g value selected with enter, right from the input
                          if (typeof option === "string") {
                              return option;
                          }
                          if (option?.title) {
                              return option?.title;
                          }
                          return option?.title;
                      }}
                      renderInput={(params) => <TextField {...params}
                                                          error={error}
                                                          helperText={helperText}
                                                          onKeyDown={onKeyDown}
                                                          autoComplete={inputAutoComplete}
                                                          variant={variant ? variant : "outlined"}
                                                          placeholder={inputPlaceholder ? inputPlaceholder : "Search"}/>}/>
    </div>;
};
export default RenderCreatableAutocomplete;