import {
    Button,
    CardContent,
    Grid,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    makeStyles,
    IconButton,
    Chip,
    Card
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import moment from 'moment';
// import Image from 'next/image';
// import Link from 'next/link';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { CommonDispatcher } from '../../../redux/Common/action';
import { OPEN_ALERT_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { GetSingleDeal, UpdateTeam } from '../../../redux/FundDeals/actions';
// import { AppRoutes } from '../../../utils/AppRoutes';
import CardComponent from '../../Common/CardComponent';
import CommonDealDetailComponent from '../../Common/CommonDealDetailComponent';
import CommonItemWrapper from '../../Common/CommonDetailsPageItemWrapper';
import CommonSectionTitle from '../../Common/CommonDetailsPageSectionTitle';
import { ExternalUrl } from '../../Common/ExternalUrl';
import RenderDealReadMoreLess from '../../Common/RenderDealReadMoreLess';
import OverflowDataComponent from '../../Common/OverflowDataComponent';
import style from './Style.module.scss';
import { SET_FUND_EQUITY_DEAL_DATA } from '../../../redux/FundDeals/actionTypes';
import {
    BUISNESS_MODEL_MASTER,
    INVESTMENT_ASSETS_CLASS_DATA,
    SET_REGIONS,
    SET_SDG_GOALS,
    SET_STAGES
} from '../../../redux/Common/actionTypes';
import {
    GetBusinessModelMaster,
    GetRegions,
    GetSdgGoals,
    GetStagesTarget,
    investingAssetClassData
} from '../AddEdit/Common';
import DealNotFound from './../../../resources/deal-not-found.svg';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../constants';

// import ChatWithMe from '../Common/Chat';

const useStyles = makeStyles(() => ({
    tooltip: {
        fontSize: '14px',
        padding: '0.5rem 1rem'
    }
}));

const FundRaiseDeals = ({
    hideActionButtons,
    isAddDeal = false,
    ShareFundsWithPinegray,
    GetSingleDeal,
    CommonDispatcher,
    jsonData,
    UpdateTeam
}: any) => {
    const { equity_id } = useParams();
    const classes = useStyles();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const location = useLocation();
    const [isAcceptLoading, setIsAcceptLoading] = React.useState(false);
    const [isDeclineLoading, setIsDeclineLoading] = React.useState(false);
    const [d, setDeal] = React.useState<any>();
    const [dealNotFound, setDealNotFound] = React.useState(false);

    const [readMore, setReadMore] = React.useState(false);
    const store = useSelector((store: RootState) => store);
    const toggleDrawerMenu = false;
    const { control, errors, handleSubmit } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });
    const [isLoading, setIsLoading] = React.useState({});

    const emailError = errors?.email;

    const emailErrorType = emailError && errors?.email?.type;
    useEffect(() => {
        // if (equity_id) {
        setIsLoading(true);
        GetSingleDeal(token, { equity_id: equity_id }).then(async (r) => {
            if (r?.response?.data?.error) {
                setDealNotFound(true);
                setIsLoading(false);
            } else {
                const investingAssetClass = await investingAssetClassData(
                    token
                );
                const buisnessModel = await GetBusinessModelMaster(token);
                const stages = await GetStagesTarget(token);
                const sdgGoalsArray = await GetSdgGoals(token);
                const regions = await GetRegions(token);

                CommonDispatcher(BUISNESS_MODEL_MASTER, buisnessModel);
                CommonDispatcher(SET_STAGES, stages);
                CommonDispatcher(SET_SDG_GOALS, sdgGoalsArray);
                CommonDispatcher(SET_REGIONS, regions);

                CommonDispatcher(
                    INVESTMENT_ASSETS_CLASS_DATA,
                    investingAssetClass
                );

                let data = { ...r?.response?.deal };
                setDeal(data);
                if (data?.hq_geos && data?.hq_geos.length) {
                    data.hq_geos = data?.hq_geos.map((d) => d?.country_id);
                }
                if (data?.operational_geos && data?.operational_geos.length) {
                    data.ops_geos = data?.operational_geos.map(
                        (d) => d?.country_id
                    );
                    delete data?.operational_geos;
                }
                if (data?.sectors && data?.sectors.length) {
                    data.sectors = data?.sectors.map((d) => d?.sector_id);
                }
                CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, data);

                setIsLoading(false);
            }
        });
        // }
        // return () => {
        //     CommonDispatcher(RESET_FUND_EQUITY_DATA, {});
        // };
    }, [equity_id]);

    const handleReadMore = () => {
        setReadMore(!readMore);
    };

    let raised: number;
    raised = d?.commitments_till_date
        ? (d?.commitments_till_date / d?.target_raise) * 100
        : 0;

    const history = useHistory();

    const handleRedirect = (pathname) => {
        history.push(`${pathname}/${equity_id}`);
    };
    const isAuthenticated = !!cookies['pgAdminToken'];

    const typeOfInvestor =
        d?.investor_type && d?.investor_type.length ? (
            <OverflowDataComponent
                data={[...d?.investor_type].map((d) => d?.name)}
            />
        ) : (
            'N/A'
        );
    const sectorsList =
        d?.sectors && d?.sectors.length ? (
            <OverflowDataComponent
                data={[...d?.sectors].map((d) => d?.label)}
            />
        ) : (
            'N/A'
        );
    const earlyStageLists =
        d?.stages_target && d?.stages_target.length ? (
            <OverflowDataComponent
                data={[...d?.stages_target].map((d) => d?.label)}
            />
        ) : (
            'N/A'
        );
    const regionsList =
        d?.regions && d?.regions.length ? (
            <OverflowDataComponent
                data={[...d?.regions].map((d) => d?.label)}
            />
        ) : (
            'N/A'
        );

    const countriesList =
        d?.ops_geos && d?.ops_geos.length ? (
            <OverflowDataComponent
                data={[...d?.ops_geos].map((d) => d?.label)}
            />
        ) : (
            'N/A'
        );

    const businessModelList =
        d?.business_model && d?.business_model.length ? (
            <OverflowDataComponent
                data={[...d?.business_model].map((d) => d?.name)}
            />
        ) : (
            'N/A'
        );

    const portfolioCount = d?.num_planned_invesments
        ? `${d?.num_planned_invesments} companies`
        : 'N/A';
    const stategies = [
        {
            title: 'Type of investor',
            subtitle: <div className="d-flex flex-wrap">{typeOfInvestor}</div>
        },
        {
            title: 'Early-stage',
            subtitle: <div className="d-flex flex-wrap">{earlyStageLists}</div>
        },
        {
            title: 'Sectors',
            subtitle: <div className="d-flex flex-wrap">{sectorsList}</div>
        },
        {
            title: 'Geographies',
            subtitle: (
                <div className="d-flex align-items-flex-start flex-direction-column w-100">
                    <div className="mb-4">
                        <div className="text-left font-weight-bold mb-2">
                            Regions:
                        </div>
                        <div className="d-flex flex-wrap">{regionsList}</div>
                    </div>
                    <div>
                        <div className="text-left font-weight-bold mb-2">
                            Countries:
                        </div>
                        <div className="d-flex flex-wrap">{countriesList}</div>
                    </div>
                </div>
            )
        },
        {
            title: 'Portfolio',
            subtitle: portfolioCount
        },
        {
            title: 'Business Models',
            subtitle: businessModelList
        }
    ];
    useEffect(() => {
        if (d?.documents && d?.documents?.length) {
            d.documents = d?.documents.map((i) => ({
                ...i,
                title: i?.name,
                url: i?.doc_url,
                icon: '/pdf.png'
            }));
        }
        if (typeof d !== 'undefined') {
            d.display_soft_circled = d?.display_commitments_till_date;
        }
        setDeal(d);
    }, []);
    const renderStatus = () => {
        let label;
        let color = d?.status;
        switch (d?.status) {
            case 'COMPLETE':
                label = 'ADDED';
                color = 'default';
                break;
            case 'DRAFT':
                label = 'DRAFT';
                color = 'primary';
                break;
            case 'INVITED':
                label = 'DRAFT';
                color = 'default';
                break;
            default:
                label = 'VIEWED';
                break;
        }
        return (
            <Chip
                label={label}
                color={color}
                className={`mt-2 ${
                    d?.status === 'COMPLETE'
                        ? 'border-0 bgColorGreen colorWhite'
                        : 'bgColorLightGray colorPrimary'
                }`}
            />
        );
    };
    const clearModalsAndLoaders = () => {
        CommonDispatcher(OPEN_ALERT_MODAL, { is_open: false });
        setIsAcceptLoading(false);
        setIsDeclineLoading(false);
    };

    const handleDecline = () => {};

    const handleView = async (data) => {
        // setIsAcceptLoading(true);
        // let requestOptions = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Authorization: token
        //     }
        // };
        // await viewDealsWithoutAuth(data, requestOptions).then((r) => {
        //     if (r?.type === 'success') {
        //         let dealData = mapDocumentsInvestorsAndFoundersData(
        //             r?.response?.deal
        //         );
        //         dealData.hideActionButtons =
        //             profileDetails?.data?.user_id !== dealData?.owner?.user_id;
        //         setDeal(dealData);
        //         setIsAcceptLoading(false);
        //     } else if (r?.type === 'error' && r?.response?.data?.error) {
        //         setIsAcceptLoading(false);
        //         CommonDispatcher(SHOW_SNACKBAR, {
        //             isShowing: true,
        //             message: r?.response?.data?.error || `Failed to view deal`,
        //             hideAlert: true,
        //             error: true
        //         });
        //     } else {
        //         setIsAcceptLoading(false);
        //         CommonDispatcher(SHOW_SNACKBAR, {
        //             isShowing: true,
        //             message: 'Failed to view deal',
        //             hideAlert: true,
        //             error: true
        //         });
        //     }
        // });
    };

    const handleDownloadLink = (doc) => {
        // FundDownloadDocumentLink(
        //     {
        //         equity_id: d?.equity_id,
        //         doc_url: doc?.doc_url,
        //         doc_id: doc?.doc_id
        //     },
        //     cookies['pgAdminToken']
        // ).then((r) => {
        //     if (r?.type === 'success') {
        //         window.open(r?.response?.url, '_blank');
        //     } else if (r?.type === 'error' && r?.response?.data?.error) {
        //         CommonDispatcher(OPEN_ALERT_MODAL, { is_open: false });
        //         CommonDispatcher(SHOW_SNACKBAR, {
        //             isShowing: true,
        //             message:
        //                 r?.response?.data?.error ||
        //                 `Failed to open the document.`,
        //             hideAlert: true,
        //             error: true
        //         });
        //     } else {
        //         CommonDispatcher(OPEN_ALERT_MODAL, { is_open: false });
        //         CommonDispatcher(SHOW_SNACKBAR, {
        //             isShowing: true,
        //             message: `Failed to open the document.`,
        //             hideAlert: true,
        //             error: true
        //         });
        //     }
        // });
    };

    const ndaPending = d?.member_status?.team_status === 'NDA_PENDING';
    const ndaDeclined = d?.member_status?.team_status === 'NDA_DECLINED';
    const ndaAccepted = d?.member_status?.team_status === 'NDA_ACCEPTED';
    const ndaPendingOrDeclined = ndaPending || ndaDeclined;
    const TRACK_RECORDS_TYPE_LIST = [
        {
            title: 'Fund Track Record',
            value: 'FUND_TRACK_RECORD',
            disabled: false
        },
        { title: 'Balance Sheet', value: 'BALANCE_SHEET', disabled: false },
        { title: 'Other', value: 'OTHER', disabled: false }
    ];
    const CAP_TABLE_ACCESS_LIST_VALUES = {
        NO_ACCESS: 'NO_ACCESS',
        CAN_VIEW: 'CAN_VIEW',
        CAN_EDIT: 'CAN_EDIT'
    };
    const disableCapTable =
        d?.member_status?.cap_table_permission ===
        CAP_TABLE_ACCESS_LIST_VALUES?.NO_ACCESS;
    const disableEditDeal = d?.member_status?.allow_edit_deal;

    return (
        <div className={`h-100 mt-5 ${style.pageContainer}`}>
            <Grid container id="listingContainer" className="h-100">
                <Grid item xs={12} md={10} className="m-auto">
                    {dealNotFound ? (
                        <div className="d-flex align-items-center flex-direction-column">
                            <img
                                src={DealNotFound}
                                alt="Deal not found"
                                height={250}
                                width={250}
                            />
                            <h1>This deal is no longer available!</h1>
                        </div>
                    ) : (
                        <>
                            <Card>
                                <div className="mt-5 py-5">
                                    <CommonDealDetailComponent
                                        data={d}
                                        raised={raised}
                                        renderStatus={renderStatus}
                                    />
                                </div>
                            </Card>
                            <Card className="mt-5 p-5">
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={toggleDrawerMenu ? 12 : 9}
                                        lg={9}
                                    >
                                        <CardComponent
                                            cardClasses="text-hover-bgColorWhite border border-radius-12"
                                            cardContentClasses="p-0"
                                        >
                                            <div
                                                className={style.cardContainer}
                                            >
                                                {!hideActionButtons ? (
                                                    <div
                                                        className="p-3 border-b-radius-unset d-flex
                                                        align-items-center justify-content-end
                                                        justify-content-xs-center flex-wrap row-gap-10 bg-grey-header"
                                                    >
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            size="small"
                                                            disabled={
                                                                disableEditDeal
                                                            }
                                                            className="text-inherit mr-3"
                                                            onClick={() =>
                                                                handleRedirect(
                                                                    `${Routes.fund_deals.deals}/edit-deal`
                                                                )
                                                            }
                                                        >
                                                            Edit
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            className={`text-inherit mr-3`}
                                                            size="small"
                                                            // onClick={
                                                            //     () =>
                                                            //         handleRedirect()

                                                            // }
                                                        >
                                                            Team
                                                        </Button>

                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            className="text-inherit mr-3"
                                                            size="small"
                                                            // onClick={
                                                            //     () =>
                                                            //         handleRedirect()
                                                            // }
                                                        >
                                                            Invite investors (
                                                            {d?.invite_count ||
                                                                0}
                                                            )
                                                        </Button>

                                                        {/* <Button
                                                            color="primary"
                                                            variant="contained"
                                                            className={`text-inherit mr-3 ${
                                                                d?.is_shared_with_pinegray
                                                                    ? 'bgColorDisabled'
                                                                    : profileDetails?.allow_team_up_with_newnex
                                                                    ? 'bgColorGreen'
                                                                    : 'bgColorGrayishBlue'
                                                            }`}
                                                            size="small"
                                                            onClick={
                                                                handleShareDealWithNewnex
                                                            }
                                                            disabled={
                                                                d?.is_shared_with_pinegray
                                                                    ? false
                                                                    : profileDetails?.allow_team_up_with_newnex
                                                                    ? false
                                                                    : true
                                                            }
                                                        >
                                                            {d?.is_shared_with_pinegray
                                                                ? 'Shared'
                                                                : 'Team-up'}{' '}
                                                            with {APP_NAME}
                                                        </Button> */}

                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            className="text-inherit"
                                                            size="small"
                                                            disabled={
                                                                disableCapTable
                                                            }
                                                            // onClick={
                                                            //     () =>
                                                            //         !disableCapTable &&
                                                            //         handleRedirect()
                                                            // }
                                                        >
                                                            Cap Table (
                                                            {d?.cap_table_count ||
                                                                0}
                                                            )
                                                        </Button>
                                                    </div>
                                                ) : null}

                                                <CardContent className="p-8">
                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            className="w-100"
                                                        >
                                                            <CommonSectionTitle>
                                                                Description
                                                            </CommonSectionTitle>
                                                            {d?.description ? (
                                                                <span className="colorGrayText">
                                                                    <RenderDealReadMoreLess
                                                                        description={
                                                                            d?.description
                                                                        }
                                                                        handleReadMore={
                                                                            handleReadMore
                                                                        }
                                                                        readMore={
                                                                            readMore
                                                                        }
                                                                    />
                                                                </span>
                                                            ) : (
                                                                'N/A'
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <br />

                                                    <Grid container spacing={2}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <CardComponent
                                                                className="d-flex "
                                                                cardClasses="h-100 border border-radius-4"
                                                            >
                                                                <div className="d-flex w-100 flex-direction-sm-column align-items-sm-unset flex-direction-xs-unset align-items-xs-center">
                                                                    {/* {d?.icon ? ( */}
                                                                    <span
                                                                        className={
                                                                            style.cardIcons
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                '/icon1.png'
                                                                            }
                                                                            alt={
                                                                                d?.title
                                                                            }
                                                                        />
                                                                    </span>
                                                                    {/* ) : null} */}
                                                                    <div className="d-flex flex-direction-column justify-content-center ml-5 ml-sm-0 ml-xs-5">
                                                                        <span className="fs-20 fs-md-14 font-weight-700 font-circular-std">
                                                                            {
                                                                                d?.company_name
                                                                            }
                                                                        </span>
                                                                        <span className="fs-16">
                                                                            Founded
                                                                            in{' '}
                                                                            {
                                                                                d?.foundation_year
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </CardComponent>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                        >
                                                            <CardComponent
                                                                className="d-flex"
                                                                cardClasses="border border-radius-4"
                                                            >
                                                                <div className="d-flex w-100 flex-direction-sm-column align-items-sm-unset flex-direction-xs-unset align-items-xs-center">
                                                                    {/* {d?.icon ? ( */}
                                                                    <span
                                                                        className={
                                                                            style.cardIcons
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={
                                                                                '/icon2.png'
                                                                            }
                                                                            alt={
                                                                                d?.title
                                                                            }
                                                                        />
                                                                    </span>
                                                                    {/* ) : null} */}
                                                                    <div className="d-flex flex-direction-column justify-content-center ml-5 ml-sm-0 ml-xs-5">
                                                                        <span className="fs-20 fs-md-14 font-weight-700 font-circular-std">
                                                                            {
                                                                                d?.display_target_raise
                                                                            }{' '}
                                                                            Fund
                                                                            target
                                                                        </span>
                                                                        <span className="fs-16">
                                                                            Portfolio
                                                                            of{' '}
                                                                            {
                                                                                portfolioCount
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </CardComponent>
                                                        </Grid>
                                                    </Grid>
                                                    <br />

                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <CommonSectionTitle>
                                                                Industry
                                                            </CommonSectionTitle>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid>
                                                        <CommonItemWrapper
                                                            isChip
                                                        >
                                                            <div className="d-flex">
                                                                {d
                                                                    ?.investing_asset_class
                                                                    .length
                                                                    ? d?.investing_asset_class.map(
                                                                          (
                                                                              x
                                                                          ) => {
                                                                              return (
                                                                                  <OverflowDataComponent
                                                                                      data={[
                                                                                          x.name
                                                                                      ]}
                                                                                  />
                                                                              );
                                                                          }
                                                                      )
                                                                    : 'N/A'}
                                                            </div>
                                                        </CommonItemWrapper>
                                                    </Grid>
                                                    <br />

                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <CommonSectionTitle>
                                                                Strategy
                                                            </CommonSectionTitle>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={2}>
                                                        {stategies?.length
                                                            ? stategies.map(
                                                                  (i) => (
                                                                      <Grid
                                                                          item
                                                                          xs={
                                                                              12
                                                                          }
                                                                          sm={6}
                                                                      >
                                                                          <CardComponent cardClasses="border border-radius-12 rounded text-hover-bgColorWhite h-100 p-4">
                                                                              <div className="d-flex justify-content-center align-items-center flex-direction-column text-center">
                                                                                  <CommonSectionTitle>
                                                                                      {
                                                                                          i?.title
                                                                                      }
                                                                                  </CommonSectionTitle>
                                                                                  {
                                                                                      i?.subtitle
                                                                                  }
                                                                              </div>
                                                                          </CardComponent>
                                                                      </Grid>
                                                                  )
                                                              )
                                                            : 'N/A'}
                                                    </Grid>
                                                    <br />

                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="border border-radius-16 py-6 px-4"
                                                    >
                                                        <CommonItemWrapper title="Last Fund Genration">
                                                            {d?.last_STARTUP_generation ||
                                                                'N/A'}
                                                        </CommonItemWrapper>

                                                        <CommonItemWrapper title="Last Fund Vintage">
                                                            {d?.last_STARTUP_vintage ||
                                                                'N/A'}
                                                        </CommonItemWrapper>

                                                        <CommonItemWrapper title="Raising a closed end fund">
                                                            {d?.is_closed_fund
                                                                ? 'YES'
                                                                : 'NO'}
                                                        </CommonItemWrapper>

                                                        <CommonItemWrapper title="LP/GP Structure">
                                                            {d?.is_STARTUP_lp_gp
                                                                ? 'YES'
                                                                : 'NO'}
                                                        </CommonItemWrapper>

                                                        <CommonItemWrapper title="Current fund generation">
                                                            {d?.current_STARTUP_generation ||
                                                                'N/A'}
                                                        </CommonItemWrapper>

                                                        <CommonItemWrapper title="Planned first closing date">
                                                            {moment(
                                                                d?.planned_first_closing
                                                            ).format(
                                                                'Do MMMM YYYY'
                                                            )}
                                                            {d?.is_expired ? (
                                                                <span className="colorDanger">
                                                                    &nbsp;(Deal
                                                                    Closed on{' '}
                                                                    {moment(
                                                                        d?.planned_first_closing
                                                                    ).format(
                                                                        'Do MMMM YYYY'
                                                                    )}
                                                                    )
                                                                </span>
                                                            ) : null}
                                                        </CommonItemWrapper>

                                                        <CommonItemWrapper title="Target fund size">
                                                            {d?.display_target_raise ||
                                                                'N/A'}
                                                        </CommonItemWrapper>

                                                        <CommonItemWrapper title="Minimum fund size/Planned first closing">
                                                            {d?.display_min_fund_size ||
                                                                'N/A'}
                                                        </CommonItemWrapper>

                                                        <CommonItemWrapper title="Hard cap of the fund">
                                                            {d?.display_hard_cap_of_the_fund ||
                                                                'N/A'}
                                                        </CommonItemWrapper>
                                                    </Grid>
                                                    <br />

                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="mt-4"
                                                    >
                                                        <Grid item>
                                                            <CommonSectionTitle>
                                                                Lead LPs
                                                            </CommonSectionTitle>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        {d?.investors.length ? (
                                                            d?.investors.map(
                                                                (i) => (
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        md={4}
                                                                        className="m-1 border border-radius-4 rounded d-flex justify-content-center align-items-center flex-direction-column p-6 text-center"
                                                                    >
                                                                        <img
                                                                            width="84"
                                                                            height="56"
                                                                            // objectFit="cover"
                                                                            src={
                                                                                i?.image ||
                                                                                '/image-not-found-webp-orig.webp'
                                                                            }
                                                                        />
                                                                        <ExternalUrl
                                                                            data={[
                                                                                {
                                                                                    title: i?.title,
                                                                                    url: i?.url
                                                                                }
                                                                            ]}
                                                                            className="mt-2 font-weight-bold"
                                                                        ></ExternalUrl>
                                                                    </Grid>
                                                                )
                                                            )
                                                        ) : (
                                                            <div className="px-2">
                                                                N/A
                                                            </div>
                                                        )}
                                                    </Grid>

                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="mt-4"
                                                    >
                                                        <Grid item>
                                                            <CommonSectionTitle>
                                                                Track Record
                                                            </CommonSectionTitle>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid>
                                                        <CommonItemWrapper
                                                            title="Track record attached in
                                                            documents?"
                                                        >
                                                            {d?.is_track_record_available ||
                                                                'N/A'}
                                                        </CommonItemWrapper>
                                                        <div className="d-flex w-100 align-items-center grid-column-gap-05">
                                                            {/* <ChatWithMe
                                                                data={d}
                                                                buttonTitle="Send Message"
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                setIsLoading={
                                                                    setIsLoading
                                                                }
                                                                user_id={
                                                                    d?.invite_owner
                                                                        ?.user_id
                                                                }
                                                                showIcon={false}
                                                            /> */}
                                                            <p>
                                                                Requesting more
                                                                information on
                                                                TR
                                                            </p>
                                                        </div>
                                                    </Grid>

                                                    {d?.track_records &&
                                                    d?.track_records.length ? (
                                                        <Grid>
                                                            <TableContainer>
                                                                <Table
                                                                    size="small"
                                                                    aria-label="simple table"
                                                                    className="mt-5"
                                                                >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                Vintage/Fund
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Fund
                                                                                size
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Companies
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Total
                                                                                Cost
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                Total
                                                                                Fair
                                                                                Value
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                MoC
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                NetIRR(%)
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                DPI
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                TVPI
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {(
                                                                            d?.track_records ||
                                                                            []
                                                                        ).map(
                                                                            (
                                                                                row
                                                                            ) => {
                                                                                const trackRecordTitle =
                                                                                    TRACK_RECORDS_TYPE_LIST.find(
                                                                                        (
                                                                                            i
                                                                                        ) =>
                                                                                            i.value ==
                                                                                            row?.type
                                                                                    )?.title;

                                                                                return (
                                                                                    <TableRow
                                                                                        key={
                                                                                            row?.name
                                                                                        }
                                                                                    >
                                                                                        <TableCell scope="row">
                                                                                            <div>
                                                                                                {
                                                                                                    row?.year_of_first_investment
                                                                                                }{' '}
                                                                                                -{' '}
                                                                                                {
                                                                                                    row?.name
                                                                                                }
                                                                                            </div>
                                                                                            <small className=" font-weight-bold">
                                                                                                {
                                                                                                    trackRecordTitle
                                                                                                }
                                                                                            </small>
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {
                                                                                                row?.display_fund_size
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell scope="row">
                                                                                            {
                                                                                                row?.no_of_companies
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell scope="row">
                                                                                            {
                                                                                                row?.display_total_cost
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell scope="row">
                                                                                            {
                                                                                                row?.display_total_fair_value
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell>
                                                                                            {
                                                                                                row?.display_multiple_on_cost
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell scope="row">
                                                                                            {
                                                                                                row?.net_irr
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell scope="row">
                                                                                            {
                                                                                                row?.dpi
                                                                                            }
                                                                                        </TableCell>
                                                                                        <TableCell scope="row">
                                                                                            {
                                                                                                row?.tvpi
                                                                                            }
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Grid>
                                                    ) : null}

                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="mt-4"
                                                    >
                                                        <Grid item>
                                                            <CommonSectionTitle>
                                                                Fund Terms
                                                            </CommonSectionTitle>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="border border-radius-16 py-6 px-4"
                                                    >
                                                        {d?.first_time_fund ? (
                                                            <CommonItemWrapper
                                                                fullWidth
                                                                title=""
                                                            >
                                                                <div className="d-flex">
                                                                    <Chip
                                                                        label="Emerging Manager"
                                                                        size="small"
                                                                        className="cursor-pointer border"
                                                                        style={{
                                                                            margin: '0 8px 8px 0',
                                                                            background:
                                                                                '#c9e0ff'
                                                                        }}
                                                                    />
                                                                    <Tooltip
                                                                        classes={{
                                                                            tooltip:
                                                                                classes.tooltip
                                                                        }}
                                                                        title="First-time fund manager"
                                                                        placement="top"
                                                                        arrow={
                                                                            true
                                                                        }
                                                                    >
                                                                        <IconButton
                                                                            aria-label="info"
                                                                            className="p-0 mb-2 text-hover-colorPurple"
                                                                        >
                                                                            <Info />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </div>
                                                            </CommonItemWrapper>
                                                        ) : null}

                                                        <CommonItemWrapper title="Fund jurisdiction">
                                                            {(
                                                                d?.fund_jurisdiction_geos ||
                                                                []
                                                            ).map(
                                                                (i) => i?.label
                                                            )}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Regulation of the Fund">
                                                            {d?.regulaton_of_fund ||
                                                                'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Fund term">
                                                            {d?.fund_term ||
                                                                'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Potential extension">
                                                            +
                                                            {d?.potential_extension ||
                                                                'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Investment Period">
                                                            {d?.investment_period ||
                                                                'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Investment Period Extension">
                                                            {d?.investment_period_extension
                                                                ? `+${d?.investment_period_extension}`
                                                                : 'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="GP Commitment">
                                                            {`Min ${
                                                                d?.display_gp_min_fixed ||
                                                                'N/A'
                                                            } - Max ${
                                                                d?.display_gp_max_fixed ||
                                                                'N/A'
                                                            }`}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="GP Commitment %">
                                                            {d?.gp_commitment
                                                                ? `${d?.gp_commitment}%`
                                                                : 'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Management Fee">
                                                            {d?.management_fee
                                                                ? `${d?.management_fee}%`
                                                                : 'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Management Fee Note">
                                                            {d?.management_fee_note ||
                                                                'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Carry Fee">
                                                            {d?.carry_fee
                                                                ? `${d?.carry_fee}%`
                                                                : 'N/A'}
                                                        </CommonItemWrapper>
                                                        <CommonItemWrapper title="Carry Fee Note">
                                                            {d?.carry_fee_note ||
                                                                'N/A'}
                                                        </CommonItemWrapper>
                                                    </Grid>
                                                </CardContent>
                                            </div>
                                        </CardComponent>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        md={toggleDrawerMenu ? 12 : 3}
                                        lg={3}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={toggleDrawerMenu ? 6 : 12}
                                                lg={12}
                                            >
                                                <CardComponent cardClasses="text-hover-bgColorWhite h-100 border border-radius-12">
                                                    <div
                                                        className={
                                                            style.cardContainer
                                                        }
                                                    >
                                                        <CommonSectionTitle>
                                                            Team
                                                        </CommonSectionTitle>

                                                        {d?.team_members &&
                                                        d?.team_members.length
                                                            ? d?.team_members.map(
                                                                  (i) => {
                                                                      return (
                                                                          <div className="d-flex align-items-center">
                                                                              <img
                                                                                  width={
                                                                                      40
                                                                                  }
                                                                                  height={
                                                                                      40
                                                                                  }
                                                                                  src="/user-large.png"
                                                                                  className="border-radius-50"
                                                                              />
                                                                              <div className="ml-3">
                                                                                  <Typography
                                                                                      variant="body2"
                                                                                      className="d-flex mb-2"
                                                                                  >
                                                                                      <ExternalUrl
                                                                                          className="text-decoration-none text-hover-underline"
                                                                                          data={[
                                                                                              {
                                                                                                  title: i?.name,
                                                                                                  url: i?.profile_link
                                                                                              }
                                                                                          ]}
                                                                                      />
                                                                                  </Typography>

                                                                                  <span>
                                                                                      {
                                                                                          i?.title
                                                                                      }
                                                                                  </span>
                                                                              </div>
                                                                          </div>
                                                                      );
                                                                  }
                                                              )
                                                            : 'N/A'}
                                                    </div>
                                                </CardComponent>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={toggleDrawerMenu ? 6 : 12}
                                                lg={12}
                                            >
                                                <CardComponent cardClasses="text-hover-bgColorWhite h-100 border border-radius-12">
                                                    <div
                                                        className={
                                                            style.cardContainer
                                                        }
                                                    >
                                                        <CommonSectionTitle>
                                                            Document Links
                                                        </CommonSectionTitle>
                                                        {d?.documents &&
                                                        d?.documents.length ? (
                                                            <ExternalUrl
                                                                listStyleNone
                                                                className="text-decoration-none text-hover-underline"
                                                                data={
                                                                    d?.documents
                                                                }
                                                                handleDownloadCallback={
                                                                    handleDownloadLink
                                                                }
                                                            />
                                                        ) : (
                                                            'N/A'
                                                        )}
                                                    </div>
                                                </CardComponent>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(null, {
    GetSingleDeal,
    CommonDispatcher,
    UpdateTeam
})(FundRaiseDeals);
