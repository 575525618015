import * as ACTIONS from "./actionTypes";

const initialValues = {
    tableHeader: [
        {id: "menuActions", label: "Actions"},
        {id: "createdAt", label: "Start Date"},
        {id: "expiry_date", label: "Expiry Date"},
        {id: "duration", label: "Duration"},
        {id: "name", label: "Name"},
        {id: "email", label: "Email"},
        {id: "user_name", label: "User name"},
        {id: "type", label: "Type"},
        {id: "description", label: "Description"},
        {id: "status", label: "Payments Status of Subscription"},
        {id: "actions", label: "Actions"}
    ],
    listingsData: {
        page_size: 0,
        current_page: 0,
        total_pages: 0,
        posts: [],
        count: 0
    }
};

const SubscriptionsReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.SET_SUBSCRIPTIONS_DATA:
            return {
                ...state,
                listingsData: {
                    posts: action?.payload?.rows,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.page_size,
                    total_pages: action?.payload?.total_pages
                }
            };
        default:
            return state;
    }
};

export default SubscriptionsReducer;