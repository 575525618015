import style from './RenderTextField.module.scss';
import { InputAdornment, InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import { NumericFormat } from 'react-number-format';

interface IRenderTextFieldProps {
    id?: string;
    name?: string;
    type?: string;
    label?: any;
    inputMode?: string;
    value?: any;
    rows?: number;
    className?: string;
    variant?: any;
    placeholder?: string;
    autoComplete?: string;
    onFocus?: (e: object) => void;
    onInput?: (e: object) => void;
    onChange?: (e: object) => void;
    onBlur?: (e: object) => void;
    onKeyPress?: (e: object) => void;
    onPaste?: (e: object) => void;
    onKeyUp?: (e: object) => void;
    error?: boolean;
    readOnly?: boolean;
    multiline?: boolean;
    helperText?: string;
    defaultValue?: string;
    inputContainerClassName?: string;
    inputLabel?: string;
    inputStyle?: string;
    minRows?: number;
    maxlength?: number | string;
    autoFocus?: boolean;
    disabled?: boolean;
    hideInput?: boolean;
    startAdornment?: any;
    endAdornment?: any;
    inputComponent?: any;
    min?: number | string;
    max?: number | string;
}

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
    props: any,
    ref
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props?.name,
                        value: values?.value
                    }
                });
            }}
            thousandSeparator
            isNumericString
        />
    );
});

const RenderMaskedTextField = ({
    helperText,
    error,
    id,
    name,
    type,
    readOnly,
    inputMode,
    label,
    value,
    className,
    variant,
    onChange,
    onFocus,
    onBlur,
    placeholder = '',
    autoComplete,
    defaultValue,
    min,
    max,
    inputLabel,
    inputStyle,
    autoFocus,
    onKeyPress,
    onPaste,
    onKeyUp,
    disabled,
    inputContainerClassName,
    startAdornment,
    endAdornment,
    onInput,
    maxlength,
    hideInput,
    inputComponent
}: IRenderTextFieldProps) => {
    return (
        <div className={`${style.inputContainer} ${inputContainerClassName}`}>
            {!inputLabel ? (
                <InputLabel shrink htmlFor={id} className={style.inputLabels}>
                    {label}
                </InputLabel>
            ) : null}
            {!hideInput ? (
                <TextField
                    id={id}
                    name={name}
                    value={
                        value && typeof value === 'object'
                            ? value?.target?.value
                            : value
                    }
                    variant={variant || 'outlined'}
                    label={inputLabel}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    autoFocus={autoFocus}
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                        startAdornment: startAdornment ? (
                            <InputAdornment position="start">
                                {startAdornment}
                            </InputAdornment>
                        ) : null,
                        endAdornment: endAdornment ? (
                            <InputAdornment position="end">
                                {endAdornment}
                            </InputAdornment>
                        ) : null,
                        disabled: disabled,
                        inputProps: {
                            max,
                            min
                        },
                        className: `${inputStyle} ${
                            disabled ? style.disabled : ''
                        }`
                    }}
                    placeholder={placeholder}
                    onInput={onInput}
                    onChange={(e: any) => onChange(e?.target?.value)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onPaste={onPaste}
                    onKeyUp={onKeyUp}
                    onKeyPress={onKeyPress}
                    autoComplete={autoComplete}
                    className={`${className || style.inputFields}`}
                />
            ) : null}
        </div>
    );
};
export default RenderMaskedTextField;
