import React, { useEffect, useState } from 'react';
import { GetSingleUser, UpdateUserProfile } from '../../../redux/Users/actions';
import { connect, useSelector } from 'react-redux';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { CommonDispatcher } from '../../../redux/Common/action';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import RenderDatePicker from '../../InputComponents/DatePickerComponent/RenderDatePicker';
import {
    LIVE_DOMAIN,
    PHONE_PREFERRED_COUNTRIES
} from '../../../utils/constants';
import ImageUploadComponent from '../../ImageUploadComponent';
import { useCookies } from 'react-cookie';
import { uploadImage } from '../../../redux/Dashboard/actions';
import AutoCompleteAddressComponent from '../../InputComponents/AutoCompleteAddress';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import style from './Style.module.scss';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import RenderToggleSwitch from '../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import { Routes } from '../../../constants';

const emailChatNotifList = [
    { title: 'Off', value: false, disabled: false },
    { title: 'On', value: true, disabled: false }
];

const frequencyList = [
    { title: 'Daily', value: 'DAILY', disabled: false },
    { title: 'Weekly', value: 'WEEKLY', disabled: false }
];
const EditUser = ({
    match,
    GetSingleUser,
    uploadImage,
    CommonDispatcher,
    UpdateUserProfile
}) => {
    const [coordinates, setCoordinates] = useState({
        lat: 51.50853,
        lng: -0.12574
    });

    const [address, setAddress] = useState({
        city: '',
        country: '',
        address: ''
    });
    const { control, handleSubmit, errors } = useForm({ mode: 'onChange' });
    const [isUpdateProfileLoading, setUpdateProfileLoading] =
        React.useState(false);
    const [profileDetails, setProfileDetails] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [allow_notifications, setAllowNotifications] = useState(true);
    const [notification_frequency, setNotificationFrequency] = useState(null);
    const [frequency, setFrequency] = useState(frequencyList);
    const storeReducer = useSelector((store) => store);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const {
        params: { user_id }
    } = match;
    const history = useHistory();

    useEffect(() => {
        setIsLoading(true);
        GetSingleUser(token, { user_id }).then((r) => {
            const jsonData = r?.response;
            setProfileDetails(jsonData);
            setAddress({
                city: jsonData?.city,
                country: jsonData?.country,
                address: jsonData?.location
            });

            if (jsonData?.allow_notifications !== null) {
                setAllowNotifications(jsonData?.allow_notifications);
            }
            if (
                jsonData?.allow_notifications &&
                jsonData?.notification_frequency !== null
            ) {
                setNotificationFrequency(
                    jsonData?.notification_frequency ||
                        jsonData?.allow_notifications
                );
            } else if (
                jsonData?.allow_notifications &&
                jsonData?.notification_frequency === null
            ) {
                setNotificationFrequency(frequencyList[0]?.value);
                UpdateUserProfile(token, {
                    user_id,
                    allow_notifications: jsonData?.allow_notifications,
                    notification_frequency: frequencyList[0]?.value
                }).then(() => {
                    GetSingleUser(token, { user_id }).then(() => {
                        setIsLoading(false);
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Settings has been updated'
                        });
                    });
                });
            } else if (
                jsonData?.allow_notifications === null ||
                !jsonData?.allow_notifications
            ) {
                setNotificationFrequency(null);
                let data = frequency.map((d) => {
                    d.disabled = true;
                    return d;
                });
                setFrequency(data);
            }
            setIsLoading(false);
        });
    }, []);

    const isUserNameTaken = async (value: string) => {
        /*try {
            return (await axios.get(ApiRoutes.profile.IS_USER_NAME_TAKEN + value))?.data?.data?.isTaken;
        } catch (e) {
            throw e;
        }*/
    };
    const onSubmit = (obj) => {
        let data = {
            ...obj,
            dob: moment(obj?.dob).format('YYYY-MM-DD'),
            user_name: obj?.user_name.trim()
        };
        console.log(data);
        try {
            data.about = data.about || null;
            data.title = data.title || null;
            setUpdateProfileLoading(true);
            UpdateUserProfile(token, {
                ...data,
                user_id,
                latitude: coordinates?.lat || null,
                longitude: coordinates?.lng || null,
                location: address?.address || null,
                city: address?.city || null,
                country: address?.country || null
            }).then((r) => {
                if (r?.type === 'error' && r?.response?.data?.error) {
                    setUpdateProfileLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        hideAlert: true,
                        message: r?.response?.data?.error
                    });
                } else if (r?.type === 'success') {
                    GetSingleUser(token, { user_id }).then(() => {
                        setUpdateProfileLoading(false);
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            hideAlert: true,
                            message: 'Profile updated successfully.'
                        });
                    });
                } else {
                    setUpdateProfileLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        hideAlert: true,
                        message: 'Unable to update Profile.'
                    });
                }
            });
        } catch (e) {
            setUpdateProfileLoading(false);
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                hideAlert: true,
                message: 'Unable to update Profile.'
            });
        }
    };

    const handleAllowNotifications = (e, allow_notifications) => {
        if (allow_notifications !== null) {
            setAllowNotifications(allow_notifications);
            let data;
            let obj: any = { allow_notifications };
            if (!allow_notifications) {
                data = frequency.map((d) => {
                    d.disabled = true;
                    return d;
                });
                setNotificationFrequency(null);
                obj.notification_frequency = null;
            } else {
                data = frequency.map((d) => {
                    d.disabled = false;
                    return d;
                });
                setNotificationFrequency(frequencyList[0]?.value);
                obj.notification_frequency =
                    notification_frequency !== null
                        ? notification_frequency
                        : frequencyList[0]?.value;
            }
            setFrequency(data);
            // setIsLoading(true);
            obj.user_id = user_id;
            UpdateUserProfile(token, obj).then(() => {
                GetSingleUser(token, { user_id }).then(() => {
                    // setIsLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Settings has been updated'
                    });
                });
            });
        }
    };

    const handleNotificationFrequency = (e, notification_frequency) => {
        if (notification_frequency !== null) {
            setNotificationFrequency(notification_frequency);
            // setIsLoading(true);
            UpdateUserProfile(token, {
                user_id,
                allow_notifications,
                notification_frequency
            }).then(() => {
                GetSingleUser(token, { user_id }).then(() => {
                    // setIsLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Settings has been updated'
                    });
                });
            });
        }
    };

    const handleRedirect = (pathname) => {
        history.push(`${pathname}/${user_id}`);
    };

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <div className="mt-5">
            <ImageUploadComponent
                isLoading={isLoading}
                token={token}
                uploadImage={uploadImage}
                user_id={user_id}
                sm_profile_picture={profileDetails?.sm_profile_picture}
                label={`${
                    profileDetails?.sm_profile_picture ? 'Change' : 'Upload'
                } profile picture`}
            />

            <Container maxWidth="sm" className="pb-5">
                <Grid item xs={12}>
                    <Card className="mb-5">
                        <CardContent>
                            <div
                                className="d-flex align-items-center justify-content-end
                                justify-content-xs-center mb-5"
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className="text-inherit mr-2"
                                    onClick={() =>
                                        handleRedirect(
                                            `${Routes.users.users}/view`
                                        )
                                    }
                                >
                                    View User
                                </Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    size="small"
                                    className="text-inherit"
                                    onClick={() =>
                                        handleRedirect(
                                            `${Routes.users.users}/edit-strategy`
                                        )
                                    }
                                >
                                    Edit Strategy
                                </Button>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Controller
                                    name="full_name"
                                    as={
                                        <TextField
                                            name="full_name"
                                            label="Full Name"
                                            variant="outlined"
                                            fullWidth
                                            // error={errors.full_name}
                                            margin="normal"
                                        />
                                    }
                                    control={control}
                                    defaultValue={profileDetails?.full_name}
                                    // rules={{
                                    //     required: true
                                    // }}
                                />
                                {/* <FormHelperText
                                    error={true}
                                    className={errors?.full_name ? 'mb-1' : ''}
                                >
                                    {errors?.full_name &&
                                        'Full name is required'}
                                </FormHelperText> */}

                                <Controller
                                    name="title"
                                    as={
                                        <TextField
                                            name="title"
                                            label="Title"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            // error={errors.title}
                                        />
                                    }
                                    control={control}
                                    defaultValue={profileDetails?.title}
                                    rules={{
                                        required: false
                                    }}
                                />

                                {/* <FormHelperText
                                    error={true}
                                    className={errors?.title ? 'mb-1' : ''}
                                >
                                    {errors?.title && 'Title is required'}
                                </FormHelperText> */}

                                <AutoCompleteAddressComponent
                                    id="__location"
                                    coordinates={coordinates}
                                    defaultValue={address?.address}
                                    setCoordinates={setCoordinates}
                                    setAddress={setAddress}
                                    hideMap={true}
                                    inputLabel="Location"
                                />

                                <Controller
                                    name="user_name"
                                    as={
                                        <TextField
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <span className="colorGray font-weight-bold">
                                                            {LIVE_DOMAIN}/
                                                        </span>
                                                    </InputAdornment>
                                                )
                                            }}
                                            name="user_name"
                                            label="Username"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            autoComplete={'off'}
                                            onKeyDown={(e: any) => {
                                                if (
                                                    e?.key === ' ' ||
                                                    e?.keyCode === 32
                                                ) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            helperText={
                                                errors?.user_name?.type &&
                                                errors?.user_name?.type ==
                                                    'isTaken'
                                                    ? `This user name is taken, please choose an another one`
                                                    : errors?.user_name &&
                                                      'Please choose a cool user name'
                                            }
                                            error={errors.user_name}
                                        />
                                    }
                                    control={control}
                                    defaultValue={profileDetails?.user_name}
                                    // rules={{
                                    //     required: true
                                    // }}
                                />

                                <Controller
                                    name="about"
                                    as={
                                        <TextField
                                            multiline
                                            name="about"
                                            label="About"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            // error={errors.about}
                                        />
                                    }
                                    control={control}
                                    defaultValue={profileDetails?.about}
                                    rules={{
                                        required: false
                                    }}
                                />

                                {/* <FormHelperText
                                    error={true}
                                    className={errors?.about ? 'mb-1' : ''}
                                >
                                    {errors?.about && 'About is required'}
                                </FormHelperText> */}

                                <Grid container spacing={3}>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl className="mt-2 w-100">
                                            <InputLabel
                                                style={{
                                                    background: '#fff',
                                                    padding: '0 8px'
                                                }}
                                                htmlFor="gender-select"
                                                variant="outlined"
                                            >
                                                Gender
                                            </InputLabel>
                                            <Controller
                                                control={control}
                                                defaultValue={
                                                    profileDetails?.gender
                                                }
                                                name="gender"
                                                // rules={{
                                                //     required: true
                                                // }}
                                                style={{ minWidth: '100px' }}
                                                as={
                                                    <Select
                                                        variant="outlined"
                                                        // error={errors?.gender}
                                                        id="gender-select"
                                                        style={{
                                                            minWidth: '100px'
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value={'MALE'}
                                                        >
                                                            Male
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'FEMALE'}
                                                        >
                                                            Female
                                                        </MenuItem>
                                                        <MenuItem
                                                            value={'NON-BINARY'}
                                                        >
                                                            Non-binary
                                                        </MenuItem>
                                                    </Select>
                                                }
                                            />
                                            {/* <FormHelperText
                                                error={true}
                                                className={
                                                    errors?.gender ? 'mb-1' : ''
                                                }
                                            >
                                                {errors?.gender &&
                                                    'Gender is required'}
                                            </FormHelperText> */}
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl className="mt-1 w-100">
                                            <Controller
                                                name="dob"
                                                as={
                                                    <RenderDatePicker
                                                        id="dob"
                                                        name="dob"
                                                        showInputLabel={false}
                                                        label="Birth date"
                                                        disableFuture={true}
                                                        value={
                                                            profileDetails?.dob
                                                        }
                                                        inputClassName="p-4"
                                                        defaultValue={
                                                            profileDetails?.dob
                                                        }
                                                        format="DD-MM-YYYY"
                                                        helperText={
                                                            errors?.dob &&
                                                            `You must be ${
                                                                errors?.dob
                                                                    ?.type ===
                                                                '18'
                                                                    ? '18+'
                                                                    : 'born'
                                                            } to use this platform`
                                                        }
                                                        // error={errors.dob}
                                                    />
                                                }
                                                control={control}
                                                defaultValue={
                                                    profileDetails?.dob
                                                }
                                                rules={{
                                                    // required: true,
                                                    validate: {
                                                        18: (date) => {
                                                            return (
                                                                moment().diff(
                                                                    date,
                                                                    'years'
                                                                ) >= 18
                                                            );
                                                        },
                                                        alive: (date) =>
                                                            moment().diff(
                                                                date,
                                                                'years'
                                                            ) <= 150
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={3}>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            className="w-100 mb-3"
                                        >
                                            <Controller
                                                name="email"
                                                as={
                                                    <TextField
                                                        name="email"
                                                        label="Email"
                                                        variant="outlined"
                                                        // error={errors.email}
                                                        fullWidth
                                                    />
                                                }
                                                control={control}
                                                defaultValue={
                                                    profileDetails?.email
                                                }
                                                rules={{
                                                    required: false
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <FormControl
                                            variant="outlined"
                                            className="w-100 mb-3"
                                            inputMode="tel"
                                        >
                                            <Controller
                                                name="phone"
                                                as={
                                                    <PhoneInput
                                                        country={
                                                            PHONE_PREFERRED_COUNTRIES[0]
                                                        }
                                                        placeholder="New Mobile"
                                                        inputClass={
                                                            style.phoneInput
                                                        }
                                                        preferredCountries={
                                                            PHONE_PREFERRED_COUNTRIES
                                                        }
                                                        preserveOrder={[
                                                            'onlyCountries',
                                                            'preferredCountries'
                                                        ]}
                                                    />
                                                }
                                                control={control}
                                                defaultValue={
                                                    profileDetails?.phone
                                                }
                                                rules={{
                                                    required: false
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <div className="mt-3 text-center">
                                    <Button
                                        color="primary"
                                        className="mx-5"
                                        onClick={() => {
                                            history.goBack();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        disabled={
                                            isLoading || isUpdateProfileLoading
                                        }
                                    >
                                        Update{' '}
                                        {isLoading ? (
                                            <CircularProgress
                                                size="1.3rem"
                                                className="circular-progress"
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                    <Card className="mb-5">
                        <CardContent>
                            <Typography
                                variant="body1"
                                color="textPrimary"
                                component="p"
                                className="fs-16"
                            >
                                NOTIFICATION:
                            </Typography>
                            <div className="d-flex align-items-center justify-content-between">
                                <h4 className="my-0">
                                    Notification of Messages via Email
                                </h4>
                                {isLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className="my-5 d-flex align-items-center justify-content-between">
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    component="p"
                                    className="mr-2 fs-16"
                                >
                                    Send Notifications of New Messages via Email
                                </Typography>
                                <RenderToggleSwitch
                                    id="allow_notifications"
                                    label=""
                                    labelClassName="ml-3"
                                    value={allow_notifications}
                                    onChange={handleAllowNotifications}
                                    dataArray={emailChatNotifList}
                                />
                            </div>
                            <div className="my-5 py-5 d-flex align-items-center justify-content-between">
                                <Typography
                                    variant="body1"
                                    color="textPrimary"
                                    component="p"
                                    className="mr-2 fs-16"
                                >
                                    Send Messages Notification Summary
                                </Typography>
                                <RenderToggleSwitch
                                    id="notification_frequency"
                                    label=""
                                    labelClassName="ml-3"
                                    value={notification_frequency}
                                    onChange={handleNotificationFrequency}
                                    dataArray={frequency}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Container>
        </div>
    );
};

export default connect(null, {
    GetSingleUser,
    uploadImage,
    CommonDispatcher,
    UpdateUserProfile
})(EditUser);
