import React, { useEffect } from 'react';
import {
    Avatar,
    Button,
    Checkbox,
    createStyles,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle as MuiDialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
    WithStyles
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import style from './Styles.module.scss';
import {
    OPEN_ALERT_MODAL,
    TOGGLE_DISCLOSE_PRE_MONEY_MODAL
} from '../../../../redux/ModalReducer/actionTypes';
import { SHOW_SNACKBAR } from '../../../../redux/Common/actionTypes';
import { RootState } from '../../../../redux/store';
import { CommonDispatcher } from '../../../../redux/Common/action';
import {
    GetCapTableData,
    SetPreMoneyAccess
} from '../../../../redux/Deals/actions';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(() =>
    createStyles({
        dialogContainer: { maxWidth: '600px' },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                flex: '1 1 30%',
                margin: 'auto'
            }
        }
    })
);

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    hidden: boolean;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, hidden, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            hidden={hidden}
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DisclosePremoneyModal = ({
    CommonDispatcher,
    GetCapTableData,
    SetPreMoneyAccess
}: any) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();
    const store = useSelector((store: RootState) => store);
    const modal: any = store?.modal?.disclosePremoneyModal || {};
    const [checked, setChecked] = React.useState({});
    const [premoneyAccess, setPremoneyAccess] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const d: any = modal?.data || {};
    const [isUsersLoading, setUsersLoading] = React.useState(true);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    useEffect(() => {
        GetCapTableData(token, d?.equity_id).then((r) => {
            setUsersLoading(false);
            const interests = r?.response?.interests;
            let obj = {};
            let data = [];
            if (interests && interests.length) {
                setUsers(interests);
                interests.forEach((d) => {
                    data.push({
                        interest_id: d?.interest_id,
                        show_pre_money: d?.show_pre_money
                    });
                    obj = { ...obj, [d?.interest_id]: d?.show_pre_money };
                });
            }
            setPremoneyAccess(data);
            setChecked(obj);
        });
    }, []);

    const handleClose = () => {
        CommonDispatcher(TOGGLE_DISCLOSE_PRE_MONEY_MODAL, { isOpen: false });
    };

    const handleSelect = () => {
        CommonDispatcher(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: `Confirm?`,
            description: `Are you sure you want to disclose.`,
            data: { disableBackdropClick: true },
            submitTitle: 'Update',
            cancelTitle: 'Cancel',
            isDelete: true,
            callBack: () => {
                SetPreMoneyAccess(token, premoneyAccess).then((r) => {
                    if (r?.type === 'success') {
                        CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message:
                                'Premoney visibility updated successfully.',
                            hideAlert: true
                        });
                    } else {
                        CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Failed to updated premoney visibility.',
                            hideAlert: true,
                            error: true
                        });
                    }
                });
            }
        });
    };

    const handleToggle = (interest_id, show_pre_money) => () => {
        let obj = { ...checked, [interest_id]: !show_pre_money };
        setChecked(obj);
        let data = [];
        Object.entries(obj).forEach(([interest_id, show_pre_money]) => {
            data.push({ interest_id, show_pre_money });
        });
        setPremoneyAccess(data);
    };

    const isChecked = (interest_id) =>
        Object.keys(checked).length && checked[interest_id];

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth={modal?.config.modalSize || 'sm'}
            fullWidth={modal?.config.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-note"
            disableBackdropClick={modal?.config.disableBackdropClick || false}
            classes={{ paperScrollPaper: classes.dialogContainer }}
            onClose={handleClose}
        >
            <DialogTitle
                id="alert-dialog-title"
                hidden={modal?.config?.hideTitle || false}
                onClose={handleClose}
            >
                {modal?.title}
            </DialogTitle>
            <DialogContent className="py-0">
                <DialogContentText id="alert-dialog-note">
                    {modal?.contentTitle ? (
                        <div className="w-100 mb-5 text-left colorPrimary">
                            <span className="fs-16">{modal?.contentTitle}</span>
                        </div>
                    ) : null}
                    {isUsersLoading ? (
                        <div className="loader" />
                    ) : (
                        <List>
                            {users && users?.length ? (
                                users.map((d) => {
                                    return (
                                        <ListItem
                                            role={undefined}
                                            dense
                                            button
                                            key={d?.interest_id}
                                            onClick={handleToggle(
                                                d?.interest_id,
                                                isChecked(d?.interest_id)
                                            )}
                                        >
                                            <Checkbox
                                                edge="start"
                                                checked={isChecked(
                                                    d?.interest_id
                                                )}
                                                defaultChecked={isChecked(
                                                    d?.interest_id
                                                )}
                                                tabIndex={-1}
                                                className="mr-3"
                                                color="primary"
                                                disableRipple
                                            />
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={d?.full_name}
                                                    src={d?.profile_picture}
                                                    className={style.avatar}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <div className="d-flex">
                                                        <span>{d?.name}</span>
                                                        {d?.email ? (
                                                            <span>
                                                                &nbsp;-&nbsp;{' '}
                                                                {d?.email}
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                }
                                                secondary={d?.company_name}
                                            />
                                        </ListItem>
                                    );
                                })
                            ) : (
                                <p className="my-0 fs-14 my-5">
                                    No users found.
                                </p>
                            )}
                        </List>
                    )}
                    <div className="d-flex align-items-center justify-content-center">
                        <Button
                            onClick={handleClose}
                            color="primary"
                            className="mr-3"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSelect}
                            variant="contained"
                            color="primary"
                        >
                            Update Premoney Visibility
                        </Button>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default connect(null, {
    CommonDispatcher,
    GetCapTableData,
    SetPreMoneyAccess
})(DisclosePremoneyModal);
