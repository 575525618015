import * as React from 'react';
import style from './Style.module.scss';
import { Link } from 'react-router-dom';
import { Routes } from '../../../constants';
import ListingsProgressBarComponent from '../../Deals/DealsDetails/ListingsProgressBarComponent';
import imageNotFound from './../../../resources/image-not-found.webp';

const CommonDealDetailComponent = ({ data, raised, renderStatus }) => {
    const detailsPageLink = `${Routes.deals.deals}/deals-details/${data?.equity_id}`;

    return (
        <div className="d-flex justify-content-center flex-wrap row-gap-10 grid-column-gap-05">
            <div
                className={`m-auto d-flex align-items-center flex-direction-column text-center ${
                    raised?.length ? 'flex-grow-1' : 'flex-grow-05'
                } pl-2`}
            >
                <img
                    src={
                        data?.company_logo ? data?.company_logo : imageNotFound
                    }
                    alt={data?.company_name}
                    height={100}
                    width={100}
                    loading="lazy"
                    className={`m-auto pt-sm-3 object-fit-cover`}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imageNotFound;
                    }}
                />

                {renderStatus()}
            </div>
            <div className="flex-grow-2 pr-5">
                <div
                    className="d-flex justify-content-between justify-content-md-center
                                    flex-direction-column"
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <Link
                            to={detailsPageLink}
                            className="colorUnset text-decoration-none font-weight-bold text-hover-underline"
                        >
                            <div className="d-flex align-items-center">
                                <h2
                                    className={`m-0 d-flex align-items-center d-flex-sm-unset ${style.truncateTitle}`}
                                >
                                    <span className="itemLink">
                                        <span className="colorBlue">
                                            {data?.company_name}
                                        </span>
                                        &nbsp;-&nbsp;
                                        {data?.current_funding_stage ? (
                                            <b className="mt-2 text-uppercase">
                                                raising{' '}
                                                {
                                                    data?.current_funding_stage
                                                        ?.label
                                                }
                                                ,&nbsp;
                                            </b>
                                        ) : null}
                                        {data?.display_target_raise
                                            ? `${data?.display_target_raise} round`
                                            : null}
                                    </span>
                                </h2>
                            </div>
                        </Link>
                    </div>
                </div>

                {data?.soft_circled ? (
                    <ListingsProgressBarComponent
                        d={data}
                        raised={raised}
                        display_soft_circled={data?.display_soft_circled}
                        display_target_raise={data?.display_target_raise}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default CommonDealDetailComponent;
