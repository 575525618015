import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import StepOneComponent from './StepOneComponent';
import StepTwoComponent from './StepTwoComponent';
import { RootState } from '../../../redux/store';
import {
    RESET_STARTUP_EQUITY_DATA,
    SET_STARTUP_EQUITY_DEAL_DATA,
    UPDATE_WIZARD_FORM_DATA
} from '../../../redux/Deals/actionTypes';
import { CommonDispatcher } from '../../../redux/Common/action';
import { GetSingleDeal } from '../../../redux/Deals/actions';
import { useCookies } from 'react-cookie';
import StepThreeComponent from './StepThreeComponent';
import StepForthComponent from './StepForthComponent';
import ReviewComponent from './ReviewComponent';

const AddEdit = ({ match, GetSingleDeal, CommonDispatcher, jsonData }: any) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const {
        params: { equity_id }
    } = match;
    const store = useSelector((store: RootState) => store);
    const activeStep = store?.deals?.wizard?.currentStep;
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (equity_id) {
            setIsLoading(true);
            GetSingleDeal(token, { equity_id }).then((r) => {
                let data = { ...r?.response?.deal };
                if (data?.hq_geos && data?.hq_geos.length) {
                    data.hq_geos = data?.hq_geos.map((d) => d?.country_id);
                }
                if (data?.operational_geos && data?.operational_geos.length) {
                    data.ops_geos = data?.operational_geos.map(
                        (d) => d?.country_id
                    );
                    delete data?.operational_geos;
                }
                if (data?.sectors && data?.sectors.length) {
                    data.sectors = data?.sectors.map((d) => d?.sector_id);
                }
                CommonDispatcher(SET_STARTUP_EQUITY_DEAL_DATA, data);
                if (jsonData?.status === 'DRAFT') {
                    CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {
                        currentStep: jsonData?.page_number - 1
                    });
                }
                setIsLoading(false);
            });
        }
        return () => {
            CommonDispatcher(RESET_STARTUP_EQUITY_DATA, {});
        };
    }, [jsonData]);

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 1:
                return <StepTwoComponent equity_id={equity_id} />;
            case 2:
                return <StepThreeComponent equity_id={equity_id} />;
            case 3:
                return <StepForthComponent equity_id={equity_id} />;
            case 4:
                return <ReviewComponent equity_id={equity_id} />;
            case 0:
            default:
                return <StepOneComponent equity_id={equity_id} />;
        }
    }

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <div className="mt-5">{getStepContent(activeStep)}</div>
    );
};

export default connect(null, { GetSingleDeal, CommonDispatcher })(AddEdit);
