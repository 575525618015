import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import {
    DeleteEvent,
    GetEventsList,
    UpdateEvents
} from '../../redux/Events/actions';
import { connect, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../../redux/store';
import { Chip, Table } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { TOGGLE_CHANGE_OWNERSHIP_MODAL } from '../../redux/ModalReducer/actionTypes';
import { CommonDispatcher, OpenModal } from '../../redux/Common/action';
import { Routes } from '../../constants';
import { SHOW_SNACKBAR } from '../../redux/Common/actionTypes';
import { getQuery, goToListing } from '../../utils/common';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    container: {
        maxHeight: 'calc( 100vh - 120px)',
        minHeight: 'calc( 100vh - 120px)'
    }
});

/*"& > table": {
            "& > tbody": {
                "& > tr": {
                    "& > td": {
                        padding: '0 5px'
                    }
                }
            }
        }
    */

const options = [
    { title: 'Edit', value: 'edit' },
    { title: 'Go to details page', value: 'redirectToListings' }, //....test....for timexyz....otherwise luxembrergexpats.lu
    { title: 'Change ownership', value: 'changeOwnership' },
    { title: 'Delete', value: 'delete' }
];

const ITEM_HEIGHT = 48;
const Events = ({
    GetEventsList,
    DeleteEvent,
    UpdateEvents,
    CommonDispatcher,
    OpenModal
}) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const tableHeader = useSelector(
        (store: RootState) => store?.events.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.events.listingsData
    );
    const categories =
        useSelector(
            (store: RootState) => store?.commonAppData.categories?.events
        ) || [];
    const [page, setPage] = useState((listingsData?.current_page || 1) - 1);
    const rowsPerPage = 30;
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const query = getQuery(history);
    const keyword = query.get('keyword');

    useEffect(() => {
        try {
            getData({
                page_number: page + 1,
                keyword
            });
        } catch (e) {
            console.log(false);
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [keyword, page]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const getData = (data) => {
        try {
            setIsLoading(true);
            GetEventsList(token, data).then(() => {
                setIsLoading(false);
            });
        } catch (e) {
            console.log(false);
            setIsLoading(false);
        }
    };

    const appendPageNumber = (pageNumber) => {
        history.push({
            pathname: Routes.events.events,
            search: `?page=${pageNumber}`
        });
    };

    const renderEvents = () => {
        GetEventsList(token, listingsData?.current_page).then(() => {
            OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                isOpen: false,
                isLoading: false
            });
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Ownership changed successfully'
            });
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        appendPageNumber(newPage + 1);
    };

    const handleChange = (value, selectedUser) => {
        switch (value) {
            case 'edit':
                history.push(
                    `/edit${Routes.events.events}/${selectedUser?.event_id}`
                );
                break;
            case 'verify':
                console.log('Status changed');
                break;
            case 'redirectToListings':
                goToListing(
                    Routes.events.events,
                    categories,
                    selectedUser?.category_id,
                    selectedUser?.event_id
                );
                break;
            case 'changeOwnership':
                OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                    isOpen: true,
                    title: 'Change ownership',
                    submitTitle: 'Change',
                    data: {
                        modalSize: 'xs',
                        disableBackdropClick: true,
                        owner_email: selectedUser?.owner_email
                    },
                    callBack: (flag, user_id) => {
                        if (flag) {
                            OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                                isOpen: true,
                                isLoading: true,
                                title: 'Change ownership',
                                submitTitle: 'Change',
                                data: {
                                    modalSize: 'xs',
                                    disableBackdropClick: true,
                                    owner_email: selectedUser?.owner_email
                                }
                            });
                            UpdateEvents(token, {
                                event_id: selectedUser?.event_id,
                                user_id
                            })
                                .then((d) => {
                                    console.log(d);
                                    if (d?.type === 'success') {
                                        renderEvents();
                                    } else if (d?.type === 'failed') {
                                        OpenModal(
                                            TOGGLE_CHANGE_OWNERSHIP_MODAL,
                                            {
                                                isOpen: true,
                                                isLoading: false
                                            }
                                        );
                                        CommonDispatcher(SHOW_SNACKBAR, {
                                            isShowing: true,
                                            message:
                                                'Failed to change ownership'
                                        });
                                    }
                                })
                                .catch(() => {
                                    OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                                        isOpen: false,
                                        isLoading: false
                                    });
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: 'Failed to change ownership'
                                    });
                                });
                        }
                    }
                });
                break;
            case 'delete':
                if (
                    window?.confirm(
                        'Are you sure you want to delete this post?'
                    )
                ) {
                    DeleteEvent(token, selectedUser?.event_id)
                        .then(() => getData({ page }))
                        .catch(() => {
                            alert('Something went wrong.');
                        });
                }
                break;
        }
    };

    const renderMenu = ({ event_id, owner_email, category_id }) => {
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) =>
                        handleClick(e, { event_id, owner_email, category_id })
                    }
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    elevation={0}
                    PaperProps={{
                        style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            border: '1px solid rgb(0 106 194 / 20%)'
                        }
                    }}
                >
                    {options.map((option, i) => (
                        <MenuItem
                            key={option?.value}
                            onClick={() => {
                                handleChange(option?.value, selectedUser);
                                setAnchorEl(null);
                            }}
                        >
                            {option?.title}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };

    return (
        <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    {isLoading ? (
                        <div className="mainLoader">
                            <div className="loader" />
                        </div>
                    ) : (
                        <>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeader?.map((column) => (
                                                <TableCell key={column.id}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listingsData?.posts.length ? (
                                            listingsData?.posts?.map((row) => {
                                                const color = [
                                                    'LIVE',
                                                    'VERIFIED'
                                                ].includes(row.status)
                                                    ? '#319401'
                                                    : '#ff0000';
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row?.event_id}
                                                        style={{
                                                            padding: 0,
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={(e) => {
                                                            history.push(
                                                                `${Routes.events.events}/${row?.event_id}`
                                                            );
                                                        }}
                                                    >
                                                        <TableCell
                                                            size={'small'}
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                        >
                                                            {renderMenu(row)}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color,
                                                                minWidth:
                                                                    '100px'
                                                            }}
                                                            size={'small'}
                                                        >
                                                            {moment(
                                                                row?.createdAt
                                                            ).format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ color }}
                                                            size={'small'}
                                                        >
                                                            {row?.paymentStatus}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ color }}
                                                            size={'small'}
                                                        >
                                                            {row?.status}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ color }}
                                                            size={'small'}
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                        >
                                                            <Chip
                                                                size="small"
                                                                label={
                                                                    row?.status ===
                                                                    'LIVE'
                                                                        ? 'Deactivate'
                                                                        : 'Activate'
                                                                }
                                                                clickable
                                                                color={
                                                                    row?.status ===
                                                                    'LIVE'
                                                                        ? 'secondary'
                                                                        : 'primary'
                                                                }
                                                                onClick={() => {
                                                                    handleChange(
                                                                        'verify',
                                                                        row?.user_id
                                                                    );
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color,
                                                                minWidth:
                                                                    '100px'
                                                            }}
                                                            size={'small'}
                                                        >
                                                            {row?.expiry_date
                                                                ? moment(
                                                                      row?.expiry_date
                                                                  ).format(
                                                                      'DD MMM, YYYY'
                                                                  )
                                                                : 'N/A'}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ color }}
                                                            size={'small'}
                                                        >
                                                            {row?.title}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ color }}
                                                            size={'small'}
                                                        >
                                                            {row?.views}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ color }}
                                                            size={'small'}
                                                        >
                                                            {row?.owner_name}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ color }}
                                                            size={'small'}
                                                        >
                                                            {row?.owner_email}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        tableHeader.length + 1
                                                    }
                                                    className="dataNotAvailable"
                                                >
                                                    Data not available
                                                </td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                hidden={!listingsData?.posts.length}
                                rowsPerPageOptions={[]}
                                component="div"
                                count={listingsData?.count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default connect(null, {
    GetEventsList,
    UpdateEvents,
    CommonDispatcher,
    OpenModal,
    DeleteEvent
})(Events);
