import * as Actions from "./actionTypes";
import axios from "axios";
import {API_PATH} from "../../constants";

export const GetSubscriptions = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: "post",
                url: `${API_PATH}/admin/get-subscription-list`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_SUBSCRIPTIONS_DATA,
                payload
            });
        } catch (e) {
            throw e;
        }
    };
};

export const CreateSubscription = (token: string, data) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/create-subscription`,
                headers: {
                    Authorization: token
                },
                data
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateSubscription = (token: string, data) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/update-subscription`,
                headers: {
                    Authorization: token
                },
                data
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};

export const CancelSubscription = (token: string, data) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/cancel-subscription`,
                headers: {
                    Authorization: token
                },
                data
            };
            const response = (await axios(config as any))?.data?.data;
            return {type: 'success', response}
        } catch (e) {
            throw e;
        }
    };
};