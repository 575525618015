import React from "react";
import style from "./Style.module.scss";
import moment from "moment";
import {CommonDispatcher} from "../../../../redux/Common/action";
import {connect} from "react-redux";
import {OPEN_EXTEND_CLOSING_DATE_MODAL} from "../../../../redux/ModalReducer/actionTypes";

const ListingsProgressBarComponent = ({d, raised, display_soft_circled, display_target_raise, progressBackgroundColor, CommonDispatcher}: any) => {

    const remainingDays = d?.remaining_days ? d?.remaining_days : moment(d?.expiry_date).diff(moment(), "days");

    const handleExtendClosingDate = () => {
        CommonDispatcher(OPEN_EXTEND_CLOSING_DATE_MODAL, {
            isOpen: true,
            title: "Extend closing date",
            data: {disableBackdropClick: true, equity_id: d?.equity_id},
            submitTitle: "Extend",
            cancelTitle: "Cancel"
        });
    };

    return <>
        <div className="d-flex align-items-center">
            <div id="myProgress" className={`my-2 ${style.progressBar}`}
                 style={{backgroundColor: progressBackgroundColor}}>
                <div id="myBar" className={`bgColorBlue`} style={{
                    width: `${raised > 100 ? 100 : raised}%`,
                    height: "15px",
                    borderRadius: "50px"
                }}>
                </div>
            </div>
            <h2 className="ml-2 my-0 fs-16 font-weight-bold">{raised.toFixed(2)}%</h2>
        </div>
        <div className="mb-5 fs-16 d-flex flex-wrap font-weight-bold">
            <span className="colorBlue">
                {display_soft_circled} collected
            </span>
            <span>
                &nbsp;
                of {display_target_raise} target
                &nbsp;
        </span>
            {d?.is_expired ?
                <>
               <span className="colorDanger">
                   (Deal Closed on {moment(d?.expiry_date).format("Do MMM YYYY")})
               </span>
                    <span className="colorBlue cursor-pointer" onClick={handleExtendClosingDate}>
                            &nbsp;-&nbsp;Extend Closing Date
                </span>
                </>
                : <>
                    <span className="d-flex d-xs-none">
                        ({remainingDays} {remainingDays > 1 ? `days left to ` : `day left to `} {moment(d?.expiry_date).format("Do MMM YYYY")})
                    </span>
                    <span
                        className="d-none d-xs-flex">({remainingDays} {remainingDays > 1 ? `days left` : `day left`})</span>
                </>}
        </div>
    </>;
};

export default connect(null, {CommonDispatcher})(ListingsProgressBarComponent);