import { OPEN_ALERT_MODAL } from '../../../redux/ModalReducer/actionTypes';

export const OpenAlert = (obj) => {
    obj?.OpenModal(OPEN_ALERT_MODAL, {
        isOpen: true,
        title: obj?.title,
        description: obj?.description,
        data: { disableBackdropClick: true, payload: obj?.payload },
        submitTitle: obj?.submitTitle,
        cancelTitle: 'No',
        callBack: () => {
            obj?.UpdateUserProfile(obj?.token, obj?.payload)
                .then(() =>
                    obj?.getData({
                        keyword: obj?.keyword,
                        type: obj?.type,
                        page: obj?.page + 1,
                        tab: obj?.tab
                    })
                )
                .catch(() => {
                    obj?.OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
                });
        }
    });
};

export const CreateStrategyFieldsData = (data, key, mainDataList = []) => {
    return data && data
        ? data.map((r) => {
              let d;
              d = { title: r?.[key] };
              if (mainDataList && mainDataList.length) {
                  let obj = mainDataList.find((a) => a?.title === d?.title);
                  let { title, ...rest } = obj;
                  d = { ...d, ...rest };
              }
              return d;
          })
        : [];
};

export const USERS_MENU_OPTIONS = [
    { title: 'Go to User', value: 'profile' },
    { title: 'Edit User', value: 'editUser' },
    { title: 'Edit Strategy', value: 'editStrategy' },
    { title: 'User Settings', value: 'firmListModal' },
    { title: 'User Sessions', value: 'userSessions' },
    { title: 'Approve', value: 'approve' },
    { title: 'Decline', value: 'decline' },
    { title: 'Suspend', value: 'suspend' },
    { title: 'Delete', value: 'delete' },
    { title: 'Change Password', value: 'changePassword' },
    { title: 'Change Email', value: 'changeEmail' },
    { title: 'Add Affiliation', value: 'Affiliated' }
];

export const USERS_TOGGLE_OPTIONS = [
    { title: 'Y', value: true, disabled: false },
    { title: 'N', value: false, disabled: false }
];
export const USERS_ADMIN_TOGGLE_OPTIONS = [
    { title: 'ADMIN', value: 'ADMIN', disabled: false },
    { title: 'USER', value: 'USER', disabled: false }
];

export const TOGGLE_STORY_POST = [
    { title: 'Y', value: true, disabled: false },
    { title: 'N', value: false, disabled: false }
];
export const SOFT_PROFILE_STATUS = [
    { title: 'SHOW', value: true, disabled: false },
    { title: 'HIDE', value: false, disabled: false }
];
export const PHONE_PROFILE_STATUS = [
    { title: 'VERIFIED', value: true, disabled: false },
    { title: 'UNVERIFIED', value: false, disabled: false }
];
