import * as ACTIONS from './actionTypes';

const initialValues = {
    tableHeader: [
        { id: 'actions', label: '' },
        { id: 'selection', label: '' },
        { id: 'createdAt', label: 'Join Date' },
        { id: 'type', label: 'Type' },
        { id: 'admin_status', label: 'Admin Status' },
        { id: 'is_profile_complete', label: 'Profile Complete' },
        { id: 'country', label: 'Country' },
        { id: 'full_name', label: 'Name' },
        { id: 'firm', label: 'Firm' },
        { id: 'email', label: 'Email' },
        { id: 'phone', label: 'Phone' }
    ],
    listingsData: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    allUsersList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    unApprovedUsersList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    activeUsersList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    suspendedUsersList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    declinedUsersList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    deletedUsersList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    userSessions: {}
};

const UsersReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.USERS_ALL:
            return {
                ...state,
                allUsersList: {
                    posts: action?.payload?.users,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.USERS_UN_APPROVED:
            return {
                ...state,
                unApprovedUsersList: {
                    posts: action?.payload?.users,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.USERS_ACTIVE:
            return {
                ...state,
                activeUsersList: {
                    posts: action?.payload?.users,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.USERS_SUSPENDED:
            return {
                ...state,
                suspendedUsersList: {
                    posts: action?.payload?.users,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.USERS_DECLINED:
            return {
                ...state,
                declinedUsersList: {
                    posts: action?.payload?.users,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case ACTIONS.USERS_DELETED:
            return {
                ...state,
                deletedUsersList: {
                    posts: action?.payload?.users,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };

        case ACTIONS.GET_USER_SESSIONS:
            return {
                ...state,
                userSessions: action?.payload
            };

        default:
            return state;
    }
};

export default UsersReducer;
