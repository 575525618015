import React from "react";
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import {connect} from "react-redux";
import {CommonDispatcher} from "../../redux/Common/action";
import {SHOW_SNACKBAR} from "../../redux/Common/actionTypes";

const CommonSnackbar = (props: any) => {
    const handleClose = () => {
        props.CommonDispatcher(SHOW_SNACKBAR, {
            isShowing: false,
            message: "",
            type: ""
        });
    };

    return props?.snackbar?.hideAlert ? <Snackbar open={props?.snackbar?.isShowing}
                                                  anchorOrigin={props?.snackbar?.type === "info" ? {
                                                      vertical: "bottom",
                                                      horizontal: "left"
                                                  } : {vertical: "bottom", horizontal: "center"}}
                                                  message={props?.snackbar?.message}
                                                  autoHideDuration={props?.snackbar?.duration || 5000}
                                                  onClose={handleClose}/> :
        <Snackbar open={props?.snackbar?.isShowing}
                  anchorOrigin={props?.snackbar?.type === "info" ? {
                      vertical: "bottom",
                      horizontal: "left"
                  } : {vertical: "bottom", horizontal: "center"}}
                  autoHideDuration={props?.snackbar?.duration || 5000}
                  onClose={handleClose}>
            <Alert onClose={handleClose} severity={props?.snackbar?.type}>
                {props?.snackbar?.message}
            </Alert>
        </Snackbar>;
};

const mapStateToProps = (state) => {
    return {
        snackbar: state.commonAppData.snackbar
    };
};

export default connect(mapStateToProps, {CommonDispatcher})(CommonSnackbar);