import React, { useCallback, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import {
    CheckUserExists,
    CommonDispatcher,
    OpenModal
} from '../../../redux/Common/action';
import { OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { useCookies } from 'react-cookie';
import _ from 'lodash';
import RenderDatePicker from '../../InputComponents/DatePickerComponent/RenderDatePicker';

const ActivateSubscriptionModal = ({
    OpenModal,
    CheckUserExists,
    CommonDispatcher
}: any) => {
    const modal: any =
        useSelector(
            (store: RootState) => store?.modal?.activateSubscriptionModal
        ) || {};
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState();
    const [start_date, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [expiry_date, setExpiryDate] = useState(
        moment().format('YYYY-MM-DD')
    );
    const [isCheckUserLoading, setIsCheckUserLoading] = useState(false);
    const initialState = {};
    const [ownerData, setOwnerData] = useState<any>(initialState);
    const { control } = useForm();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const delayedQuery = useCallback(
        _.debounce((k) => handleSearch(k), 500),
        []
    );

    const doSearch = (e: any) => {
        const value = e.target.value;
        setEmail(email);
        if (value) {
            delayedQuery(value);
            setIsCheckUserLoading(true);
        } else {
            delayedQuery.cancel();
            setIsCheckUserLoading(false);
        }
    };

    const handleSearch = (email) => {
        CheckUserExists(token, { email }).then((d) => {
            setIsCheckUserLoading(false);
            if (d?.type === 'success') {
                setOwnerData(d?.response);
            } else if (d?.type === 'failed') {
                setOwnerData(initialState);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'User not found'
                });
            }
        });
    };

    const handleClose = () => {
        OpenModal(OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL, { isOpen: false });
    };

    const handleStartDateChange = (e) => {
        setStartDate(moment(e).format('YYYY-MM-DD'));
    };
    const handleEndDateChange = (e) => {
        setExpiryDate(moment(e).format('YYYY-MM-DD'));
    };

    const handleSubmit = async () => {
        const moment = (await import('moment')).default;
        /*   setLoading(true);
           modal.callBack(true, {
               user_id: ownerData?.user_id,
               type: "MEET_PEOPLE",
               is_active: false,
               status: "CANCELLED",
               start_date: start_date || null,
               expiry_date: expiry_date || null
           });*/
    };

    return (
        <Dialog
            maxWidth={modal?.data?.modalSize || 'sm'}
            fullWidth={modal?.data?.fullWidth || true}
            onClose={handleClose}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={modal?.data?.disableBackdropClick || false}
        >
            <DialogTitle id="alert-dialog-title">{modal?.title}</DialogTitle>
            <DialogContent className="mb-5">
                <FormControl variant="outlined" className="w-100">
                    <InputLabel htmlFor="email">Email</InputLabel>
                    <Controller
                        name="email"
                        control={control}
                        render={() => (
                            <OutlinedInput
                                required
                                fullWidth
                                autoFocus
                                name="email"
                                type="email"
                                label="Email"
                                id="email"
                                placeholder="john@email.com"
                                autoComplete="off"
                                onChange={(e) => doSearch(e)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {isCheckUserLoading ? (
                                            <CircularProgress
                                                size="1.3rem"
                                                className="circular-progress-primary ml-0"
                                            />
                                        ) : null}
                                    </InputAdornment>
                                }
                            />
                        )}
                        defaultValue=""
                    />
                </FormControl>
                <div className="w-100 mt-5">
                    <RenderDatePicker
                        id="start_date"
                        name="start_date"
                        showInputLabel={false}
                        removeMarginAndPadding={true}
                        onChange={handleStartDateChange}
                        label="Start date"
                        disablePast={true}
                        value={start_date}
                        format="DD-MM-YYYY"
                    />
                </div>
                <div className="w-100 mt-5">
                    <RenderDatePicker
                        id="expiry_date"
                        name="expiry_date"
                        showInputLabel={false}
                        removeMarginAndPadding={true}
                        label="Expiry date"
                        disablePast={true}
                        disabled={!start_date}
                        onChange={handleEndDateChange}
                        value={expiry_date}
                        minDate={start_date}
                        format="DD-MM-YYYY"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {modal?.cancelTitle || 'Cancel'}
                </Button>
                <Button
                    onClick={handleSubmit}
                    className="ml-3"
                    color="primary"
                    variant="contained"
                    disabled={
                        isLoading ||
                        !(email || start_date || expiry_date) ||
                        isCheckUserLoading ||
                        !Object.keys(ownerData).length
                    }
                >
                    {isLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress-primary ml-0"
                        />
                    ) : (
                        modal?.submitTitle || 'Ok'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, { OpenModal, CheckUserExists, CommonDispatcher })(
    React.memo(ActivateSubscriptionModal)
);
