import { Card, CardContent } from '@material-ui/core';

const CardComponent = ({
    cardClasses = '',
    cardContentClasses = '',
    onClick,
    children
}: any) => {
    return (
        <Card className={cardClasses} onClick={() => {
            typeof onClick !== 'undefined' && typeof onClick === 'function' && onClick()
        }}>
            <CardContent className={cardContentClasses}>
                {children}
            </CardContent>
        </Card>
    );
};

export default CardComponent;
