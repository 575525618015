import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styles from './App.module.css';
import { siteDetails as SiteDetailsAPI } from './Network/Auth/Login';
import Login from './components/Login';
import Router from './components/Router';
import { theme } from './muiTheme';

function App() {
    const [cookies] = useCookies(['pgAdminToken']);
    const [siteDetails, setSiteDetails] = useState();

    useEffect(() => {
        getSiteDetails();
    }, []);

    const getSiteDetails = async () => {
        const data = await SiteDetailsAPI();
        setSiteDetails(data?.client);
        const favicon = document.getElementById('favicon');
        favicon.setAttribute('href', data?.client?.favicon_logo);
        document.title = `${data?.client?.name} | Admin`;
    };
    return (
        <MuiThemeProvider theme={theme}>
            <div className={styles.body}>
                {cookies.pgAdminToken ? (
                    <Router />
                ) : (
                    <Login siteDetails={siteDetails} />
                )}
            </div>
        </MuiThemeProvider>
    );
}

export default App;
