import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useCookies } from 'react-cookie';
import Paper from '@material-ui/core/Paper';
import TableHeaderFilter from '../../Common/TableHeaderFilter';
import TableContainer from '@material-ui/core/TableContainer';
import { Chip, Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import { GetUsersList } from '../../../redux/Users/actions';
import { getQuery } from '../../../utils/common';
import { ROWS_PER_PAGE, UsersTab } from '../../../constants';
import { OPEN_ALERT_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { OpenModal } from '../../../redux/Common/action';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import { CommonPaginationAction } from '../Common/CommonPaginationAction';

const DeletedComponent = ({
    sectionBaseUrl,
    isMainFilterApplied,
    GetUsersList,
    OpenModal,
    loading
}: any) => {
    const classes = CommonTableStyle();
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(isMainFilterApplied);
    const tableHeader = useSelector(
        (store: RootState) => store?.users.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.users.deletedUsersList
    );
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const history = useHistory();
    const query = getQuery(history);
    const type = query.get('type');
    const keyword = query.get('keyword');
    const [page, setPage] = useState(0);
    const count = listingsData?.count;
    const total_pages = listingsData?.total_pages;

    useEffect(() => {
        if (
            !isFilterApplied &&
            (keyword || type || typeof page !== 'undefined')
        ) {
            getData({
                keyword,
                type,
                page: page + 1,
                tab: UsersTab.TYPE_DELETED
            });
        }
        // eslint-disable-next-line
    }, [keyword, type, page]);

    const getData = (data) => {
        data.size = ROWS_PER_PAGE;
        setIsLoading(true);
        GetUsersList(token, data).then(() => {
            setIsLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setIsFilterApplied(false);
    };

    const CommonComponent = () => (
        <CommonPaginationAction
            count={count}
            page={page}
            setPage={setPage}
            total_pages={total_pages}
        />
    );

    return (
        <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <TableHeaderFilter sectionBaseUrl={sectionBaseUrl} />

                    {loading || isLoading ? (
                        <div className="mainLoader">
                            <div className="loader" />
                        </div>
                    ) : (
                        <>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeader?.map((column) => (
                                                <TableCell key={column.id}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listingsData?.posts &&
                                        listingsData?.posts.length ? (
                                            listingsData?.posts?.map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row?.id}
                                                        style={{
                                                            padding: 0,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <TableCell
                                                            size={'small'}
                                                        />
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.admin_status}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                minWidth:
                                                                    '100px'
                                                            }}
                                                            size={'small'}
                                                        >
                                                            {moment(
                                                                row?.createdAt
                                                            ).format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.firm_name}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.type ? (
                                                                <Chip
                                                                    size="small"
                                                                    label={
                                                                        row?.type
                                                                    }
                                                                    color={
                                                                        row?.type ===
                                                                        'INVESTOR'
                                                                            ? 'primary'
                                                                            : 'secondary'
                                                                    }
                                                                    clickable
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.country}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.full_name}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.email}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.status}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.phone}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.phone_status}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {
                                                                row?.is_profile_complete
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        />
                                                        <TableCell
                                                            size={'small'}
                                                        />
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        tableHeader.length + 1
                                                    }
                                                    className="dataNotAvailable"
                                                >
                                                    Data not available
                                                </td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className={classes.footer}
                                hidden={
                                    listingsData?.posts &&
                                    !listingsData?.posts.length
                                }
                                rowsPerPageOptions={[]}
                                component="div"
                                count={count}
                                rowsPerPage={listingsData?.page_size}
                                page={page}
                                ActionsComponent={CommonComponent}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default connect(null, { GetUsersList, OpenModal })(DeletedComponent);
