export const SET_BUSINESS_LISTINGS_DATA = 'SET__FUNDS_BUSINESS_LISTINGS_DATA';
export const FUNDS_ALL = 'ALL';
export const FUNDS_UN_APPROVED = 'FUNDS_UN_APPROVED';
export const FUNDS_PINEGRAY = 'FUNDS_PINEGRAY';
export const FUNDS_OTHERS = 'FUNDS_OTHERS';
export const FUNDS_DECLINED = 'FUNDS_DECLINED';
export const UPDATE_FUNDS_WIZARD_FORM_DATA = 'UPDATE_FUNDS_WIZARD_FORM_DATA';
export const RESET_FUND_EQUITY_DATA = 'RESET_FUND_EQUITY_DATA';
export const SET_FUND_EQUITY_DEAL_DATA = 'SET_FUND_EQUITY_DEAL_DATA';
export const SET_FUND_EQUITY_DEAL_FORM_ERROR =
    'SET_FUND_EQUITY_DEAL_FORM_ERROR';
export const UPDATE_WIZARD_FORM_DATA = 'UPDATE_WIZARD_FORM_DATA';
