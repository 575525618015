import {
    Button,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    Typography,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import { RootState } from '../../../../redux/store';
import { UpdateDeal } from '../../../../redux/FundDeals/actions';
import {
    SET_FUND_EQUITY_DEAL_DATA,
    SET_FUND_EQUITY_DEAL_FORM_ERROR,
    UPDATE_FUNDS_WIZARD_FORM_DATA
} from '../../../../redux/FundDeals/actionTypes';
import {
    CommonDispatcher,
    UploadImages
} from '../../../../redux/Common/action';
import RenderAutocompleteTextField from '../../../InputComponents/AutocompleteTextField';
import RenderMaskedTextField from '../../../InputComponents/MaskedTextField';
import RenderSelectField from '../../../InputComponents/SelectField/RenderSelectField';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import { validateSecondStep } from '../Common/validate';
import { UpdatePost } from '../Common';
import style from '../StepTwoComponent/Style.module.scss';
import { getQuery } from '../../../../utils/common';
import countriesArrayData from '../../../../utils/countries.json';

import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const StepThreeComponent = ({
    metaData,
    CommonDispatcher,
    UpdateDeal
}: any) => {
    const history = useHistory();
    const query = getQuery(history);

    const post_id = query.get('post_id');
    const state = useSelector((store: RootState) => store);

    const activeStep = state?.FundDeals?.wizard?.currentStep || 0;
    const deal_data_error = state?.FundDeals?.deal_data_error || {};
    const [errors, setError, errorRef] = useStateRef({
        ...deal_data_error[activeStep]
    }) as [any, any, any];
    const { control, register, reset, getValues } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });
    const createList: any = (limit: any) => [...Array(limit + 1).keys()];

    const countriesArray =
        metaData?.countries && metaData?.countries?.length
            ? metaData?.countries
            : countriesArrayData || [];

    const CURRENTLY_RAISING_LIST =
        state?.commonAppData?.stages?.CURRENTLY_RAISING_LIST || [];

    const [no_cap, setNoCap] = useState(false);
    const [no_interest, setNoInterest] = useState(false);
    const [no_commitments, setNoCommitments] = useState(false);
    const [disablePreMoney, setDisablePreMoney] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [country, setCountry] = useState('');
    const [fund_regulation, setFundRegulation] = useState('');
    const steps = state?.FundDeals?.wizard?.formSteps || [];
    const errorStep = state?.FundDeals?.wizard?.errorStep || [];
    const deal_data = state?.FundDeals?.deal_data || {};
    const [countriesList, setCountriesList] = useState([]);
    const [fundTermYearsList, setFundTermYearsList] = useState([]);
    const [hq_geos, setHqGeo] = useState([]);
    const [hqGeoSize, setHqGeoSize] = useState<number>(0);
    const MAX_HQ_GEO_TAGS_SIZE = 3;
    const [fundTermExtensionsYearsList, setFundTermExtensionsYearsList] =
        useState([]);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [investmentYearsList, setInvestmentYearsList] = useState([]);
    const [extensionInvestmentYearsList, setExtensionInvestmentYearsList] =
        useState([]);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const currencySymbol = '$';
    let fieldsToValidate = [
        'fund_jurisdiction_geos',
        'regulaton_of_fund',
        'fund_term',
        'potential_extension',
        'investment_period',
        'investment_period_extension',
        'management_fee',
        'management_fee_note',
        'carry_fee',
        'carry_fee_note',
        'gp_min_fixed',
        'gp_max_fixed',
        'gp_commitment'
    ];

    if (disablePreMoney) {
        fieldsToValidate = fieldsToValidate.filter((d) => d !== 'pre_money');
    } else if (
        !disablePreMoney &&
        fieldsToValidate.some((d) => d !== 'pre_money')
    ) {
        fieldsToValidate.push('pre_money');
    }
    if (no_commitments) {
        fieldsToValidate = fieldsToValidate.filter((d) => d !== 'soft_circled');
    } else if (
        !no_commitments &&
        fieldsToValidate.some((d) => d !== 'soft_circled')
    ) {
        fieldsToValidate.push('soft_circled');
    }

    useEffect(() => {
        let obj: any = {};
        obj.soft_circled = deal_data?.soft_circled;

        Object.entries(deal_data).forEach(([k]) => {
            if (fieldsToValidate.includes(k)) {
                obj[k] = deal_data[k];
            }
        });
        obj.equity_id = deal_data?.equity_id;

        let countriesData = [];
        countriesArray.forEach((d) => {
            countriesData.push({ title: d?.title, value: d?.id });
        });
        countriesData = countriesData.sort((a, b) => a?.value - b?.value);
        setCountriesList(countriesData);

        if (obj.fund_jurisdiction_geos && obj.fund_jurisdiction_geos.length) {
            obj.fund_jurisdiction_geos = [
                ...countriesData.filter((d) =>
                    obj.fund_jurisdiction_geos.includes(d?.value)
                )
            ];

            setHqGeo(obj.fund_jurisdiction_geos);
        }
        let regionsData = [];

        let fundTermYearsList = createList(15);
        fundTermYearsList.shift();
        let fundTermYears = fundTermYearsList.map((d) => ({
            title: d,
            value: d
        }));
        setFundTermYearsList(fundTermYears);

        let fundTermExtensionsYearsList = createList(15);
        fundTermExtensionsYearsList.shift();
        let fundTermExtensions = fundTermExtensionsYearsList.map((d) => ({
            title: `+${d}`,
            value: d
        }));
        setFundTermExtensionsYearsList(fundTermExtensions);

        let investmentYearsList = createList(10);
        investmentYearsList.shift();
        let investmentYears = investmentYearsList.map((d) => ({
            title: d,
            value: d
        }));
        setInvestmentYearsList(investmentYears);

        let extensionInvestmentYears = createList(10);
        extensionInvestmentYears.shift();
        let extensionInvestment = extensionInvestmentYears.map((d) => ({
            title: `+${d}`,
            value: d
        }));
        setExtensionInvestmentYearsList(extensionInvestment);

        reset(obj);
    }, [deal_data]);

    useEffect(() => {
        if (activeStep > 5) setWizardError();
    }, [errorStep]);

    const setWizardError = () => {
        let { ...rest } = errorRef?.current;
        CommonDispatcher(SET_FUND_EQUITY_DEAL_FORM_ERROR, {
            [activeStep]: rest
        });
        const errItemsCount = Object.keys(rest).filter((d) => rest[d])?.length;
        let errorStepObj = { ...errorStep, [activeStep]: errItemsCount };
        CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
            errorStep: errorStepObj
        });
    };

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };

    const handleBack = (flag = false) => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep - 1
            });
        }
        let data: any = getFormValues(getValues());
        const err = validateSecondStep(data, CURRENTLY_RAISING_LIST);
        setError(err);

        if (
            data?.fund_jurisdiction_geos &&
            data?.fund_jurisdiction_geos.length
        ) {
            data.fund_jurisdiction_geos = data?.fund_jurisdiction_geos.map(
                (d) => d?.value
            );
        }
        CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, data);
        flag && setWizardError();
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());
        data.equity_id = post_id || deal_data?.equity_id;

        if (
            data?.fund_jurisdiction_geos &&
            data?.fund_jurisdiction_geos.length
        ) {
            data.fund_jurisdiction_geos = data?.fund_jurisdiction_geos.map(
                (d) => d?.value
            );
        }

        if (no_commitments) {
            data.no_commitments = no_commitments;
            data.soft_circled = null;
        }
        if (disablePreMoney) {
            data.pre_money = null;
            if (no_cap) {
                data.no_cap = no_cap;
                data.convertible_cap = null;
            }
            if (no_interest) {
                data.no_interest = no_interest;
                data.convertible_interest = null;
            }
        } else {
            if (data?.pre_money) {
                data.no_cap = null;
                data.convertible_cap = null;
                data.no_interest = null;
                data.convertible_interest = null;
            }
        }
        if (data?.equity_id) {
            setLoading(true);
            UpdatePost(
                (data) => UpdateDeal(token, data),
                CommonDispatcher,
                data,
                UploadImages,
                setLoading,
                next,
                setWizardError
            );
        }
    };

    const getFormValues = (obj) => {
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            setError({ ...errorRef.current, [key]: !value });
            data[key] = value;
        });
        return data;
    };

    const onCountryChange = (e) => {
        const value = e.target.value;
        setCountry(value);
    };

    const handleFundJurisdictionChange = (value) => {
        setHqGeo(value);

        setHqGeoSize(value.length as number);
    };

    return (
        <Container maxWidth="md">
            <Grid item xs={12} sm={10} className="m-auto pb-5">
                <div className="">
                    <form>
                        <div data-aos="fade-left" data-aos-duration="200">
                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="fund_jurisdiction_geos"
                                        render={({ onChange, value }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="fund_jurisdiction_geos"
                                                    value={hq_geos}
                                                    label="Fund Jurisdiction *"
                                                    size="small"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    inputAutoComplete="off"
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleFundJurisdictionChange(
                                                            data
                                                        );
                                                    }}
                                                    options={
                                                        hqGeoSize <
                                                        MAX_HQ_GEO_TAGS_SIZE
                                                            ? countriesList
                                                            : []
                                                    }
                                                    inputPlaceholder="Search Fund Jurisdiction"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.hq_geos ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.hq_geos &&
                                            'HQ geography is mandatory'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="regulaton_of_fund"
                                        as={
                                            <RenderTextField
                                                id="regulaton_of_fund"
                                                autoComplete="off"
                                                value={fund_regulation}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Describe the regulation of the fund *"
                                            />
                                        }
                                        control={control}
                                        defaultValue={null}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="fund_term"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderSelectField
                                                    id="fund_term"
                                                    label="Fund Term (# of years) *"
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                        onCountryChange(e);
                                                    }}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-raising-currency"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        fundTermYearsList
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="potential_extension"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderSelectField
                                                    id="potential_extension"
                                                    label="Potential extensions (years) *"
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                        onCountryChange(e);
                                                    }}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-raising-currency"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        fundTermExtensionsYearsList
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="investment_period"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderSelectField
                                                    id="investment_period"
                                                    label="Investment Period (years) *"
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                        onCountryChange(e);
                                                    }}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-raising-currency"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        investmentYearsList
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="investment_period_extension"
                                        render={({ onChange, value }) => {
                                            return (
                                                <RenderSelectField
                                                    id="investment_period_extension"
                                                    label="Investment Period extension (years) *"
                                                    value={value}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                        onCountryChange(e);
                                                    }}
                                                    inputContainerClassName={
                                                        'w-100'
                                                    }
                                                    inputId="select-raising-currency"
                                                    dropdownListHeight={250}
                                                    dataArray={
                                                        extensionInvestmentYearsList
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="management_fee"
                                        as={
                                            <RenderTextField
                                                id="management_fee"
                                                type="number"
                                                inputMode="numeric"
                                                autoComplete="off"
                                                endAdornment="%"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Management Fee (%) *"
                                            />
                                        }
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="management_fee_note"
                                        as={
                                            <RenderTextField
                                                id="management_fee_note"
                                                autoComplete="off"
                                                value={fund_regulation}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Add a note for management fee"
                                            />
                                        }
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="carry_fee"
                                        as={
                                            <RenderTextField
                                                id="carry_fee"
                                                type="number"
                                                inputMode="numeric"
                                                autoComplete="off"
                                                endAdornment="%"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Carry fee (%) *"
                                            />
                                        }
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="carry_fee_note"
                                        as={
                                            <RenderTextField
                                                id="carry_fee_note"
                                                autoComplete="off"
                                                value={fund_regulation}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Add a note for carry fee"
                                            />
                                        }
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <Typography
                                        component="h4"
                                        variant="h4"
                                        className="mt-10 fs-18 font-weight-bold"
                                    >
                                        Proposed GP Commitment *
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="gp_min_fixed"
                                        as={
                                            <RenderMaskedTextField
                                                id="gp_min_fixed"
                                                autoComplete="off"
                                                startAdornment={currencySymbol}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Mi​nimum Fixed"
                                            />
                                        }
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="gp_max_fixed"
                                        as={
                                            <RenderMaskedTextField
                                                id="gp_max_fixed"
                                                autoComplete="off"
                                                startAdornment={currencySymbol}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Ma​ximum Fixed"
                                            />
                                        }
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <Typography
                                        component="h4"
                                        variant="h4"
                                        className="fs-18 font-weight-bold"
                                    >
                                        OR
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <Controller
                                        name="gp_commitment"
                                        as={
                                            <RenderTextField
                                                id="gp_commitment"
                                                type="number"
                                                inputMode="numeric"
                                                autoComplete="off"
                                                endAdornment="%"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="% of fund size"
                                            />
                                        }
                                        control={control}
                                        defaultValue={country}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.country ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.country && 'Select country'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="mt-5 py-4 border-top mb-sm-12 d-flex align-items-center justify-content-center btn-submit-post w-100">
                            <Button
                                color="secondary"
                                variant="text"
                                type="button"
                                className={`fs-16 text-inherit`}
                                onClick={() => handleBack(true)}
                            >
                                Back
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onSave}
                                disabled={isLoading}
                                className="fs-16 text-inherit btn-submit-style ml-5"
                            >
                                Review{' '}
                                {isLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : null}
                            </Button>
                        </div>
                    </form>
                </div>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    CommonDispatcher,
    UpdateDeal,
    UploadImages
})(StepThreeComponent);
