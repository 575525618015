import React from "react";

export function TabPanel({children, value, index, ...other}) {

    return (
        <div role="tabpanel"
             hidden={value !== index}
             id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`}
             {...other}>
            {value === index && (
                <>{children}</>
            )}
        </div>
    );
}