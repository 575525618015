import {DASHBOARD_DATA} from '../InitialState';
import * as ACTIONS from './actionTypes';

const dashboardReducer = (state = DASHBOARD_DATA, action:any) => {
    switch (action.type) {
        case ACTIONS.CHANGE_SELECTED_MENU:
            return {
                ...state,
                selected_index: action?.payload
            };
        case ACTIONS.SET_USER_DATA:
            return {
                ...state,
                tab_wise_data: {
                    ...state?.tab_wise_data,
                    0: {
                        ...state?.tab_wise_data?.["0"],
                        rows: action?.payload?.users,
                        count: action?.payload?.count,
                        current_page: action?.payload?.page,
                        page_size: action?.payload?.size,
                        total_pages: action?.payload?.total_pages
                    }
                }
            };
        case ACTIONS.SET_BIZ_SERVICES_DATA:
            return {
                ...state,
                tab_wise_data: {
                    ...state?.tab_wise_data,
                    1: {
                        ...state?.tab_wise_data?.["1"],
                        rows: action?.payload?.posts,
                        count: action?.payload?.count,
                        current_page: action?.payload?.page,
                        page_size: action?.payload?.size,
                        total_pages: action?.payload?.total_pages
                    }
                }
            };
        case ACTIONS.SET_REAL_ESTATE_DATA:
            return {
                ...state,
                tab_wise_data: {
                    ...state?.tab_wise_data,
                    2: {
                        ...state?.tab_wise_data?.["2"],
                        rows: action?.payload?.posts,
                        count: action?.payload?.count,
                        current_page: action?.payload?.page,
                        page_size: action?.payload?.size,
                        total_pages: action?.payload?.total_pages
                    }
                }
            };
        case ACTIONS.SET_BUY_SELL_DATA:
            return {
                ...state,
                tab_wise_data: {
                    ...state?.tab_wise_data,
                    3: {
                        ...state?.tab_wise_data?.["3"],
                        rows: action?.payload?.posts,
                        count: action?.payload?.count,
                        current_page: action?.payload?.page,
                        page_size: action?.payload?.size,
                        total_pages: action?.payload?.total_pages
                    }
                }
            };
        case ACTIONS.SET_OFFERS_DATA:
            return {
                ...state,
                tab_wise_data: {
                    ...state?.tab_wise_data,
                    4: {
                        ...state?.tab_wise_data?.["4"],
                        rows: action?.payload?.posts,
                        count: action?.payload?.count,
                        current_page: action?.payload?.page,
                        page_size: action?.payload?.size,
                        total_pages: action?.payload?.total_pages
                    }
                }
            };
        case ACTIONS.SET_STORIES_DATA:
            return {
                ...state,
                tab_wise_data: {
                    ...state?.tab_wise_data,
                    6: {
                        ...state?.tab_wise_data?.["6"],
                        rows: action?.payload?.posts,
                        count: action?.payload?.count,
                        current_page: action?.payload?.page,
                        page_size: action?.payload?.size,
                        total_pages: action?.payload?.total_pages
                    }
                }
            };
        default:
            return state;
    }
};

export default dashboardReducer;