import React from 'react';
// import Linkify from 'react-linkify';
// import renderHTML from 'react-render-html';

export default function Tuple(props: any) {
    return (
        <div
            style={{
                margin: '5px',
                padding: '10px',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <span
                style={{ lineHeight: 0.5 }}
                className="fs-16 mr-2 font-weight-bold"
            >
                {props?.label}:
            </span>
            {/* <Linkify>
                <span
                    className="removeChildMargin"
                    style={{
                        color: '#5a5a5a',
                        maxWidth: '100%'
                    }}
                >
                    {renderHTML(props?.value || 'NA')}
                </span>
            </Linkify> */}
        </div>
    );
}
