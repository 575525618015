import React from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import { OpenModal } from '../../../redux/Common/action';
import { Close } from '@material-ui/icons';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const CommonModalWrapper = ({ children, modal, onClose = null }: any) => {
    return (
        <Dialog
            maxWidth={modal?.config?.modalSize || 'xs'}
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={modal?.config?.disableBackdropClick || false}
        >
            {onClose ? (
                <CustomDialogTitle id="alert-dialog-title" onClose={onClose}>
                    {modal?.title}
                </CustomDialogTitle>
            ) : (
                <MuiDialogTitle id="alert-dialog-title">
                    {modal?.title}
                </MuiDialogTitle>
            )}

            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default connect(null, { OpenModal })(CommonModalWrapper);
