import {
    Avatar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { CancelSharp, Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { TOGGLE_STORY_POST } from '.';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { GetFirmsList, UpdateFirm } from '../../../redux/Firms/actions';
import { AFFILIATED_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { GetSingleUser, UpdateUserProfile } from '../../../redux/Users/actions';
import { RootState } from '../../../redux/store';
import RenderToggleSwitch from '../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { AsyncPaginate } from 'react-select-async-paginate';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const AffiliatedModal = ({
    OpenModal,
    UpdateFirm,
    GetFirmsList,
    UpdateUserProfile,
    CommonDispatcher,
    GetSingleUser
}) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.affiliatedModal) || {};
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [affiliatedOpt, setAffiliatedOpt] = useState([]);
    const [selectedAffiliated, setSelectedAffiliated] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statuses, setStatuses]: any = useState({});
    const user_id = modal?.data?.selectedUser?.user_id;
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [value, setValue] = useState<any>([]);

    const { control, reset } = useForm({
        mode: 'onBlur'
    });

    useEffect(() => {
        setLoading(true);
        getFirmsListData();
        getSingleUser();
    }, []);

    const getSingleUser = () => {
        GetSingleUser(token, { user_id }).then((x) => {
            if (x?.type === 'success') {
                const selectedFirms = x?.response?.firm_affiliated_data || [];
                let firm_affiliated_data = [];

                const updateFirms = selectedFirms.map((item) => {
                    firm_affiliated_data.push({
                        firm_id: item?.firm_id,
                        user_affiliation_visibility:
                            item?.user_affiliation_visibility,
                        global_affiliation_visibility:
                            item?.global_affiliation_visibility,
                        firm_logo: item?.firm_logo
                    });
                    return {
                        title: item?.label,
                        value: item?.firm_id,
                        label: item?.label,
                        ...item
                    };
                });
                setSelectedAffiliated(updateFirms);
                reset({ affiliated_firms: updateFirms });
                setStatuses({ user_id, firm_affiliated_data });
            }
            setLoading(false);
        });
    };

    const getFirmsListData = async () => {
        return await GetFirmsList(token, {
            is_affiliated: true,
            tab: 'ALL'
        }).then((r) => {
            const firms = r?.response?.firms;
            let firmsData = [];

            firms.forEach((d) => {
                firmsData.push({ title: d?.label, value: d?.firm_id });
            });

            setAffiliatedOpt(firmsData);
        });
    };

    const handleClose = () => {
        OpenModal(AFFILIATED_MODAL, { isOpen: false });
    };
    const handleFirmTypeChange = (value) => {
        let firm_affiliated_data = [...statuses.firm_affiliated_data];
        value.forEach((status) => {
            if (
                Object.keys(status).length === 3 &&
                'title' in status &&
                'value' in status
            ) {
                firm_affiliated_data.push({
                    firm_id: status.value,
                    user_affiliation_visibility: false,
                    global_affiliation_visibility: false
                });
            }
        });
        console.log(`🚀 ~ handleFirmTypeChange ~ value:`, firm_affiliated_data);

        setSelectedAffiliated(value);
        setStatuses({ user_id, firm_affiliated_data });
    };
    const handleToggleType = (firms, value, key) => {
        let firm_id = firms?.firm_id || firms?.value;
        const { user_affiliation_visibility, global_affiliation_visibility } =
            firms;
        let obj = {};
        const finalValue = !!value;
        let firm_affiliated_data = statuses?.firm_affiliated_data || [];
        if (firm_affiliated_data && firm_affiliated_data.length) {
            if (
                !firm_affiliated_data.map((a) => a?.firm_id).includes(firm_id)
            ) {
                firm_affiliated_data.push({
                    firm_id,
                    user_affiliation_visibility,
                    global_affiliation_visibility,
                    [key]: finalValue
                });
            } else {
                firm_affiliated_data = firm_affiliated_data.map((a) => {
                    if (a?.firm_id === firm_id) {
                        a.firm_id = firm_id;
                        a.user_affiliation_visibility =
                            user_affiliation_visibility;
                        a.global_affiliation_visibility =
                            global_affiliation_visibility;
                        a[key] = finalValue;
                    }
                    return a;
                });
            }
        } else {
            firm_affiliated_data.push({
                firm_id,
                user_affiliation_visibility,
                global_affiliation_visibility,
                [key]: finalValue
            });
        }
        obj = {
            user_id,
            firm_affiliated_data
        };

        let updatedData = selectedAffiliated.map((x) => {
            if (x.value === firms?.value) {
                x[key] = finalValue;
            }
            return x;
        });
        setSelectedAffiliated(updatedData);
        setStatuses({ ...statuses, ...obj });
    };
    const handleUpdate = () => {
        setIsUpdateLoading(true);
        updateFirmsList();
    };

    const updateFirmsList = () => {
        UpdateUserProfile(token, statuses).then((r) => {
            if (r?.response?.data?.error) {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: r?.response?.data?.error || 'Failed to update.',
                    hideAlert: true,
                    error: true
                });
            } else {
                getSingleUser();
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Updated successfully.'
                });
            }
            setIsUpdateLoading(false);
            setLoading(false);
        });
    };

    const toggleAffiliated = async (data = null) => {
        if (!data) {
            let promiseData = [];
            // statuses?.firm_affiliated_data.forEach((a) => {
            //     promiseData.push(
            //         UpdateFirm(token, {
            //             firm_id: a?.firm_id,
            //             is_affiliated: false
            //         })
            //     );
            // });

            // Promise.allSettled(promiseData).then((r) => {
            //     statuses.firm_affiliated_data = [];
            //     setStatuses({ ...statuses });
            //     getFirmsListData().then(() => {
            //         updateFirmsList();
            //     });
            // });
            statuses.firm_affiliated_data = [];

            setStatuses({ ...statuses });
            handleUpdate();
        } else {
            const { firm_id } = data;

            statuses.firm_affiliated_data =
                statuses?.firm_affiliated_data.filter(
                    (d) => d?.firm_id !== firm_id
                );

            setStatuses({ ...statuses });
            handleUpdate();
            // UpdateFirm(token, {
            //     firm_id,
            //     is_affiliated: false
            // }).then(() => {
            //     getFirmsListData();
            //     updateFirmsList();
            // });
        }
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        const response = await GetFirmsList(token, {
            is_affiliated: true,
            tab: 'ALL',
            search,
            page
        });

        const firms = response?.response?.firms;
        const firmsData = firms.map((d) => ({
            label: d.label,
            value: d.firm_id,
            title: d.label
        }));

        return {
            options: firmsData,
            hasMore: page < response.response.total_pages,
            additional: {
                page: page + 1
            }
        };
    };

    return (
        <Dialog
            className="w-100"
            maxWidth="sm"
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                <div className="w-100 d-flex">{modal?.title}</div>
            </DialogTitle>
            <DialogContent style={{ minHeight: '400px', height: '100%' }}>
                <div className="d-flex flex-direction-xs-column">
                    <Controller
                        name="affiliated_firms"
                        control={control}
                        render={(field) => (
                            <div className="w-100">
                                <AsyncPaginate
                                    {...field}
                                    isMulti
                                    value={field?.value}
                                    onChange={(newValue) => {
                                        setValue(newValue);
                                        field?.onChange(newValue);
                                        handleFirmTypeChange(newValue);
                                        if (!newValue?.length) {
                                            toggleAffiliated();
                                        }
                                    }}
                                    loadOptions={loadOptions}
                                    additional={{
                                        page: 1
                                    }}
                                    components={{
                                        MultiValue: ({ data }) => (
                                            <TextField
                                                value={data.label}
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                toggleAffiliated(
                                                                    data
                                                                );
                                                            }}
                                                        >
                                                            <CancelSharp />
                                                        </IconButton>
                                                    )
                                                }}
                                            />
                                        )
                                    }}
                                    styles={{
                                        menu: (provided) => ({
                                            ...provided,
                                            zIndex: 9999,
                                            position: 'absolute'
                                        })
                                    }}
                                />
                            </div>
                        )}
                    />
                </div>
                <div className="mt-5">
                    {loading ? (
                        <div className="loader" />
                    ) : (
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {[
                                            { label: 'Firm Name' },
                                            {
                                                label: 'User Affiliation Visibility'
                                            },
                                            {
                                                label: 'Global Affiliation Visibility'
                                            }
                                        ]?.map((column) => (
                                            <TableCell key={column.label}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedAffiliated.map((x) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={x?.value}
                                                style={{
                                                    padding: 0,
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <TableCell>
                                                    <div className="d-flex align-items-center row-gap-10">
                                                        <Avatar
                                                            src={x?.firm_logo}
                                                            className={
                                                                'avatar mr-4'
                                                            }
                                                        />

                                                        {x?.title}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <RenderToggleSwitch
                                                        buttonGroupClassName="w-unset ml-3"
                                                        value={
                                                            x[
                                                                'user_affiliation_visibility'
                                                            ] || false
                                                        }
                                                        onChange={(e, value) =>
                                                            handleToggleType(
                                                                x,
                                                                value,
                                                                'user_affiliation_visibility'
                                                            )
                                                        }
                                                        dataArray={
                                                            TOGGLE_STORY_POST
                                                        }
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <RenderToggleSwitch
                                                        buttonGroupClassName="w-unset ml-3"
                                                        value={
                                                            x[
                                                                'global_affiliation_visibility'
                                                            ] || false
                                                        }
                                                        onChange={(e, value) =>
                                                            handleToggleType(
                                                                x,
                                                                value,
                                                                'global_affiliation_visibility'
                                                            )
                                                        }
                                                        dataArray={
                                                            TOGGLE_STORY_POST
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button
                    onClick={handleUpdate}
                    variant="contained"
                    color="primary"
                    className="mb-4"
                >
                    Update
                    {isUpdateLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress"
                        />
                    ) : null}
                </Button>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    className="mb-4"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    UpdateFirm,
    GetFirmsList,
    UpdateUserProfile,
    CommonDispatcher,
    GetSingleUser
})(AffiliatedModal);
