import axios from 'axios';
import { API_PATH } from '../../constants';
import * as Actions from '../Feedback/actionTypes';

export const GetFeedbackList = (token: string, data) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/feedbacks`,
                headers: { Authorization: token },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_FEEDBACK_DATA,
                payload
            });
            return { type: 'success', response: payload };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
