import {
    CREATE_METADATA,
    DELETE_METADATA,
    FETCH_METADATA_LIST,
    FETCH_SINGLE_METADATA,
    UPDATE_METADATA
} from './actionTypes';

const initialState = {
    metadataList: {
        current_page: 0,
        total_pages: 0,
        metadata: [],
        count: 0
    },
    singleMetadata: null
};

const MetadataReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_METADATA_LIST:
            return {
                ...state,
                metadataList: {
                    metadata: action?.payload?.metadata,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        case UPDATE_METADATA:
            // Update an existing metadata entry in the list
            const updatedMetadataList = state.metadataList.metadata.map(
                (item) => {
                    if (item.meta_id === action.payload.meta_id) {
                        return { ...item, ...action.payload };
                    }
                    return item;
                }
            );

            return {
                ...state,
                metadataList: {
                    ...state.metadataList,
                    metadata: updatedMetadataList
                }
            };
        case DELETE_METADATA: {
            const updatedMetadataList = state.metadataList.metadata.filter(
                (item) => item.meta_id !== action.payload.meta_id
            );

            return {
                ...state,
                metadataList: {
                    ...state.metadataList,
                    metadata: updatedMetadataList,
                    count: state.metadataList.count - 1 // Also decrement the count if you're keeping track of the total number
                }
            };
        }
        // Update an existing metadata entry in the list
        case FETCH_SINGLE_METADATA:
            return { ...state, singleMetadata: action.payload };
        case CREATE_METADATA:
            return {
                ...state,
                metadataList: {
                    ...state.metadataList,
                    metadata: [...state.metadataList?.metadata, action.payload]
                }
            };
        default:
            return state;
    }
};

export default MetadataReducer;
