import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../../redux/store';
import { Chip, Table } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from '../../constants';
import { GetFeedbackList } from '../../redux/Feedback/actions';
import { getQuery } from '../../utils/common';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    container: {
        maxHeight: 'calc( 100vh - 120px)',
        minHeight: 'calc( 100vh - 120px)'
    }
});

const Feedback = ({ GetFeedbackList }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const tableHeader = useSelector(
        (store: RootState) => store?.feedback.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.feedback.listingsData
    );
    const [page, setPage] = useState((listingsData?.current_page || 1) - 1);
    const rowsPerPage = 30;
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const query = getQuery(history);
    const keyword = query.get('keyword') || '';
    const page_number = Number(query.get('page')) || 1;
    useEffect(() => {
        getData({ keyword, page: page_number });
        // eslint-disable-next-line
    }, [keyword, page_number]);

    const getData = (data) => {
        setIsLoading(true);
        GetFeedbackList(token, data).then(() => {
            setIsLoading(false);
        });
    };

    const appendPageNumber = (pageNumber) => {
        history.push({
            pathname: Routes.feedback,
            search: `?page=${pageNumber}`
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        appendPageNumber(newPage + 1);
    };

    return (
        <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    {isLoading ? (
                        <div className="mainLoader">
                            <div className="loader" />
                        </div>
                    ) : (
                        <>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeader?.map((column) => (
                                                <TableCell key={column.id}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listingsData?.posts.length ? (
                                            listingsData?.posts?.map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row?.feedback_id}
                                                        style={{
                                                            padding: 0,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <TableCell
                                                            style={{
                                                                minWidth:
                                                                    '100px'
                                                            }}
                                                            size={'small'}
                                                        >
                                                            {moment(
                                                                row?.createdAt
                                                            ).format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.name || '-'}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.email || '-'}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.phone || '-'}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.equity_id ? (
                                                                <Link
                                                                    color="inherit"
                                                                    to={`deals/deals-details/${row?.equity_id}`}
                                                                >
                                                                    {
                                                                        row?.equity_id
                                                                    }
                                                                </Link>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            <Chip
                                                                size="small"
                                                                label={
                                                                    row?.type
                                                                }
                                                                color={
                                                                    row?.type ===
                                                                    'FEEDBACK'
                                                                        ? 'primary'
                                                                        : 'secondary'
                                                                }
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.feedback_text ||
                                                                '-'}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        tableHeader.length + 1
                                                    }
                                                    className="dataNotAvailable"
                                                >
                                                    Data not available
                                                </td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                hidden={!listingsData?.posts.length}
                                rowsPerPageOptions={[]}
                                component="div"
                                count={listingsData?.count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default connect(null, { GetFeedbackList })(Feedback);
