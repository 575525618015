export const validate = (data) => {
    let errorRef: any = {};
    if (!data?.currentPassword) {
        errorRef.currentPassword = true;
    }
    if (!data?.newPassword) {
        errorRef.newPassword = true;
    }
    return errorRef;
};

export const validateWithMasterPassword = (data) => {
    let errorRef: any = {};
    if (!data?.currentPassword) {
        errorRef.currentPassword = true;
    }
    if (!data?.newPassword) {
        errorRef.newPassword = true;
    }
    if (!data?.master_password) {
        errorRef.master_password = true;
    }
    return errorRef;
};
