import {
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    Typography,
    withStyles,
    WithStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    createStyles,
    MuiThemeProvider,
    Theme
} from '@material-ui/core/styles';
import { Close, CloudUpload } from '@material-ui/icons';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
// import { theme } from '../../../../css/muiTheme';
import { useCookies } from 'react-cookie';
import { EXCEL_MIME_TYPES } from '../../../constants';
import { theme } from '../../../muiTheme';
import { CommonDispatcher } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { GetFirmsList, ImportFirms } from '../../../redux/Firms/actions';
import { IMPORT_FIRM_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import { SAMPLE_FILE_URL } from '../../../utils/constants';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    hidden: boolean;
    onClose: () => void;
}

const ImportFirmsModal = ({
    CommonDispatcher,
    GetFirmsList,
    ImportFirms
}: any) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.importFirmModal) || {};
    const [isLoading, setIsLoading] = useState(false);
    const [res, setRes] = useState({});
    const user_max_upload_limit = 50;
    const handleClose = () => {
        CommonDispatcher(IMPORT_FIRM_MODAL, { isOpen: false });
    };
    const keyword = '';
    const page_number = '';
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    let paginationInfo: any = {
        size: 10
    };

    if (page_number) {
        paginationInfo.page_number = page_number;
    }
    if (page_number) {
        paginationInfo.keyword = keyword;
    }

    const uploadDoc = ({ target }) => {
        setIsLoading(true);
        if (target?.files && target?.files?.length) {
            const file = target?.files?.[0];
            const size = file?.size / 1024 / 1024;
            const type = file?.type;

            if (size > user_max_upload_limit) {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: `The file size can not exceed ${user_max_upload_limit}MB`,
                    hideAlert: true,
                    error: true
                });
                return;
            } else if (!EXCEL_MIME_TYPES.includes(type)) {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Only .xls & .xlsx type files supported',
                    hideAlert: true,
                    error: true
                });
            } else {
                const fileReader = new FileReader();
                const name = file?.name;
                fileReader.readAsDataURL(file);
                fileReader.onload = () => {
                    let formData = new FormData();
                    formData.append('files', file as any, name);
                    ImportFirms(token, formData).then((r) => {
                        if (r?.type === 'success') {
                            if (
                                Object.keys(r?.response) &&
                                Object.keys(r?.response).length
                            ) {
                                GetFirmsList(token, paginationInfo).then(() => {
                                    setRes(r?.response);
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: `Firms Imported successfully.`,
                                        hideAlert: true
                                    });
                                    setIsLoading(false);
                                });
                            }
                        } else if (
                            r?.type === 'error' &&
                            r?.response?.data?.error
                        ) {
                            setIsLoading(false);
                            CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                message:
                                    r?.response?.data?.error ||
                                    'Failed to import Firms',
                                hideAlert: true,
                                error: true
                            });
                        } else {
                            setIsLoading(false);
                            CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                message: 'Failed to import Firms',
                                hideAlert: true,
                                error: true
                            });
                        }
                        const fileInput = document.getElementById(
                            'file'
                        ) as HTMLInputElement;
                        if (fileInput) {
                            fileInput.value = '';
                        }
                    });
                };
            }
        }
    };

    const DialogTitleDiv = withStyles(styles)((props: DialogTitleProps) => {
        const { children, hidden, classes, onClose, ...other } = props;
        return (
            <DialogTitle
                hidden={hidden}
                disableTypography
                className={`text-center ${classes.root}`}
                {...other}
            >
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    });

    return (
        <MuiThemeProvider theme={theme}>
            <Dialog
                maxWidth={modal?.config?.modalSize || 'xs'}
                fullWidth={modal?.config?.fullWidth || false}
                open={modal?.isOpen || false}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitleDiv
                    id="alert-dialog-title"
                    hidden={false}
                    onClose={handleClose}
                >
                    {modal?.title}
                </DialogTitleDiv>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            item
                            xs={12}
                            className="my-8 d-flex align-items-center justify-content-center flex-direction-column"
                        >
                            <input
                                accept={EXCEL_MIME_TYPES.toString()}
                                className="d-none"
                                type="file"
                                id="file"
                                onChange={(e) => uploadDoc(e)}
                            />
                            <div
                                className="d-flex align-items-center justify-content-center
                                        border-bottom pb-1"
                            >
                                <label htmlFor={`file`}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        component="span"
                                        className="float-right px-2"
                                        size="small"
                                    >
                                        <CloudUpload className="mr-2" />
                                        Upload Firms Excel File
                                        {isLoading ? (
                                            <CircularProgress
                                                size="1.3rem"
                                                className="circular-progress ml-2"
                                            />
                                        ) : null}
                                    </Button>
                                </label>
                            </div>
                            <span className="mt-1 fs-13">
                                Max {user_max_upload_limit}
                                MB limit
                            </span>
                        </Grid>
                        <div>
                            <b className="text-black fs-22">How to import?</b>
                            <ol className="mt-1 -ml24">
                                <a
                                    href={SAMPLE_FILE_URL}
                                    download
                                    className="text-decoration-none text-hover-underline"
                                >
                                    <li className="py-1">
                                        Download the template (Excel).
                                    </li>
                                </a>
                                <li className="py-1">
                                    Copy your Firms in the relevant columns
                                    <b> (Do not delete the first row)</b>.
                                </li>
                                <li className="py-1">Upload the Firms.</li>
                                <li className="py-1">
                                    Note: Only .xls or .xlsx files are accepted
                                    (no CSV).
                                </li>
                            </ol>
                        </div>
                        {Object.keys(res) && Object.keys(res).length ? (
                            <dl>
                                <dt>
                                    <b className="text-black fs-22">
                                        Import Firms successful:
                                    </b>
                                </dt>
                                {Object.keys(res).map((i, j) =>
                                    i !== 'user_id' ? (
                                        <dt className="py-1 text-black">
                                            {capitalizeFirstLetter(
                                                i
                                                    ?.replace(
                                                        'total_excel_',
                                                        ''
                                                    )
                                                    .replace('_', ' ')
                                            )}
                                            : {res[i]}
                                        </dt>
                                    ) : null
                                )}
                                <dt className="py-1">
                                    Duplicate records with the same email
                                    address are not imported.
                                </dt>
                            </dl>
                        ) : null}
                    </DialogContentText>
                </DialogContent>

                <DialogActions className="d-flex align-items-center justify-content-center">
                    <Button
                        onClick={handleClose}
                        disabled={isLoading}
                        autoFocus
                        variant="outlined"
                        size="small"
                        className="my-2"
                    >
                        {'Cancel'}
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiThemeProvider>
    );
};

const mapDispatchToProps = {
    CommonDispatcher,
    GetFirmsList,
    ImportFirms
};

export default connect(null, mapDispatchToProps)(ImportFirmsModal);
