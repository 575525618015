export const SET_USERS_DATA = "SET_USERS_DATA";

export const USERS_ALL = "USERS_ALL";
export const USERS_UN_APPROVED = "USERS_UN_APPROVED";
export const USERS_ACTIVE = "USERS_ACTIVE";
export const USERS_SUSPENDED = "USERS_SUSPENDED";
export const USERS_DECLINED = "USERS_DECLINED";
export const USERS_DELETED = "USERS_DELETED";

export const GET_USER_SESSIONS = "GET_USER_SESSIONS";

 