import React from 'react';
import style from './RenderToggleSwitch.module.scss';
import { InputLabel } from '@material-ui/core';
import clsx from 'clsx';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

interface DataArray {
    title: any;
    value: any;
    disabled?: boolean;
    selected?: boolean;
}

interface IRenderToggleSwitch {
    id?: string;
    label?: string;
    value?: any;
    size?: 'small' | 'medium' | 'large';
    helperText?: string;
    error?: boolean;
    toggleButtonGroupId?: any;
    labelClassName?: string;
    buttonGroupClassName?: string;
    buttonClassName?: string;
    checkedClassName?: string;
    inputContainerClassName?: string;
    dataArray: DataArray[];
    onChange?: (e: any, data: any, groupId: any) => void;
}

const RenderToggleSwitch = ({
    id,
    label,
    value,
    size,
    toggleButtonGroupId,
    checkedClassName,
    labelClassName,
    buttonGroupClassName,
    buttonClassName,
    onChange,
    dataArray,
    error,
    helperText,
    inputContainerClassName
}: IRenderToggleSwitch) => {
    return dataArray.length ? (
        <div className={`${style.inputContainer} ${inputContainerClassName}`}>
            <InputLabel
                htmlFor={id}
                className={clsx(labelClassName, style.label)}
            >
                {label}
            </InputLabel>
            <ToggleButtonGroup
                exclusive
                size={size}
                id={toggleButtonGroupId || ''}
                value={value}
                onChange={(a, b) => {
                    if (
                        typeof onChange !== 'undefined' &&
                        typeof onChange === 'function'
                    ) {
                        onChange(a, b, toggleButtonGroupId);
                    }
                }}
                aria-label={label}
                className={
                    error
                        ? clsx('w-100', buttonGroupClassName, style.error)
                        : clsx(
                              'w-100',
                              buttonGroupClassName,
                              style.toggleButtonGroup
                          )
                }
            >
                {dataArray.map((d) => {
                    const selected = d?.selected || d?.value === value;
                    return (
                        <ToggleButton
                            key={d?.value}
                            aria-label={d?.title}
                            selected={selected}
                            value={d?.value}
                            disabled={d?.disabled}
                            className={clsx(
                                buttonClassName,
                                style.toggleButton,
                                d?.disabled ? style.disabled : '',
                                selected
                                    ? checkedClassName
                                        ? checkedClassName
                                        : style.checked
                                    : ''
                            )}
                        >
                            {d?.title}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
            {error ? <InputLabel error={true}>{helperText}</InputLabel> : null}
        </div>
    ) : null;
};
export default RenderToggleSwitch;
