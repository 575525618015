import React from "react";
import {Button, CircularProgress, Grid, IconButton, Tooltip} from "@material-ui/core";
import style from "./Style.module.scss";
import {Delete} from "@material-ui/icons";

interface IFooterActionButtonsProps {
    id?: any
    isActive?: boolean
    isLoading?: boolean
    hideCancel?: boolean
    isLastStep?: boolean
    tooltipTitle?: string
    submitTitle?: string
    backButtonTitle?: string
    handleDelete?: () => void
    handleCancel?: () => void
    handleNext?: () => void
    handleBack?: () => void
    handleSubmit?: () => void
}

const WizardFooterActionButtons = ({
                                       id, isLoading, tooltipTitle, isLastStep, hideCancel, submitTitle, isActive,
                                       handleDelete, handleCancel, handleBack, handleNext, backButtonTitle, handleSubmit
                                   }: IFooterActionButtonsProps) => {


    return <Grid container className={style.fixedFooter} id="fixedStickyFooter">
        <Grid item xs={12}
              className={`py-2 d-flex align-items-center justify-content-center justify-content-xs-left
              ${style.btnContainer}`}>
            {/*className={`py-2 d-flex align-items-center ${!id ? "justify-content-end" : "justify-content-between"}*/}
            {id ? <div className={style.listingBtnGroup}>
                <Tooltip title={tooltipTitle || "Delete this Ad"}
                         aria-label={tooltipTitle || "Delete this Ad"}
                         placement="bottom"
                         arrow={true}>
                    <IconButton aria-label="delete"
                                className="d-none d-sm-block colorDanger"
                                onClick={handleDelete}>
                        <Delete/>
                    </IconButton>
                </Tooltip>

                <Button onClick={handleDelete}
                        variant="contained"
                        color="secondary"
                        className="d-block d-sm-none">
                    Delete
                </Button>
            </div> : null}
            <Button onClick={handleBack}
                    disabled={!isLastStep || isLoading}
                    className="d-block mx-5 mx-xs-2">
                {backButtonTitle ? backButtonTitle : "Back"}
            </Button>
            <div>
                {!hideCancel ? <Button color="primary"
                                       className="mx-5 mx-xs-2"
                                       onClick={() => {
                                           if (handleCancel && typeof handleCancel !== "undefined") {
                                               handleCancel();
                                           } else {
                                           }
                                       }}>
                    Cancel
                </Button> : null}
                <Button className={`${style.btnSubmit}`}
                        type={"button"}
                        variant="contained"
                        onClick={() => {
                            if (!isLastStep) {
                                handleNext();
                            } else {
                                handleSubmit();
                            }
                        }}
                        disabled={isLoading || isActive}>
                    {submitTitle}
                    {" "}
                    {isLoading ?
                        <CircularProgress size="1.3rem" className="circular-progress"/> : ""}
                </Button>
            </div>
        </Grid>
    </Grid>;
};

export default (WizardFooterActionButtons);