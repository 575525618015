import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import Dashboard from './Dashboard';
import Users from './Users';
import Deals from './Deals';
import FundDeals from './FundDeals';
import Firms from './Firms';
import Events from './Events';
import Stories from './Stories';
import Referral from './Referral';
import Subscriptions from './Subscriptions';
import thunk from 'redux-thunk';
import { ModalReducer } from './ModalReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { CommonAppData } from './Common';
import FeedbackReducer from './Feedback';
import ClientConfigReducer from './ClientConfig';
import MetadataReducer from './MetaData';
import ProductsServicesReducer from './ProductsServices';

const reducers = combineReducers({
    Dashboard,
    users: Users,
    deals: Deals,
    FundDeals: FundDeals,
    firms: Firms,
    events: Events,
    stories: Stories,
    referral: Referral,
    subscriptions: Subscriptions,
    feedback: FeedbackReducer,
    metaData: MetadataReducer,
    clientConfig: ClientConfigReducer,
    modal: ModalReducer,
    commonAppData: CommonAppData,
    productsServices: ProductsServicesReducer
});
const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default function configureStore() {
    const enhancer = composeEnhancers(applyMiddleware(thunk));
    return createStore(reducers, enhancer);
}
export const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(thunk))
);
export type RootState = ReturnType<typeof reducers>;
