import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    ListSubheader,
    DialogTitle as MuiDialogTitle,
    Popover,
    TablePagination,
    Theme,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect, useSelector } from 'react-redux';
import { OpenModal } from '../../../redux/Common/action';
import { RootState } from '../../../redux/store';
import {
    BROAD_CAST_MESSAGES_MODAL,
    BROAD_CAST_USERS_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import { GetBroadCastMessage } from '../../../redux/Users/actions';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import RenderDealReadMoreLess from '../../Common/RenderDealReadMoreLess';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '46ch',
        backgroundColor: theme.palette.background.paper
    },
    inline: {
        display: 'inline'
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const BroadCastMessageModal = ({ OpenModal, GetBroadCastMessage }) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const modal: any =
        useSelector(
            (store: RootState) => store?.modal?.broadCastMessageModal
        ) || {};
    const [broadCastData, setBroadCast] = useState<any>();
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);

    const [readMore, setReadMore] = React.useState(false);
    const handleReadMore = () => {
        setReadMore(!readMore);
    };
    const handleClose = () => {
        OpenModal(BROAD_CAST_MESSAGES_MODAL, { isOpen: false });
    };
    const [popoverState, setPopoverState] = useState<any>({
        open: false,
        anchorEl: null,
        id: null
    });

    const handlePopoverOpen = (event, data) => {
        setPopoverState({ open: true, anchorEl: event.currentTarget, data });
    };

    const handlePopoverClose = () => {
        setPopoverState({ open: false, anchorEl: null, id: null });
    };
    const fetchData = async (page_number) => {
        const resp = await GetBroadCastMessage(
            token,
            page_number + 1,
            rowsPerPage
        ); // Adjust API call
        if (resp?.type === 'success') {
            setBroadCast(resp?.response);
        }
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, rowsPerPage]);

    const classes = useStyles();

    return (
        <Dialog
            className="w-100"
            maxWidth={'xs'}
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                <div className="w-100 d-flex">{modal?.title}</div>
            </DialogTitle>
            <DialogContent>
                <List className={classes.root}>
                    {broadCastData?.history && broadCastData.history.length
                        ? broadCastData.history.map((data) => (
                              <React.Fragment key={data.id}>
                                  <ListItem
                                      alignItems="flex-start"
                                      className="d-flex flex-direction-column"
                                  >
                                      <ListItemText
                                          secondary={
                                              <React.Fragment>
                                                  Sent by{' '}
                                                  <Tooltip
                                                      title={
                                                          data?.broadcaster
                                                              ?.full_name
                                                      }
                                                      aria-label={
                                                          data?.broadcaster
                                                              ?.full_name
                                                      }
                                                      placement="bottom"
                                                      arrow
                                                  >
                                                      <strong draggable={false}>
                                                          {
                                                              data?.broadcaster
                                                                  ?.full_name
                                                          }
                                                      </strong>
                                                  </Tooltip>{' '}
                                                  on{' '}
                                                  <strong>
                                                      {moment(
                                                          data?.createdAt
                                                      ).format('DD MMM, YYYY')}
                                                  </strong>
                                              </React.Fragment>
                                          }
                                      />
                                      <ListItemText
                                          secondary={
                                              <React.Fragment>
                                                  <strong>Sent List</strong>
                                                  <Button
                                                      size="small"
                                                      color="primary"
                                                      variant="outlined"
                                                      className={`text-inherit ml-2`}
                                                      disabled={
                                                          data?.error_count===0
                                                      }
                                                      onClick={() =>
                                                          OpenModal(
                                                              BROAD_CAST_USERS_MODAL,
                                                              {
                                                                  title: 'Broadcast User List',
                                                                  isOpen: true,
                                                                  data: {
                                                                      broadcast_id:
                                                                          data?.broadcast_id
                                                                  }
                                                              }
                                                          )
                                                      }
                                                  >
                                                      Failed Users
                                                  </Button>
                                                  <Button
                                                      size="small"
                                                      color="default"
                                                      variant="outlined"
                                                      className={`text-inherit ml-2`}
                                                      onClick={(event) =>
                                                          handlePopoverOpen(
                                                              event,
                                                              data
                                                          )
                                                      }
                                                  >
                                                      View Stats
                                                  </Button>
                                                  <Popover
                                                      open={
                                                          popoverState.open &&
                                                          popoverState?.data
                                                              .id === data.id
                                                      }
                                                      anchorEl={
                                                          popoverState.anchorEl
                                                      }
                                                      onClose={
                                                          handlePopoverClose
                                                      }
                                                      anchorOrigin={{
                                                          vertical: 'bottom',
                                                          horizontal: 'right'
                                                      }}
                                                      transformOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'center'
                                                      }}
                                                      classes={{
                                                          paper: 'max-w-500 w-100'
                                                      }}
                                                  >
                                                      {popoverState.open ? (
                                                          <div
                                                              className={`p-3 text-center`}
                                                          >
                                                              <List
                                                                  subheader={
                                                                      <ListSubheader
                                                                          disableSticky={
                                                                              true
                                                                          }
                                                                          className="px-0"
                                                                      >
                                                                          View
                                                                          Status
                                                                      </ListSubheader>
                                                                  }
                                                                  className="p-0 mt-0"
                                                              >
                                                                  <ListItem className="px-0 pt-0">
                                                                      <ListItemText>
                                                                          Total
                                                                          Users
                                                                          Found:{' '}
                                                                          {
                                                                              popoverState
                                                                                  ?.data
                                                                                  .users_found
                                                                          }
                                                                      </ListItemText>
                                                                  </ListItem>
                                                                  <ListItem className="px-0 pt-0">
                                                                      <ListItemText>
                                                                          Success
                                                                          Count:{' '}
                                                                          {
                                                                              popoverState
                                                                                  ?.data
                                                                                  .success_count
                                                                          }
                                                                      </ListItemText>
                                                                  </ListItem>
                                                                  <ListItem className="px-0 pt-0">
                                                                      <ListItemText>
                                                                          Error
                                                                          Count:{' '}
                                                                          {
                                                                              popoverState
                                                                                  ?.data
                                                                                  .error_count
                                                                          }
                                                                      </ListItemText>
                                                                  </ListItem>
                                                                  <ListItem className="px-0 pt-0">
                                                                      <ListItemText>
                                                                          Queued
                                                                          Success
                                                                          Count:{' '}
                                                                          {
                                                                              popoverState
                                                                                  ?.data
                                                                                  .queue_success_count
                                                                          }
                                                                      </ListItemText>
                                                                  </ListItem>
                                                                  <ListItem className="px-0 pt-0">
                                                                      <ListItemText>
                                                                          Queued
                                                                          Error
                                                                          Count:{' '}
                                                                          {
                                                                              popoverState
                                                                                  ?.data
                                                                                  .queue_error_count
                                                                          }
                                                                      </ListItemText>
                                                                  </ListItem>
                                                              </List>
                                                          </div>
                                                      ) : null}
                                                  </Popover>
                                              </React.Fragment>
                                          }
                                      />
                                      <ListItemText
                                          secondary={
                                              <React.Fragment>
                                                  <strong>Message</strong>
                                                  <RenderDealReadMoreLess
                                                      description={
                                                          data?.message_content
                                                      }
                                                      handleReadMore={
                                                          handleReadMore
                                                      }
                                                      readMore={readMore}
                                                  />
                                              </React.Fragment>
                                          }
                                      />
                                  </ListItem>
                                  <Divider />
                              </React.Fragment>
                          ))
                        : null}
                </List>
                <TablePagination
                    rowsPerPageOptions={[30, 50, 75]}
                    component="div"
                    count={broadCastData?.count || 0}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={(event, newPage) => setCurrentPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setCurrentPage(0); // Reset to first page when changing rows per page
                    }}
                />
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button onClick={handleClose} color="primary" className="mb-4">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    GetBroadCastMessage
})(BroadCastMessageModal);
