import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { useCookies } from 'react-cookie';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TableHeaderFilter from '../../Common/TableHeaderFilter';
import TableContainer from '@material-ui/core/TableContainer';
import { Avatar, Table } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import { getQuery } from '../../../utils/common';
import {
    DeleteDeal,
    GetDealsList,
    UpdateDeal,
    UpdateDealOwnership
} from '../../../redux/FundDeals/actions';
import { OpenModal, CommonDispatcher } from '../../../redux/Common/action';
import { DealsTab, Routes, ROWS_PER_PAGE } from '../../../constants';
import {
    DECLINED,
    ENV,
    LIVE_DOMAIN,
    PINEGRAY,
    SUSPENDED,
    TEST_DOMAIN
} from '../../../utils/constants';
import { menuOptions, OpenAlert } from '../Common';
import {
    OPEN_ALERT_MODAL,
    TOGGLE_CHANGE_OWNERSHIP_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import style from '../AllListings/Style.module.scss';
import { CommonPaginationAction } from '../../Users/Common/CommonPaginationAction';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';

const DeclinedListingsComponent = ({
    sectionBaseUrl,
    GetDealsList,
    UpdateDeal,
    UpdateDealOwnership,
    DeleteDeal,
    OpenModal,
    loading,
    isMainFilterApplied,
    getAllTabsData
}: any) => {
    const classes: any = CommonTableStyle();
    const [isFilterApplied, setIsFilterApplied] = useState(isMainFilterApplied);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const tableHeader = useSelector(
        (store: RootState) => store?.FundDeals.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.FundDeals.declinedDealsList
    );
    const history = useHistory();
    const query = getQuery(history);
    const type = query.get('type');
    const keyword = query.get('keyword');
    const [page, setPage] = useState(0);
    const count = listingsData?.count;
    const total_pages = listingsData?.total_pages;
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        if (
            !isFilterApplied &&
            (keyword || type || typeof page !== 'undefined')
        ) {
            getData({
                keyword,
                type,
                page: page + 1,
                tab: DealsTab.TYPE_DECLINED
            });
        }
        // eslint-disable-next-line
    }, [keyword, type, page]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const getData = (data) => {
        data.size = ROWS_PER_PAGE;
        setIsLoading(true);
        GetDealsList(token, data).then(() => {
            setIsLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setIsFilterApplied(false);
    };

    const CommonComponent = () => (
        <CommonPaginationAction
            count={count}
            page={page}
            setPage={setPage}
            total_pages={total_pages}
        />
    );

    const handleChange = (value, selectedUser) => {
        switch (value) {
            case 'edit':
                history.push(
                    `${Routes.fund_deals.deals}/edit-deal/${selectedUser?.equity_id}`
                );
                break;
            case 'approve':
                OpenAlert({
                    title: 'Approve',
                    description: 'Are you sure you want to approve this deal?',
                    submitTitle: 'Approve',
                    token,
                    payload: {
                        equity_id: selectedUser?.equity_id,
                        admin_status: PINEGRAY
                    },
                    tab: DealsTab.TYPE_DECLINED,
                    OpenModal,
                    UpdateDeal,
                    getData: getAllTabsData,
                    page
                });
                break;
            case 'decline':
                OpenAlert({
                    title: 'Decline',
                    description: 'Are you sure you want to decline this deal?',
                    submitTitle: 'Decline',
                    token,
                    payload: {
                        equity_id: selectedUser?.equity_id,
                        admin_status: DECLINED
                    },
                    tab: DealsTab.TYPE_DECLINED,
                    OpenModal,
                    UpdateDeal,
                    getData: getAllTabsData,
                    page
                });
                break;
            case 'suspend':
                OpenAlert({
                    title: 'Suspend',
                    description: 'Are you sure you want to Suspend this deal?',
                    submitTitle: 'Suspend',
                    token,
                    payload: {
                        equity_id: selectedUser?.equity_id,
                        admin_status: SUSPENDED
                    },
                    tab: DealsTab.TYPE_DECLINED,
                    OpenModal,
                    UpdateDeal,
                    getData: getAllTabsData,
                    page
                });
                break;
            case 'delete':
                if (
                    window?.confirm(
                        'Are you sure you want to delete this deal?'
                    )
                ) {
                    DeleteDeal(token, selectedUser?.equity_id)
                        .then(() =>
                            getAllTabsData({
                                page,
                                tab: DealsTab.TYPE_DECLINED
                            })
                        )
                        .catch(() => {
                            alert('Something went wrong.');
                        });
                }
                break;
            case 'changeOwnership':
                OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                    isOpen: true,
                    title: 'Change ownership',
                    submitTitle: 'Change',
                    data: {
                        modalSize: 'xs',
                        disableBackdropClick: true,
                        owner_email: selectedUser?.owner?.email
                    },
                    callBack: (flag, user_id) => {
                        if (flag) {
                            OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                                isOpen: true,
                                isLoading: true,
                                title: 'Change ownership',
                                submitTitle: 'Change',
                                data: {
                                    modalSize: 'xs',
                                    disableBackdropClick: true,
                                    owner_email: selectedUser?.owner?.email
                                }
                            });

                            UpdateDealOwnership(token, {
                                equity_id: selectedUser?.equity_id,
                                user_id
                            })
                                .then((d) => {
                                    if (d?.type === 'success') {
                                        getAllTabsData({
                                            page
                                        });
                                    } else if (
                                        d?.type === 'failed' &&
                                        d?.response?.data?.error
                                    ) {
                                        OpenModal(
                                            TOGGLE_CHANGE_OWNERSHIP_MODAL,
                                            {
                                                isOpen: false,
                                                isLoading: false
                                            }
                                        );
                                        CommonDispatcher(SHOW_SNACKBAR, {
                                            isShowing: true,
                                            message:
                                                d?.response?.data?.error ||
                                                'Failed to change ownership'
                                        });
                                    } else {
                                        OpenModal(
                                            TOGGLE_CHANGE_OWNERSHIP_MODAL,
                                            {
                                                isOpen: false,
                                                isLoading: false
                                            }
                                        );
                                        CommonDispatcher(SHOW_SNACKBAR, {
                                            isShowing: true,
                                            message:
                                                'Failed to change ownership'
                                        });
                                    }
                                })
                                .catch(() => {
                                    OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                                        isOpen: false,
                                        isLoading: false
                                    });
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: 'Failed to change ownership'
                                    });
                                });
                        }
                    }
                });
                break;
        }
    };

    const renderMenu = (item) => {
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, item)}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    elevation={0}
                    PaperProps={{
                        style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            border: '1px solid rgb(0 106 194 / 20%)'
                        }
                    }}
                >
                    {menuOptions.map((option, i) => (
                        <MenuItem
                            key={option?.value}
                            onClick={() => {
                                handleChange(option?.value, selectedUser);
                                setAnchorEl(null);
                            }}
                        >
                            {option?.title}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };

    return (
        <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <TableHeaderFilter sectionBaseUrl={sectionBaseUrl} />
                    {loading || isLoading ? (
                        <div className="mainLoader">
                            <div className="loader" />
                        </div>
                    ) : (
                        <>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeader?.map((column) => (
                                                <TableCell key={column.id}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listingsData?.posts &&
                                        listingsData?.posts.length ? (
                                            listingsData?.posts?.map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row?.equity_id}
                                                        style={{
                                                            padding: 0,
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={(e) => {
                                                            history.push(
                                                                `funds-details/${row?.equity_id}`
                                                            );
                                                        }}
                                                    >
                                                        <TableCell
                                                            size={'small'}
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                        >
                                                            {renderMenu(row)}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.admin_status}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.status}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row
                                                                ?.sponsored_owner
                                                                ?.full_name ? (
                                                                <a
                                                                    href={
                                                                        ENV ===
                                                                        'DEVELOPMENT'
                                                                            ? `${TEST_DOMAIN}/${row?.sponsored_owner?.user_name}`
                                                                            : `${LIVE_DOMAIN}/${row?.sponsored_owner?.user_name}`
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {
                                                                        row
                                                                            ?.sponsored_owner
                                                                            ?.full_name
                                                                    }
                                                                </a>
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        />
                                                        <TableCell
                                                            style={{
                                                                minWidth:
                                                                    '100px'
                                                            }}
                                                            size={'small'}
                                                        >
                                                            {moment(
                                                                row?.received_date
                                                            ).format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {moment(
                                                                row?.deadline
                                                            ).format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.hq_geos}
                                                        </TableCell>
                                                        <TableCell
                                                            className={
                                                                classes.tableCell
                                                            }
                                                        >
                                                            <Avatar
                                                                src={
                                                                    row?.company_logo
                                                                }
                                                                alt={
                                                                    row?.company_name
                                                                }
                                                                className={`m-auto pt-sm-3 ${style.avatar}`}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            className={`${classes.tableCell} d-flex flex-direction-column`}
                                                        >
                                                            {row?.company_name}
                                                            <span
                                                                className={`colorDanger mt-2 font-weight-bold`}
                                                            >
                                                                <span
                                                                    className={
                                                                        row?.share_status
                                                                            ? 'visible'
                                                                            : 'invisible'
                                                                    }
                                                                >
                                                                    Shared Post
                                                                </span>
                                                            </span>
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {
                                                                row?.currency_symbol
                                                            }
                                                            {row?.target_raise}
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {
                                                                row?.commitment_received
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {
                                                                row?.current_funding_stage
                                                            }
                                                        </TableCell>
                                                        <TableCell
                                                            size={'small'}
                                                        >
                                                            {row?.sectors}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={
                                                        tableHeader.length + 1
                                                    }
                                                    className="dataNotAvailable"
                                                >
                                                    Data not available
                                                </td>
                                            </tr>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className={classes.footer}
                                hidden={
                                    listingsData?.posts &&
                                    !listingsData?.posts.length
                                }
                                rowsPerPageOptions={[]}
                                component="div"
                                count={count}
                                rowsPerPage={listingsData?.page_size}
                                page={page}
                                ActionsComponent={CommonComponent}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
export default connect(null, {
    GetDealsList,
    DeleteDeal,
    UpdateDeal,
    UpdateDealOwnership,
    OpenModal
})(DeclinedListingsComponent);
