import {Button, CircularProgress, Container, FormHelperText, Grid, IconButton, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import {connect, useSelector} from "react-redux";
import {Add, Delete} from "@material-ui/icons";
import moment from "moment";
import useStateRef from "react-usestateref";
import {UpdatePost} from "../Common";
import {UpdateDeal} from "../../../../redux/Deals/actions";
import {useCookies} from "react-cookie";
import {SET_STARTUP_EQUITY_DEAL_DATA, UPDATE_WIZARD_FORM_DATA} from "../../../../redux/Deals/actionTypes";
import RenderTextField from "../../../InputComponents/TextField/RenderTextField";
import RenderDatePicker from "../../../InputComponents/DatePickerComponent/RenderDatePicker";
import {CommonDispatcher, GetFirmsList, UploadImages} from "../../../../redux/Common/action";
import {RootState} from "../../../../redux/store";
import {isValidURL} from "../../../../utils/common";
import {Routes} from "../../../../constants";
import {useHistory} from "react-router-dom";
import {SHOW_SNACKBAR} from "../../../../redux/Common/actionTypes";
import CreatableAutocompleteWithoutFocus from "../../../InputComponents/CreatableAutocompleteWithoutFocus";

const StepForthComponent = ({equity_id: post_id, CommonDispatcher, GetFirmsList, UpdateDeal, UploadImages}: any) => {
    const state = useSelector((store: RootState) => store);
    const activeStep = state?.deals?.wizard?.currentStep || 0;
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const deal_data = state?.deals?.deal_data || {};
    const [isLoading, setLoading] = useState(false);
    const [expiry_date, setExpiryDate] = useState(moment().add(2, "months"));

    const history = useHistory();
    const [errors, setError, errorRef] = useStateRef({}) as [any, any, any];
    const [company_name, setCompanyName] = useState({});
    const [website, setFirmWebsite] = useState({});
    const [firmsList, setFirmsList] = useState([]);

    const {control, reset, setValue, errors: formError, getValues, register, formState: {isValid}} = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        shouldUnregister: false
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: "investors"
    });

    useEffect(() => {
        let obj: any = {...deal_data};
        if (post_id) {
            if (obj?.expiry_date) {
                setExpiryDate(moment(obj?.expiry_date));
            } else {
                obj.expiry_date = expiry_date;
                setExpiryDate(expiry_date);
            }
            setValue("expiry_date", obj?.expiry_date);
            if (obj.investors && obj.investors.length) {
                let companyData = {};
                let websiteData = {};
                obj.investors.forEach((value, index) => {
                    companyData = {...companyData, [index]: value?.name};
                    websiteData = {...websiteData, [index]: value?.profile_link};
                });
                setCompanyName(companyData);
                setFirmWebsite(websiteData);
            } else {
                obj.investors = [{
                    name: "",
                    profile_link: ""
                }];
            }
        } else {
            if (obj?.expiry_date) {
                const date = moment(obj?.expiry_date);
                const isDateValid = date.isValid();
                obj.expiry_date = isDateValid ? date : expiry_date;
            } else {
                obj.expiry_date = expiry_date;
            }
            setValue("expiry_date", obj?.expiry_date);
            obj.investors = [{
                name: "",
                profile_link: ""
            }];
        }
        reset(obj);
    }, [deal_data]);

    const handleBack = () => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {currentStep: activeStep - 1});
        }
        let data = {...deal_data};
        if (Object.keys(company_name).length || Object.keys(website).length) {
            let investors = [];
            Object.entries(company_name).forEach(([key, value]) => {
                investors.push({name: value, profile_link: website[key]});
            });
            data.investors = investors;
        } else data.investors = [];
        if (data?.investors && data?.investors.length) {
            data.investors = data?.investors.filter(d => (d?.name && d?.profile_link));
        }
        CommonDispatcher(SET_STARTUP_EQUITY_DEAL_DATA, data);
    };

    const getFormValues = obj => {
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            setError({...errorRef.current, [key]: !value});
            data[key] = value;
        });
        return data;
    };

    const handleExpiryDate = (date) => {
        const exp_date = moment(date);
        setExpiryDate(exp_date);
        setValue("expiry_date", exp_date);
    };

    const next = () => {
        history.push(`${Routes.deals.deals}/deals-details/${post_id}`);
        CommonDispatcher(SHOW_SNACKBAR, {
            isShowing: true,
            message: "Deal updated successfully."
        });
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let dataObj: any = getFormValues(getValues());
        if (Object.keys(company_name).length || Object.keys(website).length) {
            let data = [];
            Object.entries(company_name).forEach(([key, value]) => {
                data.push({name: value, profile_link: website[key]});
            });
            dataObj.investors = data;
        } else dataObj.investors = [];


        dataObj.expiry_date = moment(dataObj?.expiry_date).format("YYYY-MM-DD");

        dataObj.equity_id = post_id || deal_data?.equity_id;
        if (dataObj?.equity_id) {
            setLoading(true);
            UpdatePost(UpdateDeal, CommonDispatcher, dataObj, UploadImages, setLoading, next, token);
        }
    };

    const validateLink = (index) => {
        const link = getValues(`investors[${index}].profile_link`);
        const investorsError = formError?.investors && formError?.investors?.length && formError?.investors[index]?.profile_link;
        return <FormHelperText error={investorsError} className={investorsError ? "mb-1" : ""}>
            {link && !isValidURL(link) && "Invalid profile link."}
        </FormHelperText>;
    };

    const handleCompanyNameChange = (index, value) => {
        setCompanyName({...company_name, [index]: value?.title});
        setFirmWebsite({...website, [index]: value?.value2});
    };

    const handleFirmWebsiteChange = (index, e) => {
        setFirmWebsite({...website, [index]: e?.target?.value});
        setValue(`investors[${index}].profile_link`, e?.target?.value);
    };

    const handleInputChange = value => {
        GetFirmsList(token, value).then(r => {
            const firms = r?.response?.firms;
            if (firms && firms.length) {
                const firmsData = firms.map(d => ({title: d?.label, value: d?.firm_id, value2: d?.website}));
                setFirmsList(firmsData);
            }
        });
    };

    return <Container maxWidth="sm">
        <Grid container spacing={3} className="mb-5">
            <Grid item xs={12} md={10} sm={10} className="m-auto pb-5">
                <div className="">
                    <form>
                        <div data-aos="fade-left" data-aos-duration="200">
                            <Grid container spacing={3}>
                                <Grid item xs={12} className="py-1">
                                    <Typography variant="body2" component="p"
                                                className="colorPrimary font-weight-bold fs-16">
                                        Investors :
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} className="mb-3">
                                <Grid item xs={12} className="py-1">
                                    {fields.map((item, index) => {
                                        return <div key={index}
                                                    className="w-100 d-flex flex-direction-xs-column align-items-flex-start">
                                            <div className="mt-2 mr-3 w-100">
                                                <Controller name={`investors[${index}].name`}
                                                            render={({onChange, value}) => {
                                                                return <CreatableAutocompleteWithoutFocus
                                                                    id={`investors[${index}].name`}
                                                                    label={`Investment Firm ${index + 1} *`}
                                                                    inputAutoComplete="off"
                                                                    value={Object.keys(company_name).length && company_name[index] ? company_name[index] : ""}
                                                                    options={firmsList}
                                                                    openOnFocus={false}
                                                                    disableClearable={true}
                                                                    disableCloseOnSelect={false}
                                                                    handleInputChange={handleInputChange}
                                                                    onBlur={(e: any) => {
                                                                        const value = e?.target?.value;
                                                                        onChange({
                                                                            title: value,
                                                                            value: value
                                                                        });
                                                                        setCompanyName({
                                                                            ...company_name,
                                                                            [index]: value
                                                                        });
                                                                    }}
                                                                    onChange={(e, data) => {
                                                                        onChange(data);
                                                                        handleCompanyNameChange(index, data);
                                                                    }}
                                                                    inputPlaceholder="Search or Add Company name"/>;
                                                            }}
                                                            control={control}
                                                            defaultValue={item.name || null}
                                                            rules={{
                                                                required: false
                                                            }}
                                                />
                                            </div>
                                            <div className="mt-2 mr-3 w-100">
                                                <Controller name={`investors[${index}].profile_link`}
                                                            render={({onChange}) => {
                                                                return <RenderTextField inputContainerClassName="w-100"
                                                                                        type="text"
                                                                                        id={`investors[${index}].profile_link`}
                                                                                        label={`Website ${index + 1} *`}
                                                                                        value={Object.keys(website).length && website[index] ? website[index] : ""}
                                                                                        onChange={(e) => {
                                                                                            onChange(e);
                                                                                            handleFirmWebsiteChange(index, e);
                                                                                        }}
                                                                                        autoComplete="off"/>;
                                                            }}
                                                            inputRef={register}
                                                            control={control}
                                                            defaultValue={item.profile_link || null}
                                                            rules={{
                                                                required: false
                                                            }}
                                                />
                                                {validateLink(index)}
                                            </div>
                                            <IconButton color="secondary"
                                                        onClick={() => {
                                                            remove(index);
                                                            delete company_name[index];
                                                            setCompanyName(company_name);
                                                            delete website[index];
                                                            setFirmWebsite(website);
                                                        }}
                                                        className="d-flex align-self-flex-xs-end mt-5 mt-xs-0">
                                                <Delete/>
                                            </IconButton>
                                        </div>;
                                    })}
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} className="mb-3">
                                <Grid item xs={12} className="mt-3">
                                    <Button color="primary"
                                            variant="outlined"
                                            type="button"
                                            disabled={fields?.length >= 10}
                                            className="float-right"
                                            onClick={() => {
                                                append({name: "", profile_link: ""});
                                            }}>
                                        <Add className="mr-2"/>Add a new investor
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={3} className="mb-3">
                                <Grid item xs={12} className="py-1 d-flex flex-direction-column">
                                    <Controller name="expiry_date"
                                                render={({onChange, value}) => {
                                                    return <RenderDatePicker id="expiry_date"
                                                                             shrink={true}
                                                                             value={expiry_date}
                                                                             showInputLabel={true}
                                                                             onChange={(data) => {
                                                                                 onChange(data);
                                                                                 handleExpiryDate(data);
                                                                             }}
                                                                             label="Deal expiry date *"
                                                                             format="DD-MM-YYYY"/>;
                                                }}
                                                control={control}
                                                defaultValue={expiry_date}
                                                rules={{
                                                    required: true
                                                }}
                                    />
                                    {expiry_date ? <span className="colorGray mt-1">
                                        The deal expires on {moment(expiry_date).format("DD-MM-YYYY")} at 23:59 GMT.
                                    </span> : null}
                                </Grid>
                            </Grid>
                        </div>
                        <div className="mt-5 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <Button color="primary"
                                        variant="text"
                                        className={`fs-16 text-inherit`}
                                        onClick={() => handleBack()}>
                                    Back
                                </Button>
                            </div>
                            <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                                <Button color="primary"
                                        variant="contained"
                                        onClick={onSave}
                                        disabled={isLoading}
                                        className={`fs-16 text-inherit btn-submit-style`}>
                                    {deal_data?.status === "COMPLETE" ? "Publish" : "Save as Draft"}
                                    {isLoading ? <CircularProgress size="1.3rem" className="circular-progress"/> : null}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Grid>
        </Grid>
    </Container>;
};

export default connect(null, {
    CommonDispatcher,
    UploadImages,
    GetFirmsList,
    UpdateDeal
})(StepForthComponent);