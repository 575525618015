import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Chip,
    Container,
    Grid,
    IconButton,
    Typography
} from '@material-ui/core';
import style from './Style.module.scss';
import { connect, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import {
    DeleteUser,
    GetSingleUser,
    UpdateUserProfile,
    GetUsersList
} from '../../../redux/Users/actions';
import imageNotFound from '../../../resources/image-not-found.webp';
import moment from 'moment';
import {
    ACTIVE,
    DECLINED,
    ENV,
    LIVE_DOMAIN,
    SUSPENDED,
    TEST_DOMAIN
} from '../../../utils/constants';
import { addCommas, createExternalLink } from '../../../utils/common';
import countriesArray from '../../../utils/countries.json';
import { OpenAlert, USERS_MENU_OPTIONS } from '../Common';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { OpenModal } from '../../../redux/Common/action';
import {
    CHANGE_PASSWORD_MODAL,
    FIRM_LIST_MODAL,
    OPEN_ALERT_MODAL,
    SESSIONS_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import FirmsModal from '../Common/FirmsModal';
import { RootState } from '../../../redux/store';
import ChangePasswordModal from '../../Common/ChangePasswordModal/ChangePasswordModal';

const ViewUsers = ({
    match,
    GetSingleUser,
    GetUsersList,
    OpenModal,
    UpdateUserProfile,
    DeleteUser
}: any) => {
    const [d, setProfileDetails] = React.useState(null);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isLoading, setIsLoading] = useState(false);
    const {
        params: { user_id }
    } = match;
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [selectedUser, setSelectedUser] = useState({});
    const isStartup = d?.type === 'STARTUP';

    const firmsModal = useSelector(
        (store: RootState) => store?.modal?.firmListModal
    );

    const changePasswordModal = useSelector(
        (store: RootState) => store?.modal?.changePasswordModal
    );

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(() => {
        setIsLoading(true);
        getData();
    }, []);

    const getData = () => {
        GetSingleUser(token, { user_id }).then((r) => {
            const jsonData = r?.response;

            let countriesData = [];
            countriesArray.forEach((d) => {
                countriesData.push({ title: d?.title, value: d?.id });
            });
            setProfileDetails(jsonData);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
            setIsLoading(false);
        });
    };

    const handleChange = (value, selectedUser) => {
        switch (value) {
            case 'profile':
                const url =
                    ENV === 'DEVELOPMENT'
                        ? `${TEST_DOMAIN}/${selectedUser?.user_name}`
                        : `${LIVE_DOMAIN}/${selectedUser?.user_name}`;
                window.open(url, '_blank');
                break;
            case 'editUser':
                history.push(`/users/edit-user/${selectedUser?.user_id}`);
                break;
            case 'editStrategy':
                history.push(`/users/edit-strategy/${selectedUser?.user_id}`);
                break;
            case 'userSessions':
                OpenModal(SESSIONS_MODAL, {
                    title: 'User Sessions',
                    isOpen: true,
                    data: { selectedUser }
                });
                break;
            case 'changePassword':
                OpenModal(CHANGE_PASSWORD_MODAL, {
                    title: 'Change Password',
                    isOpen: true,
                    data: { selectedUser }
                });
                break;
            case 'firmListModal':
                OpenModal(FIRM_LIST_MODAL, {
                    isOpen: true,
                    data: { selectedUser },
                    callBack: () => {
                        getData();
                    }
                });
                break;
            case 'approve':
                OpenAlert({
                    title: 'Approve',
                    description: 'Are you sure you want to approve this user?',
                    submitTitle: 'Approve',
                    payload: {
                        user_id: selectedUser?.user_id,
                        admin_status: ACTIVE
                    },
                    token,
                    OpenModal,
                    UpdateUserProfile,
                    getData
                });
                break;
            case 'decline':
                OpenAlert({
                    title: 'Decline',
                    description: 'Are you sure you want to decline this user?',
                    submitTitle: 'Decline',
                    payload: {
                        user_id: selectedUser?.user_id,
                        admin_status: DECLINED
                    },
                    token,
                    OpenModal,
                    UpdateUserProfile,
                    getData
                });
                break;
            case 'suspend':
                OpenAlert({
                    title: 'Suspend',
                    description: 'Are you sure you want to Suspend this user?',
                    submitTitle: 'Suspend',
                    payload: {
                        user_id: selectedUser?.user_id,
                        admin_status: SUSPENDED
                    },
                    token,
                    OpenModal,
                    UpdateUserProfile,
                    getData
                });
                break;
            case 'delete':
                if (
                    window?.confirm(
                        'Are you sure you want to delete this user?'
                    )
                ) {
                    DeleteUser(token, selectedUser?.user_id)
                        .then(() => getData())
                        .catch(() => {
                            alert('Something went wrong.');
                        });
                }
                break;
        }
    };

    const renderMenu = (d) => {
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, d)}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    elevation={0}
                    PaperProps={{
                        style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            border: '1px solid rgb(0 106 194 / 20%)'
                        }
                    }}
                >
                    {USERS_MENU_OPTIONS.map((option, i) => (
                        <MenuItem
                            key={option?.value}
                            onClick={() => {
                                handleChange(option?.value, selectedUser);
                                setAnchorEl(null);
                            }}
                        >
                            {option?.title}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };

    const createLink = (data) => {
        return (
            <ol>
                {data.map((f, i) => {
                    return (
                        <li className="mt-2" key={i}>
                            {f?.profile_url ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: createExternalLink(
                                            f?.profile_url,
                                            f?.type,
                                            true
                                        )
                                    }}
                                />
                            ) : (
                                f?.type
                            )}
                        </li>
                    );
                })}
            </ol>
        );
    };

    const has_admin_statuses = [
        'AWAITING_APPROVAL',
        'SUSPENDED',
        'DECLINED'
    ].includes(d?.admin_status);
    const url =
        ENV === 'DEVELOPMENT'
            ? `${TEST_DOMAIN}/${d?.user_name}`
            : `${LIVE_DOMAIN}/${d?.user_name}`;

    let city = `${d?.city ? `${d?.city}, ` : ''}`;
    let country = `${
        d?.country && d?.country?.label ? `${d?.country?.label}` : ''
    }`;
    let location = city || country ? `${city}${country}` : d?.location;

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <div className={style.pageContainer}>
            <Grid container id="listingContainer">
                {firmsModal?.isOpen ? (
                    <FirmsModal title="User Settings" />
                ) : null}
                {changePasswordModal?.isOpen ? <ChangePasswordModal /> : null}
                <Grid item xs={12} md={10} className="m-auto">
                    <Card className="my-5">
                        <CardContent>
                            <Container maxWidth="md" className="px-0">
                                <Grid container spacing={3}>
                                    <Grid
                                        item
                                        xs={12}
                                        className="d-flex align-items-flex-start flex-direction-xs-column"
                                    >
                                        <img
                                            src={
                                                d?.sm_profile_picture ||
                                                imageNotFound
                                            }
                                            alt={d?.full_name}
                                            height={100}
                                            width={100}
                                            className="object-fit-cover"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src =
                                                    imageNotFound;
                                            }}
                                        />
                                        <div className="d-flex align-items-flex-start flex-direction-column ml-3 w-100">
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <h2 className="my-0 mt-xs-3 mr-3 colorBlue">
                                                    {d?.full_name}
                                                </h2>
                                                {renderMenu(d)}
                                            </div>
                                            <div className="d-flex flex-direction-column align-items-start">
                                                {d?.ecosystem_type &&
                                                d?.ecosystem_type[0]?.name ? (
                                                    <div className="mb-1">
                                                        {
                                                            d?.ecosystem_type[0]
                                                                ?.name
                                                        }
                                                    </div>
                                                ) : null}
                                                {d?.type ? (
                                                    <Chip
                                                        size="small"
                                                        label={d?.type}
                                                        color={
                                                            d?.type ===
                                                            'INVESTOR'
                                                                ? 'primary'
                                                                : 'secondary'
                                                        }
                                                        clickable
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            User Status&nbsp;:&nbsp;
                                            <b
                                                className={
                                                    !has_admin_statuses
                                                        ? 'colorBlue'
                                                        : 'colorDanger'
                                                }
                                            >
                                                {d?.admin_status || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Title&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.title || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Location&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {location || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Username&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                <a
                                                    href={url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {d?.user_name || 'N/A'}
                                                </a>
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Company&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.company_name || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Website&nbsp;:&nbsp;
                                            {d?.website ? (
                                                <b
                                                    className="colorBlue"
                                                    dangerouslySetInnerHTML={{
                                                        __html: createExternalLink(
                                                            d?.website,
                                                            d?.website,
                                                            true
                                                        )
                                                    }}
                                                />
                                            ) : (
                                                'N/A'
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            linkedin&nbsp;:&nbsp;
                                            {d?.linkedin ? (
                                                <b
                                                    className="colorBlue"
                                                    dangerouslySetInnerHTML={{
                                                        __html: createExternalLink(
                                                            d?.linkedin,
                                                            d?.linkedin,
                                                            true
                                                        )
                                                    }}
                                                />
                                            ) : (
                                                'N/A'
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            About&nbsp;:&nbsp;
                                            <Typography
                                                variant="body2"
                                                component="p"
                                                dangerouslySetInnerHTML={{
                                                    __html: d?.about || 'N/A'
                                                }}
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Description&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.description || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Gender&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.gender || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Birth Date&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.dob || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Email&nbsp;:&nbsp;
                                            <b
                                                className={
                                                    d?.status === 'VERIFIED'
                                                        ? 'colorBlue'
                                                        : 'colorDanger'
                                                }
                                            >
                                                {d?.email ? (
                                                    <>
                                                        <a
                                                            href={`mailto:${d?.email}`}
                                                        >
                                                            {d?.email}
                                                        </a>
                                                        {` (${d?.status})`}
                                                    </>
                                                ) : (
                                                    'N/A'
                                                )}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Phone Number&nbsp;:&nbsp;
                                            <b
                                                className={
                                                    d?.phone_status ===
                                                    'VERIFIED'
                                                        ? 'colorBlue'
                                                        : 'colorDanger'
                                                }
                                            >
                                                {d?.phone ? (
                                                    <>
                                                        <a
                                                            href={`tel:${d?.phone}`}
                                                        >
                                                            {d?.phone}
                                                        </a>
                                                        {` (${d?.phone_status})`}
                                                    </>
                                                ) : (
                                                    'N/A'
                                                )}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Account Created with&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.created_with || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            From referral&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.from_referral
                                                    ? 'YES'
                                                    : 'NO'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Created Date&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {moment(d?.createdAt).format(
                                                    'Do MMM, YYYY'
                                                ) || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </CardContent>
                    </Card>

                    <Card className="mb-5">
                        <CardContent>
                            <h2 className="my-0">STRATEGY:</h2>
                            <Container maxWidth="md" className="px-0 my-5">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Name of the Firm&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.company_name || 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Website&nbsp;:&nbsp;
                                            {d?.website ? (
                                                <b
                                                    className="colorBlue"
                                                    dangerouslySetInnerHTML={{
                                                        __html: createExternalLink(
                                                            d?.website,
                                                            d?.website,
                                                            true
                                                        )
                                                    }}
                                                />
                                            ) : (
                                                'N/A'
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Investing in&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.investments_type &&
                                                d?.investments_type.length
                                                    ? d?.investments_type
                                                          .map((a) => a?.label)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            {isStartup
                                                ? 'What was your last funding stage'
                                                : 'How do you classify your firm'}
                                            ?&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.investor_type &&
                                                d?.investor_type.length
                                                    ? d?.investor_type
                                                          .map((a) => a?.type)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Personal profile links&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.investor_profile &&
                                                d?.investor_profile.length
                                                    ? createLink(
                                                          d?.investor_profile
                                                      )
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Preferred Industry&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.investment_class &&
                                                d?.investment_class.length
                                                    ? d?.investment_class
                                                          .map((a) => a?.label)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Preferred Business
                                            Models&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.business_model &&
                                                d?.business_model.length
                                                    ? d?.business_model
                                                          .map((a) => a?.label)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Preferred Sectors&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.preferred_sector &&
                                                d?.preferred_sector.length
                                                    ? d?.preferred_sector
                                                          .map((a) => a?.label)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Preferred Stages&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.preferred_stage &&
                                                d?.preferred_stage.length
                                                    ? d?.preferred_stage
                                                          .map((a) => a?.label)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            SDG goals&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.sdg_sectors &&
                                                d?.sdg_sectors.length
                                                    ? d?.sdg_sectors
                                                          .map((a) => a?.label)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Preferred Regions&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.regions && d?.regions.length
                                                    ? d?.regions
                                                          .map((a) => a?.label)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Preferred Countries&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.investment_geos &&
                                                d?.investment_geos.length
                                                    ? d?.investment_geos
                                                          .map((a) => a?.label)
                                                          .join(', ')
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Minimum ticket size&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.min_ticket_size
                                                    ? addCommas(
                                                          d?.min_ticket_size
                                                              .toString()
                                                              .slice(0, 18)
                                                      )
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Maximum ticket size&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.max_ticket_size
                                                    ? addCommas(
                                                          d?.max_ticket_size
                                                              .toString()
                                                              .slice(0, 18)
                                                      )
                                                    : 'N/A'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </CardContent>
                    </Card>

                    <Card className="mb-5">
                        <CardContent>
                            <h2 className="my-0">NOTIFICATION:</h2>
                            <Container maxWidth="md" className="px-0 my-5">
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Send user notification of new
                                            messages via email&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.allow_notifications
                                                    ? 'On'
                                                    : 'Off'}
                                            </b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            component="p"
                                        >
                                            Send messages notification
                                            summary&nbsp;:&nbsp;
                                            <b className="colorBlue">
                                                {d?.notification_frequency}
                                            </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Container>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default connect(null, {
    GetSingleUser,
    OpenModal,
    GetUsersList,
    UpdateUserProfile,
    DeleteUser
})(ViewUsers);
