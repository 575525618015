import {Chip} from "@material-ui/core";
import React from "react";

export const VerifyNow = ({status, handleVerify}) => {
    return <div className="d-flex flex-direction-column">
        <Chip size="small"
              label={status === "VERIFIED" ? "VERIFIED" : "Verify Now"}
              color={status === "VERIFIED" ? "primary" : "secondary"}
              onClick={() => status !== "VERIFIED" && handleVerify()}
              clickable/>
    </div>;
};