import {
    createStyles,
    DialogTitle as MuiDialogTitle,
    IconButton,
    Theme,
    Typography,
    withStyles
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import React from "react";

const styles = (theme: Theme) =>
    createStyles({
        dialogContainer: {maxWidth: "600px"},
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        }
    });

const ModalTitle = ({children, hidden, classes, onClose, ...other}: any) => {

    return <MuiDialogTitle hidden={hidden} disableTypography className={`${classes.root}`} {...other} >
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <Close/>
            </IconButton>
        ) : null}
    </MuiDialogTitle>;
};

export default withStyles(styles)(ModalTitle);