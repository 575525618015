import {
    Button,
    FormControlLabel,
    Grid,
    IconButton,
    Tooltip,
    makeStyles
} from '@material-ui/core';
import { Add, Info } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
    ALLOW_EASY_VIEW_MESSAGE,
    CAP_TABLE_ACCESS_LIST,
    EDIT_DEAL_ACCESS_LIST
} from '../../../../constants';
import RenderSelectField from '../../../InputComponents/SelectField/RenderSelectField';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import RenderCheckboxComponent from '../../../InputComponents/CheckboxComponent';

const renderList = [
    { title: 'Founder', value: 'Founder' },
    { title: 'Co-Founder', value: 'Co-Founder' },
    { title: 'CEO', value: 'CEO' },
    { title: 'COO', value: 'COO' },
    { title: 'CFO', value: 'CFO' },
    { title: 'Investor', value: 'Investor' },
    { title: 'Other', value: 'Other' }
];
const useStyles = makeStyles(() => ({
    tooltip: {
        fontSize: '16px',
        padding: '0.5rem 1rem'
    }
}));

const AddPeopleModal = ({
    handleAddTeamOpen,
    addPeople,
    profile,
    equity_id,
    getCommonUpdater
}: any) => {
    const {
        control,
        reset,
        getValues,
        formState: { isValid }
    } = useForm({
        mode: 'onChange',
        shouldUnregister: false
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'team'
    });

    const defaultObj = {
        title: renderList?.[0]?.value,
        email: '',
        cap_table_permission: CAP_TABLE_ACCESS_LIST?.[0]?.value,
        allow_edit_deal: EDIT_DEAL_ACCESS_LIST?.[1]?.value
    };
    const classes = useStyles();
    const [openTooltip, setOpenTooltip] = useState(false);

    useEffect(() => {
        reset({
            team: [defaultObj]
        });
    }, []);

    const toggleAddTeamOpen = (flag) => {
        handleAddTeamOpen(flag);
    };
    const handleSetOpenTooltip = (openTooltip) => {
        setOpenTooltip(openTooltip);
    };

    const handleCapTablePermissionChange = (user_id, cap_table_permission) => {
        if (profile?.allow_cap_table_access) {
            getCommonUpdater({
                equity_id,
                user_id,
                cap_table_permission
            });
        }
    };

    const handleAllowEditDealChange = (user_id, allow_edit_deal) => {
        getCommonUpdater({
            equity_id,
            user_id,
            allow_edit_deal:
                allow_edit_deal === 'Deactivate'
                    ? false
                    : allow_edit_deal === 'Allowed'
                    ? 1
                    : false
        });
    };

    return (
        <div>
            <div>
                {fields.map((item, index) => {
                    return (
                        <div key={item?.id} className="my-2">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name={`team[${index}].title`}
                                        control={control}
                                        as={
                                            <RenderSelectField
                                                value={renderList[0]?.value}
                                                removeLabel={false}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Title"
                                                dropdownListHeight={250}
                                                dataArray={renderList}
                                            />
                                        }
                                    />
                                    <Controller
                                        name={`team[${index}].allow_deal_view_with_email`}
                                        render={({ onChange, value }) => {
                                            return (
                                                <FormControlLabel
                                                    label={
                                                        <div className="d-flex align-items-center">
                                                            <span>
                                                                Allow easy view
                                                            </span>
                                                            <Tooltip
                                                                title={
                                                                    ALLOW_EASY_VIEW_MESSAGE
                                                                }
                                                                placement="top"
                                                                arrow={true}
                                                                open={
                                                                    openTooltip
                                                                }
                                                                PopperProps={{
                                                                    disablePortal:
                                                                        true
                                                                }}
                                                                classes={{
                                                                    tooltip:
                                                                        classes.tooltip
                                                                }}
                                                                disableFocusListener
                                                                disableHoverListener
                                                                disableTouchListener
                                                            >
                                                                <IconButton
                                                                    aria-label="info"
                                                                    className="p-1 text-hover-colorPurple ml-1"
                                                                    onMouseOver={() =>
                                                                        handleSetOpenTooltip(
                                                                            true
                                                                        )
                                                                    }
                                                                    onMouseLeave={() =>
                                                                        handleSetOpenTooltip(
                                                                            false
                                                                        )
                                                                    }
                                                                >
                                                                    <Info />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    control={
                                                        <RenderCheckboxComponent
                                                            checked={value}
                                                            // disabled={
                                                            //     disableUpdate
                                                            // }
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(
                                                                    e?.target
                                                                        ?.checked
                                                                );
                                                            }}
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name={`team[${index}].email`}
                                        control={control}
                                        as={
                                            <RenderTextField
                                                label="Email"
                                                autoFocus={true}
                                                placeholder="Enter email"
                                            />
                                        }
                                    />
                                    <Controller
                                        name={`team[${index}].allow_public_link_feature`}
                                        render={({ onChange, value }) => {
                                            return (
                                                <FormControlLabel
                                                    label="Allow Public Sharing"
                                                    control={
                                                        <RenderCheckboxComponent
                                                            checked={value}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(
                                                                    e?.target
                                                                        ?.checked
                                                                );
                                                            }}
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                            );
                                        }}
                                        control={control}
                                        defaultValue={false}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name={`team[${index}].cap_table_permission`}
                                        control={control}
                                        as={
                                            <RenderSelectField
                                                value={
                                                    item?.cap_table_permission
                                                }
                                                removeLabel={false}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="CapTable"
                                                dropdownListHeight={250}
                                                dataArray={
                                                    CAP_TABLE_ACCESS_LIST
                                                }
                                                onChange={(e: any) =>
                                                    handleCapTablePermissionChange(
                                                        item?.user_id,
                                                        e?.target?.value
                                                    )
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name={`team[${index}].allow_edit_deal`}
                                        control={control}
                                        as={
                                            <RenderSelectField
                                                value={
                                                    item?.allow_edit_deal
                                                        ? 1
                                                        : false
                                                }
                                                removeLabel={false}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Edit Deal"
                                                dropdownListHeight={250}
                                                dataArray={
                                                    EDIT_DEAL_ACCESS_LIST
                                                }
                                                onChange={(e: any) =>
                                                    handleAllowEditDealChange(
                                                        item?.user_id,
                                                        e?.target?.value
                                                    )
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {fields?.length > 1 ? (
                                <Button
                                    color="secondary"
                                    size="small"
                                    className={`text-inherit float-right my-2`}
                                    onClick={() => {
                                        remove(index);
                                    }}
                                >
                                    Remove
                                </Button>
                            ) : null}
                        </div>
                    );
                })}
            </div>
            <div className="w-100 pb-5 d-flex align-items-center justify-content-end">
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={() => {
                        append({
                            email: ''
                        });
                    }}
                    className={'text-inherit float-right'}
                >
                    <Add className="mr-2" /> Add member
                </Button>
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <Button
                    onClick={toggleAddTeamOpen}
                    color="secondary"
                    className="text-inherit"
                    autoFocus
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => addPeople(getValues())}
                    className="ml-3 text-inherit"
                    color={'primary'}
                    variant="contained"
                    disabled={!isValid}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

export default AddPeopleModal;
