import { InputLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import style from './Styles.module.scss';

interface DataArray {
    title: string;
    value?: string | number | boolean;
    id?: string | number;
}

interface IRenderAutocompleteTextFieldProps {
    id?: string;
    label: string | JSX.Element;
    value?: any;
    variant?: any;
    className?: string;
    optionClassName?: string;
    inputClassName?: string;
    multiple?: boolean;
    filterSelectedOptions?: boolean;
    disableCloseOnSelect?: boolean;
    options: DataArray[] | [];
    inputPlaceholder?: string;
    inputAutoComplete?: string;
    size?: any;
    error?: boolean;
    disabled?: boolean;
    hideLabel?: boolean;
    helperText?: string;
    paperClass?: string;
    limitTags?: number;
    loading?: boolean;
    clearOnBlur?: boolean;
    field?: any;
    chipProps?: any;
    onChange?: (e: object, value: any, reason: string, option: any) => void;
    onInputChange?: (e: object, value: any) => void;
    onKeyDown?: (e: object) => void;
    onBlur?: (e: object) => void;
}

const RenderAutocompleteTextField = ({
    id,
    disableCloseOnSelect,
    options,
    multiple = true,
    filterSelectedOptions = true,
    label,
    value = [],
    className,
    optionClassName = '',
    inputClassName = '',
    variant,
    onChange,
    inputPlaceholder = '',
    error,
    helperText,
    paperClass,
    hideLabel,
    limitTags = 3,
    onKeyDown,
    onInputChange,
    inputAutoComplete,
    disabled,
    size,
    field,
    loading,
    clearOnBlur,
    chipProps
}: IRenderAutocompleteTextFieldProps) => {
    /* const [open, setOpen] = useState(false);
     const handleOpen = (flag) => {
         setOpen(flag);
     };*/
    return (
        <div className={style.inputContainer}>
            {!hideLabel ? (
                <InputLabel htmlFor={id} className={style.inputLabels}>
                    {label}
                </InputLabel>
            ) : null}
            {/*<div onClick={() => handleOpen(true)} onBlur={() => handleOpen(false)}>*/}
            <Autocomplete
                multiple={multiple}
                disableCloseOnSelect={disableCloseOnSelect}
                filterSelectedOptions={filterSelectedOptions}
                id={id}
                {...field}
                // open={open}
                loading={loading}
                autoHighlight
                value={value}
                clearOnBlur={clearOnBlur}
                disabled={disabled}
                size={size}
                getOptionSelected={
                    (option: any, value: any) =>
                        option?.value === value?.value /* ||
                    option?.title === value?.title */
                }
                limitTags={limitTags}
                options={options && options.length ? options : []}
                onChange={onChange}
                ChipProps={chipProps}
                classes={{
                    paper: paperClass,
                    inputRoot:
                        value && value.length
                            ? style.withData
                            : style.withoutData,
                    input: inputClassName,
                    option: optionClassName
                }}
                onInputChange={(event, newInputValue) => {
                    typeof onInputChange != 'undefined' &&
                        onInputChange(event, newInputValue);
                }}
                getOptionLabel={(option: any) =>
                    id === 'contact_list'
                        ? `${option?.title} (${option.count})`
                        : option?.title
                }
                className={`${style.inputFields} ${className}`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={error}
                        onKeyDown={onKeyDown}
                        helperText={helperText}
                        autoComplete={inputAutoComplete}
                        variant={variant ? variant : 'outlined'}
                        placeholder={inputPlaceholder}
                    />
                )}
            />
            {/*</div>*/}
        </div>
    );
};
export default RenderAutocompleteTextField;
