import React, { useEffect, useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tab,
    Tabs,
    useMediaQuery
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import {
    CommonDispatcher,
    GetCountries,
    GetFirmsList,
    OpenModal
} from '../../../redux/Common/action';
import {
    OPEN_ALERT_MODAL,
    OPEN_DEAL_UPDATE_MODAL
} from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import RenderCheckboxComponent from '../../InputComponents/CheckboxComponent';
import { Controller, useForm } from 'react-hook-form';
import RenderAutocompleteTextField from '../../InputComponents/AutocompleteTextField';
import style from './Styles.module.scss';
import { firmTypeList, stageList } from '../Common';
import {
    GetAccessUsersList,
    GetDealFilters,
    GetDealsList,
    GetMatchingUsersCount,
    GrantAccess,
    SaveFilters
} from '../../../redux/Deals/actions';
import { useCookies } from 'react-cookie';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { GetUsersList } from '../../../redux/Users/actions';
import { Alert } from '@material-ui/lab';
import ModalTitle from '../Common/ModalTitle';
import { TabPanel } from '../../Common/TabPanel';
import MatchingUsers from '../InvestorsModal/MatchingUsers';
import SharedUsers from '../InvestorsModal/SharedUsers';
import { useTheme } from '@material-ui/core/styles';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';

const DealUpdateModal = ({
    OpenModal,
    GetDealFilters,
    SaveFilters,
    GetUsersList,
    CommonDispatcher,
    GetFirmsList,
    GrantAccess,
    GetMatchingUsersCount,
    GetAccessUsersList,
    GetDealsList,
    GetCountries
}: any) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.dealUpdateModal) || {};
    const [isLoading, setLoading] = useState(false);
    const [isShareLoading, setIsShareLoading] = useState(false);
    const [saveFilterLoading, setSaveFilterLoading] = useState(false);
    const [resetForm, setReset] = useState(false);
    const [countries, setCountries] = useState([]);
    const [firmsList, setFirmsList] = useState([]);
    const [selectedFirmNames, setSelectedFirmNames] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [selectedFirmType, setSelectedFirmType] = useState([]);
    const [selectedStages, setSelectedStages] = useState([]);
    const [selectedHQGeos, setSelectedHQGeos] = useState([]);
    const [usersList, setUsersList]: any = useState({});
    const [membersList, setMembersList]: any = useState([]);
    const [everyOneOnThePlatform, setEveryOneOnThePlatform] = useState(false);
    const [matchingCriteria, setMatchingCriteria] = useState(null);
    const [sharedUsers, setSharedUsers] = useState([]);
    const [matchingUsers, setMatchingUsers] = useState([]);
    const deal = modal?.data?.deal;
    const getDealsListPayload = modal?.data?.getDealsListPayload;
    const [filterData, setFilterData]: any = useState({});
    // const minDistance = 10;
    /*const min = 0;
    const max = 100;
    const [value2, setValue2] = React.useState([min, max]);*/

    const [min, setMin] = useState(null);
    const [max, setMax] = useState(null);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const theme = useTheme();
    const [checked, setChecked] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [sharedChecked, setSharedChecked] = React.useState([]);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue) => {
        setValue(newValue);
    };

    const { control, reset, trigger, getValues } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });

    useEffect(() => {
        setLoading(true);
        setFilterData({ everyone: everyOneOnThePlatform || false });
        getCountriesList();
    }, []);

    useEffect(() => {
        if (resetForm) {
            setCountries([]);
            setSelectedFirmNames([]);
            setSelectedCountries([]);
            setSelectedMembers([]);
            setSelectedFirmType([]);
            setSelectedStages([]);
            setSelectedHQGeos([]);
            // setValue2([min, max]);
            reset(getValues());
        }
    }, [resetForm]);

    const getDealFilters = (countriesList) => {
        const data = { equity_id: deal?.equity_id };
        GetDealFilters(token, data).then((filterResp) => {
            GetMatchingUsersCount(token, {
                equity_id: deal?.equity_id,
                filter: filterResp?.response?.filter
            }).then((countResp) => {
                GetUsersList(token, {}).then((usersResp) => {
                    setUsersList(usersResp?.response);
                    let users = usersResp?.response?.users || [];
                    if (users && users.length) {
                        users = users.map((d) => ({
                            title: d?.full_name || d?.email,
                            value: d?.user_id
                        }));
                    }
                    setMembersList(users);
                    const filter = filterResp?.response?.filter;
                    const share_with = filter?.everyone;
                    const resident_in =
                        filter?.resident_in && filter?.resident_in.length
                            ? countriesList.filter((d) =>
                                  filter?.resident_in.includes(d?.value)
                              )
                            : [];
                    setSelectedCountries(resident_in);
                    const members =
                        filter?.members && filter?.members.length
                            ? users.filter((d) =>
                                  filter?.members.includes(d?.title)
                              )
                            : [];
                    setSelectedMembers(members);
                    const type_of_firms =
                        filter?.type_of_firms && filter?.type_of_firms.length
                            ? firmTypeList.filter((d) =>
                                  filter?.type_of_firms.includes(d?.title)
                              )
                            : [];
                    setSelectedFirmType(type_of_firms);
                    const stages =
                        filter?.stages && filter?.stages.length
                            ? stageList.filter((d) =>
                                  filter?.stages.includes(d?.title)
                              )
                            : [];
                    setSelectedStages(stages);
                    const target_geo =
                        filter?.target_geo && filter?.target_geo.length
                            ? countriesList.filter((d) =>
                                  filter?.target_geo.includes(d?.value)
                              )
                            : [];
                    setSelectedHQGeos(target_geo);
                    // setValue2([filter?.min_ticket_size || value2[0], filter?.max_ticket_size || value2[1]]);
                    setMin(filter?.min_ticket_size);
                    setMax(filter?.max_ticket_size);
                    reset({ share_with, members });
                    setEveryOneOnThePlatform(share_with);
                    setMatchingCriteria(countResp?.response);
                    GetFirmsList(token).then((r) => {
                        const firms = r?.response?.firms;
                        if (firms && firms.length) {
                            let firmsData = firms.map((d) => ({
                                title: d?.label,
                                value: d?.firm_id
                            }));
                            setFirmsList(firmsData);
                            const selectedFirms =
                                filter?.firm && filter?.firm.length
                                    ? firmsData.filter((d) =>
                                          filter?.firm.includes(d?.title)
                                      )
                                    : [];
                            setSelectedFirmNames(selectedFirms);
                        }
                        setLoading(false);
                    });
                    const matchingCriteriaUsersList =
                        countResp?.response?.users;
                    setMatchingUsers(matchingCriteriaUsersList);
                    GetAccessUsersList(token, {
                        equity_id: deal?.equity_id
                    }).then((sharedResp) => {
                        setSharedUsers(sharedResp?.response?.deal_accesses);
                    });
                    trigger();
                    setFilterData(filter);
                });
            });
        });
    };

    const handleClose = () => {
        OpenModal(OPEN_DEAL_UPDATE_MODAL, { isOpen: false });
    };

    const handleShareWithEveryone = (e, onChange) => {
        const isEveryOneChecked = e?.target?.checked;
        onChange(isEveryOneChecked);
        setEveryOneOnThePlatform(isEveryOneChecked);
        if (isEveryOneChecked) {
            reset({
                share_with: isEveryOneChecked,
                country: [],
                firm_name: [],
                members: [],
                firm_type: [],
                current_funding_stage_id: [],
                hq_geos: []
            });
            setReset(true);
            ApplyFilter({ everyone: !everyOneOnThePlatform });
        } else {
            filterData.everyone = !everyOneOnThePlatform;
            ApplyFilter(filterData);
        }
    };

    const getFilterValues = (value, key = 'title') => {
        let data = [];
        value.forEach((d) => {
            data.push(d?.[key]);
        });
        return data;
    };

    const handleCountryChange = (value) => {
        setSelectedCountries(value);
        filterData.resident_in = getFilterValues(value, 'value');
        ApplyFilter(filterData);
    };

    const handleFirmNameChange = (value) => {
        setSelectedFirmNames(value);
        filterData.firm = getFilterValues(value);
        ApplyFilter(filterData);
    };

    const handleMembersChange = (value) => {
        setSelectedMembers(value);
        filterData.members = getFilterValues(value);
        ApplyFilter(filterData);
    };

    const handleFirmTypeChange = (value) => {
        setSelectedFirmType(value);
        filterData.type_of_firms = getFilterValues(value);
        ApplyFilter(filterData);
    };

    const handleStagesChange = (value) => {
        setSelectedStages(value);
        filterData.stages = getFilterValues(value);
        ApplyFilter(filterData);
    };

    const handleHQGeosChange = (value) => {
        setSelectedHQGeos(value);
        filterData.target_geo = getFilterValues(value, 'value');
        ApplyFilter(filterData);
    };

    function valuetext(value) {
        return `${value}`;
    }

    /* const handleRangeChange = (event, newValue, activeThumb) => {
         if (!Array.isArray(newValue)) {
             return;
         }

         let min_ticket_size, max_ticket_size;

         if (newValue[0] > 0) {
             if (newValue[1] - newValue[0] < minDistance) {
                 if (activeThumb === 0) {
                     const clamped = Math.min(newValue[0], 100 - minDistance);
                     const value = [clamped, clamped + minDistance];
                     setValue2(value);
                     min_ticket_size = newValue[0];
                     max_ticket_size = newValue[1];
                 } else {
                     const clamped = Math.max(newValue[1], minDistance);
                     const value = [clamped - minDistance, clamped];
                     setValue2(value);
                     min_ticket_size = newValue[0];
                     max_ticket_size = newValue[1];
                 }
             } else {
                 min_ticket_size = newValue[0];
                 max_ticket_size = newValue[1];
                 setValue2(newValue);
             }
         }
         filterData.min_ticket_size = min_ticket_size;
         filterData.max_ticket_size = max_ticket_size;
         ApplyFilter(filterData);
     };*/

    const ApplyFilter = (filter) => {
        setFilterData(filter);
        let data = { equity_id: deal?.equity_id, filter };
        setSaveFilterLoading(true);
        GetMatchingUsersCount(token, data).then((usersCount) => {
            GetAccessUsersList(token, { equity_id: deal?.equity_id }).then(
                (sharedResp) => {
                    setSharedUsers(sharedResp?.response?.deal_accesses);
                    setMatchingCriteria(usersCount?.response);
                    const matchingCriteriaUsersList =
                        usersCount?.response?.users;
                    setMatchingUsers(matchingCriteriaUsersList);
                    setSaveFilterLoading(false);
                }
            );
        });
    };

    const onSubmitForm = () => {
        OpenModal(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: 'Alert',
            description: `Are you sure you want to update access to the selected users?`,
            data: { disableBackdropClick: true },
            submitTitle: `Yes, Share`,
            cancelTitle: 'No',
            isDelete: !everyOneOnThePlatform,
            callBack: () => {
                setIsShareLoading(true);
                let saveFilterData = {
                    equity_id: deal?.equity_id,
                    filter: filterData
                };
                SaveFilters(token, saveFilterData).then((filterResp) => {
                    const error = filterResp?.response?.data?.error;
                    if (filterResp?.type === 'failed' && error) {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: error
                        });
                        trigger();
                        setIsShareLoading(false);
                        OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
                    } else {
                        let checkedUsers = value
                            ? sharedChecked
                            : checked.concat(selected);
                        const selectedUsers = matchingCriteria?.users.filter(
                            (d) => checkedUsers.includes(d?.user_id)
                        );
                        const access = selectedUsers.map((d) => ({
                            user_id: d?.user_id,
                            email_id: d?.email,
                            name: d?.full_name,
                            permission: '1111',
                            doc_ids: []
                        }));
                        const data = {
                            equity_id: deal?.equity_id,
                            access
                        };
                        GrantAccess(token, data).then((r) => {
                            GetAccessUsersList(token, {
                                equity_id: deal?.equity_id
                            }).then((sharedResp) => {
                                GetDealsList(token, getDealsListPayload);
                                setSharedUsers(
                                    sharedResp?.response?.deal_accesses
                                );
                                setIsShareLoading(false);
                                OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
                                if (
                                    r?.type === 'failed' &&
                                    r?.response?.data?.error
                                ) {
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: r?.response?.data?.error
                                    });
                                } else {
                                    CommonDispatcher(SHOW_SNACKBAR, {
                                        isShowing: true,
                                        message: 'Access Granted successfully.'
                                    });
                                }
                            });
                        });
                    }
                });
            }
        });
    };

    const getCountriesList = () => {
        GetCountries(token).then((r) => {
            setCountries(r?.response);
            getDealFilters(r?.response);
        });
    };

    const onMinChange = (e) => {
        const min_ticket_size = e?.target?.value;
        setMin(min_ticket_size);
        filterData.min_ticket_size = min_ticket_size;
        ApplyFilter(filterData);
    };

    const onMaxChange = (e) => {
        const max_ticket_size = e?.target?.value.slice(0, 12);
        setMax(max_ticket_size);
        filterData.max_ticket_size = max_ticket_size;
        ApplyFilter(filterData);
    };

    return (
        <Dialog
            fullWidth={modal?.data?.fullWidth || true}
            onClose={handleClose}
            maxWidth="md"
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={modal?.data?.disableBackdropClick || false}
        >
            <ModalTitle id="dialog-title" onClose={handleClose}>
                {modal?.title}
            </ModalTitle>
            <DialogContent className="mb-0 pb-0 position-relative">
                {isLoading ? (
                    <div className="loader" />
                ) : (
                    <>
                        <div className={style.counters}>
                            <Alert
                                severity="info"
                                className="d-flex align-items-center justify-content-center cursor-pointer"
                            >
                                Total Users {usersList?.users?.length},{' '}
                                {matchingCriteria?.count} matched, and{' '}
                                {sharedUsers.length || 0} shared.
                            </Alert>
                        </div>

                        <Grid container spacing={3} className="h-100">
                            <Grid item md={6} sm={12} xs={12}>
                                <form>
                                    <List
                                        className="px-0 mt-0 d-none"
                                        style={{
                                            width: '100%',
                                            maxWidth: '250px'
                                        }}
                                    >
                                        <ListItem className="p-0">
                                            <ListItemText primary="Every one on the platform" />
                                            <ListItemSecondaryAction>
                                                <Controller
                                                    name={`share_with`}
                                                    render={({
                                                        onChange,
                                                        value
                                                    }) => {
                                                        return (
                                                            <RenderCheckboxComponent
                                                                id="share_with"
                                                                name="share_with"
                                                                checked={
                                                                    everyOneOnThePlatform
                                                                }
                                                                onChange={(e) =>
                                                                    handleShareWithEveryone(
                                                                        e,
                                                                        onChange
                                                                    )
                                                                }
                                                                color="primary"
                                                            />
                                                        );
                                                    }}
                                                    control={control}
                                                    defaultValue={false}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    </List>
                                    <div>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="country"
                                                    render={({ onChange }) => (
                                                        <div className="w-100">
                                                            <RenderAutocompleteTextField
                                                                id="country"
                                                                label="Residents in"
                                                                inputAutoComplete="off"
                                                                size="small"
                                                                value={
                                                                    selectedCountries
                                                                }
                                                                options={
                                                                    countries
                                                                }
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) => {
                                                                    onChange(
                                                                        data
                                                                    );
                                                                    handleCountryChange(
                                                                        data
                                                                    );
                                                                }}
                                                                inputPlaceholder="Search Residents in"
                                                            />
                                                        </div>
                                                    )}
                                                    control={control}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="firm_name"
                                                    render={({ onChange }) => (
                                                        <div className="w-100">
                                                            <RenderAutocompleteTextField
                                                                id="firm_name"
                                                                label="Name of the Firm"
                                                                inputAutoComplete="off"
                                                                size="small"
                                                                value={
                                                                    selectedFirmNames
                                                                }
                                                                options={
                                                                    firmsList
                                                                }
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) => {
                                                                    onChange(
                                                                        data
                                                                    );
                                                                    handleFirmNameChange(
                                                                        data
                                                                    );
                                                                }}
                                                                inputPlaceholder="Search Name of the Firm"
                                                            />
                                                        </div>
                                                    )}
                                                    control={control}
                                                    onChange={([
                                                        event,
                                                        data
                                                    ]) => {
                                                        return data;
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="members"
                                                    render={({ onChange }) => (
                                                        <div className="w-100">
                                                            <RenderAutocompleteTextField
                                                                id="members"
                                                                label="Members"
                                                                inputAutoComplete="off"
                                                                size="small"
                                                                value={
                                                                    selectedMembers
                                                                }
                                                                options={
                                                                    membersList
                                                                }
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) => {
                                                                    onChange(
                                                                        data
                                                                    );
                                                                    handleMembersChange(
                                                                        data
                                                                    );
                                                                }}
                                                                inputPlaceholder="Search from the Members list"
                                                            />
                                                        </div>
                                                    )}
                                                    control={control}
                                                    onChange={([
                                                        event,
                                                        data
                                                    ]) => {
                                                        return data;
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="firm_type"
                                                    render={({ onChange }) => (
                                                        <div className="w-100">
                                                            <RenderAutocompleteTextField
                                                                id="firm_type"
                                                                label="Type of Firm"
                                                                inputAutoComplete="off"
                                                                size="small"
                                                                value={
                                                                    selectedFirmType
                                                                }
                                                                options={
                                                                    firmTypeList
                                                                }
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) => {
                                                                    onChange(
                                                                        data
                                                                    );
                                                                    handleFirmTypeChange(
                                                                        data
                                                                    );
                                                                }}
                                                                inputPlaceholder="Search firm type"
                                                            />
                                                        </div>
                                                    )}
                                                    control={control}
                                                    onChange={([
                                                        event,
                                                        data
                                                    ]) => {
                                                        return data;
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="current_funding_stage_id"
                                                    render={({ onChange }) => (
                                                        <div className="w-100">
                                                            <RenderAutocompleteTextField
                                                                id="current_funding_stage_id"
                                                                label="Stages"
                                                                inputAutoComplete="off"
                                                                size="small"
                                                                value={
                                                                    selectedStages
                                                                }
                                                                options={
                                                                    stageList
                                                                }
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) => {
                                                                    onChange(
                                                                        data
                                                                    );
                                                                    handleStagesChange(
                                                                        data
                                                                    );
                                                                }}
                                                                inputPlaceholder="Search stages"
                                                            />
                                                        </div>
                                                    )}
                                                    control={control}
                                                    onChange={([
                                                        event,
                                                        data
                                                    ]) => {
                                                        return data;
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    name="hq_geos"
                                                    render={({
                                                        onChange,
                                                        value
                                                    }) => (
                                                        <div className="w-100">
                                                            <RenderAutocompleteTextField
                                                                id="hq_geos"
                                                                label="Geographies targeting"
                                                                size="small"
                                                                value={
                                                                    selectedHQGeos
                                                                }
                                                                inputAutoComplete="off"
                                                                onChange={(
                                                                    e,
                                                                    data
                                                                ) => {
                                                                    onChange(
                                                                        data
                                                                    );
                                                                    handleHQGeosChange(
                                                                        data
                                                                    );
                                                                }}
                                                                options={
                                                                    countries
                                                                }
                                                                inputPlaceholder="Search targeting Geographies"
                                                            />
                                                        </div>
                                                    )}
                                                    control={control}
                                                    onChange={([
                                                        event,
                                                        data
                                                    ]) => {
                                                        return data;
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <ListItem className="px-0 d-flex align-items-center justify-content-between">
                                                    <RenderTextField
                                                        value={min}
                                                        onInput={onMinChange}
                                                        type="number"
                                                        min={1}
                                                        size="small"
                                                        error={min && min < 0}
                                                        inputMode="numeric"
                                                        inputContainerClassName={
                                                            'w-100 mr-1'
                                                        }
                                                        label="Min Ticket Size"
                                                    />
                                                    <RenderTextField
                                                        value={max}
                                                        onInput={onMaxChange}
                                                        type="number"
                                                        min={1}
                                                        size="small"
                                                        max={100000000000}
                                                        error={
                                                            max &&
                                                            max > 100000000000
                                                        }
                                                        inputMode="numeric"
                                                        inputContainerClassName={
                                                            'w-100 mx-1'
                                                        }
                                                        label="Max Ticket Size"
                                                    />
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </form>
                            </Grid>

                            <Grid item md={6} sm={12} xs={12}>
                                <div className="mb-0 pb-0">
                                    <Tabs
                                        value={value}
                                        onChange={handleTabChange}
                                        variant="scrollable"
                                        indicatorColor="primary"
                                        textColor="primary"
                                        scrollButtons="on"
                                        classes={{
                                            flexContainer: !isSmallScreen
                                                ? 'justify-content-center'
                                                : ''
                                        }}
                                    >
                                        <Tab
                                            label={`Matching Users (${matchingUsers.length})`}
                                            className="text-inherit"
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        />
                                        <Tab
                                            label={`Shared Users (${sharedUsers.length})`}
                                            className="text-inherit"
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <MatchingUsers
                                            checked={checked}
                                            setChecked={setChecked}
                                            selected={selected}
                                            setSelected={setSelected}
                                            equity_id={deal?.equity_id}
                                            isFilterLoading={saveFilterLoading}
                                            usersList={usersList}
                                            filter={filterData}
                                            matchingUsersList={matchingUsers}
                                        />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <SharedUsers
                                            checked={sharedChecked}
                                            setChecked={setSharedChecked}
                                            isFilterLoading={saveFilterLoading}
                                            equity_id={deal?.equity_id}
                                            sharedUsersList={sharedUsers}
                                        />
                                    </TabPanel>
                                </div>
                            </Grid>
                        </Grid>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <div className="d-flex align-items-center justify-content-center w-100">
                    <Button
                        onClick={handleClose}
                        color="primary"
                        className="text-inherit"
                        autoFocus
                    >
                        {modal?.cancelTitle || 'Cancel'}
                    </Button>
                    <Button
                        className="ml-3 text-inherit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmitForm}
                        disabled={isLoading || isShareLoading}
                    >
                        {isShareLoading ? (
                            <CircularProgress
                                size="1.3rem"
                                className="circular-progress-primary ml-0"
                            />
                        ) : isLoading ? (
                            'Share with'
                        ) : (
                            `Share with ${
                                value === 1
                                    ? sharedChecked.length
                                    : checked.length + selected.length
                            } 
                        ${
                            sharedChecked.length ||
                            checked.length + selected.length > 1
                                ? 'users'
                                : 'user'
                        }`
                        )}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    GetCountries,
    GetDealFilters,
    SaveFilters,
    GetUsersList,
    GrantAccess,
    CommonDispatcher,
    GetAccessUsersList,
    GetDealsList,
    GetFirmsList,
    GetMatchingUsersCount
})(React.memo(DealUpdateModal));
