import {
    Button,
    Container,
    Grid,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import { RootState } from '../../../../redux/store';
import { CommonDispatcher } from '../../../../redux/Common/action';
import { UploadImages } from '../../../../redux/Common/action';
import { UpdateDeal } from '../../../../redux/FundDeals/actions';
import {
    SET_FUND_EQUITY_DEAL_DATA,
    SET_FUND_EQUITY_DEAL_FORM_ERROR,
    UPDATE_FUNDS_WIZARD_FORM_DATA
} from '../../../../redux/FundDeals/actionTypes';
import RenderDatePicker from '../../../InputComponents/DatePickerComponent/RenderDatePicker';
import { OPEN_ALERT_MODAL } from '../../../../redux/ModalReducer/actionTypes';
import RenderSelectField from '../../../InputComponents/SelectField/RenderSelectField';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import RenderMaskedTextField from '../../../InputComponents/MaskedTextField';
import RenderToggleSwitch from '../../../InputComponents/ToggleSwitch/RenderToggleSwitch';
import { validateThirdStep } from '../Common/validate';
import { Add, Delete } from '@material-ui/icons';
import { UpdatePost } from '../Common';
import moment from 'moment';
import { TRACK_RECORDS_TYPE_LIST } from '../../../../utils/constants';
import currenciesData from './../../../../utils/currency.json';
import { getQuery } from '../../../../utils/common';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const implementationData = [
    { title: 'Yes', value: 'YES', disabled: false },
    { title: 'Partially', value: 'PARTIALLY', disabled: false },
    { title: 'No', value: 'NO', disabled: false }
];

const trackRecordsData = [
    { title: 'Yes', value: true, disabled: false },
    { title: 'No', value: false, disabled: false }
];

const StepSixComponent = ({
    metaData,
    CommonDispatcher,
    UpdateDeal,
    UploadImages
}: any) => {
    const history = useHistory();
    const query = getQuery(history);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const post_id = query.get('post_id');
    const state = useSelector((store: RootState) => store);
    const [currencySymbol, setCurrencySymbol] = useState();
    const [isLoading, setLoading] = useState(false);
    const [implementation, setImplementation] = useState(
        implementationData?.[0]?.value
    );
    const [trackRecord, setTrackRecord] = useState(
        trackRecordsData?.[0]?.value
    );
    const [currencyList, setCurrencyList] = useState([]);
    const [currency_id, setCurrency] = useState();

    const [expiry_date, setExpiryDate] = useState<any>(
        moment().add(2, 'months')
    );

    const currencies =
        metaData?.currencies && metaData?.currencies?.length
            ? metaData?.currencies
            : currenciesData || [];

    let companyFoundedList = state?.commonAppData?.foundedList || [];
    let [, ...restCompanyFoundedList] = companyFoundedList;
    const [lastFundVintageList, setLastFundVintageList] = useState(
        [
            {
                title: 'Not Applicable',
                value: 'N/A'
            }
        ].concat(restCompanyFoundedList)
    );

    const activeStep = state?.FundDeals?.wizard?.currentStep || 0;
    const steps = state?.FundDeals?.wizard?.formSteps || [];
    const errorStep = state?.FundDeals?.wizard?.errorStep || [];
    const deal_data = state?.FundDeals?.deal_data || {};
    const deal_data_error = state?.FundDeals?.deal_data_error || {};
    const [errors, setError, errorRef] = useStateRef({
        ...deal_data_error[activeStep]
    }) as [any, any, any];
    const vintageInvestmentList = state?.commonAppData?.foundedList || [];
    const fieldsToValidate = [
        'is_track_record_available',
        'is_track_record_attached',
        'track_records',
        'expiry_date'
    ];

    const handleImplementation = (e, value) => {
        if (!!value) setImplementation(value);
    };

    const handleTrackRecords = (e, value) => {
        if (value !== null) setTrackRecord(value);
    };

    const { control, reset, watch, setValue, getValues, register } = useForm({
        mode: 'onChange'
    });

    const {
        fields: trackRecordSummaryFields,
        append: trackRecordSummaryAppend,
        remove: removeTrackRecordSummary
    } = useFieldArray({
        control,
        name: 'track_records'
    });
    useEffect(() => {
        let obj: any = {};
        Object.entries(deal_data).forEach(([k]) => {
            if (fieldsToValidate.includes(k)) {
                obj[k] = deal_data[k];
            }
        });
        setImplementation(obj?.is_track_record_available);
        setTrackRecord(obj?.is_track_record_attached);

        if (
            !obj?.track_records ||
            (obj?.track_records && !obj?.track_records.length)
        ) {
            obj.track_records = [
                {
                    type: '',
                    year_of_first_investment: '',
                    name: '',
                    no_of_companies: '',
                    tota_cost: '',
                    tota_fair_value: '',
                    muliple_on_cost: '',
                    net_irr: '',
                    dpi: '',
                    tvpi: ''
                }
            ];
        }
        let currencyData = [];
        currencies.forEach((d) => {
            currencyData.push({ ...d, title: d?.label, value: d?.currency_id });
        });
        setCurrencyList(currencyData);

        if (obj?.currency_id) {
            let curr =
                currencyData.find((d) => d?.value === obj?.currency_id) || {};
            setCurrencySymbol(curr?.symbol);
        } else {
            obj.currency_id = currencyData[0].value;
        }

        if (obj?.expiry_date) {
            const date = moment(obj?.expiry_date);
            const isDateValid = date.isValid();
            obj.expiry_date = isDateValid ? date : expiry_date;
            setExpiryDate(moment(obj?.expiry_date));
        } else {
            obj.expiry_date = expiry_date;
            setExpiryDate(expiry_date);
        }
        setCurrency(currencyData[0].value);
        setValue('expiry_date', obj?.expiry_date);
        setCurrencySymbol(currencyData[0].symbol);

        reset(obj);
    }, [deal_data]);

    const onCurrencyChange = (e) => {
        const value = e.target.value;
        const symbol = currencyList.find((d) => d?.currency_id === value);
        setCurrencySymbol(symbol?.symbol);
        setCurrency(symbol.value);
    };

    const handleExpiryDate = (date) => {
        const exp_date: any = moment(date);
        setExpiryDate(exp_date);
        setValue('expiry_date', exp_date);
    };

    const setWizardError = () => {
        CommonDispatcher(SET_FUND_EQUITY_DEAL_FORM_ERROR, {
            [activeStep]: errorRef?.current
        });
        const errItemsCount = Object.keys(errorRef?.current).filter(
            (d) => errorRef?.current[d]
        )?.length;
        let errors = { ...errorStep, [activeStep]: errItemsCount };
        CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
            errorStep: errors
        });
    };

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };
    const handleBack = (flag = false) => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep - 1
            });
        }
        let data: any = getFormValues(getValues());
        const err = validateThirdStep(data);
        setError(err);
        flag && setWizardError();
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());
        data.equity_id = post_id || deal_data?.equity_id;
        data.is_track_record_available = implementation;
        data.is_track_record_attached = trackRecord;

        const err = validateThirdStep(data);
        setError(err);
        CommonDispatcher(SET_FUND_EQUITY_DEAL_FORM_ERROR, {
            [activeStep]: errorRef?.current
        });
        if (data?.equity_id) {
            setLoading(true);
            UpdatePost(
                (data) => UpdateDeal(token, data),
                CommonDispatcher,
                data,
                UploadImages,
                setLoading,
                next,
                setWizardError
            );
        }
    };

    const getFormValues = (obj) => {
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            data[key] = value;
        });
        return data;
    };

    const removeTrackRecord = (index) => {
        removeTrackRecordSummary(index);

        let track_records: any = deal_data?.track_records;
        if (track_records && track_records.length) {
            track_records.splice(index, 1);
        }
        CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, { track_records });
        CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
    };

    return (
        <Container maxWidth="md">
            <Grid item xs={12} className="m-auto pb-5">
                <form>
                    <div className="my-5 d-flex align-items-center justify-content-between">
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            component="p"
                            className="mr-2 fs-16 w-50"
                        >
                            Does the team have a relevant track record
                            individually or as a fund?*
                        </Typography>
                        <RenderToggleSwitch
                            id="is_track_record_available"
                            label=""
                            labelClassName="ml-3"
                            inputContainerClassName="d-flex align-items-center justify-content-end justify-content-between"
                            buttonGroupClassName="w-unset ml-3"
                            value={implementation}
                            onChange={handleImplementation}
                            dataArray={implementationData}
                        />
                    </div>
                    <div className="my-5 d-flex align-items-center justify-content-between">
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            component="p"
                            className="mr-2 fs-16 w-50"
                        >
                            Are you attaching/have you attached the track record
                            in Documents?
                        </Typography>
                        <RenderToggleSwitch
                            id="is_track_record_attached"
                            label=""
                            labelClassName="ml-3"
                            value={trackRecord}
                            onChange={handleTrackRecords}
                            dataArray={trackRecordsData}
                        />
                    </div>
                    <div>
                        <Grid container spacing={2} className="mb-3">
                            <Grid
                                item
                                xs={12}
                                className="py-1 d-flex flex-direction-column"
                            >
                                <Controller
                                    name="expiry_date"
                                    render={({ onChange, value }) => {
                                        return (
                                            <RenderDatePicker
                                                shrink={true}
                                                value={value}
                                                showInputLabel={true}
                                                onChange={(data) => {
                                                    onChange(data);
                                                    handleExpiryDate(data);
                                                }}
                                                label="Deal closing date *"
                                                format="DD-MM-YYYY"
                                            />
                                        );
                                    }}
                                    control={control}
                                    defaultValue={expiry_date}
                                    rules={{
                                        required: true
                                    }}
                                />
                                {expiry_date ? (
                                    <span className="colorGray mt-1">
                                        The deal closes on{' '}
                                        {moment(expiry_date).format(
                                            'Do MMMM YYYY'
                                        )}{' '}
                                        at 23:59 UTC.
                                    </span>
                                ) : null}
                                {/* <FormHelperText
                                    error={true}
                                    className={
                                        errors?.expiry_date ? 'mb-1' : ''
                                    }
                                >
                                    {errors?.expiry_date &&
                                        'Closing date is mandatory'}
                                </FormHelperText> */}
                            </Grid>
                        </Grid>
                    </div>
                </form>
                <Typography variant="h5">Track Record Summary</Typography>
                <Grid container spacing={2} className="my-1 bgColorLightGray">
                    <Grid item xs={12} className="py-1">
                        {trackRecordSummaryFields.map((obj, index) => {
                            let item = obj;

                            const multiple_on_cost =
                                parseFloat(
                                    watch(
                                        `track_records[${index}].total_fair_value`
                                    )
                                ) /
                                parseFloat(
                                    watch(`track_records[${index}].total_cost`)
                                );
                            item.multiple_on_cost = multiple_on_cost;
                            return (
                                <div
                                    key={item?.id}
                                    className="appCard p-3 mb-4"
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name={`track_records[${index}].type`}
                                                render={({
                                                    onChange,
                                                    value
                                                }) => {
                                                    return (
                                                        <RenderSelectField
                                                            label="Type"
                                                            value={value}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(e);
                                                            }}
                                                            inputContainerClassName={
                                                                'w-100'
                                                            }
                                                            inputId="select-raising-currency"
                                                            dropdownListHeight={
                                                                250
                                                            }
                                                            dataArray={
                                                                TRACK_RECORDS_TYPE_LIST
                                                            }
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                defaultValue={item.type}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name={`track_records[${index}].year_of_first_investment`}
                                                render={({
                                                    onChange,
                                                    value
                                                }) => {
                                                    return (
                                                        <RenderSelectField
                                                            label="Vintage/Year of first investment"
                                                            value={value}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(e);
                                                            }}
                                                            inputContainerClassName={
                                                                'w-100'
                                                            }
                                                            inputId="select-raising-currency"
                                                            dropdownListHeight={
                                                                250
                                                            }
                                                            dataArray={
                                                                vintageInvestmentList
                                                            }
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                defaultValue={
                                                    item?.year_of_first_investment
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name={`track_records[${index}].name`}
                                                as={
                                                    <RenderTextField
                                                        // value={company_name}
                                                        autoComplete="off"
                                                        inputContainerClassName={
                                                            'w-100'
                                                        }
                                                        label="Name"
                                                    />
                                                }
                                                control={control}
                                                defaultValue={
                                                    item?.name || null
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Controller
                                                name={`track_records[${index}].currency_id`}
                                                render={({
                                                    onChange,
                                                    value
                                                }) => {
                                                    return (
                                                        <RenderSelectField
                                                            label="Currency"
                                                            value={value}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(e);
                                                                onCurrencyChange(
                                                                    e
                                                                );
                                                            }}
                                                            inputContainerClassName={
                                                                'w-100'
                                                            }
                                                            inputId="select-raising-currency"
                                                            dropdownListHeight={
                                                                250
                                                            }
                                                            dataArray={
                                                                currencyList
                                                            }
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                defaultValue={currency_id}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Controller
                                                name={`track_records[${index}].fund_size`}
                                                render={({
                                                    onChange,
                                                    value
                                                }) => {
                                                    return (
                                                        <RenderMaskedTextField
                                                            autoComplete="off"
                                                            startAdornment={
                                                                currencySymbol
                                                            }
                                                            inputContainerClassName={
                                                                'w-100'
                                                            }
                                                            value={value}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(e);
                                                            }}
                                                            label="Fund size"
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                defaultValue={
                                                    item?.fund_size || null
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3}>
                                            <Controller
                                                name={`track_records[${index}].no_of_companies`}
                                                render={({
                                                    onChange,
                                                    value
                                                }) => {
                                                    return (
                                                        <RenderMaskedTextField
                                                            autoComplete="off"
                                                            inputContainerClassName={
                                                                'w-100'
                                                            }
                                                            value={value}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(e);
                                                            }}
                                                            label={`Number of companies`}
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                // inputRef={register}
                                                defaultValue={
                                                    item?.no_of_companies ||
                                                    null
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name={`track_records[${index}].total_cost`}
                                                render={({
                                                    onChange,
                                                    value
                                                }) => {
                                                    return (
                                                        <RenderMaskedTextField
                                                            autoComplete="off"
                                                            inputContainerClassName={
                                                                'w-100'
                                                            }
                                                            startAdornment={
                                                                currencySymbol
                                                            }
                                                            value={value}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(e);
                                                            }}
                                                            label="Total Cost"
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                defaultValue={
                                                    item?.total_cost || null
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name={`track_records[${index}].total_fair_value`}
                                                render={({
                                                    onChange,
                                                    value
                                                }) => {
                                                    return (
                                                        <RenderMaskedTextField
                                                            inputContainerClassName={
                                                                'w-100'
                                                            }
                                                            startAdornment={
                                                                currencySymbol
                                                            }
                                                            value={value}
                                                            onChange={(
                                                                e: any
                                                            ) => {
                                                                onChange(e);
                                                            }}
                                                            autoComplete="off"
                                                            label={`Total Fair Value`}
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                inputRef={register}
                                                defaultValue={
                                                    item?.total_fair_value ||
                                                    null
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name={`track_records[${index}].multiple_on_cost`}
                                                render={({}) => {
                                                    return (
                                                        <RenderTextField
                                                            type="number"
                                                            inputContainerClassName={
                                                                'w-100'
                                                            }
                                                            value={
                                                                item?.multiple_on_cost ||
                                                                null
                                                            }
                                                            autoComplete="off"
                                                            label={`Multiple on cost`}
                                                        />
                                                    );
                                                }}
                                                control={control}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name={`track_records[${index}].net_irr`}
                                                as={
                                                    <RenderTextField
                                                        type="number"
                                                        autoComplete="off"
                                                        inputContainerClassName={
                                                            'w-100'
                                                        }
                                                        label="NetIRR %"
                                                    />
                                                }
                                                control={control}
                                                defaultValue={
                                                    item?.net_irr || null
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name={`track_records[${index}].dpi`}
                                                as={
                                                    <RenderTextField
                                                        type="number"
                                                        autoComplete="off"
                                                        inputContainerClassName={
                                                            'w-100'
                                                        }
                                                        label="DPI"
                                                    />
                                                }
                                                control={control}
                                                defaultValue={item?.dpi || null}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                type="number"
                                                name={`track_records[${index}].tvpi`}
                                                as={
                                                    <RenderTextField
                                                        inputContainerClassName={
                                                            'w-100'
                                                        }
                                                        autoComplete="off"
                                                        label={`TVPI`}
                                                    />
                                                }
                                                control={control}
                                                inputRef={register}
                                                defaultValue={
                                                    item?.tvpi || null
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                    <div className="mt-5 d-flex justify-content-end">
                                        <Button
                                            color="secondary"
                                            variant="text"
                                            type="button"
                                            className="float-right"
                                            size="small"
                                            onClick={() => {
                                                removeTrackRecord(index);
                                            }}
                                        >
                                            <Delete className="mr-2" /> Delete
                                        </Button>
                                    </div>
                                </div>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid container spacing={2} className="mb-3">
                    <Grid item xs={12} className="mt-3">
                        <Button
                            color="primary"
                            variant="outlined"
                            type="button"
                            size="small"
                            disabled={trackRecordSummaryFields?.length >= 10}
                            className="float-right"
                            onClick={() => {
                                trackRecordSummaryAppend({
                                    name: ''
                                });
                            }}
                        >
                            <Add className="mr-2" />
                            Add new
                        </Button>
                    </Grid>
                </Grid>
                <div className="mt-5 py-4 border-top mb-sm-12 d-flex align-items-center justify-content-center btn-submit-post w-100">
                    <Button
                        color="secondary"
                        variant="text"
                        type="button"
                        className={`fs-16 text-inherit`}
                        onClick={() => handleBack(true)}
                    >
                        Back
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onSave}
                        disabled={isLoading}
                        className="fs-16 text-inherit btn-submit-style ml-5"
                    >
                        Next{' '}
                        {isLoading ? (
                            <CircularProgress
                                size="1.3rem"
                                className="circular-progress"
                            />
                        ) : null}
                    </Button>
                </div>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    CommonDispatcher,
    UpdateDeal,
    UploadImages
})(StepSixComponent);
