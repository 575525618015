import * as React from 'react';
import style from './Styles.module.scss';
import { Checkbox } from '@material-ui/core';

interface IRenderCheckboxComponentProps {
    id?: string;
    name?: any;
    className?: string;
    classes?: object;
    color?: any;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (e: object, value: any) => void;
}

const RenderCheckboxComponent = ({
    id,
    name,
    checked,
    classes,
    className,
    disabled,
    color = 'primary',
    onChange
}: IRenderCheckboxComponentProps) => {
    return (
        <div className={style.inputContainer}>
            <Checkbox
                id={id}
                name={name}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                color={color}
                classes={classes}
            />
        </div>
    );
};
export default RenderCheckboxComponent;
