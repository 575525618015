import { Grid, Typography } from '@material-ui/core';
import style from './Styles.module.scss';

const CommonDetailsPageItemWrapper = ({
    children,
    title,
    col = false,
    isChip = false,
    hasLinkValue = false,
    fullWidth
}: any) => {
    return (
        <Grid item xs={12} sm={fullWidth ? fullWidth : 6}>
            <Typography
                variant="body2"
                component="p"
                className={`font-weight-bold font-public-sans d-flex flex-wrap ${
                    style.label
                } ${col ? 'align-items-flex-start flex-direction-column' : ''}`}
            >
                {title ? (
                    <span className={col ? 'mb-2' : ''}>{title}:&nbsp;</span>
                ) : null}

                <span
                    className={`font-weight-normal ${
                        !hasLinkValue ? style.label : 'colorUnset'
                    }`}
                >
                    {children}
                </span>
            </Typography>
        </Grid>
    );
};
export default CommonDetailsPageItemWrapper;
