import { Chip, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SetFilter } from '../../../redux/Common/action';
import { RootState } from '../../../redux/store';
import {
    contactsTagsFilter,
    getQuery,
    scrollToTop
} from '../../../utils/common';

const TagsFilter = ({ SetFilter, addressBook }: any) => {
    const history = useHistory();
    const location = useLocation();
    const query: any = getQuery(history);
    const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
    const store = useSelector((store: RootState) => store);
    const firm_tags = store?.firms?.firm_tags?.tagsData || [];
    const tags_ids = query.get('tags_ids');

    useEffect(() => {
        scrollToTop();
        if (Object.keys(query).length) {
            SetFilter(query);
        }
        if (tags_ids) {
            const tags = contactsTagsFilter(firm_tags, tags_ids);
            setSelectedFilters(tags);
        } else {
            setSelectedFilters([]);
        }
    }, [firm_tags, tags_ids]);

    const handleDelete = (tagId: string) => {
        const updatedTags = selectedFilters.filter(
            (tag: any) => tag?.id !== tagId
        );
        const updatedIds = updatedTags.map((tag: any) => tag?.id);
        const updatedQuery = { ...query };
        if (updatedIds.length > 0) {
            updatedQuery.tags_ids = updatedIds.join(',');
        } else {
            delete updatedQuery.tags_ids;
        }
        history.replace({
            pathname: location.pathname,
            search: new URLSearchParams(updatedQuery).toString()
        });
    };

    const handleReset = () => {
        scrollToTop();
        const updatedQuery = { ...query };
        delete updatedQuery.tags_ids;
        history.replace({
            pathname: location.pathname,
            search: new URLSearchParams(updatedQuery).toString()
        });
    };

    return selectedFilters.length > 0 ? (
        <div
            className={`d-flex align-items-center flex-wrap row-gap-10 w-100 mb-3`}
        >
            <Typography
                variant="body2"
                className="colorPrimary font-weight-bold mr-2"
                component="p"
            >
                {addressBook ? `Selected Filter:` : `Selected Filters:`}
            </Typography>
            {selectedFilters.map((tag: any, index) => (
                <Chip
                    key={index}
                    label={tag?.tag_text}
                    variant="outlined"
                    onDelete={() => handleDelete(tag?.id)}
                    className="mr-3 bgColorWhite"
                    classes={{ deleteIcon: 'colorDanger' }}
                />
            ))}
            <Chip
                label="RESET ALL"
                onDelete={handleReset}
                classes={{ deleteIcon: 'colorWhite' }}
                className="bgColorRed colorWhite"
            />
        </div>
    ) : null;
};

export default connect(null, { SetFilter })(TagsFilter);
