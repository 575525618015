import {
    Avatar,
    CssBaseline,
    Menu,
    MenuItem,
    Popover,
    useMediaQuery
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Routes } from '../../constants';
import { CommonDispatcher, OpenModal } from '../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../redux/Common/actionTypes';
import {
    CHANGE_PASSWORD_MODAL,
    OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL,
    SESSIONS_MODAL
} from '../../redux/ModalReducer/actionTypes';
import { CreateSubscription } from '../../redux/Subscriptions/actions';
import { RootState } from '../../redux/store';
import { DrawerMenuItems } from '../../utils/constants';
import style from './Styles.module.scss';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    title: {
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        paddingRight: 24
    }
}));

const Header = ({ OpenModal, CommonDispatcher, CreateSubscription }) => {                                                                       
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const theme = useTheme();              
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [cookies, setCookie, removeCookie] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [profileMenuEl, setProfileMenuEl] = React.useState(null);
    const [menuItems, setMenuItems] = React.useState([]);
    const [headerMenuAnchorEl, setHeaderMenuAnchorEl] =
        useState<null | HTMLElement>(null);
    const isProfileMenuEl = Boolean(profileMenuEl);
    const isHeaderMenuAnchorEl = Boolean(headerMenuAnchorEl);

    const handleHeaderMenuAnchorEl = (event) => {
        setHeaderMenuAnchorEl(event.currentTarget);
    };
    const handleHeaderMenuAnchorElClose = () => {
        setHeaderMenuAnchorEl(null);
    };

    const profile =
        useSelector((store: RootState) => store?.commonAppData.profile) || {};

    const profileMenuId = isProfileMenuEl ? 'Profile-Menu-Id' : undefined;
    const headerMenuAnchorElId = isHeaderMenuAnchorEl
        ? 'Header-Menu-Anchor-El'
        : undefined;

    useEffect(() => {
        const keys = Object.keys(profile);

        let menuItemsData = DrawerMenuItems.filter((d) => {
            if (d?.isActive) return d;
            else return keys.includes(d?.key) && profile[d?.key];
        });
        setMenuItems(menuItemsData);
        // renderPageName();
        // eslint-disable-next-line
    }, [profile, location.pathname]);

    const doLogout = () => {
        const oneYearFromNow = new Date();
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
        setCookie('pgAdminToken', '', {
            path: Routes.home,
            expires: oneYearFromNow
        });
        removeCookie('pgAdminToken', { path: Routes.home });
    };

    const handleActivateSubscription = () => {
        OpenModal(OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL, {
            isOpen: true,
            title: 'Create Premium Subscription',
            submitTitle: 'Create',
            data: { modalSize: 'xs' },
            callBack: (flag, data) => {
                if (flag) {
                    CreateSubscription(token, data).then(() => {
                        OpenModal(OPEN_ACTIVATE_SUBSCRIPTIONS_MODAL, {
                            isOpen: false,
                            isLoading: false
                        });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Subscription created successfully'
                        });
                    });
                }
            }
        });
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setProfileMenuEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuEl(null);
    };

    const renderMenu = (
        <Menu
            anchorEl={profileMenuEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={profileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isProfileMenuEl}
            onClose={handleProfileMenuClose}
            classes={{
                paper: style.accountMenuPopup
            }}
        >
            <MenuItem className="px-0">
                <span
                    className="d-flex align-items-center w-100 px-4"
                    onClick={() => {
                        OpenModal(SESSIONS_MODAL, {
                            title: 'Admin Sessions',
                            isOpen: true,
                            data: { isAdmin: true }
                        });
                        handleProfileMenuClose();
                    }}
                >
                    <span className={style.menuItemTitle}>Admin Sessions</span>
                </span>
            </MenuItem>
            <MenuItem className="px-0">
                <span
                    className="d-flex align-items-center w-100 px-4"
                    onClick={() => {
                        handleActivateSubscription();
                        handleProfileMenuClose();
                    }}
                >
                    <span className={style.menuItemTitle}>
                        Activate Subscription
                    </span>
                </span>
            </MenuItem>
            <MenuItem className="px-0">
                <span
                    className="d-flex align-items-center w-100 px-4"
                    onClick={() => {
                        OpenModal(CHANGE_PASSWORD_MODAL, {
                            title: 'Change Password',
                            isOpen: true,
                            data: { isAdmin: true }
                        });
                        handleProfileMenuClose();
                    }}
                >
                    <span className={style.menuItemTitle}>Change Password</span>
                </span>
            </MenuItem>

            <MenuItem className="px-0">
                <span
                    className="d-flex align-items-center w-100 px-4"
                    onClick={() => {
                        doLogout();
                        handleProfileMenuClose();
                    }}
                >
                    <span className={style.menuItemTitle}>Logout</span>
                </span>
            </MenuItem>
        </Menu>
    );

    const menu = (d, className) => {
        const isActiveLink = history?.location?.pathname === d?.route;
        return (
            <Link
                color="inherit"
                to={d?.route}
                key={d?.label}
                className={`${style.linkListItem} ${
                    isActiveLink
                        ? 'bgColorWhite colorPrimary border-radius-4'
                        : ''
                }`}
                onClick={handleHeaderMenuAnchorElClose}
                onKeyDown={handleHeaderMenuAnchorElClose}
            >
                <ListItem dense onClick={handleHeaderMenuAnchorEl}>
                    {d?.icon()}
                    <ListItemText
                        primary={d?.label}
                        className={`${!isActiveLink && className}`}
                    />
                </ListItem>
            </Link>
        );
    };

    const headerMenu = (
        <List className="py-0 d-flex" component="nav" aria-label="nav-menu">
            {menuItems && menuItems.length
                ? menuItems.map((d: any, i) => menu(d, 'colorWhite'))
                : null}
        </List>
    );

    const popupMenu = (
        <div style={{ width: '240px' }}>
            {menuItems && menuItems.length
                ? menuItems.map((d: any, i) => menu(d, 'colorPrimary'))
                : null}
        </div>
    );

    return (
        <div className="w-100">
            <CssBaseline />
            <AppBar position="fixed" className="p-0">
                <Toolbar>
                    <div className="w-100 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            {isSmallScreen ? (
                                <div className="d-flex align-items-center">
                                    <IconButton
                                        className={classes.menuButton}
                                        color="inherit"
                                        aria-label="Menu"
                                        onClick={handleHeaderMenuAnchorEl}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </div>
                            ) : (
                                headerMenu
                            )}
                        </div>
                        <Popover
                            id={headerMenuAnchorElId}
                            open={isHeaderMenuAnchorEl}
                            anchorEl={headerMenuAnchorEl}
                            onClose={handleHeaderMenuAnchorElClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                        >
                            {popupMenu}
                        </Popover>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={profileMenuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            className="p-1"
                            color="inherit"
                        >
                            <Avatar
                                alt="Profile"
                                src={profile?.sm_profile_picture}
                                className={'avatar'}
                            />
                        </IconButton>
                        {renderMenu}
                    </div>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </div>
    );
};

export default connect(null, {
    CreateSubscription,
    CommonDispatcher,
    OpenModal
})(Header);
