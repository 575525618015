import axios from 'axios';
import { API_PATH } from '../../constants';
import { EMAIL_REFERRAL, LINK_REFERRAL } from './actionTypes';

export const GetUserReferral = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/user-referrals?page_number=${data?.page_number}&size=5`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            dispatch({
                type: EMAIL_REFERRAL,
                payload: response
            });
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetUnknownUserReferral = (token: string, data) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/unknown-user-referrals?page_number=${data?.page_number}&size=1`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            dispatch({
                type: LINK_REFERRAL,
                payload: response
            });
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
