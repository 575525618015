import {Button, Container, Grid} from "@material-ui/core";
import React from "react";
import {connect, useSelector} from "react-redux";
import millify from "millify";
import {CommonDispatcher} from "../../../../redux/Common/action";
import {UPDATE_WIZARD_FORM_DATA} from "../../../../redux/Deals/actionTypes";
import {RootState} from "../../../../redux/store";
import countriesArray from "./../../../../utils/countries.json";
import sectorsArray from "./../../../../utils/sectors.json";
import DealsDetails from "../../DealsDetails";

const ReviewComponent = ({equity_id: post_id, CommonDispatcher}: any) => {

    const wrapNumbers = (deal_data) => {
        const post = {...deal_data};
        const numberFields = ["target_raise", "soft_circled", "last12_month_revenue", "next12_month_revenue"];
        numberFields.forEach(key => {
            if (post[key]) {
                post[`display_${key}`] = `${post?.currency?.symbol || ""}${millify(post[key])}`;
            } else {
                post[`display_${key}`] = "";
            }
        });
        return post;
    };

    const state = useSelector((store: RootState) => store);
    const deal_data = state?.deals?.deal_data || {};
    const activeStep = state?.deals?.wizard?.currentStep || 0;
    let deal: any = wrapNumbers(deal_data);

    deal.sectors = deal?.sectors && deal?.sectors.length ? sectorsArray.filter(d => deal?.sectors?.includes(d?.id)).map((d: any) => {
        d.label = d?.title;
        return d;
    }) : [];
    deal.hq_geos = deal?.hq_geos && deal?.hq_geos.length ? countriesArray.filter(d => deal?.hq_geos?.includes(d?.id)).map((d: any) => {
        d.label = d?.title;
        return d;
    }) : [];
    deal.operational_geos = deal?.ops_geos && deal?.ops_geos.length ? countriesArray.filter(d => deal?.ops_geos?.includes(d?.id)).map((d: any) => {
        d.label = d?.title;
        return d;
    }) : [];

    const handleBack = () => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {currentStep: activeStep - 1});
        }
    };

    const handleFinish = () => {
        /*history.push(`${Routes.deals.deals}/deals-details/${post_id}`);
        if (deal_data?.equity_id || post_id) {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: "Deal updated successfully."
            });
        }*/
    };

    return <Container maxWidth="md">
        <Grid container spacing={3} className="mb-5">
            <Grid item xs={12} className="m-auto pb-5">
                <div data-aos="fade-left" data-aos-duration="200">
                    <Grid className="py-1">
                        <DealsDetails jsonData={{deal}} hideDealStatus={true}/>
                    </Grid>
                </div>
                <div className="mt-5 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <Button color="primary"
                                variant="text"
                                className={`fs-16 text-inherit`}
                                onClick={handleBack}>
                            Back
                        </Button>
                    </div>
                    <div className="flex-grow-1 d-flex align-items-center justify-content-end">
                        <Button color="primary"
                                variant="contained"
                                onClick={handleFinish}
                                className={`fs-16 text-inherit btn-submit-style`}>
                            Publish
                        </Button>
                    </div>
                </div>
            </Grid>
        </Grid>
    </Container>;
};

export default connect(null, {CommonDispatcher})(ReviewComponent);