import React, { Component } from 'react';
import style from './AutoCompleteAddress.module.scss';
import { GoogleAPI, GoogleApiWrapper } from 'google-maps-react';
import PlacesAutocomplete, {
    geocodeByAddress,
    geocodeByPlaceId,
    getLatLng
} from 'react-places-autocomplete';
import clsx from 'clsx';
import MapComponent from '../MapComponent';
import RenderTextField from '../TextField/RenderTextField';
import { GOOGLE_MAP_API_KEY } from '../../../utils/constants';

interface IAutoCompleteProps {
    setAddress: (address: object) => void;
    setCoordinates?: (coordinates: object) => void;
    id: string;
    google: GoogleAPI;
    searchOptions?: object;
    label: string;
    inputLabel?: string;
    placeHolder?: string;
    autoComplete?: string;
    defaultValue: string;
    helperText: string;
    hideMap?: boolean;
    error?: boolean;
    disabled?: boolean;
    coordinates: object;
}

interface IAutoCompleteState {
    address: string;
    coordinates: object;
    showSuggestions: boolean;
}

class AutoCompleteAddressComponent extends Component<
    IAutoCompleteProps,
    IAutoCompleteState
> {
    constructor(props) {
        super(props);
        this.state = {
            address: props?.defaultValue || '',
            coordinates: props?.coordinates || {},
            showSuggestions: false
        };
    }

    handleAddressChange = (address) => {
        this.setState({ address, showSuggestions: true });
    };

    handleSelect = ({ area, city, country, address }) => {
        const { setAddress, setCoordinates } = this.props;
        setAddress({ area, city, country, address });
        this.setState({ showSuggestions: false });
        geocodeByAddress(address)
            .then((results) => {
                return getLatLng(results[0]);
            })
            .then((coordinates) => {
                if (typeof setCoordinates === 'function') {
                    setCoordinates(coordinates);
                }
                this.setState({ coordinates });
            })
            .catch((error) => {
                console.log('Error', error);
            });
    };

    handlePlace = async (suggestion) => {
        const place_id = suggestion?.placeId;
        const address = suggestion?.description;

        this.setState({ address });
        geocodeByPlaceId(place_id)
            .then((results) => {
                let area = '',
                    city = '',
                    country = '';
                if (results && results.length) {
                    results.forEach((d) => {
                        console.log(results);
                        d?.address_components.forEach((d) => {
                            if (!area && d.types.includes('political')) {
                                area = d?.long_name;
                            }
                            if (!city) {
                                if (d.types.includes('locality')) {
                                    city = d?.long_name;
                                } else if (
                                    d.types.includes(
                                        'administrative_area_level_2'
                                    )
                                ) {
                                    city = d?.long_name;
                                } else if (
                                    d.types.includes(
                                        'administrative_area_level_1'
                                    )
                                ) {
                                    city = d?.long_name;
                                }
                            }
                            if (d.types.includes('country')) {
                                country = d?.long_name;
                            }
                        });
                    });
                }
                console.log({ area, city, country, address });
                this.handleSelect({ area, city, country, address });
            })
            .catch((error) => console.error(error));
    };

    render() {
        const {
            id = 'address',
            google,
            label,
            inputLabel = '',
            hideMap,
            searchOptions,
            placeHolder,
            defaultValue,
            error,
            autoComplete,
            helperText,
            disabled
        } = this.props;
        const { address, coordinates, showSuggestions } = this.state;
        return (
            <>
                <div id="googleMap">
                    <PlacesAutocomplete
                        value={address || defaultValue}
                        highlightFirstSuggestion={true}
                        onChange={this.handleAddressChange}
                        searchOptions={searchOptions}
                    >
                        {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps
                        }) => {
                            return (
                                <div className="position-relative">
                                    <RenderTextField
                                        id={id}
                                        error={error}
                                        helperText={
                                            error
                                                ? helperText
                                                    ? helperText
                                                    : 'Location is mandatory'
                                                : ''
                                        }
                                        label={label}
                                        inputLabel={inputLabel}
                                        placeholder={placeHolder}
                                        onBlur={() =>
                                            this.setState({
                                                showSuggestions: false
                                            })
                                        }
                                        autoComplete={autoComplete}
                                        className={style.inputFields}
                                        value={address}
                                        {...getInputProps()}
                                        disabled={disabled}
                                        onChange={(e: any) => {
                                            if (!e?.target?.value) {
                                                this.handleSelect({
                                                    area: null,
                                                    address: null,
                                                    city: null,
                                                    country: null
                                                });
                                            }
                                            getInputProps().onChange(e);
                                        }}
                                    />
                                    {suggestions &&
                                    suggestions.length &&
                                    showSuggestions ? (
                                        <div
                                            className={
                                                style.autocompleteContainer
                                            }
                                        >
                                            {suggestions.map(
                                                (suggestion, i) => {
                                                    const className = clsx(
                                                        style.commonSuggestionsClass,
                                                        suggestion.active
                                                            ? style.suggestionItemActive
                                                            : style.suggestionItem
                                                    );
                                                    const {
                                                        formattedSuggestion: {
                                                            mainText,
                                                            secondaryText
                                                        }
                                                    } = suggestion;
                                                    return (
                                                        <div
                                                            key={`${suggestion.placeId}-${i}`}
                                                            {...getSuggestionItemProps(
                                                                suggestion,
                                                                { className }
                                                            )}
                                                            onClick={() =>
                                                                this.handlePlace(
                                                                    suggestion
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    '/maps-icon.svg'
                                                                }
                                                                alt="mapIcon"
                                                                height="25"
                                                                className={
                                                                    style.mapIcon
                                                                }
                                                            />
                                                            <span>
                                                                <strong>
                                                                    {mainText}
                                                                </strong>
                                                                {', '}
                                                                {secondaryText}
                                                            </span>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                            );
                        }}
                    </PlacesAutocomplete>
                    {!hideMap ? (
                        <MapComponent // @ts-ignore
                            zoom={14}
                            google={google}
                            visible={true}
                            coordinates={
                                coordinates || this?.props?.coordinates
                            }
                            address={address}
                        />
                    ) : null}
                </div>
            </>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: GOOGLE_MAP_API_KEY
})(AutoCompleteAddressComponent);
