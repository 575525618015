import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { RootState } from '../../redux/store';
import { useCookies } from 'react-cookie';
import { Route, useHistory } from 'react-router-dom';
import AllListingsComponent from './AllListings';
import AwaitingApprovalComponent from './AwaitingApproval';
import { DealsTab, Routes } from '../../constants';
import { Tab, Tabs, useMediaQuery } from '@material-ui/core';
import OtherListingsComponent from './OtherListings';
import DeclinedListingsComponent from './DeclinedListings';
import PinegrayListingsComponent from './PinegrayListings';
import DealUpdateModal from './DealUpdateModal';
import { GetDealsList } from '../../redux/Deals/actions';
import { getQuery } from '../../utils/common';
import { getSelectedTab } from '../Common/commonUtils';
import {
    OPEN_ALERT_MODAL,
    TOGGLE_CHANGE_OWNERSHIP_MODAL
} from '../../redux/ModalReducer/actionTypes';
import { OpenModal } from '../../redux/Common/action';

const Deals = ({ GetDealsList, OpenModal }) => {
    const dealUpdateModal: any =
        useSelector((store: RootState) => store?.modal?.dealUpdateModal) || {};
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const allDealsList = useSelector(
        (store: RootState) => store?.deals.allDealsList
    );
    const unApprovedDealsList = useSelector(
        (store: RootState) => store?.deals.unApprovedDealsList
    );
    const pinegrayDealsList = useSelector(
        (store: RootState) => store?.deals.pinegrayDealsList
    );
    const othersDealsList = useSelector(
        (store: RootState) => store?.deals.othersDealsList
    );
    const declinedDealsList = useSelector(
        (store: RootState) => store?.deals.declinedDealsList
    );
    const query = getQuery(history);
    const type = query.get('type') || '';
    const keyword = query.get('keyword');

    useEffect(() => {
        if (keyword || type || type === '') {
            setIsFilterApplied(true);
            getData({
                keyword,
                type,
                tab: getSelectedTab(history?.location?.pathname)
            });
        }
        // eslint-disable-next-line
    }, [keyword, type]);

    useEffect(() => {
        getData({ page: 1 });
        [
            DealsTab.TYPE_UN_APPROVED,
            DealsTab.TYPE_PINEGRAY,
            DealsTab.TYPE_OTHERS,
            DealsTab.TYPE_DECLINED
        ].forEach((tab) => {
            getData({ page: 1, tab });
        });
    }, []);

    const getAllTabsData = (data) => {
        if (!data?.tab) {
            getData(data, false);
        }
        [
            DealsTab.TYPE_UN_APPROVED,
            DealsTab.TYPE_PINEGRAY,
            DealsTab.TYPE_OTHERS,
            DealsTab.TYPE_DECLINED
        ].forEach((tab) => {
            getData({ page: 1, tab }, false);
        });
    };

    const getData = (data, isLoading = true) => {
        setIsLoading(isLoading);
        GetDealsList(token, data).then(() => {
            setIsLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
            OpenModal(TOGGLE_CHANGE_OWNERSHIP_MODAL, {
                isOpen: false,
                isLoading: false
            });
            setTab();
        });
    };

    const setTab = () => {
        switch (history.location?.pathname) {
            case Routes.deals.pinegrayListings:
                setValue(1);
                break;
            case Routes.deals.otherListings:
                setValue(2);
                break;
            case Routes.deals.declinedListings:
                setValue(3);
                break;
            case Routes.deals.allListings:
                setValue(4);
                break;
            default:
            case Routes.deals.awaitingApproval:
                setValue(0);
                break;
        }
    };

    const handleChange = (newValue) => {
        setValue(newValue);
        let pathname;
        switch (newValue) {
            case 1:
                pathname = Routes.deals.pinegrayListings;
                break;
            case 2:
                pathname = Routes.deals.otherListings;
                break;
            case 3:
                pathname = Routes.deals.declinedListings;
                break;
            case 4:
                pathname = Routes.deals.allListings;
                break;
            case 0:
            default:
                pathname = Routes.deals.awaitingApproval;
                break;
        }
        history.push(pathname);
    };

    return (
        <>
            {dealUpdateModal?.isOpen ? <DealUpdateModal /> : null}
            <Tabs
                value={value}
                onChange={(e, v) => handleChange(v)}
                variant="scrollable"
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="on"
                classes={{
                    flexContainer: !isSmallScreen
                        ? 'justify-content-center'
                        : ''
                }}
            >
                <Tab
                    value={0}
                    label={`Awaiting approval (${unApprovedDealsList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    value={1}
                    label={`Newnex listings (${pinegrayDealsList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    value={2}
                    label={`Other listings (${othersDealsList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    value={3}
                    label={`Declined (${declinedDealsList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
                <Tab
                    value={4}
                    label={`All Listings (${allDealsList?.count})`}
                    className="text-inherit"
                    disableRipple={true}
                    disableFocusRipple={true}
                />
            </Tabs>
            <>
                <Route
                    path={Routes.deals.awaitingApproval}
                    exact
                    render={() => (
                        <AwaitingApprovalComponent
                            sectionBaseUrl={Routes.deals.awaitingApproval}
                            loading={isLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                        />
                    )}
                />
                <Route
                    path={Routes.deals.pinegrayListings}
                    render={() => (
                        <PinegrayListingsComponent
                            sectionBaseUrl={Routes.deals.pinegrayListings}
                            loading={isLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                        />
                    )}
                />
                <Route
                    path={Routes.deals.otherListings}
                    exact
                    render={() => (
                        <OtherListingsComponent
                            sectionBaseUrl={Routes.deals.otherListings}
                            loading={isLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                        />
                    )}
                />
                <Route
                    path={Routes.deals.declinedListings}
                    exact
                    render={() => (
                        <DeclinedListingsComponent
                            sectionBaseUrl={Routes.deals.declinedListings}
                            loading={isLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                        />
                    )}
                />
                <Route
                    path={Routes.deals.allListings}
                    exact
                    render={() => (
                        <AllListingsComponent
                            sectionBaseUrl={Routes.deals.allListings}
                            loading={isLoading}
                            isFilterApplied={isFilterApplied}
                            getAllTabsData={getAllTabsData}
                        />
                    )}
                />
            </>
        </>
    );
};
export default connect(null, { GetDealsList, OpenModal })(Deals);
