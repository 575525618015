import { Button, CircularProgress, Container } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
    CommonDispatcher,
    UploadImages
} from '../../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../../redux/Common/actionTypes';
import {
    GetSingleDeal,
    SetFundAccessByUserName,
    UpdateFundEquityPost,
    UpdateDeal
} from '../../../../redux/FundDeals/actions';
import { RootState } from '../../../../redux/store';

import { useHistory } from 'react-router-dom';
import { UPDATE_WIZARD_FORM_DATA } from '../../../../redux/FundDeals/actionTypes';
import { getQuery } from '../../../../utils/common';
import FundraiseDeal from '../../FundraiseDeal';
import { UpdatePost } from '../Common';
import { useCookies } from 'react-cookie';

const ReviewComponent = ({
    GetSingleDeal,
    CommonDispatcher,
    UpdateFundEquityPost,
    jsonData,
    UploadImages,
    UpdateDeal,

    SetFundAccessByUserName
}: any) => {
    const history = useHistory();
    const query = getQuery(history);

    const post_id = query.get('post_id');
    const [isLoading, setLoading] = useState(false);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const [isGetDealLoading, setIsGetDealLoading] = useState(false);

    const [d, setDeal] = React.useState(jsonData?.deal || []);
    const store = useSelector((store: RootState) => store);
    const deal_data = store?.FundDeals.deal_data || {};
    const activeStep = store?.FundDeals?.wizard?.currentStep || 0;

    const handleBack = () => {
        if (activeStep > 0) {
            CommonDispatcher(UPDATE_WIZARD_FORM_DATA, {
                currentStep: activeStep - 1
            });
        }
    };

    useEffect(() => {
        setIsGetDealLoading(true);
        GetSingleDeal(token, post_id ? post_id : deal_data?.equity_id).then(
            (r) => {
                const deal = r?.response?.deal;
                if (deal?.investors && deal?.investors.length) {
                    deal.investors = deal?.investors.map((d) => ({
                        title: d?.name,
                        url: d?.profile_link
                    }));
                }
                if (deal?.documents) {
                    deal.documents = deal?.documents?.map((i) => ({
                        ...i,
                        title: i?.name,
                        url: i?.doc_url,
                        icon: '/pdf.png'
                    }));
                }
                setDeal(r?.response);
                setIsGetDealLoading(false);
            }
        );
        setDeal(d);
    }, []);

    const handleFinish = () => {
        deal_data.equity_id = post_id || deal_data?.equity_id;
        deal_data.is_draft = false;
        deal_data.currentStep = activeStep;
        const { status, ...rest } = deal_data;
        const obj = { equity_id: rest?.equity_id, is_draft: rest?.is_draft };
        if (rest?.equity_id) {
            setLoading(true);
            UpdatePost(
                (data) => UpdateDeal(token, data),
                CommonDispatcher,
                obj,
                UploadImages,
                setLoading,
                (dealResp) => {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        type: 'info',
                        message: 'Deal updated'
                    });
                },
                () => {},
                false,
                true
            );
        }
    };

    return (
        <Container maxWidth="md">
            {isGetDealLoading ? (
                <div className="text-center w-100">
                    <div className="loader" />
                </div>
            ) : (
                <>
                    <FundraiseDeal jsonData={d} hideActionButtons={true} />
                    <div className="py-5 mt-5 border-top d-flex align-items-center justify-content-center row-gap-10 btn-submit-post">
                        <Button
                            color="secondary"
                            variant="text"
                            type="button"
                            className={`fs-16 text-inherit`}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleFinish}
                            disabled={isLoading}
                            className="fs-16 text-inherit mx-5"
                        >
                            {post_id || deal_data?.equity_id ? `Update` : `Add`}
                            <span className="d-block d-xs-none ml-1">Deal</span>
                            {isLoading ? (
                                <CircularProgress
                                    size="1.3rem"
                                    className="circular-progress"
                                />
                            ) : null}
                        </Button>
                        <Button
                            color="secondary"
                            variant="text"
                            type="button"
                            className={`fs-16 text-inherit`}
                        >
                            Cancel
                        </Button>
                    </div>
                </>
            )}
        </Container>
    );
};

export default connect(null, {
    GetSingleDeal,
    CommonDispatcher,
    UpdateFundEquityPost,
    UploadImages,
    UpdateDeal,

    SetFundAccessByUserName
})(ReviewComponent);
