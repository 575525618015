import * as Actions from './actionTypes';
import {
    FUNDS_DECLINED,
    FUNDS_OTHERS,
    FUNDS_PINEGRAY,
    FUNDS_UN_APPROVED
} from './actionTypes';
import axios from 'axios';
import { API_PATH, DealsTab } from '../../constants';

export const GetDealsList = (token: string, data: any) => {
    let type = Actions.FUNDS_ALL;
    switch (data?.tab) {
        case DealsTab.TYPE_UN_APPROVED:
            type = FUNDS_UN_APPROVED;
            break;
        case DealsTab.TYPE_PINEGRAY:
            type = FUNDS_PINEGRAY;
            break;
        case DealsTab.TYPE_OTHERS:
            type = FUNDS_OTHERS;
            break;
        case DealsTab.TYPE_DECLINED:
            type = FUNDS_DECLINED;
            break;
    }
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/funds`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type,
                payload
            });
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetSingleDeal = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/one-fund`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const SetFundAccessByUserName = (data = null) => {
    return (dispatch) => {
        return axios
            .post(`${API_PATH}/funds/set-access-by-user-name`, data)
            .then((response) => {
                return { type: 'success', response: response?.data?.data };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const SetPreMoneyAccess = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/set-pre-money-access`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateInterest = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-interest`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const CreateInterest = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/create-interest`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const SaveAndNotify = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/save-notify`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const PublishFundEquityPost = (data) => {
    return (dispatch) => {
        return axios
            .post(`${API_PATH}/admin/create-funds`, data)
            .then((response) => {
                return {
                    type: 'success',
                    response: response?.data?.data || {}
                };
            })
            .catch((error) => {
                console.log(error?.response);
                if (!error?.response) {
                    return {
                        type: 'error',
                        response: {
                            message:
                                'Server error. Please try again after sometime.'
                        }
                    };
                } else {
                    return { type: 'error', response: error?.response };
                }
            });
    };
};

export const GetCapTableData = (token: string, equity_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/cap-table?equity_id=${equity_id}`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateFundEquityPost = (token, data) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-funds`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetAllTeam = (token: string, equity_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/get-fund-team/${equity_id}`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const AddTeam = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/add-fund-team`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateTeam = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-fund-team`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const DeleteTeam = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data,
                url: `${API_PATH}/admin/remove-fund-team`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const DeleteDeal = (token: string, equity_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data: { equity_id },
                url: `${API_PATH}/admin/delete-fund`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const DeleteInterest = (token: string, interest_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data: { interest_id },
                url: `${API_PATH}/admin/delete-interest`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetDealFilters = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/get-filter`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const SaveFilters = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/save-filter`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetMatchingUsersCount = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/get-matching-users`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetAccessUsersList = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/get-access-user-list`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GrantAccess = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/set-access`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateDeal = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-fund`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateDealOwnership = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/updateFundOwnership`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
