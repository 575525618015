import React, { useState } from 'react';
import { Avatar, Button, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import style from './index.module.scss';
import { uploadImage } from '../../redux/Dashboard/actions';
import imageCompression from 'browser-image-compression';

const ImageUploadComponent = (props: any) => {
    const [isLoading, setLoading] = useState(false);

    const uploadImage = (e) => {
        const files = e.target.files;
        if (files && files.length) {
            setLoading(true);
            imageCompression(files[0], { maxSizeMB: 1 })
                .then((file) => {
                    let formData = new FormData();
                    formData.append('user_id', props?.user_id);
                    formData.append('files', file as any, file?.name);
                    return props
                        ?.uploadImage(props?.token, formData)
                        .then(() => {
                            setLoading(false);
                            alert('Profile picture updated.');
                            window?.location?.reload();
                        });
                })
                .catch(() => {
                    setLoading(false);
                    alert('Failed to upload image.');
                });
        }
    };

    return (
        <div className={style.profileUploadContainer}>
            <div className={style.profileUpload}>
                <input
                    accept="image/*"
                    className={`${style.avatarInput}`}
                    id="icon-button-file"
                    onChange={uploadImage}
                    type="file"
                />
                <label htmlFor="icon-button-file">
                    {isLoading ? (
                        <CircularProgress
                            size="2rem"
                            className={`${
                                isLoading ? style.avatarProgress : ''
                            } circular-progress`}
                        />
                    ) : null}
                    <Avatar
                        alt="Profile"
                        src={props?.sm_profile_picture}
                        className={`${style.avatar} ${
                            isLoading ? style.avatarOpacity : ''
                        }`}
                    />
                </label>
            </div>
            <label htmlFor="icon-button-file" className={style.btnUploadLabel}>
                <Button color="primary" className={`${style.btnUpload} my-3`}>
                    {`${
                        props?.sm_profile_picture ? 'Change' : 'Upload'
                    } profile picture`}
                </Button>
            </label>
        </div>
    );
};

export default connect(null, {
    uploadImage
})(ImageUploadComponent);
