import {
    Button,
    Chip,
    Grid,
    Table,
    TableCell,
    TableContainer
} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { ExitToApp } from '@material-ui/icons';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { ROWS_PER_PAGE } from '../../../constants';
import { CommonDispatcher } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { OPEN_ALERT_MODAL } from '../../../redux/ModalReducer/actionTypes';
import {
    GetAdminSessions,
    GetUserSessions,
    LogoutAllAdmins,
    LogoutAllUsers,
    LogoutSingleAdmin,
    LogoutSingleUser
} from '../../../redux/Users/actions';
import { RootState } from '../../../redux/store';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import { CommonPaginationAction } from '../Common/CommonPaginationAction';

const UsersSessions = ({
    GetUserSessions,
    CommonDispatcher,
    user_id = null,
    LogoutSingleUser,
    LogoutAllUsers,
    GetAdminSessions,
    LogoutSingleAdmin,
    LogoutAllAdmins,
    isAdmin = false
}) => {
    const classes = CommonTableStyle();
    const [isLoading, setIsLoading] = useState(false);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const [page, setPage] = useState(0);

    const listingsData = useSelector(
        (store: RootState) => store?.users.userSessions
    );

    const count = listingsData?.count;
    const total_pages = listingsData?.total_pages;

    useEffect(() => {
        if (typeof page !== 'undefined') {
            getData({ page: page + 1, user_id });
        }
        // eslint-disable-next-line
    }, [page]);

    const getData = async (data) => {
        data.size = ROWS_PER_PAGE;
        setIsLoading(true);
        if (isAdmin) {
            GetAdminSessions(token, data).then(() => {
                setIsLoading(false);
            });
        } else {
            GetUserSessions(token, data).then(() => {
                setIsLoading(false);
            });
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const CommonComponent = () => (
        <CommonPaginationAction
            count={count}
            page={page}
            setPage={setPage}
            total_pages={total_pages}
        />
    );

    const GetUsers = () => {
        const GETAPI = isAdmin ? GetAdminSessions : GetUserSessions;
        const body = isAdmin
            ? {
                  page: page + 1
              }
            : {
                  page: page + 1,
                  user_id
              };
        GETAPI(token, body).then((r) => {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: `Successfully logged out from all the sessions`,
                hideAlert: true
            });
            CommonDispatcher(OPEN_ALERT_MODAL, {
                isOpen: false
            });
        });
    };

    const handleDeleteAll = () => {
        const API = isAdmin ? LogoutAllAdmins : LogoutAllUsers;
        const body = isAdmin
            ? null
            : {
                  user_id: user_id
              };
        CommonDispatcher(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: `Logout All!`,
            description: `Are you sure you want to Logout of all the sessions?`,
            data: { disableBackdropClick: true },
            submitTitle: 'Logout All',
            cancelTitle: 'Cancel',
            callBack: () => {
                API(token, body).then((r) => {
                    if (r?.type === 'success') {
                        GetUsers();
                    } else if (
                        r?.type === 'error' &&
                        r?.response?.data?.error
                    ) {
                        CommonDispatcher(OPEN_ALERT_MODAL, {
                            isOpen: false
                        });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message:
                                r?.response?.data?.error ||
                                'Failed to logout from all the sessions',
                            hideAlert: true,
                            error: true
                        });
                    } else {
                        CommonDispatcher(OPEN_ALERT_MODAL, {
                            isOpen: false
                        });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message:
                                r?.response?.data?.error ||
                                'Failed to logout from all the sessions',
                            hideAlert: true,
                            error: true
                        });
                    }
                });
            }
        });
    };

    const handleDeleteOne = async (d) => {
        const API = isAdmin ? LogoutSingleAdmin : LogoutSingleUser;
        const body = isAdmin
            ? { session_id: d?.session_id }
            : {
                  user_id: user_id,
                  session_id: d?.session_id
              };
        CommonDispatcher(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: `Logout!`,
            description: `Are you sure you want to Logout?`,
            data: { disableBackdropClick: true },
            submitTitle: 'Logout',
            cancelTitle: 'Cancel',
            callBack: () => {
                API(token, body).then((r) => {
                    if (r?.type === 'success') {
                        GetUsers();
                    } else if (
                        r?.type === 'error' &&
                        r?.response?.data?.error
                    ) {
                        CommonDispatcher(OPEN_ALERT_MODAL, {
                            isOpen: false
                        });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message:
                                r?.response?.data?.error ||
                                'Failed to logout the session',
                            hideAlert: true,
                            error: true
                        });
                    } else {
                        CommonDispatcher(OPEN_ALERT_MODAL, {
                            isOpen: false
                        });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message:
                                r?.response?.data?.error ||
                                'Failed to logout the session',
                            hideAlert: true,
                            error: true
                        });
                    }
                });
            }
        });
    };

    return (
        <>
            <Grid container spacing={0} className="h-100">
                <Grid item xs={12}>
                    {isLoading || isLoading ? (
                        <div className="loader" />
                    ) : (
                        <>
                            <div className="d-flex justify-content-end">
                                <Button
                                    className="colorPurple text-hover-colorWhite"
                                    variant="text"
                                    size="small"
                                    onClick={handleDeleteAll}
                                >
                                    <ExitToApp
                                        color="inherit"
                                        fontSize="small"
                                        className="mr-2"
                                    />
                                    Logout of All sessions
                                </Button>
                            </div>
                            <TableContainer className="box-shadow-none">
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={`bg-none`}>
                                                IP
                                            </TableCell>
                                            <TableCell className={`bg-none`}>
                                                Browser
                                            </TableCell>
                                            <TableCell className={`bg-none`}>
                                                OS
                                            </TableCell>
                                            <TableCell className={`bg-none`}>
                                                Session
                                            </TableCell>
                                            <TableCell className={`bg-none`}>
                                                Created At
                                            </TableCell>
                                            <TableCell className={`bg-none`}>
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listingsData?.session_data &&
                                        listingsData?.session_data?.length ? (
                                            listingsData?.session_data?.map(
                                                (d, index) => {
                                                    return (
                                                        <TableRow
                                                            hover
                                                            className="border-0 cursor-pointer"
                                                            key={d?.session_id}
                                                        >
                                                            <TableCell className="border-0 py-3">
                                                                {d?.ip || '-'}
                                                            </TableCell>
                                                            <TableCell className="border-0 py-3">
                                                                {d?.browser ||
                                                                    '-'}
                                                                {`${
                                                                    d?.browser_version
                                                                        ? ` - (${d?.browser_version})`
                                                                        : ''
                                                                }`}
                                                            </TableCell>

                                                            <TableCell className="border-0 py-3">
                                                                {d?.os || '-'}
                                                            </TableCell>

                                                            <TableCell className="border-0 py-3">
                                                                <Chip
                                                                    label={
                                                                        d?.is_active_session
                                                                            ? 'Current'
                                                                            : 'Other'
                                                                    }
                                                                    variant="outlined"
                                                                    className={`mr-3 ${
                                                                        d?.is_active_session
                                                                            ? 'bgColorPurple colorWhite'
                                                                            : 'colorPurple bgolorWhite'
                                                                    }  `}
                                                                />
                                                            </TableCell>

                                                            <TableCell className="border-0 py-3">
                                                                {d?.createdAt
                                                                    ? moment(
                                                                          d?.createdAt
                                                                      )
                                                                          .utc()
                                                                          .format(
                                                                              'DD MMMM, YYYY hh:mm a'
                                                                          )
                                                                    : '-'}
                                                            </TableCell>

                                                            <TableCell className="border-0 py-3">
                                                                <Button
                                                                    className="colorPurple text-hover-colorWhite"
                                                                    variant="text"
                                                                    size="small"
                                                                    onClick={() =>
                                                                        handleDeleteOne(
                                                                            d
                                                                        )
                                                                    }
                                                                >
                                                                    <ExitToApp
                                                                        color="inherit"
                                                                        fontSize="small"
                                                                        className="mr-2"
                                                                    />
                                                                    Logout of
                                                                    this session
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <TableRow
                                                hover
                                                className="border-0 cursor-pointer"
                                            >
                                                <TableCell
                                                    className="border-0 py-5 text-center fs-16"
                                                    colSpan={7}
                                                >
                                                    No Sessions found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                className={classes.footer}
                                hidden={
                                    listingsData?.session_data &&
                                    !listingsData?.session_data?.length
                                }
                                rowsPerPageOptions={[]}
                                component="div"
                                count={count}
                                rowsPerPage={ROWS_PER_PAGE}
                                page={page}
                                ActionsComponent={CommonComponent}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default connect(null, {
    GetUserSessions,
    CommonDispatcher,
    LogoutSingleUser,
    LogoutAllUsers,
    GetAdminSessions,
    LogoutSingleAdmin,
    LogoutAllAdmins
})(UsersSessions);
