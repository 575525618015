import axios from 'axios';
import { API_PATH } from '../../constants';
import * as ACTIONS from './actionTypes';

export const GetAllProductsServicesList = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/biz-services`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: ACTIONS.SET_ALL_PRODUCT_SERVICES_DATA,
                payload
            });
            return { type: 'success', response: payload };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const DeleteProductsServices = (token: string, service_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data: { service_id },
                url: `${API_PATH}/admin/delete/biz-services`,
                headers: { Authorization: token }
            };
            const payload = (await axios(config as any))?.data?.data;
            return { type: 'success', response: payload };
        } catch (e) {
            return { type: 'failed', response: e?.response };
        }
    };
};
