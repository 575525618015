import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    makeStyles,
    useMediaQuery,
    useTheme,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { GetUsersList, UpdateUserProfile } from '../../../redux/Users/actions';
import { RootState } from '../../../redux/store';

import { useCookies } from 'react-cookie';
import { ADD_ADDRESS_BOOK_NOTE_MODAL } from '../../../redux/ModalReducer/actionTypes';
import EditorComponent from '../../InputComponents/Editor';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { UpdateFirm, GetFirmsList } from '../../../redux/Firms/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { FirmsTab, Routes } from '../../../constants';
import { getQuery } from '../../../utils/common';

const useStyles = makeStyles(() =>
    createStyles({
        dialogContainer: { maxWidth: '700px' },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                flex: '1 1 30%',
                margin: 'auto'
            }
        }
    })
);

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const AddAdressBookSaveSignatureModal = ({
    OpenModal,
    UpdateUserProfile,
    GetUsersList,
    CommonDispatcher,
    UpdateFirm,
    GetFirmsList
}) => {
    const state = useSelector((store: RootState) => store);
    const modal: any = state?.modal?.addAdressbookNoteModal;
    const modalData: any = modal?.data?.data?.address_book;
    const [isLoading, setLoading] = useState(false);
    const [note, setNote] = useState();
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    // console.log('messageId', modal.data.profile);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const blankDescription = '<p><br></p>';
    const history = useHistory();
    const location = useLocation();

    const query = getQuery(history);
    const queryParams = new URLSearchParams(location.search);
    const filterParam = queryParams.get('filter');
    const filterKeyword = queryParams.get('keyword');
    const filterType = queryParams.get('type');

    const filterTags = queryParams.get('tags_ids');
    let filter: any = {};
    let keyword;
    let type;
    if (filterParam) {
        filter = { ...filter, ...JSON.parse(decodeURIComponent(filterParam)) };
    }

    const tagsIdsArray = filterTags
        ? filterTags.split(',').map((id) => parseInt(id))
        : [];

    if (tagsIdsArray.length > 0) {
        filter.tags_ids = tagsIdsArray;
    }

    if (filterKeyword) {
        keyword = filterKeyword;
    }
    if (filterType) {
        type = filterType;
    }

    const {
        control,
        getValues,
        reset,
        formState: { isValid }
    } = useForm({
        mode: 'onChange'
    });

    const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle
                disableTypography
                className={`text-center ${classes.root} d-flex`}
                {...other}
            >
                <Typography variant="h6" className={classes.titleMargin}>
                    {children}
                </Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <Close />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    useEffect(() => {
        reset({
            note: modalData?.note
        });
    }, []);

    const handleClose = () => {
        OpenModal(ADD_ADDRESS_BOOK_NOTE_MODAL, { isOpen: false });
    };
    const handleSaveSignature = () => {
        if (modalData?.user_id) {
            UpdateUserProfile(token, {
                user_id: modalData?.user_id,
                note
            }).then((response) => {
                if (response?.type === 'success') {
                    GetUsersList(token, {
                        type,
                        keyword,
                        filter: { ...filter },
                        size: 20
                    }).then(() => {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Settings has been updated'
                        });
                    });

                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Note updated successfully.',
                        hideAlert: true
                    });

                    setLoading(false);
                    handleClose();
                } else if (
                    response?.type === 'error' &&
                    response?.response?.data?.error
                ) {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            response?.response?.data?.error ||
                            'Failed to update note',
                        hideAlert: true,
                        error: true
                    });
                    setLoading(false);
                    // handleClose();
                } else {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Failed to update note',
                        hideAlert: true,
                        error: true
                    });
                    setLoading(false);
                    handleClose();
                }
            });
        } else {
            UpdateFirm(token, {
                firm_id: modalData?.firm_id,
                note
            }).then(() => {
                GetFirmsList(token, {
                    type,
                    is_affiliated: false,
                    tab:
                        history?.location?.pathname === Routes.firms.active
                            ? FirmsTab.TYPE_ACTIVE
                            : history?.location?.pathname ===
                              Routes.firms.inactive
                            ? FirmsTab.TYPE_IN_ACTIVE
                            : 'ALL',
                    keyword,
                    filter: { ...filter },
                    size: 20
                }).then((r) => {
                    setLoading(false);
                    handleClose();
                });
            });
        }
    };
    const handleDescriptionChange = (value: any) => {
        const isBlank = value === blankDescription;

        setNote(!isBlank ? value : null);
    };

    return (
        <Dialog
            maxWidth={'md'}
            fullScreen={fullScreen}
            fullWidth={true}
            onClose={handleClose}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            classes={{ paperScrollPaper: classes.dialogContainer }}
            disableBackdropClick={false}
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                {'Note'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className=" h-40">
                        <EditorComponent
                            initialValue={modalData?.note}
                            handleDescriptionChange={handleDescriptionChange}
                            placeholder="Type your messag"
                        />
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button onClick={handleClose} color="secondary">
                    {modal?.cancelTitle || 'Cancel'}
                </Button>
                <Button
                    onClick={handleSaveSignature}
                    className="ml-3"
                    color={modal?.isDelete ? 'secondary' : 'primary'}
                    variant="contained"
                    disabled={isLoading || !isValid}
                >
                    {isLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress-primary ml-0"
                        />
                    ) : (
                        modal?.submitTitle || 'Ok'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    UpdateUserProfile,
    GetUsersList,
    CommonDispatcher,
    UpdateFirm,
    GetFirmsList
})(AddAdressBookSaveSignatureModal);
