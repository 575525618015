import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Add, Close, Delete } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import { CommonDispatcher, OpenModal } from '../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../redux/Common/actionTypes';
import { ADD_META_DATA_MODAL } from '../../redux/ModalReducer/actionTypes';
import { RootState } from '../../redux/store';
import RenderTextField from '../InputComponents/TextField/RenderTextField';
import {
    UpdateEcoSystemType,
    GetEcoSystemType
} from '../../redux/MetaData/actions';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const AddMetaDataModal = ({
    OpenModal,
    UpdateEcoSystemType,
    CommonDispatcher,
    GetEcoSystemType
}) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.addMetaDataModal) || {};
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [loading, setLoading] = useState(false);
    const user_id = modal?.data?.selectedUser?.user_id;
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);

    const { control, getValues, setValue, reset } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'ecosystem_types'
    });

    useEffect(() => {
        setLoading(true);
        getSingleUser();
    }, []);

    const getSingleUser = () => {
        GetEcoSystemType(token).then((x) => {
            if (x?.status === 200) {
                const ecosystem_types = x?.data?.data;

                const updateFirms = ecosystem_types.map((item) => {
                    return {
                        name: item.name,
                        rank: item.rank,
                        id: item.id
                    };
                });

                if (updateFirms?.length) {
                    reset({ ecosystem_types: updateFirms });
                } else {
                    reset({
                        ecosystem_types: [{ name: '', rank: '', id: '' }]
                    });
                }
            }
            setLoading(false);
        });
    };

    const handleClose = () => {
        OpenModal(ADD_META_DATA_MODAL, { isOpen: false });
    };

    const handleUpdate = () => {
        setIsUpdateLoading(true);
        updateFirmsList();
    };

    const updateFirmsList = () => {
        let data = getValues();
        if (data?.ecosystem_types && data?.ecosystem_types?.length) {
            data?.ecosystem_types?.map((x) => !x.id && delete x.id);
        }

        UpdateEcoSystemType(token, { user_id, ...data }).then((r) => {
            if (r?.response?.data?.error) {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: r?.response?.data?.error || 'Failed to update.',
                    hideAlert: true,
                    error: true
                });
            } else {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Updated successfully.'
                });
            }
            setIsUpdateLoading(false);
            setLoading(false);
            handleClose();
        });
    };

    const handleNameChange = (index, e) => {
        setValue(`ecosystem_types[${index}].name`, e?.target?.value);
    };

    const handleEmailChange = (index, e) => {
        setValue(`ecosystem_types[${index}].rank`, e?.target?.value);
    };

    return (
        <Dialog
            className="w-100"
            maxWidth="sm"
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                <div className="w-100 d-flex">{modal?.title}</div>
            </DialogTitle>
            <DialogContent>
                {loading ? (
                    <CircularProgress
                        size="1.3rem"
                        className="circular-progress m-auto d-flex"
                    />
                ) : (
                    <>
                        <Grid container spacing={3} className="mb-3">
                            <Grid item xs={12} className="py-1">
                                {fields.map((item, index) => {
                                    return (
                                        <div
                                            key={item?.id}
                                            className="w-100 d-flex flex-direction-xs-column align-items-flex-start"
                                        >
                                            <div className="mt-2 mr-3 w-100">
                                                <Controller
                                                    name={`ecosystem_types[${index}].name`}
                                                    render={({
                                                        onChange,
                                                        value
                                                    }) => {
                                                        return (
                                                            <RenderTextField
                                                                id={`ecosystem_types[${index}].name`}
                                                                label={`Name ${
                                                                    index + 1
                                                                } *`}
                                                                value={value}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    onChange(e);
                                                                    handleNameChange(
                                                                        index,
                                                                        e
                                                                    );
                                                                }}
                                                                placeholder="Search or Add name"
                                                            />
                                                        );
                                                    }}
                                                    control={control}
                                                    defaultValue={
                                                        item.name || null
                                                    }
                                                    rules={{
                                                        required: false
                                                    }}
                                                />
                                            </div>
                                            <div className="mt-2 mr-3 w-100">
                                                <Controller
                                                    name={`ecosystem_types[${index}].rank`}
                                                    render={({
                                                        onChange,
                                                        value
                                                    }) => {
                                                        return (
                                                            <RenderTextField
                                                                inputContainerClassName="w-100"
                                                                type="text"
                                                                id={`ecosystem_types[${index}].rank`}
                                                                label={`Rank ${
                                                                    index + 1
                                                                } *`}
                                                                placeholder="Search or Add email"
                                                                value={value}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    onChange(e);
                                                                    handleEmailChange(
                                                                        index,
                                                                        e
                                                                    );
                                                                }}
                                                                autoComplete="off"
                                                            />
                                                        );
                                                    }}
                                                    control={control}
                                                    defaultValue={
                                                        item.rank || null
                                                    }
                                                    rules={{
                                                        required: false
                                                    }}
                                                />
                                            </div>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => {
                                                    remove(index);
                                                }}
                                                className="d-flex align-self-flex-xs-end mt-5 mt-xs-0"
                                            >
                                                <Delete />
                                            </IconButton>
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className="mb-3">
                            <Grid item xs={12} className="mt-3">
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    type="button"
                                    className="float-right"
                                    onClick={() => {
                                        append({ name: '', rank: '', id: '' });
                                    }}
                                >
                                    <Add className="mr-2" />
                                    Add a ecosystem
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button
                    onClick={handleUpdate}
                    variant="contained"
                    color="primary"
                    className="mb-4"
                >
                    Update
                    {isUpdateLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress"
                        />
                    ) : null}
                </Button>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    className="mb-4"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    UpdateEcoSystemType,
    CommonDispatcher,
    GetEcoSystemType
})(AddMetaDataModal);
