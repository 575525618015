import * as Actions from './actionTypes';
import axios from 'axios';
import { API_PATH } from '../../constants';

export const GetClientConfigList = (token: string, data: any) => {
    let type = Actions.SET_ALL_CLIENT_CONFIG_LIST;
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/client`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type,
                payload
            });
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetSingleClient = (token: string, id) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/client/${id}`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateClient = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'put',
                data,
                url: `${API_PATH}/admin/client/update`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const CreateClient = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/client/create`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const DeleteClient = (token: string, client_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data: { client_id },
                url: `${API_PATH}/admin/client/delete`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UploadLogo = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/upload/client-logo`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            console.log(error);
            return { type: 'failed', response: error?.response };
        }
    };
};
export const UnblockUserApi = (token: string, id) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/deleteAllCache`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
