import { DealsTab, FirmsTab, Routes, UsersTab } from '../../constants';

export const getSelectedTab = (pathname) => {
    switch (pathname) {
        case Routes.users.awaitingApproval:
            return UsersTab.TYPE_UN_APPROVED;
        case Routes.users.active:
            return UsersTab.TYPE_ACTIVE;
        case Routes.users.suspended:
            return UsersTab.TYPE_SUSPENDED;
        case Routes.users.declined:
            return UsersTab.TYPE_DECLINED;
        case Routes.users.deleted:
            return UsersTab.TYPE_DELETED;

        case Routes.deals.awaitingApproval:
            return DealsTab.TYPE_UN_APPROVED;
        case Routes.deals.pinegrayListings:
            return DealsTab.TYPE_PINEGRAY;
        case Routes.deals.otherListings:
            return DealsTab.TYPE_OTHERS;
        case Routes.deals.declinedListings:
            return DealsTab.TYPE_DECLINED;

        case Routes.fund_deals.awaitingApproval:
            return DealsTab.TYPE_UN_APPROVED;
        case Routes.fund_deals.pinegrayListings:
            return DealsTab.TYPE_PINEGRAY;
        case Routes.fund_deals.otherListings:
            return DealsTab.TYPE_OTHERS;
        case Routes.fund_deals.declinedListings:
            return DealsTab.TYPE_DECLINED;

        case Routes.firms.active:
            return FirmsTab.TYPE_ACTIVE;
        case Routes.firms.inactive:
            return FirmsTab.TYPE_IN_ACTIVE;
    }
};
