import React from "react";
import style from "./Styles.module.scss";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {InputLabel} from "@material-ui/core";
import DateUtils from "@date-io/moment";

const RenderDatePicker = ({
                              id, name, label, labelClassName, showInputLabel, value, format, defaultValue, removeMarginAndPadding,
                              containerClassName, inputClassName, onChange, helperText, error, disablePast, disableFuture, shrink, hideInput
                          }: any) => {
    return <div className={style.inputContainer}>
        {showInputLabel ? <InputLabel shrink={shrink} htmlFor={id} className={`${style.inputLabels} ${labelClassName}`}>
            {label}
        </InputLabel> : null}
        <MuiPickersUtilsProvider utils={DateUtils}>
            <DatePicker autoOk
                        id={id}
                        fullWidth
                        name={name}
                        value={value}
                        error={error}
                        format={format}
                        disablePast={disablePast}
                        disableFuture={disableFuture}
                        variant="inline"
                        inputVariant="outlined"
                        helperText={helperText}
                        defaultValue={defaultValue}
                        onChange={(e) => {
                            if (typeof onChange !== "undefined" && typeof onChange === "function") {
                                onChange(e);
                            }
                        }}
                        label={!showInputLabel ? label : ""}
                        className={`${style.inputFields} ${containerClassName} ${hideInput ? "invisible h-0 p-0" : ""}`}
                        inputProps={{
                            className: `${inputClassName} 
                    ${removeMarginAndPadding ? style.normalMarginAndPadding : style.datePicker} 
                    ${!hideInput ? "visible" : "invisible h-0 p-0 m-0"}`
                        }}/>
        </MuiPickersUtilsProvider>
    </div>;
};
export default RenderDatePicker;