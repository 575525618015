import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    tableCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    container: {
        maxHeight: 'calc(100vh - 250px)',
        minHeight: 'calc(100vh - 250px)'
    },
    footer: {
        bottom: 0,
        position: 'fixed',
        width: '100%',
        left: 0,
        right: 0,
        zIndex: 10,
        backgroundColor: '#fafafa'
    }
});
export const CommonTableStyle = () => useStyles();
