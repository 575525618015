import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Switch,
    Tooltip,
    Typography,
    makeStyles
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    ALLOW_EASY_VIEW_MESSAGE,
    CAP_TABLE_ACCESS_LIST,
    EDIT_DEAL_ACCESS_LIST,
    Routes
} from '../../../constants';
import { CommonDispatcher } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import {
    AddTeam,
    DeleteTeam,
    GetAllTeam,
    GetSingleDeal,
    UpdateTeam
} from '../../../redux/FundDeals/actions';
import { OPEN_ALERT_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { GetSingleUser } from '../../../redux/Users/actions';
import CommonDealDetailComponent from '../../Common/CommonDealDetailComponent';
import CommonModalWrapper from '../../Common/CommonModal';
import RenderCheckboxComponent from '../../InputComponents/CheckboxComponent';
import RenderSelectField from '../../InputComponents/SelectField/RenderSelectField';
import AddPeopleModal from './AddPeopleModal';

const useStyles = makeStyles(() => ({
    tooltip: {
        fontSize: '16px',
        padding: '0.5rem 1rem'
    }
}));

const AddTeamToDeal = ({
    match,
    GetSingleDeal,
    GetAllTeam,
    AddTeam,
    UpdateTeam,
    DeleteTeam,
    GetSingleUser,
    CommonDispatcher
}: any) => {
    const classes = useStyles();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const history = useHistory();
    const {
        params: { equity_id }
    } = match;
    const [isLoading, setIsLoading] = useState(false);
    const [isAddTemOpen, setAddTeamOpen] = useState(false);
    const [deal, setDeal]: any = useState({});
    const [profile, setProfile]: any = useState({});
    const [listData, setListData]: any = useState({});
    const [openTooltip, setOpenTooltip] = useState(false);
    const dealName = `${deal?.company_name} (${deal?.foundation_year}) ${deal?.current_funding_stage?.label}, ${deal?.display_target_raise} round`;

    const isPostOwner = deal?.owner?.user_id === profile?.user_id;
    const { control } = useForm({
        mode: 'onChange',
        shouldUnregister: false
    });

    const data =
        listData && listData?.length
            ? isPostOwner
                ? listData
                : listData?.filter(
                      (d) =>
                          !['NDA_PENDING', 'NDA_DECLINED'].includes(
                              d?.team_status
                          )
                  )
            : [];
    let raised: number;
    raised = deal?.soft_circled
        ? (deal?.soft_circled / deal?.target_raise) * 100
        : 0;

    useEffect(() => {
        if (equity_id) {
            setIsLoading(true);
            GetSingleDeal(token, { equity_id }).then((deal) => {
                GetAllTeam(token, equity_id).then((team) => {
                    GetSingleUser(token, {
                        user_id: deal?.response?.deal?.owner?.user_id
                    }).then((profile) => {
                        setDeal(deal?.response?.deal);
                        setListData(team?.response?.team);
                        const jsonData = profile?.response;
                        setProfile(jsonData);
                        setIsLoading(false);
                    });
                });
            });
        } else {
            history.push(Routes.fund_deals.deals);
        }
        return () => {};
    }, []);

    const handleAddMemberIdsToChat = (user_id) => {
        CommonDispatcher(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: `Update Chat`,
            description: `Are you sure you want to update Chat?`,
            data: { disableBackdropClick: true },
            submitTitle: 'Yes, Update',
            cancelTitle: 'Cancel',
            callBack: () => {
                let obj: any = listData.find((d) => d?.user_id === user_id);
                obj.chat_enabled = !obj.chat_enabled;
                setListData(listData);
                UpdateTeam(token, {
                    equity_id,
                    user_id,
                    chat_enabled: obj?.chat_enabled
                }).then((r) => {
                    if (r?.type === 'success') {
                        CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
                        GetAllTeam(token, equity_id).then((team) => {
                            setListData(team?.response?.team);
                            CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                hideAlert: true,
                                message: `Chat status updated successfully.`
                            });
                        });
                    } else if (
                        r?.type === 'error' &&
                        r?.response?.data?.error
                    ) {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            hideAlert: true,
                            message: r?.response?.data?.error
                        });
                    } else {
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            hideAlert: true,
                            message: `Failed to update the chat.`
                        });
                    }
                });
            },
            cancelCallBack: () => {
                CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
            }
        });
    };

    const getCommonUpdater = (data) => {
        CommonDispatcher(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: `Update status`,
            description: `Are you sure you want to update the status?`,
            data: { disableBackdropClick: true },
            submitTitle: 'Yes, Update',
            cancelTitle: 'Cancel',
            callBack: () => {
                UpdateTeam(token, data).then((r) => {
                    if (r?.type === 'success') {
                        CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
                        getTeamData();
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Status updated successfully',
                            hideAlert: true
                        });
                    } else if (
                        r?.type === 'error' &&
                        r?.response?.data?.error
                    ) {
                        CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message:
                                r?.response?.data?.error ||
                                `Failed to update the status.`,
                            hideAlert: true,
                            error: true
                        });
                    } else {
                        CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Failed to update the status.',
                            hideAlert: true,
                            error: true
                        });
                    }
                });
            },
            cancelCallBack: () => {
                CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
            }
        });
    };

    const handleCapTablePermissionChange = (user_id, cap_table_permission) => {
        if (profile?.allow_cap_table_access) {
            getCommonUpdater({
                equity_id,
                user_id,
                cap_table_permission
            });
        }
    };

    const handleAllowEditDealChange = (user_id, allow_edit_deal) => {
        getCommonUpdater({
            equity_id,
            user_id,
            allow_edit_deal:
                allow_edit_deal === 'Deactivate'
                    ? false
                    : allow_edit_deal === 'Allowed'
                    ? 1
                    : false
        });
    };

    const renderActionButtons = (item) => {
        return (
            <div className="ml-xs-15 mt-xs-2 w-xs-100 d-xs-flex justify-content-xs-start bgColorLightGray">
                <FormControlLabel
                    control={
                        <Switch
                            defaultChecked={
                                item?.chat_enabled || item?.is_owner
                            }
                            classes={{
                                checked: 'colorPurple',
                                track:
                                    item?.chat_enabled || item?.is_owner
                                        ? 'bgColorPurple'
                                        : ''
                            }}
                            className={
                                item?.user_id === profile?.user_id
                                    ? 'visible'
                                    : 'invisible'
                            }
                            disabled={item?.user_id !== profile?.user_id}
                        />
                    }
                    classes={{
                        label: 'fs-14'
                    }}
                    labelPlacement="start"
                    checked={item?.chat_enabled || item?.is_owner}
                    label={`Chat ${
                        item?.chat_enabled || item?.is_owner ? 'On' : 'Off'
                    }`}
                    onChange={() => handleAddMemberIdsToChat(item?.user_id)}
                />

                <span
                    className={`text-inherit fs-14 ml-3 colorPrimary p-2 cursor-pointer text-hover-colorWhite
                        border-radius-4 text-hover-bgColorPurple ${
                            item?.user_id === profile?.user_id
                                ? 'visible'
                                : 'invisible'
                        }`}
                    onClick={() => handleRemove(item?.user_id)}
                >
                    Leave
                </span>
            </div>
        );
    };
    const handleSetOpenTooltip = (openTooltip) => {
        setOpenTooltip(openTooltip);
    };
    const allowMemberPermission = (key, value, user_id) => {
        let obj: any = listData.find((d) => d?.user_id === user_id);
        obj[key] = value;
        setListData(listData);
        getCommonUpdater({
            equity_id: deal?.equity_id,
            user_id,
            [key]: value
        });
    };

    const renderPostOwnerActionButtons = (item) => {
        return (
            <div className="d-flex align-items-center  mt-sm-5 w-sm-100 d-xs-flex flex-direction-xs-column align-items-xs-flex-start">
                <div>
                    {!item?.is_owner && item?.user_id !== profile?.user_id ? (
                        <div className="d-flex">
                            <div className=" ml-sm-0">
                                <RenderSelectField
                                    value={item?.cap_table_permission}
                                    removeLabel={false}
                                    inputContainerClassName={'w-100'}
                                    label="CapTable"
                                    disabled={!profile?.allow_cap_table_access}
                                    dropdownListHeight={250}
                                    dataArray={CAP_TABLE_ACCESS_LIST}
                                    onChange={(e: any) =>
                                        handleCapTablePermissionChange(
                                            item?.user_id,
                                            e?.target?.value
                                        )
                                    }
                                />
                            </div>
                            <div className="ml-6 ml-xs-0 mt-xs-3">
                                <RenderSelectField
                                    value={
                                        item?.allow_edit_deal
                                            ? 'Allowed'
                                            : 'Deactivate'
                                    }
                                    removeLabel={false}
                                    inputContainerClassName={'w-100'}
                                    label="Edit Deal"
                                    dropdownListHeight={250}
                                    dataArray={EDIT_DEAL_ACCESS_LIST}
                                    onChange={(e: any) =>
                                        handleAllowEditDealChange(
                                            item?.user_id,
                                            e?.target?.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="ml-xs-15 d-flex mt-xs-2 w-xs-100 d-xs-flex align-items-center justify-content-xs-start">
                    <FormControlLabel
                        control={
                            <Switch
                                defaultChecked={
                                    item?.chat_enabled && isPostOwner
                                }
                                classes={{
                                    checked: 'colorPurple',
                                    track:
                                        item?.chat_enabled || true
                                            ? 'bgColorPurple'
                                            : ''
                                }}
                            />
                        }
                        classes={{
                            label: 'fs-14'
                        }}
                        labelPlacement="start"
                        checked={item?.chat_enabled}
                        label={`Chat ${item?.chat_enabled ? 'On' : 'Off'}`}
                        onChange={(e) =>
                            handleAddMemberIdsToChat(item?.user_id)
                        }
                    />
                    <div>
                        <span
                            className={`text-inherit fs-14 ml-3 colorPrimary p-2 cursor-pointer text-hover-colorWhite
                        border-radius-4 text-hover-bgColorPurple ${
                            item?.user_id !== profile?.user_id
                                ? 'visible'
                                : 'invisible'
                        }`}
                            onClick={() =>
                                item?.user_id !== profile?.user_id &&
                                handleRemove(item?.user_id)
                            }
                        >
                            Remove
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const handleRemove = (user_id) => {
        CommonDispatcher(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: `Remove`,
            description: `Are you sure you want to remove this member?`,
            data: { disableBackdropClick: true },
            submitTitle: 'Yes, Remove',
            cancelTitle: 'Cancel',
            callBack: () => {
                DeleteTeam(token, { user_id, equity_id }).then((r) => {
                    if (r?.type === 'success') {
                        CommonDispatcher(OPEN_ALERT_MODAL, {
                            isOpen: false
                        });
                        GetAllTeam(token, equity_id).then((team) => {
                            setIsLoading(false);
                            setListData(team?.response?.team);
                            CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                hideAlert: true,
                                message: `Member removed successfully.`
                            });
                        });
                    } else if (
                        r?.type === 'error' &&
                        r?.response?.data?.error
                    ) {
                        CommonDispatcher(OPEN_ALERT_MODAL, {
                            isOpen: false
                        });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message:
                                r?.response?.data?.error ||
                                `Failed to remove the member`,
                            hideAlert: true,
                            error: true
                        });
                    } else {
                        CommonDispatcher(OPEN_ALERT_MODAL, {
                            isOpen: false
                        });
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Failed to remove the member',
                            hideAlert: true,
                            error: true
                        });
                    }
                });
            }
        });
    };

    const renderStatus = () => {
        let label;
        let color = deal?.status;
        switch (deal?.status) {
            case 'COMPLETE':
                label = 'ADDED';
                color = 'default';
                break;
            case 'DRAFT':
                label = 'DRAFT';
                color = 'primary';
                break;
            case 'INVITED':
                label = 'DRAFT';
                color = 'default';
                break;
            default:
                label = 'VIEWED';
                break;
        }
        return (
            <Chip
                label={label}
                color={color}
                className={`mt-2 ${
                    deal?.status === 'COMPLETE'
                        ? 'border-0 bgColorGreen colorWhite'
                        : 'bgColorLightGray colorPrimary'
                }`}
            />
        );
    };

    const handleAddTeamOpen = () => {
        setAddTeamOpen(!isAddTemOpen);
    };

    const addPeople = (peoples) => {
        const obj: any = {};
        obj.equity_id = equity_id;
        obj.team = peoples?.team?.map((d) => ({
            ...d,
            full_name: deal?.email,
            allow_edit_deal: d?.allow_edit_deal === 'Allowed'
        }));
        setAddTeamOpen(false);
        AddTeam(token, obj).then((r) => {
            if (r?.type === 'success') {
                GetAllTeam(token, equity_id).then((team) => {
                    setListData(team?.response?.team);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        hideAlert: true,
                        message: `${
                            obj.team.length > 1 ? 'Members' : 'Member'
                        } added successfully.`
                    });
                });
            } else if (r?.type === 'error' && r?.response?.data?.error) {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    hideAlert: true,
                    message: r?.response?.data?.error
                });
            } else {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    hideAlert: true,
                    message: `Failed to add ${
                        obj.team.length > 1 ? 'Members' : 'Member'
                    }.`
                });
            }
        });
    };
    const [isMembersLoading, setIsMembersLoading] = useState(false);

    const getTeamData = () => {
        setIsMembersLoading(true);
        GetAllTeam(token, equity_id).then((r) => {
            setListData(r?.response?.team);
            setIsMembersLoading(false);
            CommonDispatcher(OPEN_ALERT_MODAL, { isOpen: false });
        });
    };

    return (
        <div className="mt-5">
            <CommonModalWrapper
                modal={{
                    isOpen: isAddTemOpen,
                    title: dealName,
                    config: { modalSize: 'md' }
                }}
            >
                <AddPeopleModal
                    getCommonUpdater={getCommonUpdater}
                    equity_id={equity_id}
                    handleAddTeamOpen={handleAddTeamOpen}
                    addPeople={addPeople}
                    CommonDispatcher={CommonDispatcher}
                    UpdateTeam={UpdateTeam}
                    getTeamData={getTeamData}
                    profile={profile}
                />
            </CommonModalWrapper>
            <Container maxWidth="md" className="p-0">
                <Grid container>
                    <Grid container spacing={2}>
                        <Grid item xs={10} className="m-auto">
                            {isLoading ? (
                                <div className="loader" />
                            ) : (
                                <>
                                    <Card>
                                        <div className="mt-5 py-5">
                                            <CommonDealDetailComponent
                                                data={deal}
                                                raised={raised}
                                                renderStatus={renderStatus}
                                            />
                                        </div>
                                    </Card>

                                    <div className="m-auto">
                                        <div className="my-5 d-flex justify-content-between flex-wrap row-gap-10">
                                            <Typography
                                                component="h5"
                                                variant="h6"
                                                className=""
                                            >
                                                Team
                                            </Typography>
                                            {isPostOwner ? (
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    size="small"
                                                    onClick={handleAddTeamOpen}
                                                    className="text-inherit"
                                                >
                                                    Add People
                                                </Button>
                                            ) : null}
                                        </div>
                                        <Card>
                                            <div className="mt-5 py-5">
                                                <CardContent className="p-0">
                                                    <Container
                                                        maxWidth="md"
                                                        className="p-0"
                                                    >
                                                        <Grid container>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="m-auto"
                                                            >
                                                                <List className="w-100">
                                                                    {data &&
                                                                    data?.length
                                                                        ? data?.map(
                                                                              (
                                                                                  item
                                                                              ) => {
                                                                                  return (
                                                                                      <ListItem
                                                                                          role={
                                                                                              undefined
                                                                                          }
                                                                                          dense
                                                                                          button
                                                                                          key={
                                                                                              item?.user_id
                                                                                          }
                                                                                          className="d-flex align-items-center justify-content-between flex-direction-xs-column my-2"
                                                                                      >
                                                                                          <Grid
                                                                                              container
                                                                                              spacing={
                                                                                                  2
                                                                                              }
                                                                                          >
                                                                                              <Grid
                                                                                                  item
                                                                                                  xs={
                                                                                                      12
                                                                                                  }
                                                                                                  sm={
                                                                                                      12
                                                                                                  }
                                                                                                  md={
                                                                                                      4
                                                                                                  }
                                                                                              >
                                                                                                  <div className="d-flex align-items-center w-xs-100">
                                                                                                      <Avatar
                                                                                                          alt="Profile"
                                                                                                          src={
                                                                                                              item?.profile_picture
                                                                                                          }
                                                                                                          className={
                                                                                                              'avatar mr-2'
                                                                                                          }
                                                                                                      />
                                                                                                      <ListItemText
                                                                                                          primary={
                                                                                                              <div className="d-flex flex-wrap flex-direction-column">
                                                                                                                  <a
                                                                                                                      href={Routes.users.viewUser.replace(
                                                                                                                          ':user_id',
                                                                                                                          item?.user_id
                                                                                                                      )}
                                                                                                                      target="_blank"
                                                                                                                      rel="noopener noreferrer"
                                                                                                                      className="text-decoration-none"
                                                                                                                  >
                                                                                                                      {
                                                                                                                          item?.full_name
                                                                                                                      }
                                                                                                                  </a>
                                                                                                                  <span>
                                                                                                                      {item?.title ||
                                                                                                                          ''}
                                                                                                                  </span>
                                                                                                                  <div>
                                                                                                                      <span className="colorPurple">
                                                                                                                          {item?.user_id ===
                                                                                                                          profile?.user_id
                                                                                                                              ? '(You)'
                                                                                                                              : ''}
                                                                                                                      </span>
                                                                                                                      {item?.is_owner
                                                                                                                          ? ' - Owner'
                                                                                                                          : item?.team_status ===
                                                                                                                            'NDA_PENDING'
                                                                                                                          ? ' - Pending invite'
                                                                                                                          : ''}
                                                                                                                  </div>
                                                                                                              </div>
                                                                                                          }
                                                                                                      />
                                                                                                  </div>
                                                                                              </Grid>
                                                                                              <Grid
                                                                                                  item
                                                                                                  xs={
                                                                                                      12
                                                                                                  }
                                                                                                  sm={
                                                                                                      12
                                                                                                  }
                                                                                                  md={
                                                                                                      8
                                                                                                  }
                                                                                              >
                                                                                                  <div
                                                                                                      className="d-flex
                                                                      align-items-flex-start
                                                                      flex-direction-column"
                                                                                                  >
                                                                                                      {isPostOwner
                                                                                                          ? renderPostOwnerActionButtons(
                                                                                                                item
                                                                                                            )
                                                                                                          : renderActionButtons(
                                                                                                                item
                                                                                                            )}
                                                                                                      <div>
                                                                                                          {!item?.is_owner &&
                                                                                                          isPostOwner ? (
                                                                                                              <>
                                                                                                                  <Controller
                                                                                                                      name={`allow_deal_view_with_email`}
                                                                                                                      render={() => {
                                                                                                                          return (
                                                                                                                              <FormControlLabel
                                                                                                                                  label={
                                                                                                                                      <div className="d-flex align-items-center">
                                                                                                                                          <span>
                                                                                                                                              Allow
                                                                                                                                              easy
                                                                                                                                              view
                                                                                                                                          </span>
                                                                                                                                          <Tooltip
                                                                                                                                              title={
                                                                                                                                                  ALLOW_EASY_VIEW_MESSAGE
                                                                                                                                              }
                                                                                                                                              placement="top"
                                                                                                                                              arrow={
                                                                                                                                                  true
                                                                                                                                              }
                                                                                                                                              open={
                                                                                                                                                  openTooltip
                                                                                                                                              }
                                                                                                                                              PopperProps={{
                                                                                                                                                  disablePortal:
                                                                                                                                                      true
                                                                                                                                              }}
                                                                                                                                              classes={{
                                                                                                                                                  tooltip:
                                                                                                                                                      classes.tooltip
                                                                                                                                              }}
                                                                                                                                              disableFocusListener
                                                                                                                                              disableHoverListener
                                                                                                                                              disableTouchListener
                                                                                                                                          >
                                                                                                                                              <IconButton
                                                                                                                                                  aria-label="info"
                                                                                                                                                  className="p-1 text-hover-colorPurple ml-1"
                                                                                                                                                  onMouseOver={() =>
                                                                                                                                                      handleSetOpenTooltip(
                                                                                                                                                          true
                                                                                                                                                      )
                                                                                                                                                  }
                                                                                                                                                  onMouseLeave={() =>
                                                                                                                                                      handleSetOpenTooltip(
                                                                                                                                                          false
                                                                                                                                                      )
                                                                                                                                                  }
                                                                                                                                              >
                                                                                                                                                  <Info />
                                                                                                                                              </IconButton>
                                                                                                                                          </Tooltip>
                                                                                                                                      </div>
                                                                                                                                  }
                                                                                                                                  control={
                                                                                                                                      <RenderCheckboxComponent
                                                                                                                                          checked={
                                                                                                                                              item?.allow_deal_view_with_email
                                                                                                                                          }
                                                                                                                                          onChange={(
                                                                                                                                              e: any
                                                                                                                                          ) => {
                                                                                                                                              allowMemberPermission(
                                                                                                                                                  'allow_deal_view_with_email',
                                                                                                                                                  e
                                                                                                                                                      ?.target
                                                                                                                                                      ?.checked,
                                                                                                                                                  item?.user_id
                                                                                                                                              );
                                                                                                                                          }}
                                                                                                                                          color="primary"
                                                                                                                                      />
                                                                                                                                  }
                                                                                                                                  className="fs-12"
                                                                                                                              />
                                                                                                                          );
                                                                                                                      }}
                                                                                                                      control={
                                                                                                                          control
                                                                                                                      }
                                                                                                                      defaultValue={
                                                                                                                          item?.allow_deal_view_with_email
                                                                                                                      }
                                                                                                                  />
                                                                                                                  <Controller
                                                                                                                      name={`allow_public_link_feature`}
                                                                                                                      render={() => {
                                                                                                                          return (
                                                                                                                              <FormControlLabel
                                                                                                                                  label="Allow Public Sharing"
                                                                                                                                  control={
                                                                                                                                      <RenderCheckboxComponent
                                                                                                                                          checked={
                                                                                                                                              item?.allow_public_link_feature
                                                                                                                                          }
                                                                                                                                          onChange={(
                                                                                                                                              e: any
                                                                                                                                          ) => {
                                                                                                                                              allowMemberPermission(
                                                                                                                                                  'allow_public_link_feature',
                                                                                                                                                  e
                                                                                                                                                      ?.target
                                                                                                                                                      ?.checked,
                                                                                                                                                  item?.user_id
                                                                                                                                              );
                                                                                                                                          }}
                                                                                                                                          color="primary"
                                                                                                                                      />
                                                                                                                                  }
                                                                                                                              />
                                                                                                                          );
                                                                                                                      }}
                                                                                                                      control={
                                                                                                                          control
                                                                                                                      }
                                                                                                                      defaultValue={
                                                                                                                          item?.allow_public_link_feature
                                                                                                                      }
                                                                                                                  />
                                                                                                              </>
                                                                                                          ) : null}
                                                                                                      </div>
                                                                                                  </div>
                                                                                              </Grid>
                                                                                          </Grid>
                                                                                      </ListItem>
                                                                                  );
                                                                              }
                                                                          )
                                                                        : 'Members not available.'}
                                                                </List>
                                                            </Grid>
                                                        </Grid>
                                                    </Container>
                                                </CardContent>
                                            </div>
                                        </Card>
                                    </div>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default connect(null, {
    GetSingleDeal,
    GetAllTeam,
    AddTeam,
    UpdateTeam,
    DeleteTeam,
    GetSingleUser,
    CommonDispatcher
})(AddTeamToDeal);
