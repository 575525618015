import React from 'react';
import {
    createStyles,
    Dialog,
    DialogContent,
    DialogTitle as MuiDialogTitle,
    IconButton,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
    withStyles,
    WithStyles
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import { OpenModal } from '../../../../redux/Common/action';
import DealNoteHistory from '../../../Common/DealNoteHistory';
import { TOGGLE_CAP_TABLE_HISTORY_MODAL } from '../../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../../redux/store';

const useStyles = makeStyles(() =>
    createStyles({
        dialogContainer: { maxWidth: '600px' },
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                flex: '1 1 30%',
                margin: 'auto'
            }
        }
    })
);

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2)
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: ` 1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    hidden: boolean;
    onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, hidden, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            hidden={hidden}
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const HistoryModal = ({ OpenModal }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStyles();
    const store = useSelector((store: RootState) => store);
    const modal: any = store?.modal?.capTableHistoryModal || {};
    const dataHistory = modal?.data?.history;
    const owner_history =
        dataHistory?.owner_history && dataHistory?.owner_history.length
            ? dataHistory?.owner_history.map((d) => ({
                  ...d,
                  owner: true
              }))
            : [];
    const history =
        dataHistory?.history && dataHistory?.history.length
            ? dataHistory?.history.concat(owner_history)
            : [];

    const handleClose = () => {
        OpenModal(TOGGLE_CAP_TABLE_HISTORY_MODAL, { isOpen: false });
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            maxWidth={modal?.config.modalSize || 'sm'}
            fullWidth={modal?.config.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-note"
            disableBackdropClick={modal?.config.disableBackdropClick || false}
            classes={{ paperScrollPaper: classes.dialogContainer }}
            onClose={handleClose}
        >
            <DialogTitle
                id="alert-dialog-title"
                hidden={modal?.config?.hideTitle || false}
                onClose={handleClose}
            >
                {modal?.title}
            </DialogTitle>
            <DialogContent>
                {modal?.contentTitle ? (
                    <div className="w-100 mb-5 text-left colorPrimary">
                        <span className="fs-16">{modal?.contentTitle}</span>
                    </div>
                ) : null}
                <div className="colorPrimary">
                    <DealNoteHistory
                        currency={modal?.data?.currency}
                        history={history}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default connect(null, { OpenModal })(HistoryModal);
