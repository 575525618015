import * as ACTIONS from "./actionTypes";

const initialValues = {
    tableHeader: [
        {id: "createdAt", label: "Created Date"},
        {id: "name", label: "Name"},
        {id: "email", label: "Email"},
        {id: "phone", label: "Phone"},
        {id: "equity_id", label: "Deal Id"},
        {id: "type", label: "Type"},
        {id: "feedback_text", label: "Feedback Text"}
    ],
    listingsData: {
        current_page: 0,
        total_pages: 0,
        posts: [],
        count: 0
    }
};

const FeedbackReducer = (state = initialValues, action: any) => {
    switch (action.type) {
        case ACTIONS.SET_FEEDBACK_DATA:
            return {
                ...state,
                listingsData: {
                    posts: action?.payload?.feedbacks,
                    count: action?.payload?.count,
                    current_page: action?.payload?.current_page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                }
            };
        default:
            return state;
    }
};

export default FeedbackReducer;