import { Avatar, Button, Table } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROWS_PER_PAGE, Routes } from '../../../constants';
import {
    DeleteClient,
    GetClientConfigList,
    UnblockUserApi
} from '../../../redux/ClientConfig/actions';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { OPEN_ALERT_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import { getQuery } from '../../../utils/common';
import CommonModalWrapper from '../../Common/CommonModal';
import { CommonTableStyle } from '../../Common/CommonTableStyle';
import TableHeaderFilter from '../../Common/TableHeaderFilter';
import WebsiteURLWrapper from '../../Firms/Common/WebsiteURLWrapper';
import { CommonPaginationAction } from '../../Users/Common/CommonPaginationAction';
import AddEditClient from '../AddEditClient';
import { MENU_OPTIONS } from '../Common';
import style from './Style.module.scss';

const AllClientConfigComponent = ({
    sectionBaseUrl,
    CommonDispatcher,
    GetClientConfigList,
    DeleteClient,
    UnblockUserApi,
    OpenModal,
    loading,
    isMainFilterApplied,
    getAllTabsData
}: any) => {
    const classes: any = CommonTableStyle();
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(isMainFilterApplied);
    const [selectedUser, setSelectedUser] = useState({ client_id: '' });
    const tableHeader = useSelector(
        (store: RootState) => store?.clientConfig.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.clientConfig.allClientConfigList
    );
    const [openAddClientModel, setOpenAddClientModel] = useState(false);
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const query = getQuery(history);
    const type = query.get('type');
    const keyword = query.get('keyword');
    const [page, setPage] = useState(0);
    const count = listingsData?.count;
    const total_pages = listingsData?.total_pages;

    useEffect(() => {
        if (
            !isFilterApplied &&
            (keyword || type || typeof page !== 'undefined')
        ) {
            getData({ keyword, type, page: page + 1 });
        }
        // eslint-disable-next-line
    }, [keyword, type, page]);

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const getData = (data) => {
        data.size = ROWS_PER_PAGE;
        setIsLoading(true);
        GetClientConfigList(token, data).then(() => {
            setIsLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        setIsFilterApplied(false);
    };

    const CommonComponent = () => (
        <CommonPaginationAction
            count={count}
            page={page}
            setPage={setPage}
            total_pages={total_pages}
        />
    );

    const handleChange = (value, selectedUser) => {
        switch (value) {
            case 'edit':
                handleToggle();
                break;
            case 'delete':
                if (
                    window?.confirm(
                        'Are you sure you want to delete this client?'
                    )
                ) {
                    DeleteClient(token, selectedUser?.client_id)
                        .then(() =>
                            getAllTabsData({
                                page: page + 1
                            })
                        )
                        .catch(() => {
                            alert('Something went wrong.');
                        });
                }
                break;
        }
    };

    const renderMenu = ({ client_id, user_name }) => {
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) => handleClick(e, { client_id, user_name })}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    elevation={0}
                    PaperProps={{
                        style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            border: '1px solid rgb(0 106 194 / 20%)'
                        }
                    }}
                >
                    {MENU_OPTIONS.map((option, i) => (
                        <MenuItem
                            key={option?.value}
                            onClick={() => {
                                handleChange(option?.value, selectedUser);
                                setAnchorEl(null);
                            }}
                        >
                            {option?.title}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };
    const handleToggle = () => {
        setOpenAddClientModel(!openAddClientModel);
    };
    const handleUnblockUser = () => {
        UnblockUserApi(token).then((r) => {
            if (r.type === 'success') {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Cach Clear'
                });
            } else {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Somthing Went Wrong'
                });
            }
        });
    };

    return (
        <>
            <CommonModalWrapper
                modal={{
                    isOpen: openAddClientModel,
                    config: { modalSize: 'xs' },
                    title: selectedUser?.client_id
                        ? 'Edit Client'
                        : 'Add New Client'
                }}
            >
                <AddEditClient
                    page={page}
                    setSelectedUser={setSelectedUser}
                    handleCloseModal={handleToggle}
                    client_id={selectedUser?.client_id || ''}
                />
            </CommonModalWrapper>
            <Grid container spacing={0} className="h-100">
                <Grid item xs={12}>
                    <Paper className={classes.root}>
                        <div className="d-flex align-items-center flex-wrap justify-content-sm-center mb-5">
                            <TableHeaderFilter
                                sectionBaseUrl={sectionBaseUrl.deals}
                            />
                            <Button
                                className="mr-5 text-inherit"
                                color={'primary'}
                                type="submit"
                                variant="contained"
                                onClick={handleUnblockUser}
                            >
                                Unblock IPs
                            </Button>
                            <Button
                                className="mr-5 text-inherit"
                                color={'primary'}
                                type="submit"
                                variant="contained"
                                onClick={handleToggle}
                            >
                                Add Client
                            </Button>
                        </div>
                        {loading || isLoading ? (
                            <div className="mainLoader">
                                <div className="loader" />
                            </div>
                        ) : (
                            <>
                                <TableContainer className={classes.container}>
                                    <Table
                                        stickyHeader
                                        aria-label="sticky table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {tableHeader?.map((column) => (
                                                    <TableCell key={column.id}>
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listingsData?.posts &&
                                            listingsData?.posts.length ? (
                                                listingsData?.posts?.map(
                                                    (row) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={
                                                                    row?.client_id
                                                                }
                                                                style={{
                                                                    padding: 0,
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    history.push(
                                                                        `${Routes.clientConfig.clientConfigDetails.replace(
                                                                            ':client_id',
                                                                            row?.client_id
                                                                        )}`
                                                                    );
                                                                }}
                                                            >
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        e.stopPropagation()
                                                                    }
                                                                >
                                                                    {renderMenu(
                                                                        row
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    style={{
                                                                        minWidth:
                                                                            '100px'
                                                                    }}
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {moment(
                                                                        row?.createdAt
                                                                    ).format(
                                                                        'DD MMM, YYYY'
                                                                    )}
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    <Avatar
                                                                        src={
                                                                            row?.logo
                                                                        }
                                                                        alt={
                                                                            row?.name
                                                                        }
                                                                        className={`pt-sm-3 ${style.avatar}`}
                                                                    />
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    <WebsiteURLWrapper
                                                                        website={
                                                                            row?.url
                                                                        }
                                                                    >
                                                                        {
                                                                            row?.name
                                                                        }
                                                                    </WebsiteURLWrapper>
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {row?.email}
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {
                                                                        row?.dbname
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                >
                                                                    {
                                                                        row?.status
                                                                    }
                                                                </TableCell>
                                                                <TableCell
                                                                    size={
                                                                        'small'
                                                                    }
                                                                />
                                                            </TableRow>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <tr>
                                                    <td
                                                        colSpan={
                                                            tableHeader.length +
                                                            1
                                                        }
                                                        className="dataNotAvailable"
                                                    >
                                                        Data not available
                                                    </td>
                                                </tr>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    className={classes.footer}
                                    hidden={
                                        listingsData?.posts &&
                                        !listingsData?.posts.length
                                    }
                                    rowsPerPageOptions={[]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={listingsData?.page_size}
                                    page={page}
                                    ActionsComponent={CommonComponent}
                                    onPageChange={handleChangePage}
                                />
                            </>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};
export default connect(null, {
    GetClientConfigList,
    CommonDispatcher,
    DeleteClient,
    UnblockUserApi,
    OpenModal
})(AllClientConfigComponent);
