import React from 'react';
import { createExternalLink } from '../../../utils/common';

export const ExternalUrl = ({ data, className }: any) => {
    const renderLink = (d) =>
        d?.url ? (
            <a
                href={d?.url.startsWith('http') || d?.url.startsWith('https')
                ? d?.url: `http://${d?.url}`}
                target="_blank"
                rel="noopener noreferrer"
                className={className}
            >
                {d?.title
                     }
            </a>
        ) : (
            d?.title
        );

    return data && data?.length && data?.length === 1 ? (
        data.map((d, i) => {
            return <span key={d?.url}>{renderLink(d)}</span>;
        })
    ) : (
        <ol>
            {data.map((d) => {
                return (
                    <li key={d?.url} className="mb-2">
                        {renderLink(d)}
                    </li>
                );
            })}
        </ol>
    );
};
