import { isValidURL } from '../../../../utils/common';

export const validateFirstStep = (data) => {
    let errorRef: any = {};
    if (!data?.company_logo) {
        errorRef.company_logo = true;
    }
    if (!data?.company_name) {
        errorRef.company_name = true;
    }
    if (!data?.website) {
        errorRef.website = true;
    }
    if (!data?.foundation_year) {
        errorRef.foundation_year = true;
    }
    if (data?.website && !isValidURL(data?.website)) {
        errorRef.isWebsiteInValid = true;
    }
    if (!data?.sectors || (data?.sectors && !data?.sectors?.length)) {
        errorRef.sectors = true;
    }
    if (
        !data?.startup_type ||
        (data?.startup_type && !data?.startup_type?.length)
    ) {
        errorRef.startup_type = true;
    }
    if (
        !data?.business_model ||
        (data?.business_model && !data?.business_model?.length)
    ) {
        errorRef.business_model = true;
    }
    if (!data?.hq_geos || (data?.hq_geos && !data?.hq_geos?.length)) {
        errorRef.hq_geos = true;
    }
    if (!data?.regions || (data?.regions && !data?.regions?.length)) {
        errorRef.regions = true;
    }
    if (!data?.ops_geos || (data?.ops_geos && !data?.ops_geos?.length)) {
        errorRef.ops_geos = true;
    }
    if (!data?.description) {
        errorRef.description = true;
    }

    return errorRef;
};

export const validateSecondStep = (data, CURRENTLY_RAISING_LIST) => {
    let errorRef: any = {};
    if (!data?.last_funding_stage_id) {
        errorRef.last_funding_stage_id = true;
    }
    if (!data?.current_funding_stage_id) {
        errorRef.current_funding_stage_id = true;
    }
    if (!data?.country_id) {
        errorRef.country_id = true;
    }
    if (!data?.head_count) {
        errorRef.head_count = true;
    }
    if (!data?.currency_id) {
        errorRef.currency_id = true;
    }
    // if (!data?.pre_money && data?.current_funding_stage_id !== CURRENTLY_RAISING_LIST?.[0]?.value) {
    //     errorRef.pre_money = true;
    // }
    if (!data?.target_raise) {
        errorRef.target_raise = true;
    }
    if (!data?.no_commitments && !data?.soft_circled) {
        errorRef.soft_circled = true;
    }
    errorRef.founders =
        !data?.founders || (data?.founders && !data?.founders?.length);
    if (data?.founders) {
        const foundersListErrors = [];
        let founders = !data?.founders?.length
            ? [{ name: '', profile_link: '' }]
            : [];
        founders?.forEach((d, i) => {
            let foundersError: any = {};
            foundersError.name = !d?.name;
            foundersError.profile_link = !d?.profile_link;
            if (d?.profile_link && !isValidURL(d?.profile_link)) {
                foundersError.inValidProfileUrl = true;
            }
            foundersListErrors[i] = foundersError;
        });
        if (foundersListErrors?.length) {
            errorRef.inValidFounders = foundersListErrors;
        }
    }
    return errorRef;
};

export const validateThirdStep = (data) => {
    let errorRef: any = {};
    errorRef.documents =
        !data?.documents || (data?.documents && !data?.documents?.length);
    if (data?.documents) {
        const documentsListErrors = [];
        let documents = !data?.documents?.length
            ? [{ name: '', doc_url: '' }]
            : [];
        documents?.forEach((d, i) => {
            let documentsErrors: any = {};
            documentsErrors.name = !d?.name;
            documentsErrors.doc_url = !d?.doc_url;
            if (d?.doc_url && !isValidURL(d?.doc_url)) {
                documentsErrors.inValidDocUrl = true;
            }
            documentsListErrors[i] = documentsErrors;
        });
        if (documentsListErrors?.length) {
            errorRef.inValidDocuments = documentsListErrors;
        }
    }
    return errorRef;
};

export const validateFourthStep = (data) => {
    let errorRef: any = {};
    if (data?.investors && data?.investors?.length) {
        const investorsListErrors = [];
        data?.investors?.forEach((d, i) => {
            let investorsErrors: any = {};
            if (d?.profile_link && !isValidURL(d?.profile_link)) {
                investorsErrors.inValidProfileUrl = true;
                investorsListErrors[i] = investorsErrors;
            }
        });
        if (investorsListErrors.length) {
            errorRef.inValidInvestors = investorsListErrors;
        }
    }

    return errorRef;
};
