import moment from "moment";
import React from "react";
import {Divider, List, ListItem, ListItemText, Typography} from "@material-ui/core";

const DealNoteHistory = ({currency, history}) => {

    return history && history.length ? history.sort((a, b) => {
        return new Date(a.date).getTime() < new Date(b.date).getTime() ? 1 : -1;
    }).map((d, i) => {
        let status = "";
        switch (d?.status) {
            case "Created":
            case "CREATED":
                status = (d?.data?.min_investment && d?.data?.max_investment) ?
                    `Submitted an interest ${currency}${d?.data?.min_investment} - ${currency}${d?.data?.max_investment}` : "Submitted an interest";
                break;
            case "Updated":
            case "UPDATED":
                status = `Investment interest updated ${currency}${d?.data?.min_investment} - ${currency}${d?.data?.max_investment}`;
                break;
            case "FINAL_AMOUNT_SUBMITTED":
                status = (d?.data?.min_investment && d?.data?.max_investment) ?
                    `Allocated an interest ${currency}${d?.data?.min_investment} - ${currency}${d?.data?.max_investment}` : "Allocated an interest";
                break;
            case "REMOVED":
                status = `Investment interest removed.`;
                break;
        }
        return <List key={i} style={{width: "100%"}}>
            <ListItem alignItems="flex-start" className="p-0">
                <ListItemText className="mt-0"
                              primary={`${moment(d?.date).format("DD MMMM, YYYY hh:mm a")} ${d?.data?.user?.full_name ?
                                  `– by ${d?.data?.user?.full_name}` : ""} ${d?.data?.is_owner ? "(Owner)" : ""}`}
                              secondary={<div className="mt-2">
                                  <Typography component="span" variant="body2">
                                      {status}
                                  </Typography>
                                  {d?.data?.note ? <div>
                                      <Typography component="span" variant="body1">
                                          {d?.data?.note}
                                      </Typography>
                                  </div> : null}
                              </div>}
                />
            </ListItem>
            {i < history.length - 1 ? <Divider variant="inset" component="li"/> : null}
        </List>;
    }) : <List style={{width: "100%"}}>
        <ListItem alignItems="flex-start" className="p-0">
            <ListItemText className="mt-0">
                Data not available.
            </ListItemText>
        </ListItem>
    </List>;
};

export default DealNoteHistory;