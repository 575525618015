import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { fetchOneStoryData } from '../../redux/Dashboard/actions';
import { useLocation } from 'react-router-dom';
import Tuple from './Tuple';
import { connect } from 'react-redux';
import { Routes } from '../../constants';

function DetailPage(props) {
    const [cookies] = useCookies(['pgAdminToken']);
    const location = useLocation();
    const [data, setData] = useState({} as any);
    useEffect(() => {
        let promise = Promise.resolve({});
        switch (location?.pathname?.split('/')?.[1]) {
            case Routes.stories?.split('/')?.[1]:
                promise = props?.fetchOneStoryData(
                    cookies?.pgAdminToken,
                    props?.match?.params?.id
                );
                break;
        }
        promise.then(setData);
        // eslint-disable-next-line
    }, [props?.match?.params?.id, cookies]);
    const makeLabel = (key: string) =>
        key
            .split('_')
            .join(' ')
            .split('-')
            .join(' ')
            .replace(/^\w/, (c) => c.toUpperCase());
    return (
        <div className="my-4 mx-0">
            {Object.keys(data).map((key: string, i) => {
                if (typeof data[key] === 'string')
                    return (
                        <Tuple
                            label={makeLabel(key)}
                            key={i}
                            value={data[key]}
                        />
                    );
                else return null;
            })}
        </div>
    );
}

export default connect(null, {
    fetchOneStoryData
})(DetailPage);
