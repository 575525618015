import {
    Avatar,
    Button,
    CircularProgress,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import { SOCIAL_MEDIA_LIST } from '../../../constants';
import {
    CommonDispatcher,
    GetBusinessModelMaster,
    GetFirmsClassify,
    GetInvestmentsTypeMaster,
    GetOneFirm,
    GetRegions,
    GetSdgGoals,
    GetSectors,
    GetStages,
    GetStartupTypeMaster,
    UploadFirmLogo
} from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { AddFirm, UpdateFirm } from '../../../redux/Firms/actions';
import { RootState } from '../../../redux/store';
import { addCommas, removeNonNumeric } from '../../../utils/common';
import { ACCOUNT_TYPE, FirmsTypeList } from '../../../utils/constants';
import countriesArray from '../../../utils/countries.json';
import InfoIconComponent from '../../Common/InfoIconComponent';
import RenderAutocompleteTextField from '../../InputComponents/AutocompleteTextField';
import RenderCheckboxComponent from '../../InputComponents/CheckboxComponent';
import RenderCreatableAutocomplete from '../../InputComponents/CreatableAutocomplete';
import RenderMaskedTextField from '../../InputComponents/MaskedTextField';
import RenderSelectField from '../../InputComponents/SelectField/RenderSelectField';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import style from './Style.module.scss';
import { GetEcoSystemType } from '../../../redux/MetaData/actions';

const AddEditFirm = ({
    firm_id = null,
    getAllData,
    handleCloseModal,
    CommonDispatcher,
    GetOneFirm,
    AddFirm,
    UpdateFirm,
    UploadFirmLogo,
    GetInvestmentsTypeMaster,
    GetStartupTypeMaster,
    GetFirmsClassify,
    GetBusinessModelMaster,
    GetSectors,
    GetStages,
    GetSdgGoals,
    GetRegions,
    GetEcoSystemType
}: any) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const MAX_FIRM_TYPE_TAGS_SIZE = 3;
    const MAX_STARTUP_TYPE_TAGS_SIZE = 1;
    const [firm_type, setFirmType] = useState([]);

    const [type, setType]: any = useState(null);
    const [ecosystem_type, setEcosystemType]: any = useState(null);

    const [investmentsTypeList, setInvestmentsTypeList] = useState([]);
    const [investmentsType, setInvestmentsType] = useState([]);
    const isStartup = type === ACCOUNT_TYPE?.STARTUP;

    const [firmTypeSize, setFirmTypeSize] = useState<number>(0);
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const store = useSelector((store: RootState) => store);
    const [investment_class, setInvestmentAssets] = useState([]);
    const [regionsList, setRegionsList] = useState([]);
    const [business_model, setBusinessModel] = useState([]);
    const [preferred_sector, setSectors] = useState([]);
    const [sdgGoals, setSdgGoals] = useState([]);
    const [preferred_stage, setCurrentFundingStageId] = useState([]);
    const [sectorsList, setSectorsList] = useState([]);
    const [sdgGoalsList, setSdgGoalsList] = useState([]);
    const [selectedOpsGeosList, setSelectedOpsGeosList] = useState([]);
    const [min, setMin] = useState(null);
    const [max, setMax] = useState(null);
    const [is_not_fundraising, setFundraising] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setError] = useStateRef({}) as [any, any, any];
    const sectorsArray = store?.commonAppData?.sectors || [];
    const sdgGoalsArray = store?.commonAppData?.sdgGoals || [];
    const startupType = store?.commonAppData?.startupType || [];
    const businessModel = store?.commonAppData?.businessModel || [];
    const [regions, setRegions] = useState([]);
    const [FIRM_TYPE_LIST, setFirmClassify] = useState([]);

    const STAGE_LIST = store?.commonAppData?.stages?.stages?.STAGE_LIST || [];
    const CURRENTLY_RAISING_LIST =
        store?.commonAppData?.stages?.stages?.CURRENTLY_RAISING_LIST || [];

    const { getValues, setValue, register, control, reset } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'investor_profile'
    });

    const [isImageUploadLoading, setImageUploadLoading] = useState(false);
    const [uploadedImage, setUploadedImage] = useState({
        firm_logo: '',
        firm_mobile_logo: ''
    });
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [firm, setFirm]: any = useState({});
    const [ecosystemTypeList, setEcosystemTypeList]: any = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const arr = [];
        arr.push(GetStartupTypeMaster());
        arr.push(GetBusinessModelMaster());
        arr.push(GetSectors());
        arr.push(GetStages());
        arr.push(GetSdgGoals());
        arr.push(GetRegions());
        arr.push(GetFirmsClassify());
        arr.push(GetEcoSystemType(token));
        if (!isStartup) {
            arr.push(GetInvestmentsTypeMaster());
        }

        axios.all(arr).then((resp) => {
            const ecosystem_types = resp?.[7]?.data?.data;

            if (ecosystem_types && ecosystem_types.length) {
                const ecosystemTypesList = ecosystem_types.map((item) => {
                    return {
                        title: item?.name,
                        value: item?.id
                    };
                });
                setEcosystemTypeList(ecosystemTypesList);
            }
            let countriesArrayData = countriesArray
                .map((d) => ({ title: d?.title, value: d?.id }))
                .sort((a, b) => a?.value - b?.value);
            const countries = countriesArrayData;
            const regions = resp?.[5]?.response?.regions;
            setFirmClassify(resp?.[6]?.response);
            let regionsData = [];
            regions.forEach((d) => {
                regionsData.push({
                    title: d?.title,
                    value: d?.id,
                    countries: d?.countries
                });
            });
            regionsData = regionsData.sort((a, b) => a?.value - b?.value);
            setRegionsList(regionsData);
            if (!isStartup) {
                setInvestmentsTypeList(resp?.[8]?.response?.investmentsType);
            }
            if (firm_id) {
                GetOneFirm(token, { firm_id }).then((r) => {
                    let data = { ...r?.response?.firm };
                    setUploadedImage({
                        firm_logo: data?.firm_log_full_url,
                        firm_mobile_logo: data?.firm_mobile_logo
                    });
                    setFirm(data);
                    setImageUploadLoading(false);
                    setSingleFirm(data, countries, regionsData);
                });
            } else {
                setSingleFirm({});
                setIsLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        let sectorsData = [];
        sectorsArray.forEach((d) => {
            sectorsData.push({ title: d?.title, value: d?.id });
        });
        sectorsData = sectorsData.sort((a, b) => a?.value - b?.value);
        setSectorsList(sectorsData);
    }, [sectorsArray]);

    useEffect(() => {
        let sdgGoalsData = [];
        sdgGoalsArray.forEach((d) => {
            sdgGoalsData.push({ title: d?.title, value: d?.id });
        });
        sdgGoalsData = sdgGoalsData.sort((a, b) => a?.value - b?.value);
        setSdgGoalsList(sdgGoalsData);
    }, [sdgGoalsArray]);

    const fieldsToValidate = [
        'label',
        'website',
        isStartup ? 'last_funding_stage' : 'investor_type',
        'investor_profile',
        'investment_class',
        'business_model',
        'preferred_sector',
        'preferred_stage',
        'regions',
        'investments_type',
        'investment_geos',
        'min_ticket_size',
        'max_ticket_size'
    ];

    const setSingleFirm = (
        data: any = {},
        countriesList = [],
        regionsArray = []
    ) => {
        let jsonData: any = {};
        setFundraising(data?.is_not_fundraising);
        Object.entries(data).forEach(([k]) => {
            if (fieldsToValidate.includes(k)) {
                jsonData[k] = data[k];
            }
        });
        jsonData.sdg_sectors = data?.sdg_sectors;
        const investment_class_assets =
            jsonData?.investment_class && jsonData?.investment_class.length
                ? jsonData?.investment_class.map((r) => ({
                      value: r?.id,
                      title: r?.label
                  }))
                : [];

        const business_model_list =
            jsonData?.business_model && jsonData?.business_model.length
                ? jsonData?.business_model.map((r) => ({
                      value: r?.id,
                      title: r?.label
                  }))
                : [];

        const preferred_sector_list =
            jsonData?.preferred_sector && jsonData?.preferred_sector.length
                ? jsonData?.preferred_sector.map((r) => ({
                      value: r?.sector_id,
                      title: r?.label
                  }))
                : [];

        const sdg_sectors_list =
            jsonData?.sdg_sectors && jsonData?.sdg_sectors.length
                ? jsonData?.sdg_sectors.map((r) => ({
                      value: r?.sdg_sector_id,
                      title: r?.label
                  }))
                : [];

        const preferred_stage_list =
            jsonData?.preferred_stage && jsonData?.preferred_stage.length
                ? jsonData?.preferred_stage.map((r) => ({
                      value: r?.funding_stage_id,
                      title: r?.label
                  }))
                : [];

        const investment_geos_list =
            jsonData?.investment_geos && jsonData?.investment_geos.length
                ? countriesList.filter((d) =>
                      jsonData?.investment_geos
                          .map((a) => a.country_id)
                          .includes(d?.value)
                  )
                : [];

        const regions_list =
            jsonData?.regions && jsonData?.regions.length
                ? regionsArray.filter((d) =>
                      jsonData?.regions
                          .map((a) => a.region_id)
                          .includes(d?.value)
                  )
                : [];

        setRegions(regions_list);

        const investors = isStartup
            ? jsonData?.last_funding_stage &&
              jsonData?.last_funding_stage.length
                ? jsonData?.last_funding_stage.map((r) => ({
                      value: r?.funding_stage_id,
                      title: r?.label
                  }))
                : []
            : jsonData?.investor_type && jsonData?.investor_type.length
            ? jsonData?.investor_type.map((r) => ({
                  value: r?.firm_classify_id,
                  title: r?.label
              }))
            : [];
        let obj: any = {
            label: jsonData?.label,
            website: jsonData?.website,
            min_ticket_size: jsonData?.min_ticket_size,
            max_ticket_size: jsonData?.max_ticket_size,
            business_model: business_model_list,
            investment_class: investment_class_assets,
            preferred_sector: preferred_sector_list,
            sdg_sectors: sdg_sectors_list,
            preferred_stage: preferred_stage_list,
            regions: regions_list,
            investment_geos: investment_geos_list,
            investor_profile:
                jsonData?.investor_profile && jsonData?.investor_profile.length
                    ? jsonData?.investor_profile
                    : [{ type: '', profile_url: '' }]
        };
        if (!isStartup) {
            obj.investor_type = investors;
            const investmentsType =
                jsonData?.investments_type && jsonData?.investments_type.length
                    ? jsonData?.investments_type.map((r) => ({
                          title: r?.name,
                          value: r?.id
                      }))
                    : [];
            obj.investments_type = investmentsType;
            setInvestmentsType(investmentsType);
        } else {
            obj.last_funding_stage = investors;
        }

        setSelectedOpsGeosList(countriesList);
        setInvestmentAssets(investment_class_assets);
        setBusinessModel(business_model_list);
        setSectors(preferred_sector_list);
        setSdgGoals(sdg_sectors_list);
        setCurrentFundingStageId(preferred_stage_list);
        setFirmType(investors);
        setType(!firm_id ? FirmsTypeList?.[1]?.value : data?.type);
        if (data?.ecosystem_type && data?.ecosystem_type.length) {
            setEcosystemType(data?.ecosystem_type[0]?.id);
        }

        if (jsonData?.min_ticket_size) {
            let min = addCommas(
                removeNonNumeric(
                    jsonData?.min_ticket_size.toString().slice(0, 18)
                )
            );
            setMin(min);
        }
        if (jsonData?.max_ticket_size) {
            let max = addCommas(
                removeNonNumeric(
                    jsonData?.max_ticket_size.toString().slice(0, 18)
                )
            );
            setMax(max);
        }

        reset(obj);
        setIsLoading(false);
    };

    const handleFirmTypeChange = (value) => {
        setFirmType(value);
        setFirmTypeSize(value.length as number);
    };

    const handleInvestmentsTypeChange = (value) => {
        setInvestmentsType(value);
    };

    const handleInvestmentChange = (value) => {
        setInvestmentAssets(value);
    };
    const handleBusinessModelChange = (value) => {
        setBusinessModel(value);
    };

    const handleSectorsChange = (value) => {
        setSectors(value);
    };

    const handleSdgGoalsChange = (value) => {
        setSdgGoals(value);
    };

    const handleCurrentFundingStageChange = (value) => {
        setCurrentFundingStageId(value);
    };

    const handleRegionsChange = (values) => {
        setRegions(values);
        let geos = values
            .map((d) => d.countries)
            .flat()
            .map((d) => ({
                title: d?.label,
                value: d?.country_id
            }))
            .filter((d) => d.value && d.title);
        const distinct = [];
        for (let i = 0; i < geos.length; i++) {
            if (!distinct.map((d: any) => d.value).includes(geos[i].value)) {
                distinct.push(geos[i]);
            }
        }
        setValue('investment_geos', distinct);
    };

    const toggle = () => {
        const flag = !is_not_fundraising;
        if (flag) {
            handleCurrentFundingStageChange([]);
            setValue('preferred_stage', []);
            setValue('min_ticket_size', '');
            setValue('max_ticket_size', '');
        }
        setFundraising(flag);
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getValues();
        let obj = { ...data };
        obj.is_not_fundraising = is_not_fundraising;

        if (isStartup) {
            if (obj?.last_funding_stage && obj?.last_funding_stage.length) {
                obj.last_funding_stage = obj?.last_funding_stage.map(
                    (d) => d?.value
                );
            }
        } else {
            if (obj?.investor_type && obj?.investor_type.length) {
                obj.investor_type = obj?.investor_type.map((d) => d?.value);
            }
        }
        if (obj?.investment_geos && obj?.investment_geos.length) {
            obj.investment_geos = obj?.investment_geos.map((d) => d?.value);
        }
        if (obj?.regions && obj?.regions.length) {
            obj.regions = obj?.regions.map((d) => d?.value);
        }
        if (obj?.investment_class && obj?.investment_class.length) {
            obj.investment_class = obj?.investment_class.map((d) => d.value);
        }
        if (obj?.business_model && obj?.business_model.length) {
            obj.business_model = obj?.business_model.map((d) => d?.value);
        }
        if (obj?.preferred_sector && obj?.preferred_sector.length) {
            obj.preferred_sector = obj?.preferred_sector.map((d) => d?.value);
        }
        if (obj?.sdg_sectors && obj?.sdg_sectors.length) {
            obj.sdg_sectors = obj?.sdg_sectors.map((d) => d?.value);
        }

        if (obj?.investments_type && obj?.investments_type.length) {
            obj.investments_type = obj?.investments_type.map((d) => d?.value);
        }
        if (obj?.preferred_stage && obj?.preferred_stage.length) {
            obj.preferred_stage = obj?.preferred_stage.map((d) => d.value);
        } else {
            obj.preferred_stage = [];
        }

        if (obj.min_ticket_size) {
            obj.min_ticket_size = parseFloat(
                obj.min_ticket_size.toString().replace(/,/g, '')
            );
        } else {
            obj.min_ticket_size = null;
        }
        if (obj.max_ticket_size) {
            obj.max_ticket_size = parseFloat(
                obj.max_ticket_size.toString().replace(/,/g, '')
            );
        } else {
            obj.max_ticket_size = null;
        }
        if (obj?.investor_profile && obj?.investor_profile.length) {
            obj.investor_profile = obj?.investor_profile.filter(
                (d) => d?.type && d?.profile_url
            );
        } else {
            obj.investor_profile = [];
        }
        if (uploadedImage?.firm_logo) {
            obj.firm_logo = uploadedImage?.firm_logo;
        }
        if (uploadedImage?.firm_mobile_logo) {
            obj.firm_mobile_logo = uploadedImage?.firm_mobile_logo;
        }

        if (firm_id) {
            setIsUpdateLoading(true);
            UpdateFirm(token, {
                label: data?.label,
                website: data?.website,
                type,
                ecosystem_type,
                firm_id,
                ...obj
            })
                .then((r) => {
                    if (r?.type === 'success') {
                        getAllData();
                        setIsUpdateLoading(false);
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Firm updated successfully.'
                        });
                        handleCloseModal();
                    } else {
                        setIsUpdateLoading(false);
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Failed to update firm.'
                        });
                    }
                })
                .finally(() => setIsUpdateLoading(false));
        } else {
            AddFirm(token, {
                label: data?.label,
                website: data?.website,
                type,
                ecosystem_type,
                ...obj
            })
                .then((r) => {
                    if (r?.type === 'success') {
                        getAllData();
                        setIsUpdateLoading(false);
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Firm added successfully.'
                        });
                        handleCloseModal();
                    } else {
                        setIsUpdateLoading(false);
                        CommonDispatcher(SHOW_SNACKBAR, {
                            isShowing: true,
                            message: 'Failed to add firm.'
                        });
                    }
                })
                .finally(() => setIsUpdateLoading(false));
        }
    };

    const handleToggleType = (e) => {
        const value = e.target.value;

        setType(value);
        let data = FirmsTypeList.filter((d) => d.value !== value);
        data.shift();
    };

    const handleEcosystemType = (e) => {
        const ecosystem_type = e.target.value;

        setEcosystemType(ecosystem_type);
        let data = ecosystemTypeList.filter((d) => d.value !== ecosystem_type);
        data.shift();
    };

    const uploadImage = (e, key) => {
        const files = e.target.files;
        if (files && files.length) {
            setImageUploadLoading(true);
            imageCompression(files[0], { maxSizeMB: 1 })
                .then((file) => {
                    let formData = new FormData();
                    if (firm_id) {
                        formData.append('user_id', firm_id);
                    }
                    formData.append('files', file as any, file?.name);
                    return UploadFirmLogo(token, formData).then((r) => {
                        if (r?.type === 'failed') {
                            setImageUploadLoading(false);
                            CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                message: r?.response.data.error
                            });
                        } else {
                            setUploadedImage({
                                ...uploadedImage,
                                [key]: r?.response?.file_url
                            });
                            setImageUploadLoading(false);
                        }
                    });
                })
                .catch(() => {
                    setImageUploadLoading(false);
                    alert('Failed to upload image. Please try again.');
                });
        }
    };

    const validateLink = (index) => {
        if (errors?.investor_profile && errors?.investor_profile.length) {
            const required = errors?.investor_profile?.[index]?.profile_url
                ? 'Social profile link is mandatory.'
                : null;
            const invalidUrl = errors?.investor_profile?.[index]
                ?.inValidProfileUrl
                ? 'Invalid social profile link.'
                : null;
            return (
                <FormHelperText
                    error={true}
                    className={required || invalidUrl ? 'visible' : 'invisible'}
                >
                    {required}
                    {!required ? invalidUrl : null}
                </FormHelperText>
            );
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="m-auto pb-5">
                {isLoading ? (
                    <div className="mainLoader">
                        <div className="loader" />
                    </div>
                ) : (
                    <form>
                        <Grid
                            container
                            spacing={3}
                            className="my-1 d-flex flex-direction-columns"
                        >
                            <Grid
                                item
                                xs={6}
                                className={`py-1 ${style.upload}`}
                            >
                                <input
                                    accept="image/*"
                                    className={`${style.avatarInput}`}
                                    id="icon-button-file"
                                    onChange={(e) =>
                                        uploadImage(e, 'firm_logo')
                                    }
                                    type="file"
                                />
                                <label htmlFor="icon-button-file">
                                    {isImageUploadLoading ? (
                                        <CircularProgress
                                            size="2rem"
                                            className={`${
                                                isImageUploadLoading
                                                    ? style.avatarProgress
                                                    : ''
                                            } circular-progress`}
                                        />
                                    ) : null}
                                    <Avatar
                                        alt="Profile"
                                        src={uploadedImage?.firm_logo}
                                        className={`${style.avatar} ${
                                            isImageUploadLoading
                                                ? style.avatarOpacity
                                                : ''
                                        }`}
                                    />
                                </label>
                                <label
                                    htmlFor="icon-button-file"
                                    className={style.btnUploadLabel}
                                >
                                    <Button
                                        color="primary"
                                        className={`${style.btnUpload} my-3`}
                                    >
                                        {`${
                                            uploadedImage?.firm_logo
                                                ? 'Change'
                                                : 'Upload'
                                        } profile picture`}
                                    </Button>
                                </label>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                className={`py-1 ${style.upload}`}
                            >
                                <input
                                    accept="image/*"
                                    className={`${style.avatarInput}`}
                                    id="icon-button-file-firm_mobile_logo"
                                    onChange={(e) =>
                                        uploadImage(e, 'firm_mobile_logo')
                                    }
                                    type="file"
                                />
                                <label htmlFor="icon-button-file-firm_mobile_logo">
                                    {isImageUploadLoading ? (
                                        <CircularProgress
                                            size="2rem"
                                            className={`${
                                                isImageUploadLoading
                                                    ? style.avatarProgress
                                                    : ''
                                            } circular-progress`}
                                        />
                                    ) : null}
                                    <Avatar
                                        alt="Profile"
                                        src={uploadedImage?.firm_mobile_logo}
                                        className={`${style.avatar} ${
                                            isImageUploadLoading
                                                ? style.avatarOpacity
                                                : ''
                                        }`}
                                    />
                                </label>
                                <label
                                    htmlFor="icon-button-file-firm_mobile_logo"
                                    className={style.btnUploadLabel}
                                >
                                    <Button
                                        color="primary"
                                        className={`${style.btnUpload} my-3`}
                                    >
                                        {`${
                                            uploadedImage?.firm_mobile_logo
                                                ? 'Change'
                                                : 'Upload'
                                        } firm mobile logo`}
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item xs={12} sm={6} className="py-1">
                                <Controller
                                    name="label"
                                    as={
                                        <RenderTextField
                                            id="label"
                                            inputContainerClassName={'w-100'}
                                            label="Company name *"
                                        />
                                    }
                                    control={control}
                                    defaultValue={null}
                                    rules={{
                                        required: true
                                    }}
                                />
                                <FormHelperText
                                    error={true}
                                    className={
                                        errors?.label ? 'visible' : 'invisible'
                                    }
                                >
                                    Firm name is mandatory.
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6} className="py-1">
                                <Controller
                                    name="website"
                                    as={
                                        <RenderTextField
                                            id="website"
                                            inputContainerClassName={'w-100'}
                                            label="Website *"
                                        />
                                    }
                                    control={control}
                                    defaultValue={null}
                                />
                                <FormHelperText
                                    error={true}
                                    className={
                                        errors?.website
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    {errors?.website && 'Website is mandatory.'}
                                    {errors?.isWebsiteInValid &&
                                        'Invalid website url.'}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12} className="py-1">
                                <RenderSelectField
                                    label="Type*"
                                    id="type"
                                    inputId="select-type"
                                    value={type}
                                    dropdownListHeight={250}
                                    onChange={handleToggleType}
                                    dataArray={FirmsTypeList}
                                />
                            </Grid>
                            {type === ACCOUNT_TYPE.ECOSYSTEM ? (
                                <Grid item xs={12} sm={6} className="py-1">
                                    <RenderSelectField
                                        label="Ecosystem Type*"
                                        inputId="select-ecosystem-type"
                                        value={ecosystem_type}
                                        dropdownListHeight={250}
                                        onChange={handleEcosystemType}
                                        dataArray={ecosystemTypeList}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid className="mt-5">
                            <Controller
                                name={
                                    isStartup
                                        ? 'last_funding_stage'
                                        : 'investor_type'
                                }
                                render={({ onChange, value }) => (
                                    <div className="w-100">
                                        <RenderAutocompleteTextField
                                            value={firm_type}
                                            label={
                                                isStartup
                                                    ? 'What was your last funding stage'
                                                    : `How do you classify your firm (up to ${MAX_FIRM_TYPE_TAGS_SIZE})? `
                                            }
                                            inputAutoComplete="off"
                                            disableCloseOnSelect={!smallScreen}
                                            size="small"
                                            onChange={(e, data) => {
                                                onChange(data);
                                                handleFirmTypeChange(data);
                                            }}
                                            options={
                                                firmTypeSize <
                                                (isStartup
                                                    ? MAX_STARTUP_TYPE_TAGS_SIZE
                                                    : MAX_FIRM_TYPE_TAGS_SIZE)
                                                    ? isStartup
                                                        ? STAGE_LIST
                                                        : FIRM_TYPE_LIST
                                                    : []
                                            }
                                            inputPlaceholder={
                                                isStartup
                                                    ? 'Search stage'
                                                    : 'Search type of firm'
                                            }
                                        />
                                    </div>
                                )}
                                control={control}
                                inputRef={register}
                            />
                            <FormHelperText
                                error={true}
                                className={
                                    errors?.[
                                        isStartup
                                            ? 'last_funding_stage'
                                            : 'investor_type'
                                    ]
                                        ? 'visible'
                                        : 'invisible'
                                }
                            >
                                Firm type is mandatory.
                            </FormHelperText>
                        </Grid>
                        {!isStartup ? (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="investments_type"
                                        render={({ onChange, value }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="investments_type"
                                                    value={investmentsType}
                                                    label="Investing in "
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    size="small"
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleInvestmentsTypeChange(
                                                            data
                                                        );
                                                    }}
                                                    options={
                                                        investmentsTypeList
                                                    }
                                                    inputPlaceholder="Search investment type"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        inputRef={register}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.investments_type
                                                ? 'visible'
                                                : 'invisible'
                                        }
                                    >
                                        Investments type is mandatory.
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                        ) : null}

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {fields.map((item, index) => {
                                    return (
                                        <div key={item?.id}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6}>
                                                    <Controller
                                                        name={`investor_profile[${index}].type`}
                                                        render={({
                                                            onChange,
                                                            value
                                                        }) => {
                                                            return (
                                                                <div className="w-100">
                                                                    <RenderCreatableAutocomplete
                                                                        id={`investor_profile[${index}].type`}
                                                                        value={
                                                                            value ||
                                                                            []
                                                                        }
                                                                        label={`Social profile ${
                                                                            index +
                                                                            1
                                                                        }`}
                                                                        inputAutoComplete="off"
                                                                        options={
                                                                            SOCIAL_MEDIA_LIST
                                                                        }
                                                                        disableCloseOnSelect={
                                                                            false
                                                                        }
                                                                        onBlur={(
                                                                            e: any
                                                                        ) => {
                                                                            const value =
                                                                                e
                                                                                    ?.target
                                                                                    ?.value;
                                                                            onChange(
                                                                                value
                                                                            );
                                                                        }}
                                                                        onChange={(
                                                                            e,
                                                                            data
                                                                        ) =>
                                                                            onChange(
                                                                                data
                                                                            )
                                                                        }
                                                                        inputPlaceholder="Search or Add Social Platform"
                                                                    />
                                                                </div>
                                                            );
                                                        }}
                                                        control={control}
                                                        defaultValue={
                                                            item.type || null
                                                        }
                                                        onChange={([
                                                            event,
                                                            data
                                                        ]) => {
                                                            return data;
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Controller
                                                        name={`investor_profile[${index}].profile_url`}
                                                        as={
                                                            <RenderTextField
                                                                id={`investor_profile[${index}].profile_url`}
                                                                placeholder={
                                                                    'www.newnex.io'
                                                                }
                                                                label={`Link/URL ${
                                                                    index + 1
                                                                }`}
                                                            />
                                                        }
                                                        control={control}
                                                        defaultValue={
                                                            item.profile_url ||
                                                            null
                                                        }
                                                    />
                                                    {validateLink(index)}
                                                </Grid>
                                            </Grid>
                                            {fields?.length > 1 ? (
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            color="secondary"
                                                            variant="text"
                                                            type="button"
                                                            className="float-right"
                                                            size="small"
                                                            onClick={() => {
                                                                remove(index);
                                                            }}
                                                        >
                                                            <Delete className="mr-2" />{' '}
                                                            Delete
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ) : null}
                                        </div>
                                    );
                                })}
                                <FormHelperText
                                    error={true}
                                    className={
                                        errors?.investor_profile &&
                                        errors?.investor_profile.length
                                            ? 'visible'
                                            : 'invisible'
                                    }
                                >
                                    Min. 1 valid Social profile is mandatory.
                                </FormHelperText>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className="mb-3">
                            <Grid item xs={12} className="mt-3">
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    type="button"
                                    disabled={fields?.length >= 10}
                                    className="float-right"
                                    size="small"
                                    onClick={() => {
                                        append({ type: '', profile_url: '' });
                                    }}
                                >
                                    <Add className="mr-2" />
                                    Add new
                                </Button>
                            </Grid>
                        </Grid>
                        <div className="mt-5">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Controller
                                        name="investment_class"
                                        render={({ onChange, value }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="investment_class"
                                                    value={investment_class}
                                                    label={`${
                                                        isStartup
                                                            ? 'Startup type'
                                                            : 'Preferred Asset Classes'
                                                    } `}
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    size="small"
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleInvestmentChange(
                                                            data
                                                        );
                                                    }}
                                                    options={startupType}
                                                    inputPlaceholder="Search investment assets"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.investment_class
                                                ? 'visible'
                                                : 'invisible'
                                        }
                                    >
                                        Preferred Investment Asset Classes is
                                        mandatory.
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={6}>
                                    <Controller
                                        name="business_model"
                                        render={({ onChange, value }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="business_model"
                                                    value={business_model}
                                                    label={`${
                                                        isStartup
                                                            ? 'Business Model'
                                                            : 'Preferred Business Models'
                                                    } `}
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    size="small"
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleBusinessModelChange(
                                                            data
                                                        );
                                                    }}
                                                    options={businessModel}
                                                    inputPlaceholder="Search business model"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.business_model
                                                ? 'visible'
                                                : 'invisible'
                                        }
                                    >
                                        Preferred Business Models is mandatory.
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="preferred_sector"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="preferred_sector"
                                                    value={preferred_sector}
                                                    label={`${
                                                        isStartup
                                                            ? 'Startup Sector'
                                                            : 'Preferred Sectors'
                                                    }`}
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    size="small"
                                                    options={sectorsList}
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleSectorsChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search sectors"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.preferred_sector
                                                ? 'visible'
                                                : 'invisible'
                                        }
                                    >
                                        Preferred preferred_sector is mandatory.
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="preferred_stage"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    value={preferred_stage}
                                                    label={
                                                        <div className="d-flex position-relative">
                                                            <span>
                                                                {isStartup
                                                                    ? 'Currently fundraising'
                                                                    : 'Preferred Stages'}
                                                            </span>
                                                            {isStartup ? (
                                                                <InputLabel
                                                                    shrink
                                                                    htmlFor="price"
                                                                    className="position-absolute right-0"
                                                                >
                                                                    <FormControlLabel
                                                                        label="Is Not Fundraising"
                                                                        className={`position-absolute w200 right-0 ${style.top13} ${style.w200}`}
                                                                        labelPlacement="start"
                                                                        control={
                                                                            <RenderCheckboxComponent
                                                                                id={`is_not_fundraising`}
                                                                                name={`is_not_fundraising`}
                                                                                classes={{
                                                                                    root: style.checkboxHeight
                                                                                }}
                                                                                checked={
                                                                                    is_not_fundraising
                                                                                }
                                                                                onChange={(
                                                                                    e: any
                                                                                ) => {
                                                                                    toggle();
                                                                                }}
                                                                                color="secondary"
                                                                            />
                                                                        }
                                                                    />
                                                                </InputLabel>
                                                            ) : null}
                                                        </div>
                                                    }
                                                    disabled={
                                                        is_not_fundraising
                                                    }
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    size="small"
                                                    options={
                                                        CURRENTLY_RAISING_LIST
                                                    }
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleCurrentFundingStageChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search stages"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={[]}
                                    />
                                    {!isStartup ? (
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.preferred_stage
                                                    ? 'visible'
                                                    : 'invisible'
                                            }
                                        >
                                            Target Stages is mandatory.
                                        </FormHelperText>
                                    ) : null}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="sdg_sectors"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="sdg_sectors"
                                                    value={sdgGoals}
                                                    label={
                                                        <span>
                                                            SDG Goals{' '}
                                                            <InfoIconComponent />
                                                        </span>
                                                    }
                                                    size="small"
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    options={sdgGoalsList}
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleSdgGoalsChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search sdg goals"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={'invisible'}
                                    >
                                        &nbsp;
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <Controller
                                        name="regions"
                                        render={({ onChange, value }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="regions"
                                                    value={regions}
                                                    label={
                                                        isStartup
                                                            ? 'Active Regions'
                                                            : 'Preferred Regions'
                                                    }
                                                    size="small"
                                                    inputAutoComplete="off"
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleRegionsChange(
                                                            data
                                                        );
                                                    }}
                                                    options={regionsList}
                                                    inputPlaceholder="Search regions"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.regions
                                                ? 'visible'
                                                : 'invisible'
                                        }
                                    >
                                        Preferred Regions is mandatory.
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            {regions && regions.length ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="investment_geos"
                                            render={({ onChange, value }) => (
                                                <div className="w-100">
                                                    <RenderAutocompleteTextField
                                                        id="investment_geos"
                                                        value={value}
                                                        label={`${
                                                            isStartup
                                                                ? 'Startup active geographies'
                                                                : 'Preferred Geographies'
                                                        }`}
                                                        inputAutoComplete="off"
                                                        disableCloseOnSelect={
                                                            !smallScreen
                                                        }
                                                        size="small"
                                                        onChange={(e, data) => {
                                                            onChange(data);
                                                        }}
                                                        options={
                                                            selectedOpsGeosList
                                                        }
                                                        inputPlaceholder="Search Geographies to invest in"
                                                    />
                                                </div>
                                            )}
                                            control={control}
                                        />
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.investment_geos
                                                    ? 'visible'
                                                    : 'invisible'
                                            }
                                        >
                                            Preferred Geographies is mandatory.
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            ) : null}

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="min_ticket_size"
                                        as={
                                            <RenderMaskedTextField
                                                id="min_ticket_size"
                                                name="min_ticket_size"
                                                value={min}
                                                autoComplete="off"
                                                min={1}
                                                disabled={is_not_fundraising}
                                                error={min && min < 0}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label={`Min ${
                                                    isStartup
                                                        ? 'round'
                                                        : 'investment'
                                                } size (USD) ${
                                                    isStartup ? '' : ''
                                                }`}
                                            />
                                        }
                                        defaultValue={null}
                                        control={control}
                                    />
                                    {!isStartup ? (
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.min_ticket_size
                                                    ? 'visible'
                                                    : 'invisible'
                                            }
                                        >
                                            {errors?.min_ticket_size &&
                                                'Min Investment size is mandatory.'}
                                        </FormHelperText>
                                    ) : null}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name="max_ticket_size"
                                        as={
                                            <RenderMaskedTextField
                                                id="max_ticket_size"
                                                name="max_ticket_size"
                                                value={max}
                                                autoComplete="off"
                                                disabled={is_not_fundraising}
                                                min={1}
                                                max={100000000000}
                                                error={
                                                    max && max > 100000000000
                                                }
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label={`Max ${
                                                    isStartup
                                                        ? 'round'
                                                        : 'investment'
                                                } size (USD) ${
                                                    isStartup ? '' : ''
                                                }`}
                                            />
                                        }
                                        defaultValue={null}
                                        control={control}
                                    />
                                    {!isStartup ? (
                                        <FormHelperText
                                            error={true}
                                            className={
                                                errors?.max_ticket_size
                                                    ? 'visible'
                                                    : 'invisible'
                                            }
                                        >
                                            {errors?.max_ticket_size &&
                                                'Max Investment size is mandatory.'}
                                        </FormHelperText>
                                    ) : null}
                                </Grid>
                            </Grid>

                            {(min && min < 0) || (max && max > 100000000000) ? (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormHelperText
                                            error={true}
                                            className={
                                                min && min < 0
                                                    ? 'visible'
                                                    : 'invisible'
                                            }
                                        >
                                            Min should not be less than 0.1
                                        </FormHelperText>

                                        <FormHelperText
                                            error={true}
                                            className={
                                                max && max > 100000000000
                                                    ? 'visible'
                                                    : 'invisible'
                                            }
                                        >
                                            Max should not be greater than
                                            100,000,000,000
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </div>

                        <div className="mt-5 d-flex align-items-center justify-content-end">
                            <Button
                                onClick={handleCloseModal}
                                color="primary"
                                disabled={
                                    isUpdateLoading || isImageUploadLoading
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onSave}
                                disabled={
                                    isUpdateLoading || isImageUploadLoading
                                }
                                className={`fs-16 ml-5 text-inherit`}
                            >
                                {firm_id ? 'Update' : 'Add'}{' '}
                                {isUpdateLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : null}
                            </Button>
                        </div>
                    </form>
                )}
            </Grid>
        </Grid>
    );
};

export default connect(null, {
    GetInvestmentsTypeMaster,
    CommonDispatcher,
    GetOneFirm,
    AddFirm,
    UpdateFirm,
    UploadFirmLogo,
    GetStartupTypeMaster,
    GetFirmsClassify,
    GetBusinessModelMaster,
    GetSectors,
    GetStages,
    GetSdgGoals,
    GetRegions,
    GetEcoSystemType
})(AddEditFirm);
