import {
    Button,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    InputAdornment,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    withStyles,
    WithStyles
} from '@material-ui/core';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { CHANGE_USER_EMAIL_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import { ChangeUserEmail } from '../../../redux/Users/actions';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import { useForm } from 'react-hook-form';
export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
export const validate = (data) => {
    let errorRef: any = {};
    if (!data?.newEmail) {
        errorRef.newEmail = true;
    }
    if (!data?.admin_password) {
        errorRef.admin_password = true;
    }
    if (!data?.master_password) {
        errorRef.master_password = true;
    }
    return errorRef;
};
const UpdateUserEmail = ({ OpenModal, CommonDispatcher, ChangeUserEmail }) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const modal: any =
        useSelector((store: RootState) => store?.modal?.changeUserEmailModal) ||
        {};
    const { control } = useForm({
        mode: 'onChange',
        shouldUnregister: false
    });
    const handleClose = () => {
        OpenModal(CHANGE_USER_EMAIL_MODAL, { isOpen: false });
    };
    const [showPassword, setShowPassword] = useState(false);
    const [showMasterPassword, setShowMasterPassword] = useState(false);

    const [email, setEmail] = useState(modal?.data?.selectedUser?.email);
    const [newEmail, setNewEmail] = useState('');

    const [admin_password, setAdminPassword] = useState(null);
    const [master_password, setMasterPassword] = useState('');
    const [errors, setError] = useStateRef({}) as [any, any, any];
    const [isLoading, setIsLoading] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleToggleMasterPasswordVisibility = () => {
        setShowMasterPassword(!showMasterPassword);
    };
    const handleSubmit = (e = null) => {
        e && e.preventDefault();

        const err = validate({
            newEmail,
            admin_password,
            master_password
        });
        setError(err);

        let payload = {
            admin_password,
            master_password,
            email: newEmail,
            user_id: modal?.data?.selectedUser?.user_id
        };
        if (Object.keys(err).length) {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Please fill the mandatory fields.',
                error: true,
                type: 'error',
                variant: 'filled'
            });
        } else {
            setIsLoading(true);
            ChangeUserEmail(token, payload).then((res) => {
                if (res?.type === 'success') {
                    setIsLoading(false);
                    CommonDispatcher(CHANGE_USER_EMAIL_MODAL, {
                        isOpen: false
                    });
                    modal?.getData({ page: 1 });

                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            res.response.message ||
                            'Password updated successfully.'
                    });
                } else if (
                    res?.type === 'failed' &&
                    res?.response?.data?.error
                ) {
                    setIsLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            res?.response?.data?.error ||
                            'Failed to update the Password.',
                        hideAlert: true,
                        error: true
                    });
                } else {
                    setIsLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Failed to update the Password.',
                        hideAlert: true,
                        error: true
                    });
                }
            });
        }
    };
    return (
        <Dialog
            className="w-100"
            maxWidth={modal?.config?.modalSize || 'xs'}
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                <div className="w-100 d-flex">{modal?.title}</div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RenderTextField
                            id={'email'}
                            inputContainerClassName={'w-100'}
                            value={email}
                            autoFocus={true}
                            onChange={(newValue: any) =>
                                setEmail(newValue.target.value)
                            }
                            label={'Current Email'}
                            disabled={true}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RenderTextField
                            id={'email'}
                            value={newEmail}
                            autoFocus={true}
                            onChange={(newValue: any) =>
                                setNewEmail(newValue.target.value)
                            }
                            label={'New Email'}
                            autoComplete="off"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RenderTextField
                            type={showPassword ? 'text' : 'password'}
                            value={admin_password}
                            onChange={(newValue: any) =>
                                setAdminPassword(newValue.target.value)
                            }
                            label={'Admin Password *'}
                            autoComplete="off"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleTogglePasswordVisibility}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <RenderTextField
                            type={showMasterPassword ? 'text' : 'password'}
                            value={master_password}
                            onChange={(newValue: any) =>
                                setMasterPassword(newValue.target.value)
                            }
                            label={'Master Password *'}
                            autoComplete="off"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={
                                            handleToggleMasterPasswordVisibility
                                        }
                                        edge="end"
                                    >
                                        {showMasterPassword ? (
                                            <VisibilityOff />
                                        ) : (
                                            <Visibility />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    color="primary"
                    className="mb-4"
                >
                    Change
                    {isLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress"
                        />
                    ) : null}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    CommonDispatcher,
    ChangeUserEmail
})(UpdateUserEmail);
