import * as Actions from './actionTypes';
import axios from 'axios';
import { API_PATH } from '../../constants';

export const GetEventsList = (token: string, data) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: `${API_PATH}/admin/event`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type: Actions.SET_EVENTS_DATA,
                payload
            });
            return { type: 'success', response: payload };
        } catch (e) {
            throw e;
        }
    };
};

export const GetOneEvent = (token: string, post_id: number) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/event/${post_id}`,
                headers: { Authorization: token }
            };
            return (await axios(config as any))?.data?.data;
        } catch (e) {
            throw e;
        }
    };
};

export const UpdateEvents = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'put',
                data,
                url: `${API_PATH}/admin/event/update`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (e) {
            return { type: 'failed' };
        }
    };
};

export const DeleteEvent = (token: string, event_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data: { event_id },
                url: `${API_PATH}/admin/event/delete`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (e) {
            throw e;
        }
    };
};
