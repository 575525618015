import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { GetOneEvent } from '../../../redux/Events/actions';
import Tuple from '../../DetailView/Tuple';

function EventDetailPage({ GetOneEvent, match }) {
    const [isPageLoading, setPageLoading] = useState(false);
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [data, setData] = useState({} as any);
    const id = match?.params?.id;
    useEffect(() => {
        try {
            if (id) {
                setPageLoading(true);
                GetOneEvent(token, id).then((response) => {
                    setData(response);
                    setPageLoading(false);
                });
            }
        } catch (e) {
            console.log(e);
            setPageLoading(false);
        }
        // eslint-disable-next-line
    }, []);

    const makeLabel = (key: string) =>
        key
            .split('_')
            .join(' ')
            .split('-')
            .join(' ')
            .replace(/^\w/, (c) => c.toUpperCase());

    return (
        <div className="my-4 mx-0">
            {isPageLoading ? (
                <div className="mainLoader">
                    <div className="loader" />
                </div>
            ) : (
                Object.keys(data).map((key: string, i) => {
                    if (typeof data[key] === 'string')
                        return (
                            <Tuple
                                label={makeLabel(key)}
                                key={i}
                                value={data[key]}
                            />
                        );
                    else return null;
                })
            )}
        </div>
    );
}

export default connect(null, {
    GetOneEvent
})(EventDetailPage);
