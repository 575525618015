import { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, FormControl, Grid } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import style from './Style.module.scss';
import { connect } from 'react-redux';
import {
    CreateClient,
    GetClientConfigList,
    GetSingleClient,
    UpdateClient,
    UploadLogo
} from '../../../redux/ClientConfig/actions';
import { CommonDispatcher } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import { useCookies } from 'react-cookie';
import PhoneInput from 'react-phone-input-2';
import { PHONE_PREFERRED_COUNTRIES } from '../../../utils/constants';
import { getQuery, onDropCallback } from '../../../utils/common';
import useStateRef from 'react-usestateref';
import { ROWS_PER_PAGE } from '../../../constants';
import { useHistory } from 'react-router-dom';
import RenderToggleSwitch from '../../InputComponents/ToggleSwitch/RenderToggleSwitch';
let participantsList = [
    { title: 'Yes', value: true },
    { title: 'No', value: false }
];
const AddEditClient = ({
    page,
    client_id,
    setSelectedUser,
    handleCloseModal,
    GetClientConfigList,
    CreateClient,
    UpdateClient,
    GetSingleClient,
    CommonDispatcher,
    UploadLogo
}: any) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const query = getQuery(history);
    const type = query.get('type');
    const keyword = query.get('keyword');

    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const [client, setClient]: any = useState({});
    const [images, setImages, imagesRef] = useStateRef([]);
    const [progress, setProgress, progressRef] = useStateRef(0);
    const [isActive, setIsActive] = useState(false);
    const [maintenance_mode, setMaintenanceMode] = useState(
        participantsList?.[0]?.value
    );
    const handleParticipantsChange = (e, value) => {
        setMaintenanceMode(value);
    };

    const {
        control,
        reset,
        handleSubmit,
        formState: { isValid }
    } = useForm({
        mode: 'onChange',
        shouldUnregister: false
    });

    useEffect(() => {
        if (client_id) {
            setLoading(true);
            getClient();
        }
        return () => {
            setSelectedUser(null);
            setImages([]);
        };
    }, []);

    const getClient = () => {
        GetSingleClient(token, client_id).then((r) => {
            let data = { ...r?.response?.client };

            if (client_id) {
                data?.logo &&
                    data?.client_logo &&
                    setImages(
                        [
                            { client_logo: data?.client_logo, src: data?.logo }
                        ].map((d, i) => ({
                            id: i + 1,
                            src: d?.src,
                            client_logo: d?.client_logo
                        }))
                    );
                setMaintenanceMode(data?.maintenance_mode ? true : false);
            } else {
                data?.logo && setImages(data?.images);
            }
            setClient(data);
            reset(data);
            setLoading(false);
        });
    };

    const submitClientData = ({ logo, ...obj }) => {
        setIsCreateLoading(true);

        let formData = new FormData();
        if (client_id) {
            if (images && images?.length && images[0]?.src) {
                obj.client_logo = images[0]?.client_logo;
            }
            UpdateClient(token, {
                client_id: obj.client_id,
                name: obj?.name,
                email: obj.email,
                contact: obj.contact,
                url: obj.url,
                sub_domain: obj.sub_domain,
                max_group_members: obj.max_group_members,
                logo: obj.client_logo,
                maintenance_mode,
                maintenance_url: obj.maintenance_url
            }).then((r) => {
                if (images && images?.length && images[0]?.apiName) {
                    formData.append('client_id', client_id);
                    images.forEach((image: any) => {
                        formData.append(
                            'files',
                            image.file as any,
                            image.apiName
                        );
                    });
                    // UploadLogo(token, formData).then((r) => {
                    //     getClientData();
                    // });
                } else {
                    getClientData();
                }
            });
        } else {
            CreateClient(token, { ...obj, maintenance_mode }).then((r) => {
                if (images && images?.length && images[0]?.apiName) {
                    formData.append('client_id', r?.response?.client_id);
                    images.forEach((image: any) => {
                        formData.append(
                            'files',
                            image.file as any,
                            image.apiName
                        );
                    });
                    // UploadLogo(token, formData).then((r) => {
                    //     getClientData();
                    // });
                } else {
                    getClientData();
                }
            });
        }
    };

    const getClientData = () => {
        GetClientConfigList(token, {
            keyword,
            type,
            page: page + 1,
            size: ROWS_PER_PAGE
        }).then((r) => {
            setIsCreateLoading(false);
            handleCloseModal();
        });
    };

    const handleDeleteImage = (id) => {
        if (id) {
            setImages(images.filter((d) => d.id !== id));
        }
    };
    const onDrop = useCallback(async (files) => {
        if (!(imagesRef?.current && imagesRef?.current?.length)) {
            onDropCallback(
                files,
                imagesRef,
                setProgress,
                progressRef,
                setIsActive,
                setImages
            );
        } else {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Only 1 image is allowed to upload'
            });
        }
    }, []);

    return isLoading ? (
        <div className="mainLoader">
            <div className="loader" />
        </div>
    ) : (
        <form onSubmit={handleSubmit(submitClientData)}>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <Controller
                        name="logo"
                        as={
                            <DragAndDrop
                                onDrop={onDrop}
                                accept={'image/png, image/jpg, image/jpeg'}
                                images={images}
                                isLoading={isActive}
                                deleteImage={handleDeleteImage}
                                maxFiles={1}
                                multiple={false}
                                label="Upload logo (1 image)"
                            />
                        }
                        control={control}
                        defaultValue={''}
                        rules={{
                            required: false
                        }}
                    />
                </Grid> */}

                <Grid item xs={12} className="mt-2">
                    <Controller
                        name="name"
                        control={control}
                        as={
                            <RenderTextField
                                label="Name"
                                autoFocus={true}
                                placeholder="Enter name"
                            />
                        }
                        defaultValue={client?.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="email"
                        control={control}
                        as={
                            <RenderTextField
                                label="Email"
                                autoFocus={true}
                                placeholder="Enter email"
                            />
                        }
                        defaultValue={client?.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="url"
                        control={control}
                        as={
                            <RenderTextField
                                label="Website"
                                autoFocus={true}
                                placeholder="Enter website url"
                            />
                        }
                        defaultValue={client?.url}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="sub_domain"
                        control={control}
                        as={
                            <RenderTextField
                                label="Sub Domain"
                                autoFocus={true}
                                placeholder="Enter Sub Domain"
                            />
                        }
                        defaultValue={client?.sub_domain}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        className="w-100"
                        inputMode="tel"
                    >
                        <label>
                            Phone
                            <Controller
                                name="contact"
                                as={
                                    <PhoneInput
                                        country={PHONE_PREFERRED_COUNTRIES[0]}
                                        placeholder="Phone"
                                        inputClass={style.phoneInput}
                                        preferredCountries={
                                            PHONE_PREFERRED_COUNTRIES
                                        }
                                        preserveOrder={[
                                            'onlyCountries',
                                            'preferredCountries'
                                        ]}
                                    />
                                }
                                control={control}
                                defaultValue={client?.contact}
                            />
                        </label>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="max_group_members"
                        control={control}
                        as={
                            <RenderTextField
                                label="Max Group Member"
                                autoFocus={true}
                                placeholder="Max Group Member"
                                type="number"
                            />
                        }
                        defaultValue={client?.sub_domain}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RenderToggleSwitch
                        id="maintenance_mode"
                        value={maintenance_mode}
                        label="Maintenance mode"
                        buttonClassName="w-100"
                        onChange={handleParticipantsChange}
                        dataArray={participantsList}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="maintenance_url"
                        control={control}
                        as={
                            <RenderTextField
                                label="Maintenance url"
                                autoFocus={true}
                                placeholder="Maintenance url"
                            />
                        }
                        defaultValue={client?.maintenance_url}
                    />
                </Grid>
            </Grid>

            <div className="d-flex align-items-center justify-content-center mt-5">
                <Button
                    onClick={handleCloseModal}
                    color="secondary"
                    className="text-inherit"
                    autoFocus
                >
                    Cancel
                </Button>

                <Button
                    className="ml-3 text-inherit"
                    color={'primary'}
                    type="submit"
                    variant="contained"
                    disabled={
                        !isValid || isLoading || isActive || isCreateLoading
                    }
                >
                    Submit
                    {isCreateLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress"
                        />
                    ) : null}
                </Button>
            </div>
        </form>
    );
};

export default connect(null, {
    GetClientConfigList,
    GetSingleClient,
    CreateClient,
    UpdateClient,
    CommonDispatcher,
    UploadLogo
})(AddEditClient);
