export const SET_BUSINESS_LISTINGS_DATA = 'SET_BUSINESS_LISTINGS_DATA';
export const FIRMS_ALL = 'ALL';
export const FIRMS_ACTIVE = 'FIRMS_ACTIVE';
export const FIRMS_ACTIVE_COUNT = 'FIRMS_ACTIVE_COUNT';
export const FIRMS_INACTIVE_COUNT = 'FIRMS_INACTIVE_COUNT';
export const FIRMS_ALL_COUNT = 'FIRMS_ALL_COUNT';



export const FIRMS_IN_ACTIVE = 'FIRMS_IN_ACTIVE';

export const DELETE_CONTACTS_POSTS_TAGS_DATA =
    'DELETE_CONTACTS_POSTS_TAGS_DATA';
export const UPDATE_CONTACTS_POSTS_TAGS_DATA =
    'UPDATE_CONTACTS_POSTS_TAGS_DATA';
export const UPDATE_SELECTED_TAG_COUNT = 'UPDATE_SELECTED_TAG_COUNT';
export const UPDATE_SINGLE_CONTACT_TAG = 'UPDATE_SINGLE_CONTACT_TAG';
export const UPDATE_ADDRESS_BOOK_TAGS = 'UPDATE_ADDRESS_BOOK_TAGS';
export const SET_ADDRESS_BOOK_TAGS = 'SET_ADDRESS_BOOK_TAGS';
