import * as Actions from './actionTypes';
import {
    DEALS_DECLINED,
    DEALS_OTHERS,
    DEALS_PINEGRAY,
    DEALS_UN_APPROVED
} from './actionTypes';
import axios from 'axios';
import { API_PATH, DealsTab } from '../../constants';

export const GetDealsList = (
    token: string,
    data: any,
    isFundFundraise = false
) => {
    let type = Actions.DEALS_ALL;
    switch (data?.tab) {
        case DealsTab.TYPE_UN_APPROVED:
            type = DEALS_UN_APPROVED;
            break;
        case DealsTab.TYPE_PINEGRAY:
            type = DEALS_PINEGRAY;
            break;
        case DealsTab.TYPE_OTHERS:
            type = DEALS_OTHERS;
            break;
        case DealsTab.TYPE_DECLINED:
            type = DEALS_DECLINED;
            break;
    }
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                url: isFundFundraise
                    ? `${API_PATH}/admin/funds`
                    : `${API_PATH}/admin/deals`,
                headers: {
                    Authorization: token
                },
                data
            };
            const payload = (await axios(config as any))?.data?.data;
            dispatch({
                type,
                payload
            });
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetSingleDeal = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/one-deal`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const SetPreMoneyAccess = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/set-pre-money-access`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateInterest = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-interest`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const CreateInterest = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/create-interest`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const SaveAndNotify = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/save-notify`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetCapTableData = (token: string, equity_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/cap-table?equity_id=${equity_id}`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetAllTeam = (token: string, equity_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'get',
                url: `${API_PATH}/admin/get-team/${equity_id}`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const AddTeam = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/add-team`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateTeam = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-team`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const DeleteTeam = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data,
                url: `${API_PATH}/admin/remove-team`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
export const DeleteDeal = (token: string, equity_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data: { equity_id },
                url: `${API_PATH}/admin/delete-deal`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const DeleteInterest = (token: string, interest_id: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'delete',
                data: { interest_id },
                url: `${API_PATH}/admin/delete-interest`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetDealFilters = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/get-filter`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const SaveFilters = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/save-filter`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetMatchingUsersCount = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/get-matching-users`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GetAccessUsersList = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/get-access-user-list`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const GrantAccess = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/set-access`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateDeal = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/update-deal`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};

export const UpdateDealOwnership = (token: string, data: any) => {
    return async (dispatch: any) => {
        try {
            const config = {
                method: 'post',
                data,
                url: `${API_PATH}/admin/updateDealOwnership`,
                headers: { Authorization: token }
            };
            const response = (await axios(config as any))?.data?.data;
            return { type: 'success', response };
        } catch (error) {
            return { type: 'failed', response: error?.response };
        }
    };
};
