import {
    Button,
    CircularProgress,
    IconButton,
    Popover
} from '@material-ui/core';
import {
    Clear,
    Close,
    DeleteOutline,
    LocalOffer,
    MoreHoriz
} from '@material-ui/icons';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect, useSelector } from 'react-redux';
import { CommonDispatcher } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import {
    DELETE_CONTACTS_POSTS_TAGS_DATA,
    UPDATE_CONTACTS_POSTS_TAGS_DATA,
    UPDATE_SINGLE_CONTACT_TAG
} from '../../../redux/Firms/actionTypes';
import {
    AttachContactWithTag,
    AttachDetachContactTags,
    DeleteAddressBookTag,
    DetachContactTags,
    GetAddressBookAllTags,
    UpdateAddressBookTag
} from '../../../redux/Firms/actions';
import { OPEN_ALERT_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import { TAGS_COLORS_LIST } from '../../../utils/common';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import style from './Style.module.scss';
import { useCookies } from 'react-cookie';

function TagsPopup({
    d: deal,
    CommonDispatcher,
    AttachDetachContactTags,
    DetachContactTags,
    GetAddressBookAllTags,
    AttachContactWithTag,
    DeleteAddressBookTag,
    UpdateAddressBookTag,
    label = null,
    filterByAllTags,
    filterTags
}: any) {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const id = `${deal?.address_book_id}-tags`;
    const [selectedTags, setSelectedTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [lastEditedTag, setLastEditedTag] = useState(null);
    const [selectedTagToEdit, setSelectedTagToEdit] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElEditTagPopup, setAnchorElEditTagPopup] =
        React.useState(null);
    const store = useSelector((store: RootState) => store);
    const tagsDataListing = store?.firms?.firm_tags;
    const addressBookTags = tagsDataListing?.tagsData;
    const open = Boolean(anchorEl);
    const openEditTagPopup = Boolean(anchorElEditTagPopup);
    const popUpId = open ? `${id}-popover` : undefined;

    const tagsData = addressBookTags;

    useEffect(() => {
        setSelectedTags(tagsData);
    }, [tagsData, searchKeyword]);

    const getAllTagsData = (data = {}, flag = false) => {
        setIsLoading(true);
        GetAddressBookAllTags(data, flag, token).then((x) => {
            setSelectedTags(x.response.tagsData);
            setIsLoading(false);
        });
    };

    const getRandomColor = useMemo(
        () =>
            TAGS_COLORS_LIST[
                Math.floor(Math.random() * TAGS_COLORS_LIST.length)
            ]?.color,
        [TAGS_COLORS_LIST]
    );

    const handleOpenPopover = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setIsLoading(false);
    };

    const handleClose = () => {
        getAllTagsData(
            {
                page_number: 1
            },
            false
        );
        setAnchorEl(null);
        setSearchKeyword('');
    };

    const handleOpenEditPopover = (event, tag) => {
        setAnchorElEditTagPopup(event.currentTarget);
        setSelectedTagToEdit(tag);
        setIsLoading(false);
    };

    const handleCloseEditPopover = () => {
        setAnchorElEditTagPopup(null);
    };

    const updateValueOnClose = () => {
        UpdateAddressBookTag(
            {
                id: selectedTagToEdit?.id,
                tag_text: lastEditedTag
            },
            token
        ).then(() => {
            getAllListingsData(null, {
                tag_text: lastEditedTag,
                id: selectedTagToEdit?.id
            });
            CommonDispatcher(UPDATE_SINGLE_CONTACT_TAG, {
                tag_text: lastEditedTag,
                id: selectedTagToEdit?.id
            });
        });
    };

    const handleSelect = (tag) => {
        if (deal?.firm_tags && deal?.firm_tags?.length) {
            const exists = deal?.firm_tags.some(
                (newTag) => newTag?.id === tag?.id
            );
            if (!exists) {
                let newTagsList = deal?.firm_tags;
                newTagsList.push(tag);
                handleAttachDetachContactTags(tag?.id, newTagsList, true);
            } else {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Tag already added to this contact',
                    hideAlert: true,
                    error: true
                });
            }
        } else {
            let newTagsList = deal?.firm_tags || [];
            newTagsList.push(tag);
            handleAttachDetachContactTags(tag?.id, newTagsList, true);
        }
    };

    const handleCreateNew = () => {
        if (deal?.firm_tags && deal?.firm_tags?.length) {
            const exists = deal?.firm_tags.some(
                (newTag) =>
                    newTag?.tag_text.toLowerCase() ===
                    searchKeyword.toLowerCase()
            );
            if (!exists) {
                createNewTag(searchKeyword);
            } else {
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Tag already added to this contact',
                    hideAlert: true,
                    error: true
                });
            }
        } else {
            createNewTag(searchKeyword);
        }
    };

    const handleClear = () => {
        setSearchKeyword('');
        getAllTagsData({ keyword: '', page_number: 1 }, false);
    };

    const addTagOnEnterKeyPress = (e) => {
        if (searchKeyword && e?.keyCode === 13) {
            handleCreateNew();
        }
    };

    const createNewTag = (tag_text) => {
        try {
            setIsLoading(true);
            AttachContactWithTag(
                {
                    firm_id: deal.firm_id || 136,
                    tag_text,
                    tag_color_code: getRandomColor
                },
                token
            ).then((response) => {
                if (response?.type === 'success') {
                    getAllListingsData(deal.firm_id, response?.response);
                    setIsLoading(false);
                    getAllTagsData({ keyword: '', page_number: 1 }, false);
                    setSearchKeyword('');
                    CommonDispatcher(OPEN_ALERT_MODAL, {
                        is_open: false
                    });
                } else if (
                    response?.type === 'error' &&
                    response?.response?.data?.error
                ) {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            response?.response?.data?.error ||
                            'Failed to add a tag',
                        hideAlert: true,
                        error: true
                    });
                    CommonDispatcher(OPEN_ALERT_MODAL, {
                        is_open: false
                    });
                }
            });
        } catch {
            clearFields();
        }
    };

    const handleDeleteTag = () => {
        CommonDispatcher(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: 'Delete?',
            description: 'Are you sure you want to delete this tag?',
            data: { disableBackdropClick: true },
            submitTitle: 'Delete',
            cancelTitle: 'No',
            callBack: async () => {
                DeleteAddressBookTag({ id: selectedTagToEdit?.id }, token)
                    .then((response) => {
                        if (response?.type === 'success') {
                            CommonDispatcher(DELETE_CONTACTS_POSTS_TAGS_DATA, {
                                tagsId: selectedTagToEdit?.id
                            });
                            CommonDispatcher(UPDATE_SINGLE_CONTACT_TAG, {
                                id: selectedTagToEdit?.id,
                                type: 'delete'
                            });
                            CommonDispatcher(OPEN_ALERT_MODAL, {
                                is_open: false
                            });
                        } else if (
                            response?.type === 'error' &&
                            response?.response?.data?.error
                        ) {
                            CommonDispatcher(SHOW_SNACKBAR, {
                                isShowing: true,
                                message:
                                    response?.response?.data?.error ||
                                    'Failed to delete ag',
                                hideAlert: true,
                                error: true
                            });
                            CommonDispatcher(OPEN_ALERT_MODAL, {
                                is_open: false
                            });
                        }
                    })
                    .finally(() => {
                        clearFields();
                    });
            }
        });
    };

    const handleUpdateColor = (id, color) => {
        setIsLoading(true);
        UpdateAddressBookTag(
            {
                id,
                tag_color_code: color?.color
            },
            token
        ).then(() => {
            getAllListingsData(null, {
                tag_color_code: color?.color,
                id: selectedTagToEdit?.id
            });
            CommonDispatcher(UPDATE_SINGLE_CONTACT_TAG, {
                tag_color_code: color?.color,
                tag_text: selectedTagToEdit?.tag_text,
                id: selectedTagToEdit?.id
            });

            setIsLoading(false);
        });
    };

    const onEditTagChange = (e) => {
        setLastEditedTag(e?.target?.value);
    };

    const getAllListingsData = (firm_id = null, tagsObj = null) => {
        try {
            CommonDispatcher(UPDATE_CONTACTS_POSTS_TAGS_DATA, {
                firm_id,
                tagsObj
            });
        } catch {
            clearFields();
        }
    };

    const clearFields = () => {
        handleCloseEditPopover();
        if (id) {
            const ele = document.getElementById(id);
            if (ele) ele.focus();
        }
        // setSearchKeyword('');
        setLastEditedTag(null);
        CommonDispatcher(OPEN_ALERT_MODAL, {
            is_open: false
        });
        setIsLoading(false);
    };

    const handleAttachDetachContactTags = (tagId, tags, isNew = false) => {
        AttachDetachContactTags(
            {
                address_book_id: deal?.address_book_id,
                tags_id: tags.map((b) => b?.id),
                firm_id: deal?.firm_id
            },
            token
        )
            .then((response) => {
                if (response?.type === 'success') {
                    getAllListingsData(deal?.firm_id, {
                        tags,
                        type: 'Attach'
                    });
                    // CommonDispatcher(UPDATE_SELECTED_TAG_COUNT, {
                    //     id: tagId,
                    //     type: 'Attach'
                    // });
                } else if (
                    response?.type === 'error' &&
                    response?.response?.data?.error
                ) {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            response?.response?.data?.error ||
                            'Failed to delete tag',
                        hideAlert: true,
                        error: true
                    });
                    CommonDispatcher(OPEN_ALERT_MODAL, {
                        is_open: false
                    });
                }
            })
            .finally(() => {
                clearFields();
            });
    };
    const DetachContactTagsList = (tagId, tags, isNew = false) => {
        DetachContactTags(
            {
                address_book_id: deal?.address_book_id,
                tags_id: tags.filter((a) => a?.id !== tagId).map((b) => b?.id),
                firm_id: deal?.firm_id
            },
            token
        )
            .then((response) => {
                if (response?.type === 'success') {
                    getAllListingsData(deal?.firm_id, {
                        tags: tags.filter((a) => a?.id !== tagId),
                        type: 'delete'
                    });
                    // CommonDispatcher(UPDATE_SELECTED_TAG_COUNT, {
                    //     id: tagId,
                    //     type: 'delete'
                    // });
                } else if (
                    response?.type === 'error' &&
                    response?.response?.data?.error
                ) {
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message:
                            response?.response?.data?.error ||
                            'Failed to delete tag',
                        hideAlert: true,
                        error: true
                    });
                    CommonDispatcher(OPEN_ALERT_MODAL, {
                        is_open: false
                    });
                }
            })
            .finally(() => {
                clearFields();
            });
    };

    const onUpdateTagText = (e) => {
        if (e?.keyCode === 13 && lastEditedTag) {
            updateValueOnClose();
        }
    };

    const delayedQuery = useCallback(
        _.debounce(
            (keyword) => getAllTagsData({ keyword, page_number: 1 }, false),
            500
        ),
        []
    );

    const onChange = (e) => {
        const value = e?.target?.value;
        setSearchKeyword(value);
        delayedQuery(e?.target?.value);
    };

    const handleDeleteSingleTag = (e, tag) => {
        e.stopPropagation();
        CommonDispatcher(OPEN_ALERT_MODAL, {
            isOpen: true,
            title: 'Delete?',
            description: 'Are you sure you want to delete this tag?',
            data: { disableBackdropClick: true },
            submitTitle: 'Delete',
            cancelTitle: 'No',
            callBack: async () => {
                DetachContactTagsList(tag?.id, deal?.firm_tags, false);
            }
        });
    };

    const renderLoader = () => {
        return isLoading ? (
            <span className={style.loader}>
                <CircularProgress size="1.3rem" className="circular-progress" />
            </span>
        ) : (
            <>
                {searchKeyword ? (
                    <span className={style.close}>
                        <IconButton
                            aria-label="close"
                            className="p-1"
                            onClick={handleClear}
                        >
                            <Close />
                        </IconButton>
                    </span>
                ) : null}
            </>
        );
    };

    const editTagPopup = () => {
        return (
            <Popover
                id={`${popUpId}-edit`}
                open={openEditTagPopup}
                anchorEl={anchorElEditTagPopup}
                onClose={handleCloseEditPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div className={style.editPopover}>
                    {renderLoader()}
                    <RenderTextField
                        type="text"
                        variant="standard"
                        placeholder="Search for an option"
                        autoFocus={true}
                        defaultValue={selectedTagToEdit?.tag_text}
                        underLineClassName={`${style.underLineClassName} ${
                            selectedTags && !selectedTags.length
                                ? style.underLineClassNameWithoutPadding
                                : ''
                        }`}
                        onChange={onEditTagChange}
                        onKeyDown={onUpdateTagText}
                        autoComplete="off"
                    />
                    <div
                        className={`${style.headerTitle} colorGray mt-1 py-2 px-4`}
                    >
                        COLORS
                    </div>
                    {TAGS_COLORS_LIST.map((color) => {
                        return (
                            <div
                                key={color?.color}
                                className={`d-flex align-items-center py-2 px-4 cursor-pointer ${style.hoverItem}`}
                                onClick={() =>
                                    handleUpdateColor(
                                        selectedTagToEdit?.id,
                                        color
                                    )
                                }
                            >
                                <span
                                    className="mr-3"
                                    style={{
                                        backgroundColor: color?.color,
                                        height: '18px',
                                        width: '18px',
                                        borderRadius: '4px'
                                    }}
                                />
                                <span>{color?.colorName}</span>
                            </div>
                        );
                    })}

                    <div
                        onClick={handleDeleteTag}
                        className={`border-top text-inherit mt-2 py-2 px-4 cursor-pointer ${style.hoverItem} ${style.deleteButton}`}
                    >
                        <IconButton
                            aria-label="Delete"
                            color="inherit"
                            className="p-0 mr-2"
                        >
                            <DeleteOutline />
                        </IconButton>
                        Delete
                    </div>
                </div>
            </Popover>
        );
    };

    const data =
        deal?.firm_tags && deal?.firm_tags.length ? deal?.firm_tags : [];
    if (data && data.length) {
        data.sort((a, b) => a?.id - b?.id);
    }

    const loadData = () => {
        getAllTagsData(
            {
                page_number:
                    tagsDataListing?.current_page < tagsDataListing?.total_pages
                        ? tagsDataListing?.current_page + 1
                        : tagsDataListing?.total_pages
            },
            true
        );
    };
    const renderTags = () => (
        <div
            className={style.tagsContainer}
            onClick={(e) => handleOpenPopover(e)}
        >
            {data.map((tag) => {
                return (
                    <div
                        key={`selected-${tag?.id}`}
                        className={style.tag}
                        style={{
                            backgroundColor: tag?.tag_color_code
                        }}
                    >
                        {tag?.tag_text}
                        <IconButton
                            aria-label="Clear"
                            color="inherit"
                            className={`p-0 ml-1 ${style.icon}`}
                            onClick={(e) => handleDeleteSingleTag(e, tag)}
                        >
                            <Clear />
                        </IconButton>
                    </div>
                );
            })}
        </div>
    );

    return (
        <div className={style.container}>
            {data && data?.length ? renderTags() : null}

            <Button
                variant="text"
                onClick={handleOpenPopover}
                size="small"
                className={`bgColorLightGray border text-inherit p-1 ${style.addTag}`}
                // disabled={filterByAllTags && tagsData && !tagsData.length}
            >
                <IconButton
                    color="inherit"
                    className={`p-0 mr-2 ${style.icon}`}
                    size="small"
                >
                    <LocalOffer />
                </IconButton>
                {label ? label : 'Add tag'}
            </Button>
            <Popover
                id={popUpId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div className={style.popover}>
                    {renderLoader()}
                    <>
                        <RenderTextField
                            id={id}
                            type="text"
                            variant="standard"
                            autoFocus={true}
                            onKeyDown={addTagOnEnterKeyPress}
                            underLineClassName={`${style.underLineClassName} ${
                                selectedTags && !selectedTags.length
                                    ? style.underLineClassNameWithoutPadding
                                    : ''
                            }`}
                            placeholder="Search for an option"
                            value={searchKeyword}
                            onChange={onChange}
                            autoComplete="off"
                        />
                        <div className={`mt-2 colorGray ${style.headerTitle}`}>
                            Select an option or create one
                        </div>
                    </>

                    <div
                        id={'POPUP_INFINITE_SCROLL_TARGET'}
                        style={{
                            height: 300,
                            overflow: 'auto'
                        }}
                    >
                        <InfiniteScroll
                            next={loadData}
                            dataLength={selectedTags && selectedTags.length}
                            hasMore={
                                tagsDataListing?.current_page <
                                tagsDataListing?.total_pages
                            }
                            loader={
                                isLoading ? (
                                    <div className="pb-5">
                                        <div className="loader" />
                                    </div>
                                ) : null
                            }
                            scrollableTarget={'POPUP_INFINITE_SCROLL_TARGET'}
                        >
                            <ul className={style.tagsList}>
                                {selectedTags && selectedTags.length
                                    ? selectedTags.map((tag) => {
                                          return (
                                              <li
                                                  key={`${tag?.id}-list`}
                                                  className={`d-flex align-items-center justify-content-between cursor-pointer ${
                                                      isLoading
                                                          ? 'pointer-events-none'
                                                          : ''
                                                  }`}
                                              >
                                                  <span
                                                      className="flex-grow-1"
                                                      onClick={() => {
                                                          if (filterByAllTags) {
                                                              filterTags(
                                                                  tag?.id
                                                              );
                                                          } else {
                                                              !isLoading &&
                                                                  handleSelect(
                                                                      tag
                                                                  );
                                                          }
                                                          handleClose();
                                                      }}
                                                  >
                                                      <span
                                                          className={`${style.tag} d-unset border`}
                                                          style={{
                                                              backgroundColor:
                                                                  tag?.tag_color_code
                                                          }}
                                                      >
                                                          {`${tag?.tag_text} ${
                                                              tag?.tag_firm_count
                                                                  ? `(${tag?.tag_firm_count})`
                                                                  : ''
                                                          }`}
                                                      </span>
                                                  </span>
                                                  {!filterByAllTags ? (
                                                      <IconButton
                                                          aria-label="MoreHoriz"
                                                          color="inherit"
                                                          className="p-1"
                                                          onClick={(e) =>
                                                              !isLoading &&
                                                              handleOpenEditPopover(
                                                                  e,
                                                                  tag
                                                              )
                                                          }
                                                      >
                                                          <MoreHoriz />
                                                      </IconButton>
                                                  ) : null}
                                              </li>
                                          );
                                      })
                                    : ''}
                                {tagsData &&
                                !tagsData.length &&
                                searchKeyword ? (
                                    <li
                                        onClick={handleCreateNew}
                                        className="cursor-pointer justify-content-start py-2 fs-14"
                                    >
                                        Create
                                        <span className="py-1 mb-0 ml-1">
                                            "{searchKeyword}"
                                        </span>
                                    </li>
                                ) : null}
                            </ul>
                        </InfiniteScroll>
                    </div>
                </div>
            </Popover>
            {editTagPopup()}
        </div>
    );
}

export default connect(null, {
    CommonDispatcher,
    UpdateAddressBookTag,
    GetAddressBookAllTags,
    DetachContactTags,
    AttachContactWithTag,
    DeleteAddressBookTag,
    AttachDetachContactTags
})(TagsPopup);
