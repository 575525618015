import React from 'react';
import style from './ImageList.module.scss';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Delete';
import imageNotFound from './../../resources/image-not-found.webp';

const ImageComponent = ({ image, deleteImage }) => (
    <Grid
        item
        xs={6}
        sm={3}
        md={3}
        lg={3}
        alignItems="center"
        className={`mx-5 ${style.imageListGrid}`}
        id={image.id}
        draggable={true}
    >
        <img
            className={`object-fit-cover ${style.imageListItem}`}
            src={image.src}
            alt={`img-${image.src}`}
            width={100}
            height={100}
            draggable={false}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = imageNotFound;
            }}
        />
        <ClearIcon
            className={style.clearIcon}
            onClick={(e) => {
                e.stopPropagation();
                deleteImage(image.id);
            }}
        />
        <Tooltip
            title={image.name}
            aria-label={image.name}
            placement="bottom"
            arrow={true}
        >
            <Typography
                noWrap={true}
                className={style.imageName}
                draggable={false}
            >
                {image.name}
            </Typography>
        </Tooltip>
    </Grid>
);

const ImageList = ({ images, deleteImage }) => {
    let dragEl, nextEl, newPos;
    const section = document.getElementById('mainDragSection');

    const onDragStart = (e) => {
        [...images].map((d) => {
            const childElements = document.getElementById(d.id);
            childElements.draggable = true;
            return document.getElementById(d.id).getBoundingClientRect();
        });
        console.log(e.target);
        dragEl = e.target;
        console.log(dragEl.nextSibling);
        nextEl = dragEl.nextSibling;
        /* dragGhost = dragEl.cloneNode(true);
        dragGhost.classList.add('hidden-drag-ghost'); */

        /*  document.body.appendChild(dragGhost);
         e.dataTransfer.setDragImage(dragGhost, 0, 0); */

        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('Text', dragEl.textContent);
        console.log(e);
        section.addEventListener('dragover', _onDragOver, false);
        section.addEventListener('dragend', _onDragEnd, false);

        setTimeout(function () {
            dragEl.classList.add('ghost');
        }, 0);
    };

    const _onDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';

        const target = e.target;
        if (target && target !== dragEl && target.nodeName === 'DIV') {
            //getBoundinClientRect contains location-info about the element (relative to the viewport)
            const targetPos = target.getBoundingClientRect();
            //checking that dragEl is dragged over half the target y-axis or x-axis. (therefor the .5)
            const next =
                (e.clientY - targetPos.top) /
                    (targetPos.bottom - targetPos.top) >
                    0.5 ||
                (e.clientX - targetPos.left) /
                    (targetPos.right - targetPos.left) >
                    0.5;
            section.insertBefore(
                dragEl,
                next && (target.nextSibling || target)
            );

            /*  console.log("oldPos:" + JSON.stringify(oldPos));
             console.log("newPos:" + JSON.stringify(newPos)); */
            /* console.log(newPos.top === oldPos.top ? 'They are the same' : 'Not the same'); */
        }
    };

    const _onDragEnd = (e) => {
        e.preventDefault();
        newPos = [...images].map((d) => {
            return document.getElementById(d.id).getBoundingClientRect();
        });
        console.log(newPos);
        dragEl.classList.remove('ghost');
        section.removeEventListener('dragover', _onDragOver, false);
        section.removeEventListener('dragend', _onDragEnd, false);

        return nextEl !== dragEl.nextSibling ? onUpdate(dragEl) : false;
    };

    const onUpdate = (e) => {
        console.log('CB', e);
    };

    const renderImage = (image, i) => (
        <ImageComponent
            key={`${i}-${image.src}-image`}
            deleteImage={deleteImage}
            image={image}
        />
    );
    return (
        <section
            className={style.imageListContainer}
            id="mainDragSection"
            onDragStart={onDragStart}
        >
            {images.map(renderImage)}
        </section>
    );
};
export default ImageList;
