import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect, useSelector } from 'react-redux';
import { OpenModal } from '../../../redux/Common/action';
import { RootState } from '../../../redux/store';
import { BROAD_CAST_USERS_MODAL } from '../../../redux/ModalReducer/actionTypes';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GetBroadCastUserList } from '../../../redux/Users/actions';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '46ch',
        backgroundColor: theme.palette.background.paper
    },
    table: {
        minWidth: 650
    },
    inline: {
        display: 'inline'
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const BroadCastUserModal = ({ OpenModal, GetBroadCastUserList }) => {
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const modal: any =
        useSelector((store: RootState) => store?.modal?.broadCastUserModal) ||
        {};
    const [broadCastData, setBroadCast] = useState<any>({});
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);

    const handleClose = () => {
        OpenModal(BROAD_CAST_USERS_MODAL, { isOpen: false });
    };

    const fetchData = async (page_number) => {
        const resp: any = await GetBroadCastUserList(
            token,
            modal?.data?.broadcast_id,
            page_number + 1
        );
        if (resp?.type === 'success') {
            setBroadCast(resp?.response);
        }
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, rowsPerPage]);

    const classes = useStyles();

    return (
        <Dialog
            className="w-100"
            maxWidth={'sm'}
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={handleClose}>
                <div className="w-100 d-flex">{modal?.title}</div>
            </DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-label="broadcast user table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Join Date</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {broadCastData?.users &&
                            broadCastData.users.length > 0 ? (
                                broadCastData.users.map((user) => (
                                    <TableRow key={user.user_id}>
                                        <TableCell>
                                            {new Date(
                                                user.createdAt
                                            ).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>{user.full_name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        No users found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[30, 50, 75]}
                    component="div"
                    count={broadCastData?.count || 0}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={(event, newPage) => setCurrentPage(newPage)}
                    onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value, 10));
                        setCurrentPage(0); // Reset to first page when changing rows per page
                    }}
                />
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button onClick={handleClose} color="primary" className="mb-4">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    GetBroadCastUserList
})(BroadCastUserModal);
