import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect, useSelector } from 'react-redux';
import { OpenModal } from '../../../redux/Common/action';
import { SESSIONS_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import UsersSessions from '../UsersSessions';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const SessionsModal = ({ OpenModal }) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.sessionsModal) || {};

    const handleClose = () => {
        OpenModal(SESSIONS_MODAL, { isOpen: false });
    };

    return (
        <Dialog
            className="w-100"
            maxWidth={'lg'}
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                <div className="w-100 d-flex">{modal?.title}</div>
            </DialogTitle>
            <DialogContent>
                <div className="d-flex flex-direction-xs-column">
                    <UsersSessions
                        user_id={modal?.data?.selectedUser?.user_id}
                        isAdmin={modal?.data?.isAdmin}
                    />
                </div>
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button onClick={handleClose} color="primary" className="mb-4">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal
})(SessionsModal);
