import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    DialogTitle as MuiDialogTitle,
    Theme,
    Typography,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Controller, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';

import PhoneInput from 'react-phone-input-2';
import { UpdateClient } from '../../../redux/ClientConfig/actions';
import { CommonDispatcher, OpenModal } from '../../../redux/Common/action';
import { ADD_SUPPORT_USER_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { RootState } from '../../../redux/store';
import { PHONE_PREFERRED_COUNTRIES } from '../../../utils/constants';
import RenderTextField from '../../InputComponents/TextField/RenderTextField';
import style from '../AddEditClient/Style.module.scss';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500]
        },
        titleMargin: {
            marginRight: `1.25rem !important`,
            paddingRight: `1.25rem !important`
        }
    });
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle
            disableTypography
            className={`text-center ${classes.root}`}
            {...other}
        >
            <Typography variant="h6" className={classes.titleMargin}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const AddSupportModal = ({
    OpenModal,
    CommonDispatcher,
    match,
    UpdateClient
}) => {
    const modal: any =
        useSelector((store: RootState) => store?.modal?.addSuportUserModal) ||
        {};
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const {
        params: { client_id }
    } = match;
    const { control, getValues, reset } = useForm({
        mode: 'onChange',
        shouldUnregister: false
    });
    const client = modal?.data;
    console.log(`🚀 ~ client:`, client);
    const handleClose = () => {
        OpenModal(ADD_SUPPORT_USER_MODAL, { isOpen: false });
    };

    const handleUpdate = () => {
        submitClientData();
    };

    const submitClientData = () => {
        const data = getValues();
        setIsUpdateLoading(true);

        if (client_id) {
            UpdateClient(token, {
                client_id,
                ...data
            }).then((r) => {
                if (r?.type === 'success') {
                    setIsUpdateLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Details updated successfully.',
                        hideAlert: true
                    });
                    handleClose();
                } else {
                    setIsUpdateLoading(false);
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Failed to update details.',
                        hideAlert: true,
                        error: true
                    });
                }
            });
        }
    };

    return (
        <Dialog
            className="w-100"
            maxWidth="xs"
            fullWidth={modal?.config?.fullWidth || true}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={() => handleClose()}>
                <div className="w-100 d-flex">{modal?.title}</div>
            </DialogTitle>
            <DialogContent>
                <form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="mt-2">
                            <Controller
                                name="support_name"
                                control={control}
                                as={
                                    <RenderTextField
                                        label="Name"
                                        autoFocus={true}
                                        placeholder="Enter name"
                                    />
                                }
                                defaultValue={client?.support_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="support_email"
                                control={control}
                                as={
                                    <RenderTextField
                                        label="Email"
                                        autoFocus={true}
                                        placeholder="Enter email"
                                    />
                                }
                                defaultValue={client?.support_email}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <FormControl
                            variant="outlined"
                            className="w-100"
                            inputMode="tel"
                        >
                            <label>
                                Phone
                                <Controller
                                    name="support_phoneno"
                                    as={
                                        <PhoneInput
                                            country={
                                                PHONE_PREFERRED_COUNTRIES[0]
                                            }
                                            placeholder="Phone"
                                            inputClass={style.phoneInput}
                                            preferredCountries={
                                                PHONE_PREFERRED_COUNTRIES
                                            }
                                            preserveOrder={[
                                                'onlyCountries',
                                                'preferredCountries'
                                            ]}
                                        />
                                    }
                                    control={control}
                                    defaultValue={client?.support_phoneno}
                                />
                            </label>
                        </FormControl>
                    </Grid>
                    <Typography variant="body1" className="mt-3">
                        Public
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="mt-2">
                            <Controller
                                name="support_public_name"
                                control={control}
                                as={
                                    <RenderTextField
                                        label="Public Name"
                                        autoFocus={true}
                                        placeholder="Enter name"
                                    />
                                }
                                defaultValue={client?.support_public_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="support_public_email"
                                control={control}
                                as={
                                    <RenderTextField
                                        label="Public Email"
                                        autoFocus={true}
                                        placeholder="Enter email"
                                    />
                                }
                                defaultValue={client?.support_public_email}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="mt-2">
                        <FormControl
                            variant="outlined"
                            className="w-100"
                            inputMode="tel"
                        >
                            <label>
                                Phone
                                <Controller
                                    name="support_public_phoneno"
                                    as={
                                        <PhoneInput
                                            country={
                                                PHONE_PREFERRED_COUNTRIES[0]
                                            }
                                            placeholder="Public Phone"
                                            inputClass={style.phoneInput}
                                            preferredCountries={
                                                PHONE_PREFERRED_COUNTRIES
                                            }
                                            preserveOrder={[
                                                'onlyCountries',
                                                'preferredCountries'
                                            ]}
                                        />
                                    }
                                    control={control}
                                    defaultValue={
                                        client?.support_public_phoneno
                                    }
                                />
                            </label>
                        </FormControl>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className="mt-3">
                            <Controller
                                name="powered_by_text"
                                control={control}
                                as={
                                    <RenderTextField
                                        label="Powered By Name"
                                        autoFocus={true}
                                        placeholder="Enter powered by name"
                                    />
                                }
                                defaultValue={client?.powered_by_text}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="powered_by_url"
                                control={control}
                                as={
                                    <RenderTextField
                                        label="Powered By Link"
                                        autoFocus={true}
                                        placeholder="Enter powered by link"
                                    />
                                }
                                defaultValue={client?.powered_by_url}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button
                    onClick={handleUpdate}
                    variant="contained"
                    color="primary"
                    className="mb-4"
                >
                    Update
                    {isUpdateLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress"
                        />
                    ) : null}
                </Button>
                <Button
                    onClick={handleClose}
                    color="secondary"
                    className="mb-4"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    OpenModal,
    CommonDispatcher,
    UpdateClient
})(AddSupportModal);
