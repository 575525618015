import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import {
    CancelSubscription,
    GetSubscriptions,
    UpdateSubscription
} from '../../redux/Subscriptions/actions';
import { connect, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from '../../redux/store';
import { Button, Chip, Table } from '@material-ui/core';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants';
import { SHOW_SNACKBAR } from '../../redux/Common/actionTypes';
import { CommonDispatcher, OpenModal } from '../../redux/Common/action';
import { OPEN_EXTEND_SUBSCRIPTIONS_MODAL } from '../../redux/ModalReducer/actionTypes';
import { ENV } from '../../utils/constants';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import style from './Styles.module.scss';
import MenuItem from '@material-ui/core/MenuItem';
import TableHeaderFilter from '../Common/TableHeaderFilter';

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%'
    },
    container: {
        maxHeight: 'calc( 100vh - 220px)',
        minHeight: 'calc( 100vh - 220px)'
    }
});

const options = [
    { title: 'Profile', value: 'profile' },
    { title: 'Meet People Profile', value: 'meet_people_profile' }
];

const ITEM_HEIGHT = 48;

const Subscriptions = ({
    GetSubscriptions,
    UpdateSubscription,
    CancelSubscription,
    CommonDispatcher,
    OpenModal
}) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const tableHeader = useSelector(
        (store: RootState) => store?.subscriptions.tableHeader || []
    );
    const listingsData = useSelector(
        (store: RootState) => store?.subscriptions.listingsData
    );
    const [page, setPage] = useState((listingsData?.current_page || 1) - 1);
    const [selectedUser, setSelectedUser] = useState({});
    const rowsPerPage = listingsData?.page_size;
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>, user) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    useEffect(() => {
        const index = history?.location?.search.indexOf('=');
        const query = new URLSearchParams(history?.location?.search);
        const keyword = query.get('keyword');
        /*if (keyword) {
            getData({keyword});
        } else {
            const pageNo = parseInt(history?.location?.search.substr(index + 1));
            if (pageNo) {
                getData({page_number: pageNo});
                setPage(pageNo - 1);
            } else {
                getData({page_number: 1});
            }
        }*/
        // eslint-disable-next-line
    }, [history?.location?.search]);

    const getData = (data) => {
        setIsLoading(true);
        GetSubscriptions(token, data).then(() => {
            setIsLoading(false);
        });
    };

    const appendPageNumber = (pageNumber) => {
        history.push({
            pathname: Routes.subscriptions,
            search: `?page=${pageNumber}`
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        const updatedPage = newPage + 1;
        setPage(newPage);
        appendPageNumber(updatedPage);
    };

    const renderSubscriptions = () => {
        GetSubscriptions(token, listingsData?.current_page).then(() => {
            OpenModal(OPEN_EXTEND_SUBSCRIPTIONS_MODAL, {
                isOpen: false,
                isLoading: false
            });
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: 'Subscription updated successfully'
            });
        });
    };

    const handleExtend = (subscription_id, expiry_date) => {
        OpenModal(OPEN_EXTEND_SUBSCRIPTIONS_MODAL, {
            isOpen: true,
            title: 'Expiry date',
            submitTitle: 'Update',
            data: { modalSize: 'xs', expiry_date },
            callBack: (flag, expiry_date) => {
                if (flag) {
                    UpdateSubscription(token, {
                        subscription_id,
                        expiry_date
                    }).then(() => {
                        renderSubscriptions();
                    });
                }
            }
        });
    };

    const handleCancel = (subscription_id, user_id) => {
        if (
            window?.confirm(
                'Are you sure you want to cancel this subscription?'
            )
        ) {
            CancelSubscription(token, { subscription_id, user_id })
                .then(() => {
                    renderSubscriptions();
                })
                .catch(() => {
                    alert('Something went wrong.');
                });
        }
    };

    const renderMenu = (row) => {
        const { user_id, user } = row;
        return (
            <>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(e) =>
                        handleClick(e, {
                            user_id,
                            user_name: user?.user_name || null
                        })
                    }
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    classes={{ paper: `${style.menu} border2px w-auto` }}
                    elevation={0}
                    PaperProps={{
                        style: {
                            // maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            border: '1px solid rgb(0 106 194 / 20%)'
                        }
                    }}
                >
                    {options.map((option, i) => (
                        <MenuItem
                            key={option?.value}
                            onClick={() => {
                                handleChange(option?.value, selectedUser);
                                setAnchorEl(null);
                            }}
                        >
                            {option?.title}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        );
    };

    const handleChange = (value, selectedUser) => {
        switch (value) {
            case 'profile':
                if (selectedUser?.user_name) {
                    const url =
                        ENV === 'DEVELOPMENT'
                            ? `https://www.timexyz.com/${selectedUser?.user_name}`
                            : `https://www.luxembourgexpats.lu/${selectedUser?.user_name}`;
                    window.open(url, '_blank');
                } else {
                    alert('User details not available.');
                }
                break;
        }
    };

    return (
        <Grid container spacing={0} className="h-100">
            <Grid item xs={12}>
                <Paper className={classes.root}>
                    <TableHeaderFilter sectionBaseUrl={Routes.subscriptions} />
                    {isLoading ? (
                        <div className="mainLoader">
                            <div className="loader" />
                        </div>
                    ) : (
                        <>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {tableHeader?.map((column) => (
                                                <TableCell key={column.id}>
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listingsData?.posts.length ? (
                                            listingsData?.posts?.map((row) => {
                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={
                                                            row?.subscription_id
                                                        }
                                                        style={{
                                                            padding: 0,
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <TableCell
                                                            size={'small'}
                                                            onClick={(e) =>
                                                                e.stopPropagation()
                                                            }
                                                        >
                                                            {renderMenu(row)}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                minWidth:
                                                                    '100px'
                                                            }}
                                                        >
                                                            {moment(
                                                                row?.start_date
                                                            ).format(
                                                                'DD MMM, YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                minWidth:
                                                                    '100px'
                                                            }}
                                                        >
                                                            {row?.expiry_date
                                                                ? moment(
                                                                      row?.expiry_date
                                                                  ).format(
                                                                      'DD MMM, YYYY'
                                                                  )
                                                                : 'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.duration ||
                                                                'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.user
                                                                ?.full_name ||
                                                                'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.user?.email ||
                                                                'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.user
                                                                ?.user_name
                                                                ? `@${row?.user?.user_name}`
                                                                : 'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.type || 'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.description ||
                                                                'N/A'}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                minWidth:
                                                                    '300px'
                                                            }}
                                                        >
                                                            <div className="d-flex align-items-flex-start flex-direction-column my-3">
                                                                <Chip
                                                                    size="small"
                                                                    label={
                                                                        row?.status
                                                                    }
                                                                    className="mb-2"
                                                                    color={
                                                                        row?.status ===
                                                                        'ACTIVE'
                                                                            ? 'primary'
                                                                            : row?.status ===
                                                                              'EXPIRED'
                                                                            ? 'secondary'
                                                                            : 'default'
                                                                    }
                                                                />
                                                                {row?.status ===
                                                                'ACTIVE'
                                                                    ? 'Subscription will auto-renew for the same duration.'
                                                                    : ''}
                                                                {row?.status ===
                                                                'EXPIRED'
                                                                    ? 'Premium Subscription has expired. Extend/Upgrade for premium benefits.'
                                                                    : ''}
                                                                {row?.status ===
                                                                'CANCELLED'
                                                                    ? 'Auto renewal is cancelled. This subscription will end on the expiry date.'
                                                                    : ''}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                minWidth:
                                                                    '200px'
                                                            }}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <Button
                                                                    color={
                                                                        'secondary'
                                                                    }
                                                                    onClick={() =>
                                                                        handleExtend(
                                                                            row?.subscription_id,
                                                                            row?.expiry_date
                                                                        )
                                                                    }
                                                                >
                                                                    Expiry date
                                                                </Button>
                                                                {row?.status ===
                                                                'ACTIVE' ? (
                                                                    <Button
                                                                        color={
                                                                            'primary'
                                                                        }
                                                                        className="ml-3"
                                                                        onClick={() => {
                                                                            handleCancel(
                                                                                row?.subscription_id,
                                                                                row?.user_id
                                                                            );
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                ) : null}
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        ) : (
                                            <TableRow
                                                style={{
                                                    padding: 0,
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <TableCell
                                                    colSpan={
                                                        tableHeader.length + 1
                                                    }
                                                    className="dataNotAvailable text-center"
                                                >
                                                    No Subscription history to
                                                    show
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                hidden={!listingsData?.posts.length}
                                rowsPerPageOptions={[]}
                                component="div"
                                count={listingsData?.count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </>
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};

export default connect(null, {
    GetSubscriptions,
    UpdateSubscription,
    CancelSubscription,
    CommonDispatcher,
    OpenModal
})(Subscriptions);
