export const SOFT_PROFILE_STATUS = [
    { title: 'Y', value: true, disabled: false },
    { title: 'N', value: false, disabled: false }
];
const WebsiteURLWrapper = ({ children, website }) => {
    return (
        <a
            href={
                ['https', 'http'].includes(website)
                    ? website
                    : `https://${website}` || '-'
            }
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    );
};
export default WebsiteURLWrapper;
