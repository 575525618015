import React, { useState } from 'react';
import {
    Button,
    CircularProgress,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../redux/store';
import { OPEN_EXTEND_CLOSING_DATE_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { CommonDispatcher } from '../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../redux/Common/actionTypes';
import RenderDatePicker from '../../InputComponents/DatePickerComponent/RenderDatePicker';
import { GetSingleDeal, UpdateDeal } from '../../../redux/Deals/actions';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(() =>
    createStyles({
        dialogContainer: { maxWidth: '400px' }
    })
);

const ExtendClosingDateModal = ({
    match,
    CommonDispatcher,
    UpdateDeal,
    GetSingleDeal
}: any) => {
    const classes = useStyles();
    const modal: any =
        useSelector(
            (store: RootState) => store?.modal?.extendClosingDateModal
        ) || {};
    const [isLoading, setLoading] = useState(false);
    const [closing_date, setClosingDate] = useState(moment().add(1, 'months'));
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];

    const handleClose = () => {
        CommonDispatcher(OPEN_EXTEND_CLOSING_DATE_MODAL, { isOpen: false });
    };

    const handleExpiryDate = (date) => {
        setClosingDate(moment(date));
    };

    const handleSubmit = async () => {
        const expiry_date = closing_date
            ? moment(closing_date).format('YYYY-MM-DD')
            : null;
        setLoading(true);
        const equity_id = modal?.data?.equity_id;
        UpdateDeal({ equity_id, expiry_date }).then((r) => {
            if (r?.type === 'success') {
                GetSingleDeal(token, { equity_id }).then(() => {
                    setLoading(false);
                    handleClose();
                    CommonDispatcher(SHOW_SNACKBAR, {
                        isShowing: true,
                        message: 'Closing date extended successfully.',
                        hideAlert: true
                    });
                });
            } else {
                setLoading(false);
                CommonDispatcher(SHOW_SNACKBAR, {
                    isShowing: true,
                    message: 'Failed to extend closing date.',
                    hideAlert: true,
                    error: true
                });
            }
        });
    };
    return (
        <Dialog
            maxWidth={modal?.data?.modalSize || 'sm'}
            fullWidth={modal?.data?.fullWidth || true}
            onClose={handleClose}
            open={modal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={modal?.data?.disableBackdropClick || false}
            classes={{ paperScrollPaper: classes.dialogContainer }}
        >
            <DialogTitle id="alert-dialog-title">{modal?.title}</DialogTitle>
            <DialogContent className="mb-5">
                <RenderDatePicker
                    id="closing_date"
                    shrink={true}
                    value={closing_date}
                    showInputLabel={true}
                    disablePast={true}
                    minDate={moment()}
                    onChange={(d) => handleExpiryDate(d)}
                    label="Select Closing Date"
                    format="DD-MM-YYYY"
                />
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center w-100">
                <Button onClick={handleClose} color="primary" autoFocus>
                    {modal?.cancelTitle || 'Cancel'}
                </Button>
                <Button
                    onClick={handleSubmit}
                    className="ml-3"
                    color="primary"
                    variant="contained"
                    disabled={isLoading || !closing_date}
                >
                    {isLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress-primary ml-0"
                        />
                    ) : (
                        modal?.submitTitle || 'Ok'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, {
    CommonDispatcher,
    UpdateDeal,
    GetSingleDeal
})(ExtendClosingDateModal);
