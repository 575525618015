import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { RootState } from '../../redux/store';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import AllListingsComponent from './AllListings';
import { Routes } from '../../constants';
import { useMediaQuery } from '@material-ui/core';
import { GetClientConfigList } from '../../redux/ClientConfig/actions';
import { getQuery } from '../../utils/common';
import { OPEN_ALERT_MODAL } from '../../redux/ModalReducer/actionTypes';
import { OpenModal } from '../../redux/Common/action';

const ClientConfig = ({ GetClientConfigList, OpenModal }) => {
    const addClient =
        useSelector((store: RootState) => store?.modal?.addClient) || {};
    const history = useHistory();
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const allClientConfigList = useSelector(
        (store: RootState) => store?.clientConfig.allClientConfigList
    );
    const query = getQuery(history);
    const type = query.get('type') || '';
    const keyword = query.get('keyword');

    useEffect(() => {
        if (keyword || type || type === '') {
            setIsLoading(true);
            getData({ page: 1 });
            getData({
                keyword,
                type
            });
        }
        // eslint-disable-next-line
    }, [keyword, type]);

    const getAllTabsData = (data) => {
        if (!data?.tab) {
            getData(data, false);
        }
    };

    const getData = (data, isLoading = true) => {
        setIsLoading(isLoading);
        GetClientConfigList(token, data).then(() => {
            setIsLoading(false);
            OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
        });
    };

    return (
        <>
            {/*{addClient ? <AddClient /> : null}*/}
            <AllListingsComponent
                sectionBaseUrl={Routes.clientConfig.index}
                loading={isLoading}
                getAllTabsData={getAllTabsData}
            />
        </>
    );
};
export default connect(null, { GetClientConfigList, OpenModal })(ClientConfig);
