import {
    Button,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect, useSelector } from 'react-redux';
import useStateRef from 'react-usestateref';
import {
    CommonDispatcher,
    UploadImages
} from '../../../../redux/Common/action';
import { SHOW_SNACKBAR } from '../../../../redux/Common/actionTypes';
import {
    SET_FUND_EQUITY_DEAL_DATA,
    UPDATE_FUNDS_WIZARD_FORM_DATA
} from '../../../../redux/FundDeals/actionTypes';
import { UpdateDeal } from '../../../../redux/FundDeals/actions';
import { RootState } from '../../../../redux/store';
import { onDropCallback } from '../../../../utils/common';
import { WEBSITE_PLACEHOLDER } from '../../../../utils/constants';
import DragAndDrop from '../../../DragAndDrop';
import RenderAutocompleteTextField from '../../../InputComponents/AutocompleteTextField';
import RenderSelectField from '../../../InputComponents/SelectField/RenderSelectField';
import RenderTextField from '../../../InputComponents/TextField/RenderTextField';
import { UpdatePost } from '../Common';
import style from './Style.module.scss';

import EditorComponent from '../../../InputComponents/Editor';
import { useCookies } from 'react-cookie';

const StepOneComponent = ({
    equity_id: post_id,
    CommonDispatcher,
    UpdateDeal,
    UploadImages
}: any) => {
    const state = useSelector((store: RootState) => store);
    const activeStep = state?.FundDeals?.wizard?.currentStep || 0;
    const deal_data = state?.FundDeals?.deal_data || {};

    const deal_data_error = state?.FundDeals?.deal_data_error || {};
    const companyFoundedList = state?.commonAppData?.foundedList || [];
    const investingAssetClass = state?.commonAppData?.investingAssetClass || [];

    const [errors, setError, errorRef] = useStateRef({
        ...deal_data_error[activeStep]
    }) as [any, any, any];
    const { control, reset, getValues, setValue } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        shouldUnregister: false
    });
    const [cookies] = useCookies(['pgAdminToken']);
    const token = cookies['pgAdminToken'];
    const MAX_FILES = 1;
    const blankDescription = '<p><br></p>';
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [company_name, setCompanyName] = useState('');
    const [website, setWebsite] = useState('');
    const [foundation_year, setCompanyFounded] = useState();

    const [isLoading, setLoading] = useState(false);
    const steps = state?.FundDeals?.wizard?.formSteps || [];
    const errorStep = state?.FundDeals?.wizard?.errorStep || [];
    const [description, setDescription] = useState(null);
    const [investorType, setInvestorType] = useState([]);
    const [investorTypeList, setInvestorTypeList] = useState();

    const [isActive, setIsActive] = useState(false);
    const [images, setImages, imagesRef] = useStateRef([]);
    const [progress, setProgress, progressRef] = useStateRef(0);
    const fieldsToValidate = [
        'company_logo',
        'company_name',
        'website',
        'investing_asset_class',
        'foundation_year',
        'description'
    ];

    const [startupType, setStartupType] = useState([]);
    const [startupTypeList, setStartupTypeList] = useState([]);

    const investorTypeListArray =
        useSelector(
            (store: RootState) => store?.commonAppData?.investMentTypeMaster
        ) || [];

    useEffect(() => {
        setInvestorTypeList(investorTypeListArray);
    }, [investorTypeListArray]);
    useEffect(() => {
        const metaDataInvestingAssetClass = investingAssetClass
            .map((d) => ({
                title: d?.name || d?.label || d?.title,
                value: d?.id || d?.value
            }))
            .sort((a, b) => a?.value - b?.value);

        setStartupTypeList(
            metaDataInvestingAssetClass && metaDataInvestingAssetClass.length
                ? metaDataInvestingAssetClass
                : []
        );

        let obj: any = {};
        obj.equity_id = deal_data?.equity_id;
        obj.company_logo = deal_data?.company_logo;

        Object.entries(deal_data).forEach(([k]) => {
            if (fieldsToValidate.includes(k)) {
                obj[k] = deal_data[k];
            }
        });

        if (obj.investing_asset_class && obj.investing_asset_class.length) {
            obj.investing_asset_class = [
                ...metaDataInvestingAssetClass.filter((d) =>
                    obj.investing_asset_class.includes(d?.value || d?.id)
                )
            ];

            setStartupType(obj.investing_asset_class);
        }

        if (post_id) {
            obj?.company_logo &&
                setImages(
                    [obj?.company_logo].map((d, i) => ({ id: i + 1, src: d }))
                );
        } else {
            obj?.company_logo && setImages(deal_data?.images);
        }
        setDescription(obj?.description);

        reset(obj);
    }, [deal_data]);

    useEffect(() => {
        if (activeStep > 0) setWizardError();
    }, [errorStep]);

    const next = () => {
        if (activeStep < steps?.length) {
            CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
                currentStep: activeStep + 1
            });
        }
    };

    const setWizardError = () => {
        let ref = errorRef?.current;
        const errItemsCount = Object.keys(ref).some((d) => ref[d]);
        let errorStepObj = { ...errorStep, [activeStep]: errItemsCount };
        CommonDispatcher(UPDATE_FUNDS_WIZARD_FORM_DATA, {
            errorStep: errorStepObj
        });
    };

    const getData = (data) => {
        return data && data.length ? data.map((d) => d?.value) : [];
    };

    const handleDescriptionChange = (value: any) => {
        const isBlank = value === blankDescription;
        if (isBlank) {
            setValue('description', null);
        }
        setDescription(!isBlank ? value : null);
    };

    const handleStartupTypeChange = (value) => {
        setStartupType(value);
    };

    const onSave = (e = null) => {
        e && e.preventDefault();
        let data: any = getFormValues(getValues());
        data.equity_id = post_id || deal_data?.equity_id;
        data.description = description;

        if (data?.equity_id) {
            setLoading(true);
            UpdatePost(
                (data) => UpdateDeal(token, data),
                CommonDispatcher,
                data,
                UploadImages,
                setLoading,
                next,
                setWizardError,
                true,
                false,
                token
            );
        }
    };

    const getFormValues = (obj) => {
        obj.investing_asset_class =
            obj?.investing_asset_class && obj?.investing_asset_class?.length
                ? getData(obj?.investing_asset_class)
                : [];

        if (
            (images && images?.length) ||
            (imagesRef?.current && imagesRef?.current.length)
        ) {
            obj.images = images;
            obj.company_logo = images[0]?.src;
        } else {
            obj.images = [];
            obj.company_logo = null;
        }
        let data = {};
        Object.entries(obj).forEach(([key, value]) => {
            if (!value) {
                value = null;
            }
            setError({ ...errorRef.current, [key]: !value });
            data[key] = value;
        });
        return data;
    };

    const handleDeleteImage = (id) => {
        let data: any = getFormValues(getValues());
        if (id) {
            const restImages = images.filter((d) => d.id !== id);
            setImages(restImages);
            setValue('company_logo', null);
            data.company_logo = null;
            data.images = null;
            CommonDispatcher(SET_FUND_EQUITY_DEAL_DATA, data);
        }
    };

    const onDrop = useCallback(async (files) => {
        if (!(imagesRef?.current && imagesRef?.current?.length)) {
            onDropCallback(
                files,
                imagesRef,
                setProgress,
                progressRef,
                setIsActive,
                setImages
            );
        } else {
            CommonDispatcher(SHOW_SNACKBAR, {
                isShowing: true,
                message: `Only ${MAX_FILES} image is allowed to upload`,
                hideAlert: true,
                error: true
            });
        }
    }, []);
    const handleInvestorTypeChange = (value) => {
        setInvestorType(value);
    };
    return (
        <Container maxWidth="md">
            <Grid item xs={12} sm={10} className="m-auto pb-5">
                <div className="">
                    <form>
                        <div data-aos="fade-left" data-aos-duration="200">
                            <Grid
                                container
                                spacing={2}
                                className="mb-5 w-50 w-sm-100 mx-auto"
                            >
                                <Grid item xs={12} className="py-1">
                                    <Controller
                                        name="company_logo"
                                        as={
                                            <DragAndDrop
                                                onDrop={onDrop}
                                                accept={
                                                    'image/png, image/jpg, image/jpeg'
                                                }
                                                images={images}
                                                isLoading={isActive}
                                                deleteImage={handleDeleteImage}
                                                maxFiles={MAX_FILES}
                                                multiple={false}
                                                label={`Upload logo (only ${MAX_FILES}) *`}
                                            />
                                        }
                                        control={control}
                                        defaultValue={''}
                                        rules={{
                                            required: false
                                        }}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.company_logo ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.company_logo &&
                                            'Company Logo is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="company_name"
                                        as={
                                            <RenderTextField
                                                id="company_name"
                                                value={company_name}
                                                autoComplete="off"
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Fund name *"
                                            />
                                        }
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.company_name ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.company_name &&
                                            'Company name is mandatory'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="website"
                                        as={
                                            <RenderTextField
                                                id="website"
                                                value={website}
                                                autoComplete="off"
                                                placeholder={
                                                    WEBSITE_PLACEHOLDER
                                                }
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                label="Website *"
                                            />
                                        }
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.website ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.website &&
                                            'Website is mandatory'}
                                        {errors?.isWebsiteInValid &&
                                            'Invalid website url'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="investing_asset_class"
                                        render={({ onChange }) => (
                                            <div className="w-100">
                                                <RenderAutocompleteTextField
                                                    id="investing_asset_class"
                                                    value={startupType}
                                                    label="Industry *
                                                    "
                                                    size="small"
                                                    inputAutoComplete="off"
                                                    disableCloseOnSelect={
                                                        !smallScreen
                                                    }
                                                    paperClass={
                                                        style.autocompleteBackground
                                                    }
                                                    options={startupTypeList}
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        handleStartupTypeChange(
                                                            data
                                                        );
                                                    }}
                                                    inputPlaceholder="Search Industry"
                                                />
                                            </div>
                                        )}
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.investing_asset_class
                                                ? 'mb-1'
                                                : ''
                                        }
                                    >
                                        {errors?.investing_asset_class &&
                                            'Startup Type is mandatory'}
                                    </FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={6} className="py-1">
                                    <Controller
                                        name="foundation_year"
                                        as={
                                            <RenderSelectField
                                                id="foundation_year"
                                                label="Firm initial founding year *"
                                                value={foundation_year}
                                                inputContainerClassName={
                                                    'w-100'
                                                }
                                                inputId="select-company-founded"
                                                dropdownListHeight={250}
                                                dataArray={companyFoundedList}
                                            />
                                        }
                                        control={control}
                                        defaultValue={null}
                                    />
                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.foundation_year
                                                ? 'mb-1'
                                                : ''
                                        }
                                    >
                                        {errors?.foundation_year &&
                                            'Founding year is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} className="my-1">
                                <Grid item xs={12} className="py-1">
                                    <EditorComponent
                                        initialValue={description}
                                        placeholder="Enter your description here..."
                                        label="Details about the product/service *"
                                        handleDescriptionChange={
                                            handleDescriptionChange
                                        }
                                    />

                                    <FormHelperText
                                        error={true}
                                        className={
                                            errors?.description ? 'mb-1' : ''
                                        }
                                    >
                                        {errors?.description &&
                                            'Description is mandatory'}
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="mt-5 py-4 border-top mb-sm-12 d-flex align-items-center justify-content-center btn-submit-post w-100">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={onSave}
                                disabled={isLoading || isActive}
                                className="fs-16 text-inherit btn-submit-style mr-5"
                            >
                                Next{' '}
                                {isLoading ? (
                                    <CircularProgress
                                        size="1.3rem"
                                        className="circular-progress"
                                    />
                                ) : null}
                            </Button>
                        </div>
                    </form>
                </div>
            </Grid>
        </Container>
    );
};

export default connect(null, {
    CommonDispatcher,
    UpdateDeal,
    UploadImages
})(StepOneComponent);
