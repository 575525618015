import { Chip } from '@material-ui/core';
import React, { useMemo, useState } from 'react';

const OverflowDataComponent = ({
    data,
    defaultToggle = false,
    showCounter = false,
    limit = 1
}: any) => {
    const [toggle, setToggle] = useState(defaultToggle);

    const handleToggleCountries = () => {
        setToggle(!toggle);
    };

    return useMemo(() => {
        return (
            <div className="d-flex align-items-center flex-wrap">
                {!toggle ? (
                    limit > 1 ? (
                        data.map(
                            (d, i) =>
                                i < limit && (
                                    <Chip
                                        label={d}
                                        title={d}
                                        key={`${d}-${i}`}
                                        size="small"
                                        className="cursor-pointer border"
                                        style={{ margin: '0 8px 8px 0' }}
                                    />
                                )
                        )
                    ) : (
                        <Chip
                            label={data?.[0]}
                            title={data?.[0]}
                            size="small"
                            className="cursor-pointer border"
                            style={{ margin: '0 8px 8px 0' }}
                        />
                    )
                ) : (
                    data.map((d, i) => (
                        <Chip
                            label={d}
                            title={d}
                            key={`${d}-${i}`}
                            size="small"
                            className="cursor-pointer border"
                            style={{ margin: '0 8px 8px 0' }}
                        />
                    ))
                )}
                {!toggle && data.length > 1 ? (
                    <span
                        style={{ marginBottom: '8px' }}
                        className="cursor-pointer colorPrimary font-weight-bold"
                        onClick={!showCounter ? handleToggleCountries : null}
                    >
                        {showCounter
                            ? `+${data.length - 1}`
                            : limit > 1
                            ? data.length - limit
                                ? `+${data.length - limit} more`
                                : ''
                            : `+${data.length - 1} more`}
                    </span>
                ) : null}
            </div>
        );
    }, [toggle]);
};

export default OverflowDataComponent;
