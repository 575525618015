import { sortAllTagsData } from '../../utils/common';
import * as ACTIONS from './actionTypes';
import {
    DELETE_CONTACTS_POSTS_TAGS_DATA,
    SET_ADDRESS_BOOK_TAGS,
    UPDATE_ADDRESS_BOOK_TAGS,
    UPDATE_CONTACTS_POSTS_TAGS_DATA,
    UPDATE_SELECTED_TAG_COUNT,
    UPDATE_SINGLE_CONTACT_TAG
} from './actionTypes';

const initialValues = {
    tableHeader: [
        { id: 'actions', label: '' },
        { id: 'tags', label: 'Tags' },
        { id: 'joining_date', label: 'Joined Date' },
        { id: 'is_active', label: 'Status' },
        { id: 'firm_logo', label: 'Firm Logo' },
        { id: 'label', label: 'Firm Name' },
        { id: 'website', label: 'Website' },
        { id: 'type', label: 'Type' },
        { id: 'members', label: 'Members' }
    ],
    allFirmsList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    activeFirmsList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    inActiveFirmsList: {
        current_page: 0,
        page_size: 0,
        total_pages: 0,
        posts: [],
        count: 0
    },
    firm_tags: {
        tagsData: [],
        total_pages: null,
        current_page: null,
        count: null,
        keyword: null
    },
    firms_counts: {
        allFirmCount: 0,
        activeCount: 0,
        inActiveCount: 0
    }
};

const FirmsReducer = (state = initialValues, action: any) => {
    const payload = action?.payload;

    switch (action.type) {
        case ACTIONS.FIRMS_ALL:
            return {
                ...state,
                allFirmsList: {
                    posts: action?.payload?.firms,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                },
                firms_counts: {
                    ...state.firms_counts,
                    allFirmCount: action?.payload?.count
                }
            };
        case ACTIONS.FIRMS_ACTIVE:
            return {
                ...state,
                allFirmsList: {
                    posts: action?.payload?.firms,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                },
                firms_counts: {
                    ...state.firms_counts,
                    activeCount: action?.payload?.count
                }
            };
        case ACTIONS.FIRMS_IN_ACTIVE:
            return {
                ...state,
                allFirmsList: {
                    posts: action?.payload?.firms,
                    count: action?.payload?.count,
                    current_page: action?.payload?.page,
                    page_size: action?.payload?.size,
                    total_pages: action?.payload?.total_pages
                },
                firms_counts: {
                    ...state.firms_counts,
                    inActiveCount: action?.payload?.count
                }
            };
        case ACTIONS.FIRMS_ACTIVE_COUNT:
            return {
                ...state,
                firms_counts: {
                    ...state.firms_counts,
                    activeCount: action?.payload?.count
                }
            };
        case ACTIONS.FIRMS_INACTIVE_COUNT:
            return {
                ...state,
                firms_counts: {
                    ...state.firms_counts,
                    inActiveCount: action?.payload?.count
                }
            };
        case ACTIONS.FIRMS_ALL_COUNT:
            return {
                ...state,
                firms_counts: {
                    ...state.firms_counts,
                    allFirmCount: action?.payload?.count
                }
            };
        case DELETE_CONTACTS_POSTS_TAGS_DATA:
            state.allFirmsList.posts.map((x) => {
                let obj =
                    x.firm_tags && x.firm_tags.length
                        ? x.firm_tags.filter(
                              (i) => i.id !== action?.payload.tagsId
                          )
                        : {};

                return (x.firm_tags = obj);
            });

            return {
                ...state,
                ...state.allFirmsList
            };
        case UPDATE_ADDRESS_BOOK_TAGS:
            action.payload.tagsData =
                action?.payload?.tagsData && action?.payload?.tagsData.length
                    ? sortAllTagsData(action?.payload?.tagsData)
                    : [];
            return {
                ...state,
                firm_tags: {
                    ...action?.payload,
                    tagsData: [
                        ...state?.firm_tags?.tagsData,
                        ...action?.payload.tagsData
                    ]
                }
            };
        case SET_ADDRESS_BOOK_TAGS:
            action.payload.tagsData =
                action?.payload?.tagsData && action?.payload?.tagsData.length
                    ? sortAllTagsData(action?.payload?.tagsData, true)
                    : [];
            return { ...state, firm_tags: action?.payload };
        case UPDATE_CONTACTS_POSTS_TAGS_DATA:
            state.allFirmsList.posts.map((x) => {
                let tags_obj = [];
                if (!action?.payload.firm_id) {
                    // update tags color and text
                    let obj =
                        x.firm_tags && x.firm_tags.length
                            ? x.firm_tags.find(
                                  (i) => i.id == action?.payload.tagsObj['id']
                              )
                            : {};
                    if (obj) {
                        obj.tag_text =
                            action?.payload.tagsObj['tag_text'] || obj.tag_text;
                        obj.tag_color_code =
                            action?.payload.tagsObj['tag_color_code'] ||
                            obj.tag_color_code;
                    }
                    return obj;
                } else if (x.firm_id === action?.payload.firm_id) {
                    // for create new tages
                    if (action?.payload.tagsObj.type === 'Attach') {
                        tags_obj.push(...action?.payload.tagsObj.tags);
                    } else if (x.firm_tags === null) {
                        tags_obj.push(action?.payload.tagsObj);
                    } else if (action?.payload.tagsObj.type === 'delete') {
                        tags_obj.push(...action?.payload.tagsObj.tags);
                    } else {
                        tags_obj.push(...x.firm_tags, action?.payload.tagsObj);
                    }
                    return (x.firm_tags = tags_obj);
                }
            });
            return {
                ...state,
                ...state.allFirmsList
            };
        case UPDATE_SINGLE_CONTACT_TAG:
            if (payload.type == 'delete') {
                state.firm_tags.tagsData = state?.firm_tags?.tagsData.filter(
                    (i) => i.id !== payload.id
                );
            } else {
                let obj = state?.firm_tags?.tagsData.find(
                    (i) => i.id == payload.id
                );
                obj.tag_text = payload.tag_text;
                obj.tag_color_code = payload.tag_color_code;
            }

            return { ...state };
        case UPDATE_SELECTED_TAG_COUNT:
            const data = state?.firm_tags?.tagsData?.map((x) => {
                if (x?.id === payload?.id) {
                    x.tag_firm_count = payload?.isNew
                        ? x?.tag_firm_count + 1
                        : x?.tag_firm_count - 1;
                }
                return x;
            });
            return {
                ...state,
                firm_tags: {
                    ...payload,
                    tagsData: [...state?.firm_tags?.tagsData, ...data]
                }
            };
        default:
            return state;
    }
};

export default FirmsReducer;
