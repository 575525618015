import {useMediaQuery, useTheme} from "@material-ui/core";
import React from "react";

const RenderDealReadMoreLess = ({description, MAX_LENGTH = 200, handleReadMore, readMore}) => {
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const length = isExtraSmallScreen ? 100 : MAX_LENGTH;
    let descData = description.length > length && !readMore ? `${description.substr(0, length)}...` : description;

    return <>
        <div className="my-3 fs-16"
             dangerouslySetInnerHTML={{__html: descData}}/>
        {description.length > length ? <span onClick={handleReadMore} className="colorBlue cursor-pointer fs-16">
            Read {readMore ? "less" : "more"}
        </span> : null}
    </>;
};

export default RenderDealReadMoreLess;