import React, { useState } from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox
} from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { OPEN_ALERT_MODAL } from '../../../redux/ModalReducer/actionTypes';
import { OpenModal } from '../../../redux/Common/action';

interface IAlertModalProps {
    OpenModal: (type: string, payload: object) => void;
}

const AlertModalComponent = ({ OpenModal }: IAlertModalProps) => {
    const alertModal: any =
        useSelector((store: RootState) => store?.modal?.alertModal) || {};
    const [isLoading, setLoading] = useState(false);
    const [isChecked, setChecked] = useState(
        alertModal?.data?.payload?.send_email
    );

    const handleClose = () => {
        OpenModal(OPEN_ALERT_MODAL, { isOpen: false });
    };
    const handleYes = () => {
        setLoading(true);
        alertModal.callBack();
    };
    const handleToggle = () => () => {
        alertModal.data.payload.send_email = !isChecked;
        setChecked(!isChecked);
    };
    return (
        <Dialog
            maxWidth={alertModal?.data?.modalSize || 'xs'}
            fullWidth={alertModal?.data?.fullWidth || true}
            onClose={handleClose}
            open={alertModal?.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={
                alertModal?.data?.disableBackdropClick || false
            }
        >
            <DialogTitle id="alert-dialog-title">
                {alertModal?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {alertModal?.description}
                    {alertModal?.subDescription ? (
                        <div className="my-4">{alertModal?.subDescription}</div>
                    ) : null}
                </DialogContentText>
                {alertModal.data.payload ? (
                    <div className="d-flex align-items-center fs-12 color-gray ">
                        <Checkbox
                            edge="start"
                            defaultChecked={isChecked}
                            tabIndex={-1}
                            color="primary"
                            disableRipple
                            onChange={handleToggle()}
                        />
                        <span>Send Email Notification to User</span>
                    </div>
                ) : null}
            </DialogContent>
            <DialogActions className="d-flex align-items-center justify-content-center">
                <Button onClick={handleClose} color="primary" autoFocus>
                    {alertModal?.cancelTitle || 'Cancel'}
                </Button>
                <Button
                    onClick={handleYes}
                    className="ml-3"
                    color={alertModal?.isDelete ? 'secondary' : 'primary'}
                    variant="contained"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <CircularProgress
                            size="1.3rem"
                            className="circular-progress-primary ml-0"
                        />
                    ) : (
                        alertModal?.submitTitle || 'Ok'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default connect(null, { OpenModal })(AlertModalComponent);
